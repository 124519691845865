<template>
  <div>
    <cs-spinner v-if="!people"></cs-spinner>
    <div v-if="people" class="ch-request">
      <div v-for="(request, $index) in people" :key="$index">
        <request-item :user="request"></request-item>
      </div>
      <app-infinite-offset :query="$apollo.queries.people" />
    </div>
    <cs-empty-state
      v-if="people && people.length === 0"
      title="Zero Requests"
      description="Hooray, you're all caught up with your friend requests!"
    />
  </div>
</template>

<script>
import ListFriendRequests from '@/gql/connections/ListFriendRequests.gql';
import $auth from '@/services/auth';
import RequestItem from '@/components/menu/requests/RequestItem.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    RequestItem,
    AppInfiniteOffset,
  },
  apollo: {
    people: {
      query: ListFriendRequests,
      variables() {
        return {
          viewerId: $auth.getUserId(),
        };
      },
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.people.refetch();
      return true;
    },
  },
};
</script>
