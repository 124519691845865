<template>
  <div class="ch-page-menu-category">
    <div class="ch-page-menu-category__title cs-textstyle-category-tags" v-if="title">
      {{ title }}
    </div>
    <div class="ch-page-menu-category__options" v-if="$slots.default">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style scoped>
.ch-page-menu-category__title {
  color: var(--cs-gray-05);
  margin-bottom: 2px;
  margin-top: 35px;
}
</style>
