var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meeting-menu"},[_c('app-action-sheet',{attrs:{"open":_vm.showMeetingOptions},on:{"close":function($event){return _vm.$emit('close-meeting-menu')}}},[(
        _vm.meeting.privacy === 'private' && _vm.meeting.status !== 'LIVE' && !_vm.isAdmin
      )?_c('app-action-sheet-button',[_vm._v("Empty action list")]):_vm._e(),(_vm.canShare && !_vm.listView)?_c('app-action-sheet-button',{on:{"click":function($event){return _vm.shareMeeting()}}},[_vm._v("Share Event")]):_vm._e(),(
        _vm.canShare &&
        !_vm.listView &&
        (_vm.meeting.status == 'NOT_STARTED' || _vm.meeting.status == 'LIVE') &&
        _vm.userId
      )?_c('app-action-sheet-button',{on:{"click":_vm.inviteMembers}},[_vm._v(" Invite Members ")]):_vm._e(),(
        _vm.meeting.myMembership &&
        (_vm.meeting.myMembership.role == 'VIEWER' ||
          _vm.meeting.myMembership.role == 'ATTENDING') &&
        _vm.meeting.status == 'LIVE' &&
        _vm.meeting.enableQueue
      )?_c('app-action-sheet-button',{on:{"click":function($event){return _vm.setRole('QUEUE')}}},[_vm._v("✋ Raise Hand")]):_vm._e(),(_vm.isAdmin)?[_c('app-action-sheet-button',{on:{"click":function($event){return _vm.deleteMeeting()}}},[_vm._v(_vm._s(_vm.deleteOrCancel))]),_c('app-action-sheet-button',{on:{"click":_vm.edit}},[_vm._v(" Edit Meeting ")])]:[(_vm.isSuperAdmin)?_c('app-action-sheet-button',{attrs:{"variant":"primary","fill":"outline"},on:{"click":function($event){return _vm.deleteMeeting()}}},[_vm._v(" [SA]"+_vm._s(_vm.deleteOrCancel)+" ")]):_vm._e(),(_vm.canAttend)?_c('app-action-sheet-button',{attrs:{"variant":"primary","fill":"outline"},on:{"click":_vm.signup}},[_vm._v(" Attend ")]):_vm._e(),(
          !_vm.canAttend &&
          _vm.meeting.myMembership &&
          _vm.meeting.myMembership.role === 'ATTENDING'
        )?_c('app-action-sheet-button',{attrs:{"variant":"secondary","fill":"outline"},on:{"click":_vm.unsignup}},[_vm._v("UnAttend")]):_vm._e()],(!_vm.listView)?_c('app-action-sheet-button',{on:{"click":function($event){return _vm.leaveMeeting()}}},[_vm._v("Leave Meeting")]):_vm._e(),(!_vm.isScheduled && _vm.canModerate && _vm.meeting.recordingUrlMp4 !== null)?_c('app-action-sheet-button',{on:{"click":_vm.downloadMeeting}},[_vm._v("Download Meeting")]):_vm._e()],2),(_vm.showShareSheet)?_c('app-meeting-share-sheet',{attrs:{"meeting":_vm.meeting,"show-modal":_vm.showShareSheet},on:{"close":_vm.closeShareSheet}}):_vm._e(),(_vm.showEditModal)?_c('app-edit-meeting-modal',{attrs:{"original-meeting":_vm.editableMeeting,"show-meeting-edit":_vm.showEditModal,"list-view":_vm.listView},on:{"close-edit":_vm.closeEdit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }