<template>
  <div>
    <keep-alive>
      <app-post-list
        :posts="posts"
        list-id="followingPostList"
        empty-description="Follow more people!"
      />
    </keep-alive>
    <app-infinite-simple
      :query="$apollo.queries.posts"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import ListFollowingPosts from '@/gql/posts/ListFollowingPosts.gql';
import AppPostList from '@/components/post/lists/PostList.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppPostList,
    AppInfiniteSimple,
  },
  apollo: {
    posts: {
      query: ListFollowingPosts,
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.posts.refetch();
      return true;
    },
  },
};
</script>
