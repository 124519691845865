<template>
  <div
    v-observe-visibility="{ callback: visibilityChanged }"
    class="video-container"
  >
    <iframe
      v-if="isVisible"
      ref="player"
      :src="playsrc"
      class="video-player"
      allowFullScreen="true"
      allow="autoplay; fullscreen; accelerometer; gyroscope; encrypted-media; picture-in-picture;"
    />
  </div>
</template>

<script>
import $cdn from '@/services/cdn';

export default {
  props: {
    src: {
      type: String,
      required: false,
    },
    poster: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    showAds: {
      type: Boolean,
      default: true,
    },
    streamId: {
      type: String,
      default: null,
    },
    streamStatus: {
      type: String,
      default: null,
    },
    pauseIfHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    transcoded() {
      return (
        this.streamId && ['COMPLETE', 'PLAYABLE'].includes(this.streamStatus)
      );
    },
    embedPoster() {
      let poster = this.poster;
      if (poster) {
        poster = $cdn.optimize(poster);
      }
      if (!poster && this.transcoded) {
        poster = `${process.env.VUE_APP_VIDEO_DOMAIN}/${this.streamId}/thumbnail.jpg`;
      }
      return poster;
    },
    embedSrc() {
      let src = this.src;
      if (src) {
        src = $cdn.optimize(src);
      }
      if (this.transcoded) {
        src = `${process.env.VUE_APP_VIDEO_DOMAIN}/${this.streamId}/playlist.m3u8`;
      }
      return src;
    },
    playsrc() {
      const qs = new URLSearchParams();
      qs.set('src', this.embedSrc);
      if (this.embedPoster) {
        qs.set('poster', this.embedPoster);
      }
      if (this.showAds) {
        // qs.set('ad', '1');
      }
      if (this.autoplay) {
        qs.set('autoplay', '1');
        qs.set('muted', '1');
      }
      return `${process.env.VUE_APP_PLAYER_DOMAIN}/?${qs.toString()}`;
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (this.isVisible && !this.pauseIfHidden) {
        return;
      }

      this.isVisible = isVisible;
    },
  },
};
</script>

<style media="screen">
.video-container {
  height: 230px;
}

.video-player {
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
}
</style>
