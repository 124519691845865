<template>
  <div class="ch-profile__buttons-container">
    <div v-if="loggedInUser" class="ch-profile__buttons-myself">
      <div class="ch-profile__all-counts">
        <!-- Followers -->
        <div
          class="ch-profile__individual-count"
          @click="goToFriendsView(user.id)"
        >
          <div class="cs-textstyle-paragraph-small-bold">
            {{ user.followerCount }}
          </div>
          <div class="cs-textstyle-paragraph-small-bold">Followers</div>
        </div>
        <!-- Following -->
        <div
          class="ch-profile__individual-count"
          @click="goToFriendsView(user.id)"
        >
          <div class="cs-textstyle-paragraph-small-bold">
            {{ user.followingCount }}
          </div>
          <div class="cs-textstyle-paragraph-small-bold">Following</div>
        </div>
        <!-- Friends -->
        <div
          class="ch-profile__individual-count"
          @click="goToFriendsView(user.id)"
        >
          <div class="cs-textstyle-paragraph-small-bold">
            {{ user.friendCount }}
          </div>
          <div class="cs-textstyle-paragraph-small-bold">Friends</div>
        </div>
      </div>
    </div>
    <div v-if="!loggedInUser" class="ch-profile__buttons">
      <div class="ch-profile__buttons-first">
        <app-follow-button
          :target="user"
          size="small"
          class="ch-profile__button-follow"
        ></app-follow-button>
        <app-friend-button
          :target="user"
          size="small"
          class="ch-profile__button-friend"
        ></app-friend-button>
        <div>
          <cs-button
            class="ch-profile__button-message"
            size="small"
            @click="openNewMessageModal"
            ><i class="cs-icons-email ch-profile__cs-email"></i
            >Message</cs-button
          >
        </div>
      </div>
    </div>

    <div
      v-if="user.id === '435004d1-7885-421f-9b48-79af938d69bf'"
      class="ch-profile__buttons"
    >
      <cs-button
        size="small"
        variant="danger"
        class="group-detail-view__action_button"
        href="https://www.supportcleanelections.com"
        target="_blank"
        style="width: 100%; max-width: 335px; margin: 0 auto"
      >
        Report Election Fraud Now
      </cs-button>
    </div>
    <!-- Profile Edit Sheet -->
    <profile-edit-modal
      :user="user"
      :show-profile-edit="showProfileEdit"
      @closeProfileEditModal="closeProfileEdit"
    >
    </profile-edit-modal>
    <!-- Action Sheet -->
    <app-action-sheet :open="showOptionSheet" @close="closeOptionSheet">
      <app-action-sheet-button @click="report"
        >Report <app-username :user="user"
      /></app-action-sheet-button>
      <app-action-sheet-button @click="blockUser"
        >Block <app-username :user="user"
      /></app-action-sheet-button>
    </app-action-sheet>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import GetChannel from '@/gql/videos/GetChannel.gql';
// Components
import ProfileEditModal from '@/components/connections/EditProfile.vue';
import AppFriendButton from '@/components/general/FriendButton.vue';
import AppFollowButton from '@/components/general/FollowButton.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    ProfileEditModal,
    AppFriendButton,
    AppFollowButton,
    AppActionSheet,
    AppActionSheetButton,
    AppUsername,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    loggedInUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showShareProfile: false,
      showMsgModal: false,
      showProfileEdit: false,
      showOptionSheet: false,
      channel: null,
    };
  },
  computed: {
    hasVideo() {
      return this.channel && this.channel.videoPosts.count > 0;
    },
  },
  async mounted() {
    const resp = await this.$apollo.query({
      query: GetChannel,
      variables: {
        id: this.user.id,
      },
    });
    this.channel = { ...resp.data.user };
  },
  methods: {
    shareProfile() {
      let link = `/${this.user.id}`;
      if (this.user.username) {
        link = `/${this.user.username.replace('@', '')}`;
      }
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Profile',
      });
    },
    // Open a new message modal
    openNewMessageModal() {
      $bus.$emit('new-message-modal', { user: this.user });
    },
    // ActionSheet
    closeOptionSheet() {
      this.showOptionSheet = false;
    },
    openOptionSheet() {
      this.showOptionSheet = true;
    },
    // Report user
    report() {
      const data = {
        report: this.user,
        reportType: 'user',
      };
      $bus.$emit('show-report-action', data);
    },
    // Block user
    blockUser() {
      $bus.$emit('show-block-user', this.user);
    },
    // Edit Profile
    openProfileEdit() {
      this.showProfileEdit = true;
    },
    closeProfileEdit() {
      this.showProfileEdit = false;
    },
    goToFriendsView(id) {
      if (id === $auth.getUserId()) {
        this.$router.push({ name: 'Friends', params: { id } });
      }
    },
  },
};
</script>

<style scoped>
.ch-profile__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
}
.ch-profile__buttons-myself {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2px 5px;
}
.ch-profile__button-edit {
  margin-right: 10px;
}
.ch-profile__buttons-share-myself {
  color: var(--cs-gray-04);
  font-size: 12px;
  margin-top: 10px;
}
.ch-profile__buttons-share,
.ch-profile__buttons-more {
  margin-top: 10px;
  font-size: 12px;
  padding-right: 15px;
}
.ch-profile__icon {
  padding-right: 3px;
}
.ch-profile__button-follow >>> .cs-button {
  width: 70px;
  margin-right: 5px;
}
.ch-profile__button-friend >>> .friend-button {
  width: 25px;
  --cs-button-padding: 5px !important;
  margin-right: 3px;
}
.ch-profile__button-message {
  width: 120px;
  /* --cs-button-padding: 5px !important; */
}
.ch-profile__buttons-first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.ch-profile__buttons-first button {
  width: 100px !important;
  margin: 0 1px;
}
.ch-profile__buttons-second {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  width: 100%;
}
.ch-profile__cs-email {
  margin-right: 5px;
}
.ch-profile__all-counts {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.ch-profile__individual-count {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
</style>
