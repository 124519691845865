<template>
  <ion-page>
    <ion-content>
      <div class="ch-set-email">
        <div class="ch-set-email__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <app-register-subheader
          title="Create your Account"
          description="Enter your information below to create your account."
        />
        <app-register-form>
          <cs-input
            v-model="emailAddress"
            label="Email Address"
            type="email"
            :disabled="verifying"
            placeholder="Email Address"
          />
          <cs-input
            v-model="confirmEmailAddress"
            label="Confirm Email Address"
            type="email"
            :disabled="verifying"
            placeholder="Confirm Email Address"
          />
          <div class="ch-email-form__requirements-info">
            <p class="ch-email-form__requirements">Requirements</p>
            <p
              :class="
                emailFormatError
                  ? 'ch-email-form__requirement'
                  : 'ch-email-form__requirement_success'
              "
            >
              <i class="cs-icons-check-success"></i> Valid email entered.
            </p>
            <p
              :class="
                isEmailMismatch
                  ? 'ch-email-form__requirement'
                  : 'ch-email-form__requirement_success'
              "
            >
              <i class="cs-icons-check-success"></i> Email matches with confirm
              email.
            </p>
            <p
              v-for="errorMessage in emailErrorMessages"
              class="ch-email-form__requirement"
            >
              <i class="cs-icons-check-success"></i> {{ errorMessage.message }}
            </p>
          </div>
          <cs-spinner v-if="loading" size="medium" />
          <div v-if="!loading">
            <cs-button
              block
              corners="round"
              size="large"
              :disabled="!valid"
              @click="next"
            >
              Continue
            </cs-button>
          </div>
          <div v-if="!isMeeting" class="ch-email__back">
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" @click="previous"
                >Back</cs-button
              >
            </div>
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" to="/login"
                >Back to Login</cs-button
              >
            </div>
          </div>
        </app-register-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import CheckUserEmail from '@/gql/onboarding/CheckUserEmail.gql';
import AppRegisterForm from '@/components/register/Form.vue';
import AppRegisterSubheader from '@/components/register/Subheader.vue';

export default {
  components: {
    AppRegisterSubheader,
    AppRegisterForm,
  },
  props: {
    draftUser: {
      type: Object,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailAddress: '',
      confirmEmailAddress: '',
      alreadyUse: false,
      verifying: false,
      errorMassage: '',
      emailErrorMessages: [],
      loading: false,
    };
  },
  computed: {
    emailFormatError() {
      const emailFormat =
        this.emailAddress === null || this.emailAddress === ''
          ? true
          : this.emailAddress &&
            !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              this.emailAddress
            );
      return emailFormat;
    },
    valid() {
      return (
        !!this.confirmEmailAddress &&
        !!this.emailAddress &&
        this.emailAddress === this.confirmEmailAddress &&
        !this.emailFormatError
      );
    },
    isEmailMismatch() {
      return this.emailAddress === null || this.emailAddress === ''
        ? true
        : this.emailAddress !== this.confirmEmailAddress;
    },
  },
  created() {
    this.emailAddress = this.draftUser.email ? this.draftUser.email : null;
    this.confirmEmailAddress = this.draftUser.email
      ? this.draftUser.email
      : null;
  },
  methods: {
    async next() {
      this.alreadyUse = false;
      this.verifying = true;
      try {
        this.loading = true;
        const results = await this.$apollo.query({
          query: CheckUserEmail,
          variables: {
            email: this.emailAddress,
          },
        });
        this.verifying = false;
        this.loading = false;
        if (results.data.check_email_availability) {
          this.$emit('next', { email: this.emailAddress });
        } else {
          this.emailErrorMessages = results.errors;
        }
      } catch (error) {
        this.verifying = false;
        this.emailErrorMessages = [error];
        this.loading = false;
      }
    },
    previous() {
      this.$emit('previous');
    },
  },
};
</script>

<style scoped>
.ch-set-email {
  padding: 20px;
  background: var(--cs-gray-01);
}
.ch-set-email__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-email-form__requirement i {
  margin-right: 3px;
}
.ch-email-form__requirement {
  margin: 3px;
  color: var(--cs-gray-04);
}
.ch-email-form__requirement_success {
  margin: 3px;
  color: var(--cs-success-base);
}
.ch-email-form__requirements-info {
  margin-bottom: 20px;
}
.ch-email-form__requirements {
  margin-bottom: 0;
  color: var(--cs-gray-05);
}
.cs-spinner {
  min-height: 45px;
}
.cs-spinner >>> .cs-spinner__dots {
  margin: 0px auto !important;
}
.ch-email__back {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
}
</style>
