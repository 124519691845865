<template>
  <app-modal
    :show="showAddLeaders"
    @close="close"
    @action="addLeaders"
    title="Add Leader(s)"
    action="Add"
    :disableAction="saving"
    class="ch-add-leader-modal"
  >
    <div class="ch-add-leader-button__content">
      <div class="ch-add-leader-button__info cs-textstyle-paragraph">
        Search and add one or more leaders for the event.
      </div>
      <app-multiselect-user-input
        label="Add Presenters"
        v-model="presenters"
        @input="removeFromModerators"
        hide-logged-in-user
      />
      <app-multiselect-user-input
        label="Add Moderators"
        v-model="moderators"
        @input="removeFromPresenters"
        hide-logged-in-user
      />
    </div>
  </app-modal>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $meetingsApi from '@/api/meetings';
import $popups from '@/services/popups';

import AppModal from '@/components/general/Modal.vue';
import AppMultiselectUserInput from '@/components/general/MultiselectUserInput.vue';

const removeAfromB = (usersA, usersB) =>
  usersB.filter((ub) => {
    let isNotInA = true;
    usersA.forEach((ua) => {
      if (ua.id === ub.id) isNotInA = false;
    });
    return isNotInA;
  });

export default {
  components: {
    AppModal,
    AppMultiselectUserInput,
  },
  data() {
    return {
      showAddLeaders: false,
      meeting: null,
      members: null,
      saving: false,
      presenters: [],
      moderators: [],
    };
  },
  mounted() {
    $bus.$on('show-add-leaders-modal', (data) => {
      this.meeting = data.meeting;
      this.members = data.members;
      this.showAddLeaders = true;
      this.presenters = this.members
        .filter((m) => m.role === 'PRESENTER' && m.userId !== $auth.getUserId())
        .map((m) => m.user);
      this.moderators = this.members
        .filter((m) => m.role === 'MODERATOR' && m.userId !== $auth.getUserId())
        .map((m) => m.user);
    });
  },
  methods: {
    close() {
      this.showAddLeaders = false;
    },
    async addLeaders() {
      this.saving = true;
      try {
        await $meetingsApi.setLeaders(this.meeting.id, {
          presenters: this.presenters.map((u) => u.id).join(','),
          moderators: this.moderators.map((u) => u.id).join(','),
        });
        this.saving = false;
        this.close();
      } catch (e) {
        this.saving = false;
        $popups.alert('There was an error saving.  Please try again or contact support.');
        throw e;
      }
    },
    removeFromModerators(presenters) {
      this.moderators = removeAfromB(presenters, this.moderators);
    },
    removeFromPresenters(moderators) {
      this.presenters = removeAfromB(moderators, this.presenters);
    },
  },
};
</script>

<style scoped>
.ch-add-leader-button__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
