<template>
  <div class="video-detail-header">
    <app-expandable-text
      class="video-detail-header__stream-name"
      :content="title"
      placeholder="Untitled Stream"
    />
    <!--
    <i
      class="ch-icons-options video-detail-header__options"
      @click="openOptions"
    />
    -->
    <div class="video-detail-header__bar">
      <app-avatar
        v-router-link="{ name: 'VideoProfile', params: user }"
        size="small"
        :picture="avatar"
        :name="channelName"
      />
      <div class="video-detail-header__details">
        <app-expandable-text
          class="video-detail-header__channel-name"
          :content="channelName"
          placeholder="Untitled Channel"
        />
        <div class="video-detail-header__description">
          {{ post.createdAt | moment('from') }}
        </div>
        <div class="video-detail-header__description">
          {{ post.viewCount }} views
        </div>
      </div>
      <!--
      <div class="video-detail-header__control">
        <i
          class="video-detail-header__share ch-icons-share"
          @click="shareChannel()"
        />
        <app-bookmark-button :post="post" size="large" />
        <app-queue-button :post="post" size="large" />
      </div>
      -->
    </div>
    <app-video-detail-option-sheet v-model="isOptionsOpened" :post="post" />
    <app-video-detail-handouts v-model="isHandoutsOpened" :post="post" />
  </div>
</template>
<script>
import $bus from '@/services/bus';

import AppAvatar from '@/components/general/Avatar.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppExpandableText from '@/components/general/ExpandableText.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppVideoDetailHandouts from '@/components/videos/detail/VideoDetailHandouts.vue';
import AppVideoDetailOptionSheet from '@/components/videos/detail/VideoDetailOptionSheet.vue';

export default {
  components: {
    AppAvatar,
    AppQueueButton,
    AppExpandableText,
    AppBookmarkButton,
    AppVideoDetailHandouts,
    AppVideoDetailOptionSheet,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOptionsOpened: false,
    isHandoutsOpened: false,
  }),
  computed: {
    user() {
      return this.post.user;
    },
    avatar() {
      return this.user.channelPicture || this.user.profilePhotoURL;
    },
    title() {
      return (
        this.post.video.title || this.post.bodyText || this.post.video.name
      );
    },
    channelName() {
      return this.user.channelName || `${this.user.displayname}'s Channel`;
    },
  },
  methods: {
    openOptions() {
      this.isOptionsOpened = true;
    },
    openHandouts() {
      this.isHandoutsOpened = true;
    },
    shareChannel() {
      const link = `/c/${this.user.channelSlug || this.user.id}`;
      $bus.$emit('show-share-sheet', {
        title: 'Share Channel',
        link,
      });
    },
  },
};
</script>
<style scoped>
.video-detail-header {
  position: relative;
  padding: 10px;
}
.video-detail-header__options {
  position: absolute;
  top: 10px;
  right: 10px;
}
.video-detail-header__stream-name {
  font-size: 22px;
  font-weight: bold;
  color: #4a5359;
}
.video-detail-header__bar {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
.video-detail-header__control {
  margin-left: auto;
}
.video-detail-header__channel-name {
  font-size: 12px;
  font-weight: bold;
  color: #4a5359;
}
.video-detail-header__description {
  font-size: 12px;
  color: #4a5359;
}
.video-detail-header__control {
  display: flex;
  gap: 20px;
  margin-top: auto;
  color: #4a5359;
}
.video-detail-header__share {
  font-size: 21px;
}
</style>