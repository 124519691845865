<template>
  <i
    class="bookmark-button"
    :class="[size, { active: post.isBookmarked }, filled]"
    @click="toggleBookmark()"
  ></i>
</template>

<script>
import $posts from '@/api/posts';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
  },
  computed: {
    filled() {
      return this.post.isBookmarked
        ? 'cs-icons-bookmark-filled'
        : 'cs-icons-bookmark';
    },
  },
  methods: {
    toggleBookmark() {
      if (this.post.isBookmarked) {
        $posts.unbookmarkPost(this.post);
      } else {
        $posts.bookmarkPost(this.post);
      }
    },
  },
};
</script>

<style scoped>
.bookmark-button {
  margin: 10px;
  cursor: pointer;
}
.bookmark-button.active {
  color: var(--cs-primary-base);
}
.bookmark-button.small {
  font-size: 12px;
  margin: 0;
}
.bookmark-button.medium {
  margin: 0 0;
  font-size: 16px;
}
.bookmark-button.large {
  margin: 0;
  font-size: 22px;
}
</style>
