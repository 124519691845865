<template>
  <div v-if="group && memberList && memberList.length > 0" @click.stop>
    <cs-avatar-list
      @click="$emit('click', $event)"
      :user-list="memberList"
      size="small"
      :max="5"
      :total-user-count="group.memberCount"
    />
  </div>
</template>

<script>
import $cdn from '@/services/cdn';
import $auth from '@/services/auth';
import ListGroupMembers from '@/gql/groups/ListGroupMembers.gql';

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    members: {
      query: ListGroupMembers,
      variables() {
        return {
          id: this.group.id,
          viewerId: $auth.getUserId(),
          role: 'member',
        };
      },
    },
  },
  computed: {
    memberList() {
      if (!this.members) return null;
      return this.members.map((membership) => {
        const user = membership.user;
        return {
          name: `${user.firstname} ${user.lastname}`,
          picture: user.profilePhotoURL
            ? $cdn.optimize(user.profilePhotoURL)
            : null,
        };
      });
    },
  },
};
</script>

<style scoped>
.cs-avatar-list >>> .cs-avatar-list__avatar-more {
  width: unset;
  min-width: var(--cs-avatar-list-size);
  padding: 2px;
  border-radius: calc(var(--cs-avatar-list-size) / 2);
}
</style>
