<template>
  <app-page-menu title="Explore Videos">
    <div class="cs-textstyle-item-heading">Explore your videos</div>
    <!-- Channels -->
    <app-page-menu-category title="Channels">
      <app-page-menu-category-item
        label="My Channel"
        :to="{ name: 'VideoProfile', params: { id: myid } }"
      />
      <app-page-menu-category-item
        v-for="channel in channels"
        :key="channel.view"
        :label="channel.label"
        :to="{ name: 'VideoChannelList', params: { view: channel.view } }"
      />
    </app-page-menu-category>
    <!-- Videos -->
    <app-page-menu-category title="Videos">
      <app-page-menu-category-item
        v-for="video in videos"
        :key="video.view"
        :label="video.label"
        :to="{ name: 'VideoList', params: { view: video.view } }"
      />
    </app-page-menu-category>
    <!-- Categories -->
    <app-page-menu-category title="Categories">
      <app-page-menu-category-item
        v-for="cat in categories"
        :key="cat.value"
        :label="cat.label"
        :to="{ name: 'VideoCategory', params: { category: cat.value } }"
      />
    </app-page-menu-category>
  </app-page-menu>
</template>

<script>
import $auth from '@/services/auth';
import categories from '@/assets/config/videocategories.json';
// Component
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuCategory from '@/components/general/PageMenuCategory.vue';
import AppPageMenuCategoryItem from '@/components/general/PageMenuCategoryItem.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuCategory,
    AppPageMenuCategoryItem,
  },
  data() {
    return {
      //myid: $auth.getUserId(),
      categories,
      videos: [
        {
          label: 'Latest',
          view: 'latest',
        },
        {
          label: 'Popular',
          view: 'popular',
        },
        /*
        {
          label: 'Recently Watched',
          view: 'history',
        },
        {
          label: 'Bookmarked',
          view: 'bookmarked',
        },
        {
          label: 'Watch Later',
          view: 'queue',
        },
        {
          label: 'From Followed',
          view: 'following',
        },
        */
      ],
      channels: [
        {
          label: 'Live',
          view: 'live',
        },
        {
          label: 'Recommended',
          view: 'recommended',
        },
        /*
        {
          label: 'Subscribed Channels',
          view: 'subscribed',
        },
        */
      ],
    };
  },
};
</script>

<style scoped></style>
