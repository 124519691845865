<template>
  <div v-if="memberCount > 0" class="ch-meeting-people-tab-segment">
    <div class="ch-meeting-people-tab-segment__header">
      <div
        v-if="title"
        class="ch-meeting-people-tab-segment__title cs-textstyle-paragraph-bold"
      >
        {{ title }}
      </div>
    </div>
    <div class="ch-meeting-people-tab-segment__members">
      <app-meeting-member
        v-for="member in maxMembers"
        :key="member.id"
        class="ch-meeting-people-tab-segment__member"
        :member="member"
        :meeting="meeting"
        :show-role="type === 'leaders'"
      />
    </div>
    <hr v-if="!noLine" size="3" />
    <app-meeting-people-modal
      :show="showAll"
      :title="title"
      :members="members"
      :meeting="meeting"
      @close="showAll = false"
    />
  </div>
</template>

<script>
import AppMeetingMember from '@/components/meetings/MeetingMember.vue';
import AppMeetingPeopleModal from '@/components/meetings/MeetingPeopleModal.vue';

export default {
  components: {
    AppMeetingMember,
    AppMeetingPeopleModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    members: {
      type: Array,
      required: true,
    },
    meeting: {
      type: Object,
      required: true,
    },
    memberLimit: {
      type: Number,
      default: 21,
    },
    noLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    memberCount() {
      return (this.members && this.members.length) || 0;
    },
    maxMembers() {
      return this.members.slice(0, this.memberLimit);
    },
  },
};
</script>

<style scoped>
.ch-meeting-people-tab-segment {
  padding-bottom: 15px;
  margin-bottom: 15px;
  color: #7b7b7b;
  border-bottom: solid 1px #9B9B9B7F;
}
.ch-meeting-people-tab-segment:last-child {
  border-bottom: none;
}
.ch-meeting-people-tab-segment__title {
  color: #7b7b7b;
}
.ch-meeting-people-tab-segment__header {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ch-meeting-people-tab-segment__total {
  flex: 1;
  color: var(--cs-primary-base);
}
.ch-meeting-people-tab-segment__view-all {
  color: var(--cs-primary-base);
  cursor: pointer;
}
.ch-meeting-people-tab-segment__view-all i {
  margin-left: 10px;
}
.ch-meeting-people-tab-segment__members {
  /* display: flex;
  flex-direction: row;
  overflow-x: auto; */
}
.ch-meeting-people-tab-segment__member {
  /* flex-shrink: 0; */
  display: inline-block;
}
</style>
