import $apollo from '@/services/apollo';
import StartMeeting from '@/gql/meetings/StartMeeting.gql';
import SetLeaders from '@/gql/meetings/SetLeaders.gql';
import SetMeetingMemberRole from '@/gql/meetings/SetMeetingMemberRole.gql';
import Signup from '@/gql/meetings/Signup.gql';
import UnSignup from '@/gql/meetings/UnSignup.gql';
import DeleteMeetingReferralCode from '@/gql/meetings/DeleteMeetingReferralCode.gql';

const setLeaders = (meetingId, { presenters, moderators, admins }) =>
  $apollo.mutate({
    mutation: SetLeaders,
    variables: {
      meetingId,
      presenters,
      moderators,
      admins,
    },
    optimisticResponse: {
      set_leaders: true,
    },
    // TODO update cache lists?
  });

const setRole = (meetingId, userId, role) =>
  $apollo.mutate({
    mutation: SetMeetingMemberRole,
    variables: {
      meetingId,
      userId,
      role,
    },
  });

const signup = (meetingId) =>
  $apollo.mutate({
    mutation: Signup,
    variables: {
      meetingId,
    },
  });

const unsignup = (meetingId) =>
  $apollo.mutate({
    mutation: UnSignup,
    variables: {
      meetingId,
    },
    optimisticResponse: {
      meeting_unsignup: true,
    },
  });

const startMeeting = (meetingId) =>
  $apollo.mutate({
    mutation: StartMeeting,
    variables: {
      meetingId,
    },
  });

const deleteReferralCode = async (id, meeting) =>
  $apollo.mutate({
    mutation: DeleteMeetingReferralCode,
    variables: {
      id,
    },
    update: (cache) => {
      cache.modify({
        id: cache.identify(meeting),
        fields: {
          referralCodes: (existingFieldData) =>
            existingFieldData.filter(({ __ref }) => __ref.split(':')[1] !== id),
        },
      });
    },
  });

export default {
  setLeaders,
  setRole,
  signup,
  startMeeting,
  unsignup,
  deleteReferralCode,
};
