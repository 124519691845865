<template lang="html">
  <div class="ch-register__form"><slot/></div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
.ch-register__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}
</style>
