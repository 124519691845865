<template>
  <div class="user-location">
    <cs-small-tag
      variant="default"
      v-if="showAddress"
    >
      <i class="cs-icons-marker-filled"/>
      {{address}}
      <i class="cs-icons-close-filled" @click="removeLocation"/>
    </cs-small-tag>
    <div v-if="!showAddress">
    <cs-button v-if="!showAddressInput" variant="default" size="small" corners="round" class="cs-exta-btns" @click="getLocation">
      <i class="cs-icons-marker" />
    </cs-button>
    <div v-if="showAddressInput">
      <cs-textarea
        v-model="address"
        placeholder="Enter a location"
        :disabled="!address"
      />
      <cs-button
        variant="primary"
        size="small"
        fill="outline"
        :disabled="!address"
        @click="addAddress"
      >
        <i class="cs-icons-add"></i> <span>Add</span>
      </cs-button>
      <cs-button
        variant="primary"
        size="small"
        fill="outline"
        @click="showAddressInput = false"
      >
        <i class="cs-icons-close"></i> <span>Cancel</span>
      </cs-button>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  props: {
    value: String,
  },
  components: {
    AppSpinner,
  },
  data() {
    return {
      address: this.value,
      showAddress: false,
      showAddressInput: false
    };
  },
  mounted() {
    if(this.location) this.address = this.location;
  },
  methods: {
    addAddress() {
      this.showAddress = true;
      this.$emit('add', this.address);
      this.showAddressInput = false
    },
    async getLocation() {
      this.showAddressInput = true;
      const { data } = await axios.get('https://ipapi.co/json/');
      if (data) {
        this.address = '';
        if (data.city) this.address = `${data.city}, `;
        if (data.region) this.address += `${data.region}, `;
        if (data.country_name) this.address += `${data.country_name}`;
      }
    },
    removeLocation() {
      this.showAddress = false;
      this.address = null;
      this.$emit('remove');
    },
  },
};
</script>
<style scoped>
.cs-exta-btns{
   --cs-button-color: var(--cs-gray-01) !important;
    --cs-button-text-color: var(--cs-gray-05) !important;

}
.user-location{
  min-height: 50px;
}
</style>
