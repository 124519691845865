<template lang="html">
  <span v-if="memberNotUser" class="groups-group-member-menu">
    <i
      class="cs-icons-options-filled groups-member-menu__icon"
      @click="showSheet = true"
    ></i>
    <app-action-sheet :open="showSheet" @close="showSheet = false">
      <!-- Can send message to all members -->
      <app-action-sheet-button @click="sendMessage()">
        Send Message
      </app-action-sheet-button>
      <template v-if="userHasMemberStatus || isSuperAdmin">
        <!-- All members or superadmin can report other group members -->
        <app-action-sheet-button @click="reportUser">
          Report User
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="isAvailable('removeFromGroup')"
          @click="removeMember"
        >
          Remove from Group
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="isAvailable('makeMember')"
          @click="setRole('member')"
        >
          Make Member
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="isAvailable('makeAdmin')"
          @click="setRole('admin')"
        >
          Make Admin
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="isAvailable('makeModerator')"
          @click="setRole('moderator')"
        >
          Make Moderator
        </app-action-sheet-button>
      </template>
    </app-action-sheet>
  </span>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $groupsApi from '@/api/groups';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';

const memberRules = {
  admin: {
    admin: [
      'sendMessage',
      'reportUser',
      'removeFromGroup',
      'makeModerator',
      'makeMember',
    ],
    moderator: [
      'sendMessage',
      'reportUser',
      'removeFromGroup',
      'makeMember',
      'makeAdmin',
    ],
    member: [
      'sendMessage',
      'reportUser',
      'removeFromGroup',
      'makeModerator',
      'makeAdmin',
    ],
    'pending-member': [
      'sendMessage',
      'reportUser',
      'makeModerator',
      'makeAdmin',
      'makeMember',
    ],
  },
  moderator: {
    admin: ['sendMessage', 'reportUser'],
    moderator: ['sendMessage', 'reportUser'],
    member: ['sendMessage', 'reportUser', 'removeFromGroup', 'makeModerator'],
    'pending-member': [
      'sendMessage',
      'reportUser',
      'makeModerator',
      'makeMember',
    ],
  },
  member: {
    admin: ['sendMessage', 'reportUser'],
    moderator: ['sendMessage', 'reportUser'],
    member: ['sendMessage', 'reportUser'],
    'pending-member': ['sendMessage', 'reportUser'],
  },
};

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSheet: false,
    };
  },
  computed: {
    userRole() {
      if (!this.group || !this.group.myMembership) return null;
      return this.group.myMembership.role;
    },
    memberRole() {
      return this.member.role;
    },
    memberNotUser() {
      return this.member.user.id !== $auth.getUserId();
    },
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    availableActions() {
      return memberRules[this.userRole][this.memberRole];
    },
    userHasMemberStatus() {
      return Object.keys(memberRules).includes(this.userRole);
    },
  },
  methods: {
    isAvailable(action) {
      return this.isSuperAdmin || this.availableActions.includes(action);
    },
    async sendMessage() {
      $bus.$emit('new-message-modal', { user: this.member.user });
    },
    async reportUser() {
      const reportData = {
        report: this.member.user,
        reportType: 'user',
      };
      $bus.$emit('show-report-action', reportData);
    },
    async setRole(role) {
      await $groupsApi.updateMember(this.group, this.member, role);
    },
    async removeMember() {
      await $groupsApi.removeMember(this.group, this.member);
    },
  },
};
</script>
