<template>
  <ion-page>
    <ion-header>
      <div>
        <app-category-header title="Friends/Followers" />
      </div>
      <div class="ch-friends__nav">
        <app-tabs v-model="selectedOption" :tabs="friendsFilters" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div v-show="selectedKey === 'connections'">
        <app-friends-list ref="friends-list" />
      </div>
      <div v-show="selectedKey === 'followers'">
        <app-followers-list ref="followers-list" />
      </div>
      <div v-show="selectedKey === 'following'">
        <app-following-list ref="following-list" />
      </div>
      <!-- Floating Button -->
      <app-connections-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppFriendsList from '@/components/connections/friends/FriendList.vue';
import AppFollowersList from '@/components/connections/friends/FollowersList.vue';
import AppFollowingList from '@/components/connections/friends/FollowingList.vue';
import AppConnectionsExploreBtn from '@/components/connections/ConnectionsExploreBtn.vue';

export default {
  components: {
    AppCategoryHeader,
    AppTabs,
    AppPullToRefresh,
    AppFriendsList,
    AppFollowersList,
    AppFollowingList,
    AppConnectionsExploreBtn,
  },
  data() {
    return {
      selectedOption: 0,
      friendsFilters: [
        {
          label: 'Friends',
          key: 'connections',
        },
        {
          label: 'Followers',
          key: 'followers',
        },
        {
          label: 'Following',
          key: 'following',
        },
      ],
    };
  },
  computed: {
    selectedKey() {
      const selectedView = this.friendsFilters[this.selectedOption].key;
      return selectedView;
    },
  },
  methods: {
    async refresh($event) {
      await Promise.all([
        this.$refs['friends-list'].refresh(),
        this.$refs['followers-list'].refresh(),
        this.$refs['following-list'].refresh(),
      ]);
      $event.target.complete();
    },
  },
};
</script>

<style scoped>
ion-content {
  --ion-background-color: var(--cs-gray);
}

.cs-page-tabs {
  --cs-page-tabs-label-padding: 12px;
  background-color: var(--cs-gray-00);
}
</style>
