<template>
  <div class="ch-meeting-list" :class="direction">
    <cs-spinner v-if="!meetings" class="ch-spinner" />
    <div v-if="meetings" class="ch-meeting-list__main">
      <div v-if="filteredMeetings.length">
        <div
          class="ch-meeting-list__main--wrapper"
          v-for="(meeting, index) in filteredMeetings"
          :key="meeting.id + index"
        >
          <app-meeting-item :meeting="meeting" :direction="direction"></app-meeting-item>
        </div>
      </div>
      <cs-empty-state
        title="No matching events"
        v-if="!filteredMeetings.length"
        class="ch-meeting-list__empty cs-textstyle-detail-heading"
      ></cs-empty-state>
    </div>
  </div>
</template>

<script>
import AppMeetingItem from '@/components/meetings/MeetingItem.vue';

export default {
  components: {
    AppMeetingItem,
  },
  props: {
    meetings: {
      type: Array,
    },
    direction: {
      type: String,
      default: 'row',
    },
  },
  computed: {
    filteredMeetings() {
      return this.meetings.filter(
        (meeting) =>
          meeting.privacy === 'public' || (meeting.privacy === 'private' && meeting.myMembership)
      );
    },
  },
};
</script>

<style scoped>
.ch-meeting-list.row .ch-meeting-list__main {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.ch-meeting-list.column .ch-meeting-list__main {
  display: flex;
  flex-direction: column;
}
.ch-meeting-list.row .ch-meeting-list__main .ch-meeting-list__main--wrapper {
  padding: 7px; /* CloutHub requested */
}
.ch-meeting-list__main--wrapper {
  padding: 7px 0; /* CloutHub requested */
}
.ch-meeting-list__empty {
  border: dashed 1px var(--cs-gray-02);
  border-radius: 10px;
  margin-top: 10px;
  flex: 1;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
