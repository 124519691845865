<template>
  <div class="post-card-header" :class="{ 'post-header-custom': preview }">
    <app-avatar
      class="post-card-header-avatar"
      :picture="post.user.profilePhotoURL"
      :name="userDisplayName"
      size="medium"
      @click="$router.push({ name: 'Profile', params: post.user })"
      ><!-- CloutHub requested medium avatar -->
    </app-avatar>
    <div class="post-card-header-name">
      <div
        class="cs-textstyle-paragraph-bold"
        @click="$router.push({ name: 'Profile', params: post.user })"
      >
        <app-username :user="post.user" type="displayname" />
      </div>
      <div
        class="post-card-header__username cs-textstyle-paragraph-small all-copy"
      >
        {{ post.user.username }}
      </div>
    </div>
    <div v-if="!preview" class="ch-icon-button-wrap">
      <app-bookmark-button :post="post" />
    </div>
    <div
      class="post-card-header-timestamp cs-textstyle-paragraph-small"
      @click="$router.push({ name: 'PostDetail', params: post })"
    >
      {{ post.createdAt | moment('from') }}
    </div>
    <div v-if="!preview">
      <app-post-menu
        :post="post"
        :can-delete="canDelete"
        @pinned="$emit('pinned')"
      />
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
import AppPostMenu from '@/components/post/items/PostMenu.vue';
import AppUsername from '@/components/general/Username.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';

export default {
  components: {
    AppAvatar,
    AppPostMenu,
    AppUsername,
    AppBookmarkButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userDisplayName() {
      if (this.post.user.displayname) return this.post.user.displayname;
      if (this.post.user.username) return this.post.user.username.substring(1);
      return 'CloutHub User';
    },
    formattedDate() {
      const datetime = new Date(this.post.createdAt);
      const time = datetime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
      });
      const date = `${datetime.getDate()} ${
        datetime.toDateString().split(' ')[1]
      }`;
      return `${date} ${time}`;
    },
  },
  methods: {
    postDetailClick() {
      if (this.$route.name === 'PostDetail') return;
      this.$router.push({ name: 'PostDetail', params: this.post });
    },
  },
};
</script>

<style scoped>
.ch-icon-button-wrap {
  margin: 0 4px;
}

.post-card-header {
  padding: 9px 0px 6px 0px; /* CloutHub requested */
  display: flex;
  flex-direction: row;
  align-items: center;
}
.post-card-header-avatar {
  margin-right: 10px;
  cursor: pointer;
  height: 55px; /* CloutHub requested */
}
.post-card-header-name {
  flex: 1;
  cursor: pointer;
  color: var(--cs-gray-06);
}
.post-card-header-name:nth-child(2) {
  color: var(--cs-gray-05);
}
.post-card-header__username {
  margin-top: -4px; /* CloutHub requested */
}
.post-card-header-timestamp {
  color: var(--cs-gray-04);
  margin-right: 5px;
  user-select: none;
}
.post-card-header-menu {
  color: var(--cs-gray-03);
  font-size: 24px;
}
.post-header-custom {
  pointer-events: none;
}
.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}
</style>
