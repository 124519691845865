<template>
  <div class="ch-profile__card">
    <app-user-list
        v-if="users"
        :users="filteredPeople"
        :meeting="meeting"
        userType="Search Followers"
        @on-query-change="onQueryChange"
    ></app-user-list>
  </div>
</template>

<script>
import AppUserList from '@/components/meetings/tabs/MeetingUserList.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

import UserSearch from '@/gql/search/UserSearch.gql';

export default {
  components: {
    AppUserList,
    AppInfiniteOffset,
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: '',
          limit: 25,
        };
      },
    },
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredPeople() {
      return this.users.filter((person) => !person.myRelationship.isBlocked);
    },
  },
  methods: {
    onQueryChange(query) {
      this.$apollo.queries.users.refetch({
        query,
        limit: 25,
      });
    },
  },
};
</script>
