<template>
  <div>
    <app-spinner v-if="!comments"></app-spinner>
    <div v-if="comments" class="ch-profile-comment-list">
      <app-user-comment
        v-for="comment in comments"
        :key="comment.id"
        class="ch-profile-comment-list"
        :comment="comment"
        :user="user"
      />
    </div>
    <cs-empty-state
      v-if="showEmptyState"
      description="No Comments to display"
    />
    <app-infinite-simple
      :query="$apollo.queries.comments"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import ListUserComments from '@/gql/connections/ListUserComments.gql';
import AppUserComment from '@/components/connections/UserComment.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  components: {
    AppUserComment,
    AppInfiniteSimple,
    AppSpinner,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showEmptyState() {
      return this.comments && this.comments.length === 0;
    },
  },
  apollo: {
    comments: {
      query: ListUserComments,
      variables() {
        return {
          id: this.user.id,
        };
      },
    },
  },
};
</script>

<style scoped>
.ch-profile-comment-list {
  margin: 10px 5px;
}
</style>
