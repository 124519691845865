<template lang="html">
  <div class="ch-slide">
    <div class="ch-slide-icon"></div>
    <div class="slider-container">
      <img class="ch-slide__img" :src="image" />
      <div>
        <div class="ch-slide__title">{{ title }}</div>
        <p class="ch-slide__description">{{ description }}</p>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    image: {
      default: '',
      type: String,
    },
  },
};
</script>

<style lang="css" scoped>
.ch-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.ch-slide__img {
  width: 100%;
  max-height: 230px;
  min-height: 230px;
  border-radius: 0 35px 0 35px;
}
.ch-slide__title {
  margin-top: 30px;
  padding: 0 25px;
  font-weight: bold;
  font-size: var(--cs-font-size-500);
  color: var(--cs-primary-base);
}
.ch-slide__description {
  font-size: var(--cs-font-size-100);
  color: var(--cs-gray-05);
}
.slider-container {
  height: 100vh;
  margin-top: 35px;
  padding: 20px;
}
@media (min-width: 375px) {
  .ch-slide__title {
    font-size: var(--cs-font-size-500);
  }
  .ch-slide__description {
    font-size: var(--cs-font-size-200);
  }
}
</style>
