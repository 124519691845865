<template>
  <div class="ch-sub-header">
    <div
      class="ch-sub-header__buttons-left"
      :class="buttonWidthClass"
      v-if="showBack"
    >
      <i class="cs-icons-back-arrow-filled" @click="goBack" />
    </div>
    <div class="ch-sub-header__title cs-textstyle-section-heading">
      {{ title }}
    </div>
    <div class="ch-sub-header__buttons-right" :class="buttonWidthClass">
      <slot></slot>
      <app-notification-icon v-if="notifications" />
      <app-messages-icon v-if="message" />
      <i
        v-if="create"
        class="cs-icons-add-filled ch-sub-header__create-icon"
        @click="$emit('create')"
      />
      <cs-button
        v-if="!!button || !!icon"
        :disabled="disabled"
        :icon="icon"
        class="ch-sub-header__button"
        fill="clear"
        size="large"
        @click="$emit('action')"
        >{{ button }}</cs-button
      >
    </div>
  </div>
</template>

<script>
import AppNotificationIcon from '@/components/general/NotificationIcon.vue';
import AppMessagesIcon from '@/components/general/MessagesIcon.vue';

export default {
  components: {
    AppNotificationIcon,
    AppMessagesIcon,
  },
  props: {
    title: String,
    button: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Boolean,
      default: false,
    },
    message: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    usesSlot: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$emit('closing');
      this.$router.go(-1);
    },
  },
  computed: {
    buttonWidthClass() {
      if (this.usesSlot) return 'ch-header__buttons--3x';
      if (this.button) return 'ch-sub-header__buttons--Bx';
      if (this.create && this.notifications)
        return 'ch-sub-header__buttons--2x';
      return 'ch-sub-header__buttons--1x';
    },
  },
};
</script>

<style scoped>
.ch-sub-header {
  background-color: var(--cs-gray-00);
  display: flex;
  padding: 10px 15px;
  border-bottom: solid 1px var(--cs-gray-02);
  align-items: center;
  ---ch-subheader__icon-width: 34px;
  ---ch-subheader__icon-gap: 5px;
  ---ch-subheader__button-padding: 5px;
}
.ch-sub-header__buttons-right {
  text-align: right;
}
.ch-sub-header__buttons--1x {
  width: var(---ch-subheader__icon-width);
}
.ch-sub-header__buttons--2x {
  width: calc(
    var(---ch-subheader__icon-width) * 2 + var(---ch-subheader__icon-gap)
  );
}
.ch-sub-header__buttons--Bx {
  width: 70px;
}
.ch-sub-header__buttons-right > *:nth-child(2) {
  margin-left: var(---ch-subheader__icon-gap);
}
.ch-sub-header i {
  font-size: 24px;
  color: var(--cs-gray-05);
  padding: var(---ch-subheader__button-padding);
  display: inline-flex;
  line-height: 1;
}
.ch-sub-header__title {
  color: var(--cs-gray-06);
  flex: 1;
  text-align: center;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-sub-header__button {
  background-color: white !important;
  --cs-button-padding: var(---ch-subheader__button-padding);
  --cs-button-color: var(--cs-primary-base);
  --cs-button-color-hover: var(--cs-primary-base);
  border: none;
  text-transform: uppercase;
}
.ch-sub-header__button >>> i {
  color: var(--cs-gray-05);
}
.ch-sub-header .ch-sub-header__create-icon {
  color: var(--cs-primary-base);
}
</style>
