<template>
  <div class="image-crop">
    <div class="image-crop__cropper">
      <vue-cropper
        v-if="!croppedImg"
        ref="cropper"
        :aspect-ratio="aspectRatio"
        :src="image"
        drag-mode="none"
        :auto-crop-area="1"
        :zoomable="false"
        :movable="false"
        :crop-box-movable="true"
        :crop-box-resizable="true"
        :class="{ 'icon-cropper': channelIcon }"
      />
    </div>
    <div class="image-crop__btns">
      <cs-button size="small" @click="cropImage">Crop</cs-button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import $utils from '@/services/utils';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    VueCropper,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 1 / 1,
    },
    channelIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      croppedImg: null,
    };
  },
  methods: {
    async cropImage() {
      this.croppedImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const file = await $utils.dataURLToFile(this.croppedImg, 'channel-banner.png');
      this.$emit('cropped', file);
      this.croppedImg = null;
    },
  },
};
</script>

<style scoped>
.image-crop {
  position: relative;
}
.image-crop__cropper {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
  min-height: 300px;
}
.image-crop__btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.image-crop__reset {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.icon-cropper >>> .cropper-view-box {
  border-radius: 100% !important;
}

.icon-cropper >>> .cropper-face {
  border-radius: 100% !important;
}
</style>
