<template>
  <div class="create-button">
    <i class="cs-icons-plus" :style="{'bottom': bottom}"></i>
  </div>
</template>
<script>
export default {
  computed: {
    isIOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    },
    bottom() {
      return this.isIOS ? '110px' : '20px';
    },
  },
};
</script>

<style scoped>
.create-button {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: var(--cs-primary-base);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px;
  transform: translate3d(0, 0, 0);
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .create-button {
    bottom: 100px !important;
  }
}
</style>
