<template>
  <app-modal
    :show="showSharedList"
    class="ch-user-shared-modal"
    title="Shares"
    @close="onClose"
  >
    <cs-spinner v-if="!list_post_shares"></cs-spinner>
    <app-user-list
      v-if="list_post_shares && list_post_shares.length"
      :users="users"
      @click="onClose"
    />
    <app-infinite-loader
      :paused="$apollo.queries.list_post_shares.loading"
      :finished="!mayHaveMore"
      @more="loadMore()"
    />
    <cs-empty-state
      v-if="list_post_shares && list_post_shares.length === 0"
      description="No one has shared this yet"
    />
  </app-modal>
</template>

<script>
import $bus from '@/services/bus';
import ListPostShares from '@/gql/posts/ListPostShares.gql';
import AppModal from '@/components/general/Modal.vue';
import AppUserList from '@/components/general/UserList.vue';
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
  components: {
    AppUserList,
    AppModal,
    AppInfiniteLoader,
  },
  apollo: {
    list_post_shares: {
      query: ListPostShares,
      variables() {
        return {
          id: this.post.id,
        };
      },
      skip() {
        return !this.post;
      },
    },
  },
  data() {
    return {
      post: null,
      showSharedList: false,
      limit: 30,
      offset: 0,
      mayHaveMore: true,
    };
  },
  computed: {
    users() {
      if (this.list_post_shares)
        return this.list_post_shares.map((data) => data.user);
      return null;
    },
  },
  mounted() {
    $bus.$on('show-share-list-modal', (post) => {
      this.showSharedList = true;
      this.post = post;
    });
  },
  methods: {
    onClose() {
      this.showSharedList = false;
    },
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const userId =
          this.list_post_shares[this.list_post_shares.length - 1].userId;
        this.$apollo.queries.list_post_shares.fetchMore({
          variables: {
            id: this.post.id,
            userId,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.list_post_shares.length;
            return {
              list_post_shares: [
                ...previousResult.list_post_shares,
                ...fetchMoreResult.list_post_shares,
              ],
            };
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.ch-user-shared-modal >>> .cs-modal__popup {
  padding: 0px;
}
.ch-user-shared-modal >>> .cs-modal__header {
  display: none;
}
.ch-user-shared-modal >>> .cs-modal__body {
  margin-top: 5px;
  padding: 0px;
}
.modal-body {
  width: 100%;
}
.body-header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--cs-gray-02);
  margin-bottom: 20px;
}
.ch-user-shared-modal >>> .cs-modal__footer {
  display: none;
}
</style>
