<template>
  <div class="ch-blocked-user-profile">
    <app-avatar
      v-if="!user.myRelationship.blockedIn"
      :picture="user.profilePhotoURL"
      :name="user.displayname"
      variant="primary"
      size="large"
      @click="viewUser(user)"
    />
    <template v-if="user.myRelationship.blockedOut">
      <h3>Blocked</h3>
      <div class="ch-blocked-info" @click="viewUser(user)">
        <div>You have blocked <app-username :user="user" /></div>
        <div>
          You won't see posts, messages, and requests from {{ user.username }}.
        </div>
      </div>
      <div>
        <cs-button class="unblock-button" @click="unblock()">
          Unblock {{ user.username }}
        </cs-button>
      </div>
      <div>
        <cs-button v-router-link="{ name: 'Settings' }" fill="outline">
          Manage Settings
        </cs-button>
      </div>
    </template>
    <template v-else>
      <h3>Blocked</h3>
      <div class="ch-blocked-info">
        <div>You have blocked <app-username :user="user" /></div>
        <div>
          You won't see posts, messages, and requests from {{ user.username }}.
        </div>
      </div>
      <div>
        <cs-button class="unblock-button" @click="unblock()">
          Unblock {{ user.username }}
        </cs-button>
      </div>
      <div>
        <cs-button v-router-link="{ name: 'Settings' }" fill="outline">
          Manage Settings
        </cs-button>
      </div>
    </template>
  </div>
</template>

<script>
import $relationships from '@/api/relationships';

import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    AppAvatar,
    AppUsername,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async unblock() {
      $relationships.unblockUser(this.user);
    },
    viewUser(person) {
      this.$router.push({ name: 'Profile', params: person });
    },
  },
};
</script>

<style>
.ch-blocked-user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.ch-blocked-info {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--cs-gray-05);
  gap: 5px;
}
.unblock-button {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
