<template>
  <ion-page>
    <ion-header class="ch-search__header">
      <app-category-header title="Search" />
      <div class="search__input-header">
        <cs-search-bar placeholder="Search CloutHub..." @input="inputSearch" />
      </div>
    </ion-header>
    <ion-content>
      <cs-empty-state
        v-if="noQuery"
        description="Use the search box above to search across CloutHub"
      />
      <div v-else>
        <app-people-search class="search-wrap" :search="search" />
        <app-group-search class="search-wrap" :search="search" />
        <app-meeting-search class="search-wrap" :search="search" />
        <app-post-search class="search-wrap" :search="search" />
        <app-video-search class="search-wrap" :search="search" />
        <app-news-search class="search-wrap" :search="search" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppPeopleSearch from '@/components/universalSearch/PeopleSearch.vue';
import AppGroupSearch from '@/components/universalSearch/GroupSearch.vue';
import AppMeetingSearch from '@/components/universalSearch/MeetingSearch.vue';
import AppPostSearch from '@/components/universalSearch/PostSearch.vue';
import AppVideoSearch from '@/components/universalSearch/VideoSearch.vue';
import AppNewsSearch from '@/components/universalSearch/NewsSearch.vue';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';

export default {
  components: {
    AppCategoryHeader,
    AppPeopleSearch,
    AppGroupSearch,
    AppMeetingSearch,
    AppPostSearch,
    AppVideoSearch,
    AppNewsSearch,
  },
  data() {
    return {
      tabs: ['Users', 'Posts', 'Groups', 'Videos', 'News'],
      tabIndex: 0,
      search: '',
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.videos && this.videos.length === 0) return true;
      return this.noQuery && (!this.videos || this.videos.length === 0);
    },
  },
  mounted() {
    if (this.$route.params.index) {
      const index = this.$route.params.index;
      this.tabIndex = parseInt(index, 10);
    }
  },
  methods: {
    inputSearch(data) {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.search = data;
      }, 700);
    },
  },
};
</script>

<style scoped>
.cs-page-tabs {
  --cs-page-tabs-label-padding: 12px;
  background-color: var(--cs-gray-00);
}

.search__input-header {
  padding: 10px;
  background-color: var(--cs-gray-01);
}
.search__input-header >>> .cs-search-bar {
  background-color: var(--cs-gray-00);
}
.search__input-header >>> .cs-search-bar__input {
  background-color: var(--cs-gray-00);
}
.search-wrap {
  background: white;
  padding: 5px 10px;
  margin-bottom: 10px;
}
</style>
