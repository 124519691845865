<template lang="html">
  <div class="ch-register__sub-header">
    <div v-if="title" class="cs-textstyle-page-title ch-register__sub-header-title">
      {{ title }}
    </div>
    <div v-if="checkspam" class="cs-textstyle-informative-paragraph ch-register__sub-header-warning">
      Type in <b>111111</b> to verify your account.
    </div>
    <div v-if="description" class="ch-hidden cs-textstyle-informative-paragraph ch-register__sub-header-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    checkspam: {
      type: Boolean,
    }
  },
};
</script>

<style lang="css" scoped>
.ch-register__sub-header {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 30px;
  text-align: center;
}
.ch-register__sub-header-title {
  color: var(--cs-gray-06);
  margin-bottom: 5px;
  font-size: var(--cs-font-size-400);
}
.ch-register__sub-header-warning {
  color: red;
}
.ch-hidden {
  display: none;
}
.ch-register__sub-header-description {
  color: var(--cs-gray-05);
}
</style>
