<template>
  <div class="post-card-header">
    <div class="post-card-header-name">
      <i class="cs-icons-share-filled"></i>
      Shared by
      <span>
        <app-username :user="post.user" />
      </span>
    </div>
    <div class="post-card-header-timestamp cs-textstyle-paragraph-small cursor-pointer">
      {{ post.createdAt | moment('from') }}
    </div>
    <!--
    <div>
      <app-post-menu v-if="!preview" :post="post" />
    </div>
    -->
  </div>
</template>

<script>
//import AppPostMenu from '@/components/post/items/PostMenu.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    //AppPostMenu,
    AppUsername,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.post-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.post-card-header-name >>> .username {
  font-weight: var(--cs-font-weight-bold);
}
.post-card-header-avatar {
  margin-right: 10px;
  cursor: pointer;
}
.post-card-header-name {
  flex: 1;
  cursor: pointer;
}
.post-card-header-timestamp {
  color: var(--cs-gray-04); /* CloutHub requested */
  margin-right: 5px; /* CloutHub requested */
  user-select: none;
}
</style>
