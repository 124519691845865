<template>
  <div>
    <app-button-plain v-if="group.myMembership" @click="showActionsheet = true">
      <img class="icon-action" src="@/assets/icons/option.svg" alt="Options" />
    </app-button-plain>
    <app-action-sheet
      ref="actionSheet"
      :open="showActionsheet"
      @close="showActionsheet = false"
    >
      <app-actions-title>{{ group.name }}</app-actions-title>

      <h4 class="notification-config-title">Notifications</h4>

      <div>
        <app-config-item
          label="All Posts"
          explanation="Every post in the group."
          :icon="bell"
          :active="
            group.myMembership.notificationsType === GroupNotificationsType.ALL
          "
          @click="setNotificationsType(GroupNotificationsType.ALL)"
        />

        <app-config-item
          label="Friends' and Followers' posts"
          explanation="Only your friends’ and followers' posts."
          :icon="messageSquare"
          :active="
            group.myMembership.notificationsType ===
            GroupNotificationsType.FRIENDS_AND_FOLLOWERS
          "
          @click="
            setNotificationsType(GroupNotificationsType.FRIENDS_AND_FOLLOWERS)
          "
        />

        <app-config-item
          label="Off"
          explanation="Only mentions and important updates to group settings or privacy."
          :icon="bellOff"
          :active="
            group.myMembership.notificationsType === GroupNotificationsType.NONE
          "
          @click="setNotificationsType(GroupNotificationsType.NONE)"
        />
      </div>

      <hr class="divider" />

      <div>
        <app-button-plain class="button-action" @click="leaveGroup">
          <img
            class="icon-action button-action-icon"
            src="@/assets/icons/log-out.svg"
            alt="Leave Group"
          />
          Leave Group
        </app-button-plain>
      </div>
    </app-action-sheet>
  </div>
</template>

<script>
import $groups from '@/services/groups';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppButtonPlain from '@/components/general/ButtonPlain.vue';
import AppActionsTitle from '@/components/general/ActionsTitle.vue';

import SetGroupNotificationsType from '@/gql/groups/SetGroupNotificationsType.gql';
import AppConfigItem from '@/components/general/ConfigItem.vue';

import bell from '@/assets/icons/bell.svg';
import bellOff from '@/assets/icons/bell-off.svg';
import messageSquare from '@/assets/icons/message-square.svg';

const GroupNotificationsType = {
  ALL: 'ALL',
  FRIENDS_AND_FOLLOWERS: 'FRIENDS_AND_FOLLOWERS',
  NONE: 'NONE',
};

export default {
  components: {
    AppConfigItem,
    AppActionsTitle,
    AppActionSheet,
    AppButtonPlain,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showActionsheet: false,
      GroupNotificationsType,
      bell,
      messageSquare,
      bellOff,
    };
  },
  methods: {
    async leaveGroup() {
      await $groups.leaveGroup(this.group);
      this.showActionsheet = false;
    },
    async setNotificationsType(notificationsType) {
      await this.$apollo.mutate({
        mutation: SetGroupNotificationsType,
        variables: {
          groupId: this.group.id,
          notificationsType,
        },
        optimisticResponse: {
          set_group_notifications_type: {
            ...this.group.myMembership,
            notificationsType,
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.icon-action {
  height: 24px;
  width: 24px;
}

.button-action {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.notification-config-title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.divider {
  margin: 20px 0;
  background-color: #e7eaea;
}
</style>
