<template lang="html">
  <ion-refresher slot="fixed" @ionRefresh="refresh">
    <ion-refresher-content
      pulling-text="Pull to refresh"
      refreshing-spinner="dots"
      refreshing-text="Refreshing...">
    </ion-refresher-content>
  </ion-refresher>
</template>

<script>
export default {
  mounted() {
    const el = this.$el;
    const parent = el && el.parentNode;
    if (!parent || parent.nodeName !== 'ION-CONTENT') console.warn('pull-to-refresh should be a child of ion-content');
    const siblings = parent && parent.children;
    const first = siblings && siblings[0];
    if (first !== this.$el) console.warn('pull-to-refresh should probably be the top element of ion-content');
  },
  methods: {
    refresh($event) {
      this.$emit('refresh', $event);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
