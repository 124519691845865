<template>
  <span @click="openUploadVideoModal">
    <slot />
  </span>
</template>

<script>
import $bus from '@/services/bus';

export default {
  methods: {
    openUploadVideoModal() {
      $bus.$emit('show-create-video-modal');
    },
  },
};
</script>
