<template>
  <div class="follow-button">
    <cs-button
      v-if="target.myRelationship && target.myRelationship.isFollower"
      :size="size"
      variant="secondary"
      fill="outline"
      :title="unfollowTip"
      @click="unfollow()"
      >Unfollow</cs-button
    >
    <cs-button
      v-else
      :size="size"
      variant="primary"
      :title="followTip"
      @click="follow()"
      >Follow</cs-button
    >
  </div>
</template>
<script>
import $relationshipsApi from '@/api/relationships';
import $popups from '@/services/popups';

export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    errorCaptured(err, vm, info) {
      console.log(`cat EC: ${err.toString()}\ninfo: ${info}`);
      return false;
    },
    target: {
      // The target user, including relationships object if any
      type: Object,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    followTip() {
      return `Follow to see more from ${this.target.username} in your feed`;
    },
    unfollowTip() {
      return `Unfollow to see less from ${this.target.username} in your feed`;
    },
  },
  methods: {
    async follow() {
      try {
        await $relationshipsApi.followUser(this.target);
      } catch (error) {
        $popups.alert('Follow limit reached. Please try again later.');
      }
    },
    async unfollow() {
      await $relationshipsApi.unfollowUser(this.target);
    },
  },
};
</script>
<style scoped>
.follow-button .cs-button {
  min-width: 100px;
  width: 100px;
}
</style>
