<template>
  <div>
    <div v-if="user && shouldShowPosts" class="post-comment">
      <app-avatar
        :picture="user.profilePhotoURL"
        :name="user.displayname"
        size="small"
        class="post-comment-avatar"
      >
      </app-avatar>
      <div class="post-comment-body">
        <div class="post-comment-input-wrapper">
          <div class="post-comment-input-container">
            <app-textarea
              :id="`${parent.id}_composer`"
              ref="composer"
              v-model="bodyText"
              :maxlength="500"
              placeholder="Write a reply..."
              class="post-comment-input cs-textstyle-paragraph"
              rows="1"
              @input="handleInput"
            />
            <div class="post-comment-icons-wrapper">
              <i
                class="ch-icons-add-media attachment-icon"
                @click="attach()"
              ></i>
              <i
                :id="`${parent.id} gif-popover`"
                class="ch-icons-gif attachment-icon"
                @click="openGIFsModal()"
              ></i>
              <i class="cs-icons-send-filled send-icon" @click="send()"></i>
            </div>
          </div>
          <div class="ch-list-container">
            <!-- User list to mention in comment -->
            <app-mention-user
              v-if="showList"
              :is-comment="true"
              :user-list="userList"
              @mention-user="mentionUser"
            />
          </div>
          <div v-if="link" class="live-link-container">
            <i
              class="cs-icons-close-filled close-icon remove-link"
              @click="removeLinkPreview"
            ></i>
            <cs-link-preview
              :title="link.title"
              :src="link.url"
              :description="link.details"
              :picture="link.image"
            />
          </div>
          <div>
            <app-post-attachment
              v-if="attachments.length"
              :attached-image="attachments"
              @remove="removeAttachment"
            ></app-post-attachment>
          </div>
          <div v-if="loadingLink" class="cs-spinner-container-comment">
            <cs-spinner class="ch-spinner"></cs-spinner>
          </div>
        </div>
        <app-upload-progress v-if="saving" />
      </div>
    </div>
    <div
      v-if="blockedUserFound"
      class="post-comment-editor__chars cs-textstyle-paragraph-small text-red"
    >
      You are not allowed to mention this user!
    </div>
    <div
      v-if="bodyText && bodyText.length"
      class="post-comment-editor__chars cs-textstyle-paragraph-small"
    >
      {{ bodyText ? bodyText.length : 0 }}/500 Characters
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $popups from '@/services/popups';
import $upload from '@/services/upload';
import $utils from '@/services/utils';
// Components
import AppAvatar from '@/components/general/Avatar.vue';
import AppTextarea from '@/components/general/Textarea.vue';
import AppPostAttachment from '@/components/general/Attachments.vue';
import AppUploadProgress from '@/components/general/UploadProgress.vue';
import AppMentionUser from '@/components/post/composer/MentionUser.vue';
// GQL
import GetLinkPreview from '@/gql/general/GetLinkPreview.gql';
import InsertPost from '@/gql/posts/InsertPost.gql';
import GetGroup from '@/gql/groups/GetGroup.gql';
import UserSearch from '@/gql/search/UserSearch.gql';

export default {
  components: {
    AppAvatar,
    AppTextarea,
    AppPostAttachment,
    AppUploadProgress,
    AppMentionUser,
  },
  props: {
    parent: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      bodyText: '',
      showGifsModal: false,
      showEmojiPicker: false,
      attachments: [],
      show: false,
      saving: false,
      blockedUserList: new Set(),
      blockedUserFound: false,
      link: '',
      loadingLink: false,
      ignoredLinks: [],
      linkTimer: null,
      user: null,
      showList: false,
      userList: null,
    };
  },
  computed: {
    displayGroups() {
      return this.groups.filter((group) => !group.parentGroupId);
    },
    userIsMember() {
      if (!this.group || !this.group.myMembership) return null;
      return this.group.myMembership.role !== 'pending-member';
    },
    userIsSuperAdmin() {
      return $auth.isAdmin();
    },
    shouldShowPosts() {
      return !this.group || this.userIsMember || this.userIsSuperAdmin;
    },
  },
  apollo: {
    group: {
      query: GetGroup,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return !this.id;
      },
    },
  },
  async mounted() {
    this.user = await $auth.getUser();
  },
  methods: {
    mentionUser(user) {
      const textarea = this.$refs.composer.$el.querySelector('textarea');
      const cursorPos = textarea.selectionStart;
      const beforeText = this.bodyText.substring(0, cursorPos);
      const beforeCursorWords = beforeText.replace(/\n/g, ' ').split(' ');
      const currentWord = beforeCursorWords[beforeCursorWords.length - 1];
      this.bodyText = this.bodyText.replace(currentWord, `${user.username} `);
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPos + user.username.length;
        textarea.focus();
        this.showList = false;
      });
    },
    // IMAGES
    async attach() {
      const type = 'image/*';
      const files = await $utils.selectFiles(type);
      files.forEach((file) => {
        file.dataurl = URL.createObjectURL(file);
        this.attachments.push(file);
      });
    },
    removeAttachment() {
      this.attachments = [];
    },

    // GIFS
    openGIFsModal() {
      $bus.$emit('show-select-gif-modal');
      $bus.$once('gif-modal-select', this.selectedGIF);
    },
    selectedGIF(gif) {
      const gifImage = {
        url: gif.url,
        name: 'Embedded Gif',
        type: 'image/gif',
        contentType: 'image/gif',
      };
      this.attachments.push(gifImage);
    },
    // POSTING
    async send() {
      if (
        (!this.bodyText && !this.attachments.length) ||
        this.blockedUserFound
      ) {
        return false;
      }
      this.saving = true;
      try {
        const comment = {
          parentPostId: this.parent.id,
          rootPostId: this.parent.rootPostId,
          postLevel: (this.parent.postLevel * 1 + 1).toString(),
          bodyText: this.bodyText,
          images: [],
        };
        if (this.link) {
          comment.linkPreview = {
            ...this.link,
          };
        }
        if (this.attachments.length) {
          comment.images = await $upload.uploadImages(this.attachments);
        }
        const optimisticResponse = { ...comment };
        optimisticResponse.id = Date.now();
        optimisticResponse.createdAt = new Date();
        optimisticResponse.timestamp = Date.now();
        optimisticResponse.user = this.user;
        optimisticResponse.reactionTotals = [];
        optimisticResponse.myReaction = {};
        optimisticResponse.replyCount = 0;
        optimisticResponse.sharedPostCount = 0;
        optimisticResponse.__typename = 'Post';

        await this.$apollo.mutate({
          mutation: InsertPost,
          variables: comment,
          optimisticResponse: {
            create_post: optimisticResponse,
          },
          update: (cache, mutationResponse) => {
            cache.modify({
              id: cache.identify({
                __typename: 'Post',
                id: comment.rootPostId,
              }),
              fields: {
                commentsCount(prevCount) {
                  return prevCount + 1;
                },
              },
            });
            if (this.$route.name !== 'Home') {
              cache.modify({
                id: cache.identify(this.parent),
                fields: {
                  replies: (cachedRefs = [], { toReference }) => {
                    const newRef = toReference(
                      cache.identify(mutationResponse.data.create_post)
                    );
                    return [newRef, ...cachedRefs];
                  },
                },
              });
            } else {
              this.$router.push({
                name: 'PostDetail',
                params: { id: this.parent.rootPostId },
              });
            }
            this.saving = false;
            this.bodyText = '';
            this.attachments = [];
            this.ignoredLinks = [];
            this.link = '';
          },
        });
        this.saving = false;
        this.$emit('reply');
      } catch (e) {
        this.saving = false;
        const error = JSON.stringify(e, ['message']);
        if (error.includes('prohibited')) {
          $popups.alert(`${e}`);
        } else
          $popups.alert(
            'There was an error while posting the comment.  Please try again later or contact support'
          );
        throw e;
      }
      return false;
    },
    setFocus() {
      this.$refs.composer.focus();
    },
    async getLinks(notIgnoredLinks) {
      this.link = '';
      const url = notIgnoredLinks[0];
      this.loadingLink = true;
      try {
        const { data } = await this.$apollo.query({
          query: GetLinkPreview,
          variables: {
            url,
          },
        });
        if (data) {
          const link = {
            ...data.link_preview,
            url,
          };
          delete link.__typename;
          this.link = link;
          link.url = url;
          this.$emit('detectLink', link);
        }
      } catch (e) {
        // TODO: track this error
        // eslint-disable-next-line
        console.log('e', e);
      }
      this.loadingLink = false;
    },
    removeLinkPreview() {
      this.ignoredLinks.push(this.link.url);
      this.link = '';
    },
    async searchUsers(query) {
      const { data } = await this.$apollo.query({
        query: UserSearch,
        variables: {
          query,
        },
      });
      this.userList = data.users;
      data.users.forEach((user) => {
        if (user.myRelationship.isBlocked)
          this.blockedUserList.add(user.username);
      });
    },
    async handleInput() {
      if (!this.bodyText.length) {
        this.ignoredLinks = [];
        this.link = '';
      }
      if (!this.link) {
        clearTimeout(this.linkTimer);
        const links = this.bodyText.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)/g
        );
        if (links) {
          const notIgnoredLinks = links.filter(
            (link) => !this.ignoredLinks.includes(link)
          );
          if (notIgnoredLinks.length) {
            this.linkTimer = setTimeout(
              () => this.getLinks(notIgnoredLinks),
              700
            );
          }
        }
      }
      const textarea = this.$refs.composer.$el.querySelector('textarea');
      const cursorPos = textarea.selectionStart;
      const beforeText = this.bodyText.substring(0, cursorPos);
      const beforeCursorWords = beforeText.replace(/\n/g, ' ').split(' ');
      const currentWord = beforeCursorWords[beforeCursorWords.length - 1];

      if (currentWord.startsWith('@')) {
        this.showList = true;
        clearTimeout(this.userSearchTimer);
        this.userSearchTimer = setTimeout(
          () => this.searchUsers(currentWord),
          700
        );
      } else this.showList = false;
      const foundMentionnedUsers = this.bodyText.match(/\B@[a-z0-9_-]+/gi);
      let containsBlockedUsers;
      if (foundMentionnedUsers && this.blockedUserList) {
        containsBlockedUsers = foundMentionnedUsers.reduce(
          (accumulator, mentionedUser) =>
            accumulator || !!this.blockedUserList.has(mentionedUser),
          false
        );
      }
      this.blockedUserFound = containsBlockedUsers;
      if (containsBlockedUsers) {
        this.$emit('blockPosting', true);
      } else {
        this.$emit('blockPosting', false);
      }
    },
  },
};
</script>

<style scoped>
.ch-post-comment-composer {
  position: relative;
}
.post-comment {
  margin-bottom: 5px; /* CloutHub requested */
  display: flex;
  flex-direction: row;
  align-items: center; /* CloutHub requested */
}
.post-comment-avatar {
  margin-top: 4px;
  margin-right: 5px; /* CloutHub requested */
  align-self: flex-start; /* CloutHub requested */
}
.post-comment-body {
  position: relative;
  flex: 1;
  width: 0;
}
.post-comment-input-wrapper {
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  padding-right: 10px;
}
.post-comment-input-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.post-comment-input {
  flex: 1;
}
.post-comment-input >>> textarea {
  border: none;
  background: none;
  padding: 12px 5px; /* CloutHub requested */
  line-height: 1.2; /* CloutHub requested */
}
.post-comment-icons-wrapper {
  margin-top: 5px; /* CloutHub requested */
}
.send-icon {
  color: var(--cs-primary-base);
  font-size: 22px;
  padding: 5px;
}
.attachment-icon {
  color: var(--cs-gray-04);
  margin-right: 4px;
  font-size: 18px;
  padding: 5px 0;
}
.gifs {
  position: absolute;
}
.live-link-container {
  position: relative;
}
.live-link-container .remove-link {
  position: absolute;
  right: 0;
  /* background: #e4e4e4; */
  padding: 0 14px;
  border-radius: 7px;
  font-weight: 600;
  font-size: 19px;
  height: 25px;
  width: 40px;
  display: flex;
  align-items: center;
}
.cs-spinner-container-comment {
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-comment-spinner-box {
  display: flex;
  justify-content: center;
  gap: 40px;
}
.ch-list-container {
  width: 100%;
  background: white;
  margin-left: 0;
}
.post-comment-editor__chars {
  margin: 3px 0;
  text-align: right;
  color: var(--cs-gray-04);
}

.text-red {
  color: red;
}
</style>
