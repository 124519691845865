<template>
  <div v-if="seen" class="ch-reaction-bar">
    <div
      v-for="reaction in reactions"
      :key="reaction.unicode"
      class="emoji-wrapper"
      @click="react(reaction.value)"
    >
      <img
        class="emoji"
        :src="'./assets/emojis/' + reaction.value + '.png'"
        :alt="reaction.value"
      />
    </div>
  </div>
</template>

<script>
// NOTE: Required when ReactionList is displayed inside a Ionic Popover
import $apollo from '@/services/apollo';
import $auth from '@/services/auth';

import reactions from '@/assets/emojis/reactions.json';
import SetPostReaction from '@/gql/posts/SetPostReaction.gql';
import GetPost from '@/gql/posts/GetPost.gql';
import ListPostReactions from '@/gql/posts/ListPostReactions.gql';
import GetVideo from '@/gql/videos/GetVideo.gql';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      seen: true,
      reactions,
    };
  },
  methods: {
    updateReactionSummary(existingFieldData, vars) {
      const newData = [];
      let found = false;
      let dataToPush;
      existingFieldData.forEach((summary) => {
        dataToPush = summary;
        if (summary.type === vars.type) {
          dataToPush = {
            count: summary.count + 1,
            type: summary.type,
          };
          found = true;
        }
        newData.push(dataToPush);
      });
      if (!found) {
        newData.push({
          count: 1,
          type: vars.type,
        });
      }
      return newData;
    },
    async addPostReaction(data) {
      const vars = {
        postId: this.post.id,
        type: data,
      };
      const hasReaction = !!this.myReaction;
      await $apollo.mutate({
        mutation: SetPostReaction,
        variables: vars,
        optimisticResponse: {
          react_post: {
            ...vars,
            id: Date.now(),
            __typename: 'PostReaction',
          },
        },
        refetchQueries: [
          {
            query: GetVideo,
            variables: {
              id: this.post.id,
            },
          },
          {
            query: GetPost,
            variables: {
              id: this.post.id,
            },
          },
          {
            query: ListPostReactions,
            variables: {
              id: this.post.id,
            },
          },
        ],
        update: (cache, mutationResponse) => {
          const query = ListPostReactions;
          const queryVariableTypes = [null, data];
          queryVariableTypes.forEach((type) => {
            const variables = {
              id: this.post.id,
              type,
            };
            const queryCache = cache.readQuery({
              query,
              variables,
            });
            if (queryCache && queryCache.list_post_reactions) {
              const userId = $auth.getUserId();
              cache.writeQuery({
                query,
                variables,
                data: {
                  list_post_reactions: [
                    mutationResponse.data.react_post,
                    ...queryCache.list_post_reactions,
                  ],
                },
              });
            }
          });
          cache.modify({
            id: cache.identify(this.post),
            fields: {
              reactionSummary: (existingFieldData) =>
                this.updateReactionSummary(existingFieldData, vars),
              reactionCount: (existingFieldData) => {
                if (hasReaction) {
                  return existingFieldData || 0;
                }
                return (existingFieldData || 0) + 1;
              },
              myReaction: (_existingFieldData, { toReference }) =>
                toReference(cache.identify(mutationResponse.data.react_post)),
            },
          });
        },
      });
    },
    async react(data) {
      this.seen = false;
      this.addPostReaction(data);
      this.$emit('closeThis');
    },
  },
};
</script>

<style>
/* .ch-reaction-bar {
  text-align: center;
} */
.emoji-wrapper {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 2px;
}
.emoji {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
</style>
