<template>
  <div class="ch-attachments">
    <!-- Images -->
    <div v-if="attachedImage && attachedImage.length > 0" class="row w-100 mr-0">
      <div v-for="(image, index) in attachedImage" :key="index" class="col-md-6">
        <div class="ch-post-attachment">
          <i class="cs-icons-delete ch-attachment-delete" @click="$emit('remove', image)"></i>
          <app-img :src="image.url" class="ch-image" v-if="image.type === 'image/gif'" />
          <app-img  :src="image.url" class="ch-image"  v-else-if="image.url"/>
          <app-img  :src="image.dataurl" class="ch-image" v-else />
        </div>
      </div>
    </div>
    <div class="ch-attachment-container" v-if="attachment">
      <!-- Video -->
      <div v-if="attachment.type.startsWith('video')" class="ch-post-attachment">
        <i class="cs-icons-delete ch-attachment-delete" @click="$emit('remove')"></i>
          <!-- 
            <app-video :src="attachmentSrc" /> 
            Has a problem in iOS native - need to investigate
          -->
          <cs-video-player :src="attachmentSrc" />
      </div>
      <!-- File Preview -->
      <div v-if="attachment.type.startsWith('application') || attachment.type.startsWith('document')" class="ch-post-attachment">
        <i class="cs-icons-delete ch-attachment-delete" @click="$emit('remove')"></i>
        <cs-file-preview
          :src="attachmentSrc"
          :file-type="attachment.type"
          :title="attachment.name"
          :file-size="attachment.size"
          class="attachment-file"
        ></cs-file-preview>
      </div>
    </div>
  </div>
</template>

<script>
import $cdn from '@/services/cdn';
import AppImg from '@/components/general/Img.vue';
import AppVideo from '@/components/general/Video.vue';

export default {
  components: {
    AppImg,
    AppVideo
  },
  props: {
    attachment: {
      type: [Object, File],
    },
    attachedImage: Array,
  },
  computed: {
    attachmentSrc() {
      return this.attachment.dataurl || $cdn.optimize(this.attachment.url);
    },
  },
};
</script>

<style scoped>
.ch-attachments {
  padding-bottom: 15px;
  overflow: auto;
  max-height: 400px;
}
.ch-post-attachment {
  position: relative;
  max-width: 250px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.ch-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.ch-image {
  height: 120px;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
  /* flex: 1 auto; */
}
.ch-attachment-container {
  position: relative;
  /* display: flex;
  flex: 1; */
  padding: 10px;
}
.attachment-file {
  flex: 1;
}
.ch-image-box {
  display: flex;
  flex-direction: row;
}
.ch-attachment-delete {
  background: var(--cs-white);
  font-size: 20px;
  padding: 5px 11px;
  right: 5px;
  cursor: pointer;
  position: absolute;
  color: var(--cs-secondary-dark);
  z-index: 1;
  border-radius: 10px;
  top: 5px;
  font-weight: 700;
  opacity: 0;
  transition: 1s ease-in;
}
.ch-post-attachment:hover .ch-attachment-delete {
  opacity: 1;
  transition: 1s ease-out;
}
.ch-post-attachment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000026;
  opacity: 0;
  border-radius: 15px;
  transition: 1s ease-in;
}
.ch-post-attachment:hover::before {
  opacity: 1;
  transition: 1s ease-out;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: 0;
}
.w-100 {
  width: 100% !important;
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
</style>
