<template>
  <portal to="modals">
    <app-form-modal
      :show="show"
      :title="title"
      :saving="saving"
      @close="$emit('close')"
    >
      <app-image-crop
        :image="image"
        :aspect-ratio="aspectRatio"
        @cropped="$emit('cropped', $event)"
      />
    </app-form-modal>
  </portal>
</template>

<script>
// General components
import AppFormModal from '@/components/general/FormModal.vue';

// Group common components
import AppImageCrop from '@/components/general/ImageCrop.vue';

export default {
  components: {
    AppImageCrop,
    AppFormModal,
  },
  props: {
    image: {
      type: String,
      required: false,
      default: '',
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 1 / 1,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      saving: false,
      checkingSlug: false,
    };
  },
  computed: {
    title() {
      return 'Crop';
    },
  },
};
</script>
