<template>
  <app-action-sheet :open="value" @close="close">
    <app-action-sheet-button v-if="isAuthor" @click="editVideo">
      Edit Video
    </app-action-sheet-button>
    <app-action-sheet-button v-if="isAuthor" @click="deleteVideo">
      Delete Video
    </app-action-sheet-button>
    <app-action-sheet-button v-if="!isAuthor" @click="report('video')">
      Report Video
    </app-action-sheet-button>
    <app-action-sheet-button v-if="isAuthor" @click="report('own-video')">
      Report Video
    </app-action-sheet-button>
    <app-action-sheet-button v-if="!isAuthor" @click="report('user')">
      Report <app-username :user="post.user" />
    </app-action-sheet-button>
    <app-action-sheet-button v-if="!isAuthor" @click="blockAuthor">
      Block <app-username :user="post.user"/>
    </app-action-sheet-button>
    <app-action-sheet-button v-if="isSuperadmin" @click="deleteVideo">
      [SA] Delete Video
    </app-action-sheet-button>
  </app-action-sheet>
</template>
<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $popups from '@/services/popups';

import $videosApi from '@/api/videos';

import AppUsername from '@/components/general/Username.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';

export default {
  components: {
    AppUsername,
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    isAuthor() {
      return this.post && this.post.user.id === $auth.getUserId();
    },
    isSuperadmin() {
      return $auth.isAdmin();
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    editVideo() {
      $bus.$emit('show-edit-video-modal', this.post);
    },
    async deleteVideo() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Video',
        message: 'Are you sure you wish to delete this video?',
      });
      if (!confirmDelete) return;
      await $videosApi.deleteVideo(this.post);
      await this.$router.push({ name: 'VideoHome' });
    },
    report(type) {
      const isVideoReport = type === 'video' || type === 'own-video';
      $bus.$emit('show-report-action', {
        report: isVideoReport ? this.post : this.post.user,
        reportType: type,
      });
    },
    blockAuthor() {
      $bus.$emit('show-block-user', this.post.user);
    },
  }
}
</script>