<template>
  <div class="ch-search-result"  @click="viewUser(user)">
    <app-avatar
      class="avatar"
      :picture="user.profilePhotoURL"
      :name="user.displayname"
    />
    <div>{{user.username}}</div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    user: Object,
  },
  methods: {
    viewUser(user) {
      this.$router.push({
        name: 'Profile',
        params: user,
      });
    },
  },
};
</script>

<style scoped>
.ch-search-result{
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  gap: 10px;
  background-color: var(--cs-gray-00);
}
</style>
