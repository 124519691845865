<template>
  <div>
    <app-modal
      :action="actionLabel"
      :show="show"
      class="modal image-preview-modal"
      :link="data[currentSlide].src"
      title="Preview"
      @close="close"
      @action="downloadImage()"
    >
      <v-zoomer-gallery
        v-model="currentSlide"
        class="v-zommer"
        :list="allImages"
      ></v-zoomer-gallery>
    </app-modal>
  </div>
</template>

<script>
import $cdn from '@/services/cdn';
import $popups from '@/services/popups';
import AppModal from '@/components/general/Modal.vue';

export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Array,
    },
    profilePicture: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    allImages() {
      return this.data.map((img) => img.src || img.url);
    },
    actionLabel() {
      if (window.imagedownloader) {
        return 'Save';
      }
      return '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    downloadImage() {
      const item = this.data[0];
      const url = $cdn.optimize(item.src || item.url);
      window.imagedownloader.download(
        url,
        () => {
          this.$toast.open({
            message: 'Saved to phone',
            type: 'success',
            duration: 2000,
            dismissible: true,
            position: 'top',
          });
        },
        () => {
          $popups.alert('Unable to save');
        }
      );
    },
  },
};
</script>

<style scoped>
* >>> .ch-modal__body {
  padding: 0px !important;
}
.v-zommer {
  width: 100%;
  height: 100%;
}
</style>
