<template lang="html">
  <ion-page>
    <ion-header>
      <app-category-header title="Members" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <template v-if="group">
        <!-- Lists of members visible only in public groups or groups the user is a member of -->
        <div v-if="userIsMember || group.privacy === 'public'">
          <app-group-members-list-card :group="group" role="pending-member" />
          <app-group-members-list-card :group="group" role="admin" />
          <app-group-members-list-card :group="group" role="moderator" />
          <app-group-members-list-card :group="group" role="member" />
        </div>

        <!-- Warning for a private group the user is not allowed to view content of -->
        <app-group-private-warning
          v-if="!userIsMember && group.privacy !== 'public'"
        />
      </template>
      <cs-spinner v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import $auth from '@/services/auth';

// GQL
import GetGroup from '@/gql/groups/GetGroup.gql';

// General components
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';

// Group common components
import AppGroupMembersListCard from '@/components/groups/GroupMembersListCard.vue';
import AppGroupPrivateWarning from '@/components/groups/GroupPrivateWarning.vue';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppGroupMembersListCard,
    AppGroupPrivateWarning,
  },
  apollo: {
    group: {
      query: GetGroup,
      variables() {
        return {
          id: this.$route.params.id,
          viewerId: $auth.getUserId(),
        };
      },
    },
  },
  computed: {
    userIsMember() {
      if (!this.group || !this.group.myMembership) return null;
      const membership = this.group.myMembership.role;
      return membership !== 'pending-member';
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.group.refetch();
      $event.target.complete();
    },
  },
};
</script>
