<template>
  <div class="ch-profile__card">
    <app-spinner v-if="!groups"/>
    <app-group-list v-else :users="groups" :meeting="meeting" />
    <app-infinite-offset :query="$apollo.queries.groups" />
  </div>
</template>

<script>
import ListManagedGroups from '@/gql/groups/ListManagedGroups.gql';
import AppGroupList from '@/components/meetings/tabs/MeetingGroupList.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    AppGroupList,
    AppSpinner,
    AppInfiniteOffset,
  },
  props: {
    meeting: Object,
  },
  apollo: {
    groups: {
      query: ListManagedGroups,
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
};
</script>
