<template>
  <div>
    <app-video-item-mini
      v-for="video in videos"
      :key="video.id"
      :video="video"
      :show-bookmark="showBookmark"
      :show-queue="showQueue"
      :is-history="isHistory"
    />
    <app-infinite-offset :query="$apollo.queries.videos"></app-infinite-offset>
  </div>
</template>
<script>
import AppVideoItemMini from '@/components/videos/PublicVideoItemMini.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    AppVideoItemMini,
    AppInfiniteOffset,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    /*
    showQueue: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBookmark: {
      type: Boolean,
      default: false,
      required: false,
    },
    isHistory: {
      type: Boolean,
      default: false,
      required: false,
    },
    */
  },
  apollo: {
    videos: {
      query() {
        return this.query;
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.videos.refetch();
    },
  },
};
</script>
