<template>
  <div class="conversation-message-content" :class="incomingOutgoingClass">
    <div v-if="outgoing" class="conversation-message-content__menu">
      <i class="cs-icons-options" @click="openDeleteSheet()"></i>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="!!message.msgBody"
      class="cs-textstyle-paragraph-small conversation-message-content__text"
    >
      {{ msgText }}
    </div>
    <!-- Link Preview -->
    <div v-if="message && message.linkPreview" class="link-preview">
      <app-link-preview
        :title="message.linkPreview.title"
        :description="message.linkPreview.details"
        :picture="message.linkPreview.image"
        :src="message.linkPreview.url"
        :preview="true"
      />
    </div>
    <!-- File -->
    <div v-if="message && message.document">
      <cs-file-preview
        :src="cdn(message.document.url)"
        :file-type="message.document.type"
        :title="message.document.name"
        :file-size="message.document.size"
        class="attachment-file"
        @click="openPDF(message.document.url)"
      ></cs-file-preview>
    </div>
    <!-- Video -->
    <div v-if="message && message.video">
      <cs-video-player :src="cdn(message.video.url)" />
    </div>
    <!-- Images -->
    <div
      v-if="message && message.images && message.images.length > 0"
      class="ch-image-container"
      @click.stop="openImagePreview"
    >
      <div v-for="(image, index) in message.images" :key="image.url + index">
        <app-img :src="cdn(image.url)" class="ch-image" />
      </div>
      <app-image-preview
        :show="visible"
        :data="message.images"
        @close="visible = false"
      />
    </div>
    <!-- Shared Post -->
    <div v-if="message && message.sharedPost" class="ch-shared-post">
      <app-conversation-post-preview :shared-post="message.sharedPost" />
    </div>

    <!-- Delete and confirm delete message -->
    <template v-if="outgoing">
      <app-action-sheet :open="showDeleteSheet" @close="closeDeleteSheet">
        <app-action-sheet-button @click="confirmDelete = true"
          >Delete message</app-action-sheet-button
        >
      </app-action-sheet>
      <app-popup
        :show="confirmDelete"
        type="confirm"
        variant="danger"
        ok-label="Delete"
        title="Delete Message"
        message="Are you sure you wish to delete this message?"
        @close="confirmDelete = false"
        @ok="deleteMessage(message)"
      />
    </template>
  </div>
</template>

<script>
import $browser from '@/services/browser';
import $cdn from '@/services/cdn';
// import * as linkify from 'linkifyjs';

// Components
import AppImg from '@/components/general/Img.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppPopup from '@/components/general/Popup.vue';
import AppLinkPreview from '@/components/general/LinkPreview.vue';
import AppConversationPostPreview from '@/components/conversations/ConversationPostPreview.vue';
import AppImagePreview from '@/components/general/ImagePreview.vue';

// GQL
import DeleteMessage from '@/gql/conversations/DeleteMessage.gql';

export default {
  components: {
    AppImg,
    AppActionSheet,
    AppActionSheetButton,
    AppPopup,
    AppLinkPreview,
    AppImagePreview,
    AppConversationPostPreview,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    outgoing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDeleteSheet: false,
      lightboxIndex: 0,
      visible: false,
      confirmDelete: false,
    };
  },
  computed: {
    incomingOutgoingClass() {
      return this.outgoing
        ? 'conversation-message-content--outgoing'
        : 'conversation-message-content--incoming';
    },
    msgText() {
      // const linkInMsg = linkify.find(this.message.msgBody);
      // if (linkInMsg.length > 0) {
      //   let newMsgBody = JSON.parse(JSON.stringify(this.message.msgBody));
      //   linkInMsg.forEach((link) => {
      //     newMsgBody = newMsgBody
      //       .split(link.value)
      //       .join(`<a href=${link.href} target="_blank">${link.value}</a>`);
      //   });
      //   return newMsgBody;
      // }
      return this.message.msgBody;
    },
  },
  methods: {
    openImagePreview() {
      this.visible = true;
    },
    cdn(url) {
      return $cdn.optimize(url);
    },
    openPDF(url) {
      $browser.open(this.cdn(url));
    },
    // ActionSheet for Delete Messages
    closeDeleteSheet() {
      this.showDeleteSheet = false;
    },
    openDeleteSheet() {
      if (this.outgoing) {
        this.showDeleteSheet = true;
      }
    },
    // Delete Message
    async deleteMessage(message) {
      await this.$apollo.mutate({
        mutation: DeleteMessage,
        variables: {
          messageId: message.id,
        },
        update: (cache) => {
          const cacheId = cache.identify(message);
          cache.modify({
            fields: {
              list_conversation_messages(existingMsgRefs, { readField }) {
                return existingMsgRefs.filter(
                  (msgRef) => message.id !== readField('id', msgRef)
                );
              },
              list_conversations: (_existingFieldData, { toReference }) =>
                toReference(cacheId),
            },
          });
        },
      });
    },
  },
};
</script>

<style scoped>
.conversation-message-content {
  padding: 10px;
  border-radius: 5px;
  min-width: 80px;
}
.conversation-message-content--incoming {
  background-color: var(--cs-primary-lightest);
}
.conversation-message-content--outgoing {
  background-color: var(--cs-gray-01);
}
.conversation-message-content__menu {
  text-align: right;
  color: var(--cs-gray-04);
}

.conversation-message-content__text {
  color: var(--cs-gray-05);
}

.ch-image {
  padding: 10px;
  object-fit: contain;
}

.link-preview {
  margin-top: 2px;
}

.link-preview >>> .ch-link-preview {
  padding: 0px;
}
</style>
