<template>
  <div class="ch-video-list" :class="direction">
    <cs-spinner v-if="!videos" class="ch-spinner" />
    <div v-if="videos" class="ch-video-list__main">
      <div
        v-for="(video, index) in safeVideos"
        :key="video.id + index"
        class="ch-video-list__main--wrapper"
      >
        <app-video-item :video="video" :direction="direction"></app-video-item>
      </div>
      <cs-empty-state
        v-if="!videos.length"
        title="No Matching Videos"
        class="ch-video-list__empty cs-textstyle-detail-heading"
      ></cs-empty-state>
    </div>
  </div>
</template>

<script>
import AppVideoItem from '@/components/videos/PublicVideoItem.vue';

export default {
  components: {
    AppVideoItem,
  },
  props: {
    videos: {
      type: Array,
      required: false,
      default: () => [],
    },
    direction: {
      type: String,
      required: false,
      default: 'row',
    },
  },
  computed: {
    safeVideos() {
      return this.videos.filter((video) => video.safe);
    },
  },
};
</script>

<style scoped>
.ch-video-list.row .ch-video-list__main {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.ch-video-list.column .ch-video-list__main {
  display: flex;
  flex-direction: column;
}
.ch-video-list.row .ch-video-list__main .ch-video-list__main--wrapper {
  padding: 7px; /* CloutHub requested */
}
.ch-video-list__main--wrapper {
  padding: 7px 0; /* CloutHub requested */
}
.ch-video-list__empty {
  border: dashed 1px var(--cs-gray-02);
  border-radius: 10px;
  margin-top: 10px;
  flex: 1;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
