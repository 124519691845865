<template>
  <ion-page>
    <ion-header>
      <app-category-header />
    </ion-header>

    <ion-content>
      <div class="category-banner">
        <app-img
          class="category-banner-img"
          :src="categoryImage"
          auto-optimize
        />

        <h2 class="category-banner-title">{{ categoryTitle }}</h2>
      </div>

      <div class="group-search">
        <app-text-input
          v-model="search"
          class="home-group-search-input"
          placeholder="Search Groups"
        />
      </div>

      <p v-if="search && !showSearchResults" class="category-min-search-length">
        Type at least three characters
      </p>
      <app-linear-group-list
        v-else
        :loading="loading"
        :groups="groups"
      ></app-linear-group-list>

      <app-infinite-offset
        v-if="showSearchResults"
        key="search-groups-offset"
        query-key="groups"
        :limit="100"
        :query="$apollo.queries.searchedGroups"
      />
      <app-infinite-offset
        v-else
        key="list-groups-offset"
        query-key="groups"
        :query="$apollo.queries.listedGroups"
      />
    </ion-content>
  </ion-page>
</template>

<script>
// GQL
import ListCategoryGroups from '@/gql/groups/ListCategoryGroups.gql';
import FindGroupsByCategory from '@/gql/groups/FindGroupsByCategory.gql';

// General components
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppLinearGroupList from '@/components/groups/LinearGroupList.vue';
import AppTextInput from '@/components/search/TextInput.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';
import AppImg from '@/components/general/Img.vue';

import categories from '@/assets/config/groupcategories.json';

const titleMap = {};
const categoryImageMap = new Map(categories.map((c) => [c.value, c.img]));
categories.forEach((cat) => {
  titleMap[cat.value] = cat.label;
});

export default {
  components: {
    AppCategoryHeader,
    AppImg,
    AppLinearGroupList,
    AppTextInput,
    AppInfiniteOffset,
  },
  data() {
    return {
      category: this.$route.params.category,
      search: '',
      before: new Date(),
    };
  },
  computed: {
    categoryTitle() {
      const type = this.category;
      return titleMap[type] || type;
    },
    categoryImage() {
      return categoryImageMap.get(this.category);
    },
    showSearchResults() {
      return this.search && this.search.length >= 3;
    },
    groups() {
      return this.showSearchResults ? this.searchedGroups : this.listedGroups;
    },
    groupsQuery() {
      return this.showSearchResults
        ? this.$apollo.queries.searchedGroups
        : this.$apollo.queries.listedGroups;
    },
    loading() {
      return this.groupsQuery.loading;
    },
  },
  watch: {
    $route() {
      this.category = this.$route.params.category;
    },
  },
  apollo: {
    listedGroups: {
      query: ListCategoryGroups,
      fetchPolicy: 'network-only',
      variables() {
        return {
          category: this.category,
          before: this.before,
        };
      },
      skip() {
        return this.showSearchResults;
      },
      update(data) {
        return data.groups;
      },
    },
    searchedGroups: {
      query: FindGroupsByCategory,
      fetchPolicy: 'network-only',
      variables() {
        return {
          category: this.category,
          query: this.search,
        };
      },
      skip() {
        return !this.showSearchResults;
      },
      update(data) {
        return data.groups;
      },
      throttle: 500,
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: #ffffff;
}

.group-search {
  padding: 15px 15px;
}

.category-min-search-length {
  margin: 0 16px;
}

.category-banner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 48%;
}

.category-banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-banner-title {
  position: absolute;
  bottom: 9px;
  left: 12px;
  margin: 0;
  font-size: 22px;
  font-weight: 800;
  color: #fff;
}
</style>
