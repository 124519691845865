<template>
  <div class="ch-menu-nav-item">
    <i :class="icon" class="ch-menu-nav-item__icon"></i>
    <span class="ch-menu-nav-item__label cs-textstyle-informative-paragraph-bold">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped>
.ch-menu-nav-item {
  display: flex;
  gap: 18px;
  color: var(--cs-gray-06);
  padding: 15px 0;
  flex-direction: row;
  align-items: center;
}
.ch-menu-nav-item__icon {
  color: var(--cs-gray-05);
  font-size: 22px;
}
</style>
