<template>
  <div class="meeting-file-upload">
    <div v-if="uploading" class="upload-progress">
      <app-upload-progress />
    </div>
    <cs-button
      v-if="!uploading"
      size="small"
      :disabled="disableUpload"
      @click="uploadFile"
      >Add file</cs-button
    >
  </div>
</template>

<script>
import $popups from '@/services/popups';
import $utils from '@/services/utils';
import $aws from '@/services/aws';
import $socket from '@/services/socket';

import AppUploadProgress from '@/components/general/UploadProgress.vue';

// GQL
import InsertMeetingFile from '@/gql/meetings/InsertMeetingFile.gql';

export default {
  components: {
    AppUploadProgress,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uploading: false,
    };
  },
  computed: {
    disableUpload() {
      return (
        this.meeting.status === 'TRANSCODING' || this.meeting.status === 'ENDED'
      );
    },
  },
  methods: {
    async uploadFile() {
      const file = await $utils.selectFile('application/*, .pdf, .doc, .docx');
      this.uploading = true;
      try {
        const url = await $aws.uploadFile(file, 'forum');
        const vars = {
          meetingId: this.meeting.id,
          name: file.name,
          size: file.size,
          contentType: file.type,
          description: '',
          url,
        };
        await this.$apollo.mutate({
          mutation: InsertMeetingFile,
          variables: vars,
        });
        this.uploading = false;
        $socket.sendRoom(
          'meeting',
          this.meeting.id,
          'meeting-handouts-modified'
        );
      } catch (e) {
        this.uploading = false;
        $popups.alert(
          'There was an error uploading your file.  Please try again later, or contact support.'
        );
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.meeting-file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}
.upload-progress {
  min-height: 50px;
  padding: 20px;
}
</style>
