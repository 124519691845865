var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meeting-card",class:_vm.direction},[_c('app-img',{staticClass:"meeting-item-thumbnail cursor-pointer",attrs:{"src":_vm.meeting.picture},on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}}),_c('div',{staticClass:"meeting-item-body"},[_c('div',{staticClass:"meeting-info"},[_c('div',{staticClass:"meeting-heading"},[_c('div',{staticClass:"meeting-title cs-textstyle-paragraph-bold no-wrap",on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[(_vm.meeting.privacy === 'private')?_c('i',{staticClass:"cs-textstyle-paragraph cs-icons-lock meeting-private-icon"}):_vm._e(),_vm._v(" "+_vm._s(_vm.meeting.name)+" ")]),_c('div',{staticClass:"meeting-item__icons"},[_c('span',{staticClass:"ch-options"},[(_vm.isAdmin || _vm.isSuperAdmin)?_c('i',{staticClass:"ch-icons-options meeting-color",on:{"click":_vm.openMeetingMenu}}):_vm._e()]),_c('i',{staticClass:"ch-icons-share meeting-color",on:{"click":_vm.shareMeeting}})])]),_c('div',{staticClass:"cs-textstyle-paragraph-small meeting-color"},[_vm._v(" "+_vm._s(_vm.displayTime)+" ")]),(_vm.filteredPeople && _vm.filteredPeople.length)?_c('div',{staticClass:"meeting-meta"},[_vm._m(0),_c('div',{staticClass:"meeting-meta-info"},[_c('div',[(_vm.filteredPeople.length > 1)?_c('div',{staticClass:"\n                cs-textstyle-paragraph-small\n                meeting-color\n                cursor-pointer\n              "},[_c('app-username',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                  name: 'Profile',
                  params: _vm.filteredPeople[0].user,
                }),expression:"{\n                  name: 'Profile',\n                  params: filteredPeople[0].user,\n                }"}],staticClass:"ch-user-mention",attrs:{"user":_vm.filteredPeople[0].user}}),_vm._v("  + "+_vm._s(_vm.filteredPeople.length - 1)+" "+_vm._s(_vm.filteredPeople.length - 1 > 1 ? 'others' : 'other')+" ")],1):_c('div',{staticClass:"cs-textstyle-paragraph-small cursor-pointer"},[_c('app-username',{directives:[{name:"router-link",rawName:"v-router-link",value:({
                  name: 'Profile',
                  params: _vm.filteredPeople[0].user,
                }),expression:"{\n                  name: 'Profile',\n                  params: filteredPeople[0].user,\n                }"}],staticClass:"ch-user-mention",attrs:{"user":_vm.filteredPeople[0].user}})],1)])])]):_vm._e(),_c('div',{staticClass:"truncate-overflow cs-textstyle-paragraph-small text-light",on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[_vm._v(" "+_vm._s(_vm.meeting.description || 'No description')+" ")]),_c('div',{staticClass:"meeting-btns"},[(_vm.members && _vm.members.length)?_c('div',{staticClass:"cs-textstyle-paragraph-small meeting-color",on:{"click":_vm.showMeetingParticipants}},[_vm._v(" "+_vm._s(_vm.members.length)+" Participants ")]):_vm._e(),(
            _vm.meeting.status !== 'ENDED' && _vm.meeting.status !== 'TRANSCODING'
          )?_c('div',{staticClass:"meeting-button-class"},[(_vm.meeting.status === 'LIVE')?_c('cs-button',{staticClass:"cs-button",attrs:{"size":"small","fill":"clear"},on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[_vm._v("Join")]):_vm._e(),(
              _vm.isScheduled &&
              !_vm.meeting.myMembership &&
              _vm.meeting.status !== 'LIVE'
            )?_c('cs-button',{staticClass:"cs-button",attrs:{"size":"small","fill":"clear"},on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[_vm._v("Attend")]):_vm._e(),(
              _vm.isScheduled && _vm.meeting.myMembership && _vm.meeting.status !== 'LIVE'
            )?_c('cs-button',{staticClass:"cs-button",attrs:{"fill":"clear","size":"small"},on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[_vm._v("Attending")]):_vm._e()],1):_c('div',{staticClass:"meeting-button-class"},[_c('cs-button',{staticClass:"cs-button",attrs:{"fill":"clear","size":"small"},on:{"click":function($event){return _vm.openMeeting(_vm.meeting)}}},[_vm._v("Watch")]),(!_vm.isScheduled && _vm.isAdmin && _vm.meeting.recordingUrlMp4 !== null)?_c('cs-button',{staticClass:"cs-button",attrs:{"fill":"clear","size":"small"},on:{"click":_vm.downloadMeeting}},[_vm._v(" Download ")]):_vm._e()],1)])])]),_c('app-meeting-menu',{attrs:{"show-meeting-options":_vm.showMenu,"meeting":_vm.meeting,"is-admin":_vm.isAdmin,"list-view":""},on:{"close-meeting-menu":_vm.closeOption}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ch-user-mention-info"},[_c('div',{staticClass:"cs-textstyle-paragraph-small text-light"},[_vm._v("Hosted by")])])}]

export { render, staticRenderFns }