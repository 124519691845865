<template>
  <div>
    <div v-if="post" class="publisher">
      <div class="publisher__second-row">
        <app-post-author-bar :post="post" hide-friend />
        <div class="bookmark-queue-buttons">
          <app-bookmark-button :post="post" />
          <app-queue-button :post="post" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPostAuthorBar from '@/components/post/items/PostAuthorBar.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';

export default {
  components: {
    AppPostAuthorBar,
    AppBookmarkButton,
    AppQueueButton,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewProfile() {
      this.$router.push({ name: 'Profile', params: this.post.user });
    },
  },
};
</script>

<style scoped>
.publisher {
  background-color: var(--cs-gray-00);
}
.publisher__second-row {
  display: flex;
  justify-content: space-between;
}
.bookmark-queue-buttons {
  display: flex;
  position: relative;
  bottom: -65px;
  height: fit-content;
}
</style>
