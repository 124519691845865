<template>
  <ion-page>
    <ion-content>
      <div class="ch-login">
        <div>
          <div class="ch-login__img-class">
            <img src="@/assets/logo/clouthub-logo.png" />
          </div>
          <ch-register-subheader
            title="Welcome"
            description="Login with your email and password"
          />
          <cs-spinner v-if="loading" />
          <form v-if="!loading" class="ch-login__fields">
            <cs-input
              v-model="email"
              label="Email"
              type="text"
              required
              placeholder="Email Address"
              style="color:white !important"
            />
            <app-password-input v-model="password" label="Password" required />
            <div class="ch-login-options">
              <cs-checkbox
                v-model="rememberUser"
                label="Remember Me"
              ></cs-checkbox>
              <a
                v-router-link="{ name: 'ResetPassword' }"
                class="cs-textstyle-paragraph"
                >Forgot Password?</a
              >
            </div>
            <div>
              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="login"
                @expired="onCaptchaExpired"
              >
                <cs-button block corners="round" size="medium">
                  Continue
                </cs-button>
              </vue-recaptcha>
            </div>
          </form>
          <br />
          <cs-button v-router-link="{ name: 'Signup' }" fill="clear" block>
            Create New Account
          </cs-button>
          <div class="login-links">
            <a @click="reportBug()">Report a Problem</a> ·
            <a @click="openExternal(termsOfUseUrl)">Terms of Use</a>
            ·
            <a @click="openExternal(privacyPolicyUrl)">Privacy Policy</a>
          </div>
        </div>
      </div>
      <!-- OTP modal -->
      <app-verify-email-modal
        v-if="showVerifyModal"
        :show-verify-modal="showVerifyModal"
        :email="email"
        @close-verify-modal="closeVerifyModal"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import $popups from '@/services/popups';
import $browser from '@/services/browser';
import Login from '@/gql/general/Login.gql';
import CheckEmailVerification from '@/gql/auth/CheckEmailVerification.gql';
import ChRegisterSubheader from '@/components/register/Subheader.vue';
import AppPasswordInput from '@/components/general/PasswordInput.vue';
import AppVerifyEmailModal from '@/components/onboarding/VerifyEmailModal.vue';
import { policyPagesUrls, gReCaptchaSiteKey } from '@/constants';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    ChRegisterSubheader,
    AppPasswordInput,
    AppVerifyEmailModal,
    VueRecaptcha,
  },
  data() {
    return {
      sitekey: gReCaptchaSiteKey,
      email: localStorage.email,
      errMsg: null,
      loading: false,
      rememberUser: !!localStorage.email,
      password: localStorage.password || '',
      showOnLoad: false,
      showVerifyModal: false,
      token: null,
      ...policyPagesUrls,
    };
  },
  mounted() {
    localStorage.skipOnboarding = true;
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    async login(recaptchaToken) {
      this.errMsg = null;
      localStorage.clear();
      if (this.email && this.password) {
        if (this.rememberUser) {
          localStorage.email = this.email.trim();
          localStorage.password = this.password.trim();
        }
        try {
          this.loading = true;
          const resp = await this.$apollo.query({
            query: Login,
            variables: {
              email: this.email.trim(),
              password: this.password.trim(),
              recaptchaToken,
            },
          });
          this.token = resp.data.token;
          this.checkIfEmailVerified();
        } catch (err) {
          this.showOnLoad = true;
          this.loading = false;
          $popups.alert(err.message);
        }
      }
    },
    async checkIfEmailVerified() {
      const response = await this.$apollo.query({
        query: CheckEmailVerification,
        variables: {
          email: this.email.trim(),
        },
      });
      if (response.data.verified) {
        this.redirectToApp();
      } else {
        this.loading = false;
        this.showVerifyModal = true;
      }
    },
    async redirectToApp() {
      this.loading = true;
      localStorage.access_token = this.token;
      let loggedInUrl =
        this.$route.query.redirect === undefined
          ? '#/tabs/home'
          : `#${this.$route.query.redirect}`;
      if (this.$route.query.url) {
        loggedInUrl += `?url=${this.$route.query.url}`;
        if (this.$route.query.title) {
          loggedInUrl += `title=${this.$route.query.title}`;
        }
      }
      window.location.replace(loggedInUrl);
      window.location.reload();
    },
    closeVerifyModal(verified) {
      this.showVerifyModal = false;
      if (verified) {
        this.redirectToApp();
      }
    },
    openExternal(url) {
      $browser.open(url);
    },
    reportBug() {
      let device = 'mobile';
      if (window.cordova) {
        device = `mobile.installed.${window.cordova.platformId}`;
      }
      const feedbackLink = `https://app.clouthub.com/feedback.html?device=${device}`;
      this.openExternal(feedbackLink);
    },
  },
};
</script>

<style scoped>
.ch-login {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-content: center;
  background: inherit;
}
.ch-login__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-login__img-class > img {
  width: 250px;
}
.ch-login__fields {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
  color: white !important;
}
a {
  color: var(--cs-primary-base);
  text-decoration: none;
}
.login-links {
  text-align: center;
  margin-top: 15px;
}
.ch-login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cs-input__input {
  color: white !important;
}
</style>
