<template>
  <ion-page>
    <ion-header class="slide-in">
      <transition name="slide-fade">
        <app-category-header
          v-if="!beforeClosing"
          title="Post Details"
          @closing="onClosing"
        />
      </transition>
    </ion-header>

    <ion-content :scroll-events="true"
      @ionScroll="handleScroll($event)"
      class="slide-in post-details-page" >
      <transition name="slide-fade">
        <div v-if="!beforeClosing">
          <app-pull-to-refresh @refresh="refresh" />
          <cs-spinner v-if="!post"></cs-spinner>
          <app-post-card
            v-if="post"
            :post="post"
            :autoplay="true"
            @refresh="refreshVideo"
          ></app-post-card>
        </div>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script>
import GetPost from '@/gql/posts/GetPost.gql';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';

export default {
  components: {
    AppPullToRefresh,
    AppPostCard,
    AppCategoryHeader,
  },
  data() {
    return {
      beforeClosing: false,
      show: true,
    };
  },
  apollo: {
    post: {
      query: GetPost,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  methods: {
    onClosing() {
      this.beforeClosing = true;
    },
    async refresh($event) {
      await this.$apollo.queries.post.refetch();
      $event.target.complete();
    },
    async refreshVideo() {
      this.$apollo.queries.post.skip = false;
      await this.$apollo.queries.post.refetch();
    },
    async handleScroll(event){
      if(this.post.type === "video") {
        const videoContainer = document.querySelector('.video-container');
        const videoPostitionOffset = videoContainer.offsetTop;
        if(event.detail.scrollTop >= videoPostitionOffset && !videoContainer.classList.contains('video-container-sticky')) {
          videoContainer.classList.add("video-container-sticky");
          videoContainer.style.position = "fixed";
        } else {
          if(event.detail.scrollTop <= videoPostitionOffset) {
            videoContainer.classList.remove("video-container-sticky");
            videoContainer.style.position = "unset";
          }
        }
      }
    }
  },
};
</script>

<style scoped>
@-webkit-keyframes slideInSmooth {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slide-in {
  -webkit-animation: slideInSmooth ease-in 1;
  animation: slideInSmooth ease-in 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
}

.slide-fade-enter-active {
  transition: all 0.4s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.4s ease-in;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  -webkit-transform: translate3d(-100%, 0, 0);
  opacity: 0;
}
</style>
