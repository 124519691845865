<template>
  <div>
    <cs-button
      v-if="target.myRelationship && target.myRelationship.isFollower"
      :size="size"
      variant="secondary"
      fill="outline"
      :title="unfollowTip"
      class="follow-button"
      @click="unfollow()"
      >UNFOLLOW</cs-button
    >
    <cs-button
      v-else
      :size="size"
      :title="followTip"
      class="unfollow-button"
      @click="follow()"
      >FOLLOW</cs-button
    >
  </div>
</template>
<script>
import $relationshipsApi from '@/api/relationships';
import $popups from '@/services/popups';

export default {
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    target: {
      // The target user, including relationships object if any
      type: Object,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    followTip() {
      return `Follow to see more from ${this.target.username} in your feed`;
    },
    unfollowTip() {
      return `Unfollow to see less from ${this.target.username} in your feed`;
    },
  },
  methods: {
    async follow() {
      try {
        await $relationshipsApi.followUser(this.target);
        this.$emit('changed');
      } catch (error) {
        $popups.alert('Follow limit reached. Please try again later.');
      }
     
    },
    async unfollow() {
      await $relationshipsApi.unfollowUser(this.target);
      this.$emit('changed');
    },
  },
};
</script>
<style scoped>
.follow-button >>> .cs-button {
  min-width: 100px;
  color: var(--cs-gray-05);
  /* width: 130px; */
}
.unfollow-button >>> .cs-button {
  min-width: 100px;
  color: var(--cs-gray-00);
}
</style>
