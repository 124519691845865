import { render, staticRenderFns } from "./VideoProfileHeader.vue?vue&type=template&id=dffca87a&scoped=true&"
import script from "./VideoProfileHeader.vue?vue&type=script&lang=js&"
export * from "./VideoProfileHeader.vue?vue&type=script&lang=js&"
import style0 from "./VideoProfileHeader.vue?vue&type=style&index=0&id=dffca87a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dffca87a",
  null
  
)

export default component.exports