<template>
  <div>
    <div class="mb-2 cs-textstyle-item-heading">
      Upcoming Events
      <button @click="setFilter('all')">All</button>
      <button @click="setFilter('mine')">My Upcoming</button>
    </div>
    <cs-spinner v-if="!allDataLoaded" />
    <div
      v-if="
        allDataLoaded &&
        filteredScheduledStreams.length &&
        allStreamUsersLoaded
      "
      class="stream-grid"
    >
      <div
        v-for="stream in filteredScheduledStreams"
        :key="stream.cssid"
        class="scheduled-stream-item"
      >
        <router-link :to="linkValue(stream)">
          <div class="channel-item">
            <div class="channel-item__image">
              <img :src="stream.stream_image || defaultPicture" alt="Stream Image">
            </div>
            <div class="channel-item__info">
              <h3>{{ stream.stream_title }}</h3>
              <p>{{ convertToEST(stream.stream_datetime) }}</p>
              <p>{{ streamUsers[stream.cssid].user.displayname }}</p>
              <p>{{ stream.stream_description.slice(0, 500) }}</p>
            </div>
          </div>
        </router-link>
        <div class="channel-item__option" v-if="isStreamOwnedByUser(stream)">
          <app-context-menu>
            <app-context-menu-item @click="deleteStream(stream.cssid)">
              Delete Stream
            </app-context-menu-item>
          </app-context-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import getScheduledStreams from '@/gql/posts/ScheduleGetSchedule.gql';
import GetProfile from '@/gql/general/GetProfile.gql';
import DeleteScheduledStream from '@/gql/posts/ScheduleDeleteSchedule.gql';
import AppAvatar from '@/components/general/Avatar.vue';
import AppContextMenu from '@/components/general/ContextMenu.vue';
import AppContextMenuItem from '@/components/general/ContextMenuItem.vue';

export default {
  components: {
    AppAvatar,
    AppContextMenu,
    AppContextMenuItem,
  },
  data() {
    return {
      scheduledStreams: [],
      streamUsers: {},
      allStreamUsersLoaded: false,
      defaultPicture: require('@/assets/images/placeholders/event_defaultimg.png'),
      currentUserId: $auth.getUserId(), // Assuming you will set this somewhere, probably in the mounted() hook
      currentFilter: 'all',
      allDataLoaded: false, // To prevent the delayed loading
    };
  },
  computed: {
    linkValue() {
      return function(stream) {
        return { name: 'VideoProfile', params: { id: this.streamUsers[stream.cssid].user.id } };
      };
    },
    sortedScheduledStreams() {
      return [...this.scheduledStreams].sort(
        (a, b) => new Date(a.stream_datetime) - new Date(b.stream_datetime)
      );
    },
    filteredScheduledStreams() {
      if (this.currentFilter === 'all') {
        return this.sortedScheduledStreams;
      }

      if (this.currentFilter === 'mine') {
        return this.sortedScheduledStreams.filter(
          (stream) => this.isStreamOwnedByUser(stream)
        );
      }
    },
  },
  apollo: {
    scheduledStreams: {
      query: getScheduledStreams,
      result({ data }) {
        this.scheduledStreams = data.scheduledstreams_getSchedule || [];

        let fetches = this.scheduledStreams.map((stream) => {
          return this.$apollo.query({
            query: GetProfile,
            variables: {
              id: stream.uid,
            },
          });
        });

        Promise.all(fetches).then((results) => {
          results.forEach(({data}, index) => {
            this.streamUsers[this.scheduledStreams[index].cssid] = data;
          });

          this.allStreamUsersLoaded = true;
          this.allDataLoaded = true; 
        });
      },
    },
  },
  methods: {
    async deleteStream(cssid) {
      if (confirm("Are you sure you want to delete this?")) {
        await this.$apollo.mutate({
          mutation: DeleteScheduledStream,
          variables: { cssid },
        });
        this.scheduledStreams = this.scheduledStreams.filter(
          (stream) => stream.cssid !== cssid
        );
        alert('Stream deleted successfully!');
      }
    },
    isStreamOwnedByUser(stream) {
      return this.currentUserId === stream.uid;
    },
    setFilter(filter) {
      this.currentFilter = filter;
    },
    convertToEST(datetime) {
        let date = new Date(datetime);
        return date.toLocaleString("en-US", {timeZone: "America/New_York"});
    },
  },
};
</script>

<style scoped>
.stream-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.scheduled-stream-item {
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  flex-basis: calc(50% - 10px); /* 50% for 2 columns, -10px for the margin-right */
  box-sizing: border-box;
}

.channel-item {
    display: flex;
    align-items: flex-start; /* make the image and the text independent of each other's height */
}

.channel-item__image {
    width: 150px; /* specify a bigger fixed width for the image container */
    height: 150px; /* specify a bigger fixed height for the image container */
    overflow: hidden; /* prevent the image from overflowing its container */
    flex-shrink: 0; /* prevent the image block from shrinking */
}

.channel-item__image img {
    width: 100%; 
    height: 100%;
    object-fit: cover; /* ensures the aspect ratio of the image is maintained */
}
  .channel-item__info {
    padding-left: 20px; /* add some space between the image and the text */
    flex: 1; /* makes the text block take up the remaining space */
  }

  .channel-item__info h3 {
    margin-bottom: 10px;
  }

  .channel-item__info p {
    margin-bottom: 10px;
  }

  .channel-item__info p:last-child {
    margin-bottom: 0;
  }


.scheduled-stream-item h3 {
  margin-bottom: 10px;
}

.scheduled-stream-item p {
  margin-bottom: 10px;
}

.scheduled-stream-item p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .scheduled-stream-item {
    flex-basis: 100%;
  }
}
.channel-item__option {
  color: var(--cs-gray-04);
  cursor: pointer;
}

</style>
