<template>
  <div>
    <app-modal
      v-if="showModal"
      class="live-stream-modal"
      :show="showModal"
      title="Live Stream"
      @close="showModal = false"
    >
      <div class="cs-textstyle-paragraph">
        To stream with third-party software, please use the following settings:
      </div>
      <cs-input
        label="Stream URL"
        value="rtmp://rtmp.clouthub.com:5222/app"
      ></cs-input>
      <cs-input label="Stream Key" :value="streamKey"></cs-input>
    </app-modal>
    <app-action-sheet :open="showMenu" @close="showMenu = false">
      <app-action-sheet-button @click="openLiveSite()"
        >Phone Camera</app-action-sheet-button
      >
      <app-action-sheet-button @click="showModal = true"
        >Other Software</app-action-sheet-button
      >
    </app-action-sheet>
    <cs-button
      size="small"
      variant="default"
      class="ch-profile__button btn-class"
      block
      @click="showMenu = true"
      >Go Live</cs-button
    >
  </div>
</template>

<script>
import $browser from '@/services/browser';
import GetLiveStream from '@/gql/general/GetLiveStream.gql';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppModal from '@/components/general/Modal.vue';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
    AppModal,
  },
  apollo: {
    get_live_stream: {
      query: GetLiveStream,
    },
  },
  data() {
    return {
      showMenu: false,
      showModal: false,
    };
  },
  computed: {
    streamKey() {
      return this.get_live_stream && this.get_live_stream.liveStreamKey;
    },
  },
  methods: {
    openLiveSite() {
      const key = this.streamKey;
      const token = localStorage.access_token;
      $browser.open(
        `https://livestream.clouthub.com/?key=${key}&token=${token}`
      );
    },
  },
};
</script>

<style scoped>
.live-stream-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.btn-class {
  --cs-button-color: #747577 !important;
}
</style>
