<template>
  <div v-if="!embedUrl" class="ch-post-link-preview" @click="open()">
    <app-img
      v-if="picture && !hidePicture"
      hide-on-error
      :src="displayPicture"
      :alt="title"
    />
    <div class="ch-post-link-preview_data">
      <div
        v-if="title"
        class="cs-textstyle-detail-heading ch-post-link-preview__title"
      >
        {{ title }}
      </div>
      <div class="ch-post-link-domain">
        <app-img
          v-if="picture && !hidePicture"
          hide-on-error
          :src="picture"
          :alt="title"
        />
        <div class="cs-textstyle-link-text ch-post-link-preview__source">
          {{ displaySrc }}
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ch-post-link-preview">
    <iframe :src="embedUrl"></iframe>
  </div>
</template>

<script>
import AppImg from '@/components/general/Img.vue';
import $browser from '@/services/browser';
import $shortlinks from '@/services/shortlinks';

export default {
  components: {
    AppImg,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    hidePicture: {
      type: Boolean,
      default: false,
      required: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    linkLabel: {
      type: String,
      default: 'Link',
    },
    picture: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    linkIcon: {
      type: String,
      default: 'cs-icons-link-filled',
    },
  },
  computed: {
    displayPicture() {
      return this.picture;
      // return (
      //   this.picture &&
      //   `https://chproxy.backendcdn.com/image?url=${encodeURIComponent(this.picture)}`
      // );
    },
    displayIcon() {
      return `https://www.google.com/s2/favicons?domain=${this.displaySrc}`;
    },
    displaySrc() {
      let src = this.src;
      if (!src.startsWith('http')) {
        src = `https://${src}`;
      }
      try {
        return src && new URL(src).hostname;
      } catch (err) {
        return this.src;
      }
    },
    embedUrl() {
      return null; // TODO Disabling embedded players for now
      // if (this.src) {
      //   try {
      //     const u = new URL(this.src);
      //     const thisDomain = window.location.hostname;
      //     if (this.src.includes('youtube.com/watch?v=')) {
      //       // YouTube
      //       const v = u.searchParams.get('v');
      //       if (v) {
      //         return `https://www.youtube.com/embed/${v}`;
      //       }
      //     }
      //     else if (this.src.includes('bitchute.com/video')) {
      //       // Bitchute
      //       const btId = this.src.split('/')[4];
      //       return `https://www.bitchute.com/embed/${btId}/`;
      //     }
      //     else if (this.src.includes('www.twitch.tv/videos/')) {
      //       // Twitch
      //       const twitchId = this.src.split('/')[4];
      //       return `https://player.twitch.tv/?video=${twitchId}&parent=${thisDomain}`;
      //     }
      //   } catch (err) {
      //     // If something goes wrong, display a normal link preview
      //   }
      // }
    },
  },
  methods: {
    open() {
      const isClouthubLink = this.src.startsWith(
        process.env.VUE_APP_SHARE_DOMAIN
      );
      if (isClouthubLink) {
        $shortlinks.navigate(this.src);
      } else {
        const email = this.src.match(/.*@.*/);
        if (email) $browser.open(`mailto:${this.src}`);
        else $browser.open(this.src);
      }
    },
  },
};
</script>

<style scoped>
.ch-post-link-preview {
  background-color: var(--cs-gray-01);
  display: block;
}
.ch-post-link-preview img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}
.ch-post-link-preview_data {
  background-color: var(--cs-gray-01);
  padding: 5px 15px; /* CloutHub requested */
  font-weight: normal;
}
.ch-post-link-preview__title {
  max-height: 72px;
  line-height: 1.2; /* CloutHub requested */
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ch-post-link-domain {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 2px; /* CloutHub requested */
}
.ch-post-link-domain img {
  width: 15px;
  height: 15px;
  border-radius: 5px;
}
.ch-post-link-preview__source {
  max-height: 24px;
  word-break: break-all;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  color: var(--cs-gray-04);
  overflow: hidden;
}
.ch-post-link-preview iframe {
  width: 100%;
  height: 250px;
  border-style: none;
  margin: 0px;
}
</style>
