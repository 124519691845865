<template>
  <app-action-sheet :open="open" @close="close">
    <!-- Used when creating a post in a parent group to share it to the subgroups -->
    <div class="cs-textstyle-paragraph-bold" :style="titleStyle">
      Select subgroups to share
    </div>
    <div v-for="subgroup in subgroups" :key="subgroup.id">
      <cs-checkbox
        :label="subgroup.name"
        @input="toggleSubgroup($event, subgroup.id)"
      ></cs-checkbox>
    </div>
    <cs-empty-state
      v-if="subgroups && subgroups.length === 0"
      description="The group does not have any subgroups!"
    />
    <div :style="closeButtonStyle">
      <cs-button fill="outline" size="small" @click="close"> Close </cs-button>
    </div>
  </app-action-sheet>
</template>

<script>
// GQL
import ListSubgroupsByGroupId from '@/gql/groups/ListSubgroupsByGroupId.gql';

// General components
import AppActionSheet from '@/components/general/ActionSheet.vue';

import { sortByName } from '@/services/utils';

// NOTE: Title and Close button have their style set programmatically because actionsheet drops style element
const titleStyle = `
  padding: 10px;
  padding-left: 0;
  font-size: 20px;
`;
const closeButtonStyle = `
  margin-top: 10px;
  text-align: center;
`;

export default {
  components: {
    AppActionSheet,
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    groupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedSubgroups: new Set(),
      groups: [],
      titleStyle,
      closeButtonStyle,
    };
  },
  apollo: {
    groups: {
      query: ListSubgroupsByGroupId,
      variables() {
        return {
          parentGroupId: this.groupId,
        };
      },
      result(x) {
        return x.data.subgroups;
      },
    },
  },
  computed: {
    subgroups() {
      return this.groups.slice().sort(sortByName);
    },
  },
  methods: {
    toggleSubgroup(value, id) {
      if (value) this.selectedSubgroups.add(id);
      else this.selectedSubgroups.delete(id);
      this.update();
    },
    update() {
      this.$emit('changeSelectedSubgroups', Array.from(this.selectedSubgroups));
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
