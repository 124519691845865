<template>
  <ion-col size="6" class="group-card">
    <div
      v-router-link="{ name: 'GroupDetail', params: group }"
      class="group-card-content"
    >
      <div class="group-card-cover">
        <app-img :src="groupPicture" class="group-card-img" />
      </div>
      <div class="group-card-body">
        <div class="group-card-name cs-textstyle-paragraph-bold">
          <i
            v-if="group.privacy !== 'public'"
            class="cs-icons-lock group-card-lock-icon"
          ></i>
          {{ group.name }}
        </div>
        <div class="group-card-summary cs-textstyle-paragraph-small">
          <span>{{ group.memberCount }} {{ membersLabel }}</span>
        </div>
      </div>
    </div>
  </ion-col>
</template>

<script>
// General components
import AppImg from '@/components/general/Img.vue';

const defaultPictureUrl = require('@/assets/images/placeholders/group-picture.png');

export default {
  components: {
    AppImg,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    groupPicture() {
      return this.group.picture ? this.group.picture : defaultPictureUrl;
    },
    membersLabel() {
      if (this.group.memberCount === 1) return 'member';
      return 'members';
    },
  },
};
</script>

<style scoped>
.group-card {
  padding-bottom: 10px;
}
.group-card-content {
  overflow: hidden;
  border: solid 1px var(--cs-gray-02);
  background-color: var(--cs-gray-00);
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}
.group-card-cover {
  position: relative;
  width: 100%;
  height: 132px;
  overflow: hidden;
}
.group-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.group-card-body {
  padding: 8px;
  text-align: center;
}
.group-card-lock-icon {
  color: var(--cs-primary-base);
}
.group-card-name {
  color: var(--cs-gray-07);
}
.group-card-summary {
  margin-top: 4px;
  color: var(--cs-gray-04);
}
</style>
