<template>
  <router-link
    :to="to"
    class-active="active"
    class="ch-page-menu-category-item cs-textstyle-button-text"
  >
    {{ label }}
  </router-link>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.ch-page-menu-category-item {
  text-decoration: none;
  color: var(--cs-gray-06);
  background-color: var(--cs-gray-02);
  display: inline-block;
  border-radius: 20px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px 16px;
  line-height: 1;
}
.ch-page-menu-category-item .active,
.router-link-active {
  background-color: var(--cs-primary-base);
  color: var(--cs-primary-contrast);
}
</style>
