<template>
  <div class="ch-profile__card">
    <app-spinner v-if="!groups" />
    <app-group-list v-else :users="groups" />
    <app-infinite-loader
      :paused="$apollo.queries.groups.loading"
      :finished="!mayHaveMore"
      @more="loadMore()"
    />
  </div>
</template>

<script>
import ListManagedGroups from '@/gql/groups/ListManagedGroups.gql';
import AppGroupList from '@/components/videos/tabs/ChannelGroupList.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
  components: {
    AppGroupList,
    AppSpinner,
    AppInfiniteLoader,
  },
  apollo: {
    groups: {
      query: ListManagedGroups,
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  methods: {
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const offset = this.groups.length;
        this.$apollo.queries.groups.fetchMore({
          variables: {
            offset,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.groups.length;
            return {
              groups: [...previousResult.groups, ...fetchMoreResult.groups],
            };
          },
        });
      }
    },
  },
};
</script>
