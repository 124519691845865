<template>
  <div class="my-groups-list">
    <template v-if="displayGroups && displayGroups.length > 0">
      <app-my-group-list
        :groups="displayGroups"
        :sort-by="roleSortBy"
        @changeSort="roleSortBy = $event"
      />
      <app-infinite-offset :query="$apollo.queries.groups" />
    </template>

    <template v-else-if="!$apollo.queries.groups.loading">
      <div class="block welcome">
        <h2 class="title">Welcome!</h2>
        <p>
          Come on in and check out our groups! Here are some groups you might
          like like to join.
        </p>
      </div>

      <div class="block row">
        <h2 class="title">Recommended for You</h2>
        <router-link :to="{ name: 'RecommendedGroupsList' }" class="link"
          >See More</router-link
        >
      </div>
    </template>
  </div>
</template>

<script>
// GQL
import ListMyGroups from '@/gql/groups/ListMyGroups.gql';

// General components
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

// Group common components
import AppMyGroupList from '@/components/groups/MyGroupList.vue';

export default {
  apollo: {
    groups: {
      query: ListMyGroups,
      variables() {
        return {
          sortBy: 'role',
          roleSortBy: this.roleSortBy,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  components: {
    AppInfiniteOffset,
    AppMyGroupList,
  },
  data() {
    return {
      roleSortBy: 'joined',
    };
  },
  computed: {
    displayGroups() {
      return this.groups && this.groups.filter((group) => !group.parentGroupId);
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.groups.refetch();
    },
  },
};
</script>

<style scoped>
.block {
  padding: 0 15px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #747576;
}

.welcome {
  margin-bottom: 30px;
}

.block p {
  margin: 0 0 5px;
  line-height: inherit;
}

.row {
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 0 0 5px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.link {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #3f76e9;
}

.my-groups-list {
  position: relative;
}
</style>
