<template>
  <cs-spinner v-if="!finished || paused" v-observe-visibility="options"></cs-spinner>
</template>
<script>
export default {
  props: {
    paused: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      options: {
        callback: this.visibilityChanged,
      },
    };
  },
  watch: {
    paused() {
      if (!this.paused && this.visible) {
        this.$emit('more');
      }
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      this.visible = isVisible;
      if (!this.paused && isVisible) {
        this.$emit('more');
      }
    },
  },
};
</script>
