<template>
  <app-modal
    class="signup-meeting-modal"
    :show="showSignup"
    title="Signup"
    @close="onClose"
  >
    <cs-form class="meeting-form">
      <app-signup-form :is-meeting="true" @registered="accountCreated" />
    </cs-form>
  </app-modal>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import AppSignupForm from '@/views/onboarding/Signup.vue';

export default {
  components: {
    AppModal,
    AppSignupForm,
  },
  props: {
    showSignup: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClose() {
      this.$emit('close-signup');
    },
    accountCreated() {
      this.$emit('account-created');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.meeting-form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
</style>
