<template>
  <ion-page>
    <ion-header>
      <app-main-header hide-notifications title="Marketplace" />
    </ion-header>
    <ion-content>
      <div class="marketplace-container cs-textstyle-item-heading">
        COMING SOON: CloutHub Marketplace
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';

export default {
  components: {
    AppMainHeader,
  },
};
</script>

<style scoped>
.marketplace-container {
  display: flex;
  height: 85vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
</style>
