<template>
  <ion-page>
    <ion-header>
      <div v-if="user">
        <app-category-header v-if="!cat" :title="user.displayname" />
        <app-category-header v-else title="My Profile" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <cs-spinner v-if="loading" />
      <div class="ch-profile__main">
        <!-- Other User -->
        <div v-if="otherUnblockedUser">
          <div class="ch-profile__body">
            <app-profile-card :user="user"></app-profile-card>
          </div>
          <div class="ch-profile__nav">
            <cs-page-tabs
              v-model="selectedFeed"
              align="justify"
              no-border
              :tabs="tabFilters"
            >
            </cs-page-tabs>
          </div>
          <app-post-list
            v-if="selectedKey === 'others'"
            :selected-key="selectedKey"
            :user="user"
            :with-recommended-content="false"
          ></app-post-list>
          <app-tab-comments v-if="selectedKey == 'comments'" :user="user" />
          <app-tab-mutual v-if="selectedKey == 'mutual'" :user="user" />
        </div>
        <!-- Other User when blocked -->
        <div v-if="otherBlockedUser">
          <app-blocked-user-profile
            :user="user"
            v-if="user.myRelationship.blockedOut"
          />
          <div v-if="user.myRelationship.blockedIn">
            You don't have permission to view this profile.
          </div>
        </div>

        <!-- Logged-in User -->
        <div v-if="cat && user" class="ch-profile__long">
          <div class="ch-profile__body">
            <app-profile-card :user="user"></app-profile-card>
          </div>
          <div class="ch-profile__nav">
            <cs-page-tabs
              v-model="selectedFeed"
              align="justify"
              :tabs="feedFilters"
            >
            </cs-page-tabs>
          </div>
          <app-post-list
            v-if="selectedKey === 'me'"
            :selected-key="selectedKey"
            :user="user"
            :with-recommended-content="false"
          ></app-post-list>
          <app-tab-mutual v-if="selectedKey == 'mutual'" :user="user" />
          <app-tab-comments v-if="selectedKey == 'comment'" :user="user" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';
// Component
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppProfileCard from '@/components/connections/ProfileCard.vue';
import AppPostList from '@/components/post/lists/ProfilePostList.vue';
import AppBlockedUserProfile from '@/components/connections/BlockedUserProfile.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppTabComments from '@/components/connections/TabComments.vue';
import AppTabMutual from '@/components/connections/TabMutual.vue';

// GQL
import GetProfile from '@/gql/general/GetProfile.gql';

export default {
  components: {
    AppCategoryHeader,
    AppProfileCard,
    AppPostList,
    AppPullToRefresh,
    AppBlockedUserProfile,
    AppTabComments,
    AppTabMutual,
  },
  apollo: {
    user: {
      query: GetProfile,
      variables() {
        if (this.$route.params.id) {
          return {
            id: this.$route.params.id,
          };
        }
        return {
          username: `@${this.$route.params.username}`,
        };
      },
      result() {
        if (this.user) {
          this.loading = false;
        }
      },
    },
  },
  data() {
    return {
      selectedFeed: 0,
      loading: true,
      feedFilters: [
        {
          label: 'My Posts',
          key: 'me',
        },
        {
          label: 'Comments',
          key: 'comment',
        },
      ],
      tabFilters: [
        {
          label: 'Posts',
          key: 'others',
        },
        {
          label: 'Comments',
          key: 'comments',
        },
        {
          label: 'Common',
          key: 'mutual',
        },
      ],
    };
  },
  computed: {
    selectedKey() {
      if (this.user && $auth.getUserId() !== this.user.id) {
        const selectedView = this.tabFilters[this.selectedFeed].key;
        return selectedView;
      }

      const selectedView = this.feedFilters[this.selectedFeed].key;
      return selectedView;
    },
    cat() {
      return this.user && $auth.getUserId() === this.user.id;
    },
    otherUnblockedUser() {
      return !this.cat && this.user && !this.user.myRelationship.isBlocked;
    },
    otherBlockedUser() {
      return !this.cat && this.user && this.user.myRelationship.isBlocked;
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.user.refetch();
      $event.target.complete();
    },
  },
};
</script>

<style scoped>
.ch-profile__body {
  border-bottom: 2px solid var(--cs-gray-01);
}
.ch-profile__main {
  display: flex;
  flex-direction: column;
}
.ch-profile__nav {
  background-color: var(--cs-gray-00);
}
</style>
