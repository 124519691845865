<template>
  <div>
    <cs-page-tabs
      v-model="selectedTab"
      :tabs="tabs"
      align="justify"
    ></cs-page-tabs>
    <div v-if="selectedTab == 0">
      <app-spinner v-if="$apollo.queries.users.loading" />
      <div class="search-result__grid">
        <app-channel-circle
          v-for="user in users"
          :key="user.id"
          :user="user"
          show-button
        />
      </div>
      <cs-empty-state
        v-if="users && users.length === 0"
        description="There are currently no channels matching your search"
      />
    </div>
    <div v-if="selectedTab == 1" class="video-search-results">
      <app-spinner v-if="$apollo.queries.videos.loading" />
      <app-video-item-mini
        v-for="video in videos"
        :key="video.id"
        :video="video"
        direction="column"
        :show-share="false"
        class="search-video-item"
      ></app-video-item-mini>
      <cs-empty-state
        v-if="videos && videos.length === 0"
        description="There are currently no videos matching your search"
      />
    </div>
  </div>
</template>

<script>
// import hideKeyboard from '@/mixins/hideKeyboard.mixin';

import AppSpinner from '@/components/general/Spinner.vue';
import AppVideoItemMini from '@/components/videos/VideoItemMini.vue';
import AppChannelCircle from '@/components/videos/new/ChannelCircleNew.vue';

import VideoSearch from '@/gql/search/VideoSearch.gql';
import ChannelSearch from '@/gql/search/ChannelSearch.gql';

export default {
  components: {
    AppSpinner,
    AppVideoItemMini,
    AppChannelCircle,
  },
  props: {
    query: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          label: 'Channels',
        },
        {
          label: 'Videos',
        },
      ],
    };
  },
  apollo: {
    users: {
      query: ChannelSearch,
      variables() {
        return {
          query: this.query,
        };
      },
      skip() {
        return !this.query;
      },
    },
    videos: {
      query: VideoSearch,
      variables() {
        return {
          query: this.query,
        };
      },
      skip() {
        return !this.query;
      },
    },
  },
};
</script>

<style scoped>
.search-result__grid {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.video-search-results {
  margin-top: 3px;
}
</style>
