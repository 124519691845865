<template>
  <div>
    <div class="search-header">Posts</div>
    <div v-if="posts">
      <cs-empty-state
        v-if="showEmptyState"
        title="No matching posts"
        class="cs-textstyle-detail-heading"
      />
      <div v-else>
        <app-post-card
          v-for="post in searchedPosts"
          :key="post.id"
          :post="post"
          :preview="true"
        ></app-post-card>
        <cs-button
          v-if="mayHaveMore && !$apollo.queries.posts.loading"
          block
          fill="outline"
          size="small"
          @click="loadMore"
        >
          More Posts
        </cs-button>
      </div>
    </div>
    <app-spinner v-if="$apollo.queries.posts.loading" />
  </div>
</template>

<script>
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppSpinner from '@/components/general/Spinner.vue';

import PostSearch from '@/gql/search/PostSearch.gql';

export default {
  components: {
    AppPostCard,
    AppSpinner,
  },
  props: {
    search: String,
  },
  apollo: {
    posts: {
      query: PostSearch,
      variables() {
        return {
          query: this.search,
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  computed: {
    searchedPosts() {
      const safePost = this.posts.filter((post) => post.safe);
      if (safePost.length) return safePost;
      this.loadMore();
    },
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.posts && !this.posts.length) return true;
      return false;
    },
  },
  methods: {
    loadMore() {
      const offset = this.posts.length;
      this.$apollo.queries.posts.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMore = !!fetchMoreResult.posts.length;
          return {
            posts: [...previousResult.posts, ...fetchMoreResult.posts],
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
