<template>
  <cs-main-header class="ch-header">
    <div class="ch-header__buttons-left" :class="buttonWidthClass">
<!--
      <app-avatar
        v-if="user"
        :name="user.displayname"
        :picture="user.profilePhotoURL"
        size="small"
        @click="toggleMenu()"
      />
      <i
        v-router-link="{ name: 'universalsearch' }"
        class="ch-icons-search ch-header__search_icon"
      />
-->            
    </div>

    <span class="ch-header__title cs-textstyle-section-heading">{{
      title
    }}</span>

    <div class="ch-header__buttons-right" :class="buttonWidthClass">
<!--
      <slot>
        <app-messages-icon />
        <app-notification-icon />
        <i
          v-if="create"
          class="cs-icons-add-filled ch-header__create-icon"
          @click="$emit('create')"
        ></i>
      </slot>
-->
    </div>
  </cs-main-header>
</template>

<script>
import $bus from '@/services/bus';
// import $auth from '@/services/auth';

//import AppNotificationIcon from '@/components/general/NotificationIcon.vue';
//import AppMessagesIcon from '@/components/general/MessagesIcon.vue';
//import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
//    AppNotificationIcon,
//    AppMessagesIcon,
//    AppAvatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    create: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    buttonWidthClass() {
      if (this.create) return 'ch-header__buttons--3x';
      return 'ch-header__buttons--2x';
    },
  },
  async mounted() {
//    this.user = await $auth.getUser();
  },
  methods: {
    toggleMenu() {
      $bus.$emit('showAppMenu');
    },
  },
};
</script>

<style scoped>
.ch-header {
  background-color: var(--cs-gray-00);
  display: flex;
  padding: 10px 15px;
  border-bottom: solid 1px var(--cs-gray-02);
  align-items: center;
  ---ch-subheader__icon-width: 34px;
  ---ch-subheader__icon-gap: 5px;
  ---ch-subheader__button-padding: 5px;
}
.ch-header__buttons-right,
.ch-header__buttons-left {
  display: flex;
  align-items: center;
}
.ch-header__buttons--1x {
  width: var(---ch-subheader__icon-width);
}
.ch-header__buttons--2x {
  width: calc(
    var(---ch-subheader__icon-width) * 2 + var(---ch-subheader__icon-gap)
  );
}
.ch-header__buttons--3x {
  width: calc(
    var(---ch-subheader__icon-width) * 3 + var(---ch-subheader__icon-gap)
  );
}
.ch-header__buttons-right > *:nth-child(2) {
  margin-left: var(---ch-subheader__icon-gap);
}
.ch-header i {
  font-size: 24px;
  color: var(--cs-gray-05);
  padding: var(---ch-subheader__button-padding);
  display: inline-flex;
  line-height: 1;
}
.ch-header__title {
  color: var(--cs-gray-06);
  flex: 1;
  text-align: center;
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-header .ch-header__create-icon {
  color: var(--cs-primary-base);
}
.ch-header__search_icon {
  font-size: 26px !important;
  line-height: 24px !important;
}
</style>
