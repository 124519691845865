<template>
  <div class="ch-posts-tags">
    <cs-tag
      v-for="(tag, $index) in tags"
      :key="tag + $index"
      v-router-link="{ name: 'Tag', params: { tag: tag } }"
      class="ch-posts-tags__tag"
    >
      #{{ tag }}
    </cs-tag>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.ch-posts-tags__tag {
  margin-right: 4px;
}
</style>
