<template>
  <app-form-modal
    :show="showChangeProfile"
    class="ch-profile-modal"
    action="Save"
    :disable-action="!isChanged"
    :saving="profilePictureUploading"
    @close="onCloseProfileImgModal"
    @action="saveNewProfile"
  >
    <div class="ch-profile-modal__body">
      <div class="ch-profile-modal__header">
        <div>Here's your picture!</div>
      </div>
      <div class="profile-container">
        <app-image-input
          :value="profileImage"
          label="Required ratio: 1 : 1"
          :enforce-ratio="1 / 1"
          @input="changePhoto($event)"
        />
      </div>

      <div class="ch-profile-modal__info">Are you happy with how it looks?</div>
      <div class="ch-profile-modal__change-button">
        <cs-button
          fill="outline"
          size="small"
          :disabled="!isChanged"
          @click="saveNewProfile"
          >Change Photo</cs-button
        >
      </div>
    </div>
  </app-form-modal>
</template>

<script>
import $aws from '@/services/aws';
import $popups from '@/services/popups';

import UpdateUserProfile from '@/gql/general/UpdateUserProfile.gql';

import AppFormModal from '@/components/general/FormModal.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppImageInput from '@/components/general/ImageInput.vue';

export default {
  components: {
    AppFormModal,
    AppAvatar,
    AppImageInput,
  },
  props: {
    showChangeProfile: {
      type: Boolean,
      default: false,
      require: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileImage: this.user.profilePhotoURL,
      file: null,
      isChanged: false,
      profilePictureUploading: false,
    };
  },
  methods: {
    onCloseProfileImgModal() {
      this.$emit('closeProfileImgModal');
    },
    async changePhoto(file) {
      try {
        if (file) {
          this.file = file;
          file.dataurl = URL.createObjectURL(file);
          this.profileImage = file.dataurl;
        } else {
          this.file = null;
          this.profileImage = '';
        }

        this.isChanged = true;
      } catch (error) {
        $popups.alert(`Error: ${error}`);
      }
    },
    async saveNewProfile() {
      try {
        this.profilePictureUploading = true;
        let imgUrl = '';

        if (this.file) {
          imgUrl = await $aws.uploadFile(this.file, null, 'profile');
        }

        this.finalSave(imgUrl);
      } catch (error) {
        this.profilePictureUploading = false;
        $popups.alert(`Error: ${error}`);
      }
    },
    async finalSave(profilePhotoURL) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateUserProfile,
          variables: {
            id: this.user.id,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            displayname: this.user.displayname,
            bio: this.user.bio,
            website: this.user.website,
            profilePhotoURL,
            bannerImageURL: this.user.bannerImageURL,
            location: this.user.location,
            privateLocation: this.user.privateLocation,
          },
        });
        this.profilePictureUploading = false;
        this.onCloseProfileImgModal();
      } catch (e) {
        this.profilePictureUploading = false;
        $popups.alert(
          'Error saving profile.  Please try again later or contact support'
        );
      }
    },
  },
};
</script>

<style scoped>
.ch-profile-modal__header {
  font-size: 20px;
  font-weight: bold;
}
.ch-profile-modal__info {
  font-size: 20px;
  font-weight: 500;
}
.ch-profile-modal__body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.ch-profile-modal__change-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
</style>
