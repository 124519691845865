<template>
  <div>
    <div class="post-response-user">
      <app-avatar
        v-router-link="{ name: profileView, params: post.user }"
        class="post-response-user-avatar"
        :picture="post.user.profilePhotoURL"
        :name="post.user.username"
      >
      </app-avatar>
      <div v-router-link="{ name: profileView, params: post.user }" class="post-response-user-name">
        <div class="cs-textstyle-paragraph-bold">
          {{ name || post.user.username }}
        </div>
        <div class="cs-textstyle-paragraph-small text-light">
          {{ post.createdAt | moment('from') }}
        </div>
      </div>
    </div>
    <div class="post-response-buttons">
      <follow-button v-if="!isMe" :target="post.user" size="small"></follow-button>
      <friend-button v-if="!isMe && !hideFriend" :target="post.user" size="small"></friend-button>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import FollowButton from '@/components/general/FollowButton.vue';
import FriendButton from '@/components/general/FriendButton.vue';
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    FollowButton,
    FriendButton,
    AppAvatar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    hideFriend: {
      type: Boolean,
      default: false,
    },
    profileView: {
      type: String,
      default: 'Profile',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    isMe() {
      return this.post.user.id === $auth.getUserId();
    },
  },
};
</script>

<style scoped>
.post-response-user-avatar {
  margin-right: 10px;
}
.post-response-user-name {
  flex: 1;
  word-break: break-word;
}
.post-response-user {
  display: flex;
  padding-top: 10px;
  margin-top: 10px;
}
.post-response-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
</style>
