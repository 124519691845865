<template>
  <div class="ch-follow-list">
    <cs-spinner v-if="!people" class="ch-spinner" />
    <div v-if="people" class="ch-follow-list__main">
      <div
        v-for="(person, index) in people"
        :key="person.id + index"
        class="ch-follow-list__main--wrapper"
      >
        <app-follow-item
          :person="person"
          :is-meeting="isMeeting"
        ></app-follow-item>
      </div>
      <cs-empty-state
        v-if="!people.length"
        title="No Matching Recommendations"
        class="ch-follow-list__empty cs-textstyle-detail-heading"
      ></cs-empty-state>
    </div>
  </div>
</template>

<script>
import AppFollowItem from '@/components/followCards/FollowItem.vue';

export default {
  components: {
    AppFollowItem,
  },
  props: {
    people: {
      type: Array,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.ch-follow-list .ch-follow-list__main {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.ch-follow-list .ch-follow-list__main .ch-follow-list__main--wrapper {
  padding: 7px; /* CloutHub requested */
}
.ch-follow-list__main--wrapper {
  padding: 7px 0; /* CloutHub requested */
}
.ch-follow-list__empty {
  border: dashed 1px var(--cs-gray-02);
  border-radius: 10px;
  margin-top: 10px;
  flex: 1;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
