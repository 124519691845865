<template>
  <ion-page>
    <ion-header>
      <div class="ch-video-category__header">
        <app-category-header :title="modifiedTitle" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-video-list :videos="videos" direction="column"></app-video-list>
      <!-- Floating Button -->
      <app-video-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppVideoList from '@/components/videos/VideoList.vue';
import AppVideoExploreBtn from '@/components/videos/VideoExploreBtn.vue';
// GQL
import ListCategoryVideos from '@/gql/videos/ListCategoryVideos.gql';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppVideoList,
    AppVideoExploreBtn,
  },
  data() {
    return {
      category: this.$route.params.category,
    };
  },
  computed: {
    modifiedTitle() {
      const titleMap = {
        news: 'News',
        faith: 'Faith',
        politics: 'Politics & Civic',
        music: 'Music',
        finance: 'Finance & Investing',
        business: 'Business',
        health: 'Health & Fitness',
        movies: 'Movies & Shows',
        sports: 'Sports',
        technology: 'Technology',
        games: 'Games & Gaming',
        education: 'Education',
        personalgrowth: 'Personal Growth',
        fashion: 'Fashion & Beauty',
        humor: 'Humor & Memes',
      };
      const type = this.category;

      return titleMap[type] || type;
    },
  },
  watch: {
    $route() {
      this.category = this.$route.params.category;
    },
  },
  apollo: {
    videos: {
      query: ListCategoryVideos,
      variables() {
        return {
          category: this.category,
        };
      },
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.videos.refetch();
      $event.target.complete();
    },
  },
};
</script>
