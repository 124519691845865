<template>
  <ion-page>
    <ion-header>
      <app-category-header title="You Should Follow" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <cs-spinner v-if="!users"></cs-spinner>
      <div v-if="users">
        <app-news-publisher-item v-for="user in users" :key="user.id" :user="user" />
      </div>
      <cs-empty-state v-if="users && users.length === 0" title="No News To Follow">
      </cs-empty-state>
      <app-news-page-menu />
    </ion-content>
  </ion-page>
</template>

<script>
import ListFeaturedPublishers from '@/gql/news/ListFeaturedPublishers.gql';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppNewsPublisherItem from '@/components/news/NewsPublisherItem.vue';
import AppNewsPageMenu from '@/components/news/NewsPageMenu.vue';

export default {
  apollo: {
    users: ListFeaturedPublishers,
  },
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppNewsPublisherItem,
    AppNewsPageMenu,
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.users.refetch();
      $event.target.complete();
    },
  },
};
</script>
