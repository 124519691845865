<template>
  <cs-button
    size="small"
    class="cs-button"
    fill="outline"
    :variant="variant"
    @click="leaveMeeting"
  >
    Leave Event
  </cs-button>
</template>

<script>

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    leaveMeeting() {
        this.$router.push({ name: 'MeetingHome' });
    },
  },
};
</script>
