<template>
  <div class="ch-report__content">
    <div class="ch-report__title">
      <span>Report {{ report.name }}</span>
      <i class="cs-icons-close" @click="$emit('close')" />
    </div>
    <div class="ch-report__options">
      <cs-small-tag
        v-for="option in reportOptions"
        :key="option.label"
        variant="default"
        class="cs-button"
        selectable
        @select="onSelect(option)"
      >
        {{ option.label }}
      </cs-small-tag>
    </div>
    <div class="ch-report__textarea">
      <app-textarea
        v-model="reason"
        label="Help us understand the problem."
        placeholder="Specify the reason"
        :maxlength="300"
      />
      <div class="ch-composer-counter">
        <span class="ch-character-cnt">{{ noOfChars }}/300 characters</span>
      </div>
    </div>
    <cs-button block class="cs-button__submit" @click="submirReport"
      >Submit</cs-button
    >
  </div>
</template>

<script>
import AppTextarea from '@/components/general/Textarea.vue';
import ReportEntity from '@/gql/general/ReportEntity.gql';

export default {
  components: {
    AppTextarea,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
      reasons: [],
      reportOptions: [
        {
          label: 'Inappropriate content',
          value: 'Inappropriate content',
        },
        {
          label: 'Spam',
          value: 'Spam',
        },
        {
          label: 'Abusive language or behavior',
          value: 'Abusive language or behavior',
        },
        {
          label: 'Possible illegal activites',
          value: 'Possible illegal activites',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    };
  },
  computed: {
    noOfChars() {
      const characters = this.reason.length;
      return characters;
    },
  },
  methods: {
    async submirReport() {
      await this.$apollo.mutate({
        mutation: ReportEntity,
        variables: {
          type: 'group',
          id: this.report.id,
          comment: this.reason,
          issues: this.reasons,
        },
      });
      this.$emit('close');
      this.$toast.open({
        message: 'Report Sent',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'bottom',
      });
    },
    onSelect(val) {
      const index = this.reasons.findIndex((t) => t === val.value);
      if (index >= 0) this.reasons.splice(index, 1);
      else this.reasons.push(val.value);
    },
  },
};
</script>

<style scoped>
.ch-report__title {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ch-report__options {
  margin-top: 20px;
  gap: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ch-report__options >>> .cs-small-tag {
  text-transform: none;
  border-radius: 20px;
}
.ch-report__options >>> .cs-small-tag-selectable {
  padding: 8px;
}
.ch-report__textarea {
  margin-top: 20px;
}
.ch-character-cnt {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.cs-button__submit {
  margin-top: 20px;
}
</style>
