<template>
  <div
    v-router-link="{ name: 'NewsProfile', params: user }"
    class="cursor-pointer news-publisher-item"
  >
    <app-avatar
      :name="user.displayname"
      :picture="user.profilePhotoURL"
      size="medium"
    />
    <div>
      <div class="cs-textstyle-paragraph-bold">
        <app-username :user="user" type="displayname" />
      </div>
      <div class="cs-textstyle-paragraph-small text-light">
        <span
          >{{ user.followerCount }}
          {{ user.followerCount == 1 ? 'follower' : 'followers' }}</span
        >
      </div>
      <div class="cs-textstyle-paragraph-small news-publisher-description">{{ user.bio }}</div>
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    AppAvatar,
    AppUsername,
  },
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.news-publisher-item {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
.news-publisher-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
