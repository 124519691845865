<template>
  <div>
    <app-gif-modal />
    <app-share-post-sheet />
    <app-share-list-modal />
    <app-reaction-list-modal />
    <app-share-post-modal />
    <app-report-action />
    <app-post-composer-modal />
    <app-new-message-modal />
    <app-share-sheet />
    <app-edit-comment-modal />
    <app-block-user-modal />
    <app-create-video-modal />
    <app-edit-video-modal />
    <app-create-meeting-modal />
    <app-add-leaders-modal />
    <app-meeting-participants-modal />
    <app-profile-preview-modal />
  </div>
</template>

<script>
import AppPostComposerModal from '@/components/post/modals/PostComposerModal.vue';
import AppReactionListModal from '@/components/post/modals/ReactionListModal.vue';
import AppSharePostSheet from '@/components/post/modals/SharePostSheet.vue';
import AppSharePostModal from '@/components/post/modals/SharePostModal.vue';
import AppEditCommentModal from '@/components/post/modals/EditCommentModal.vue';
import AppShareListModal from '@/components/post/modals/ShareListModal.vue';
import AppGifModal from '@/components/post/modals/GifModal.vue';
import AppReportAction from '@/components/general/report/ReportAction.vue';
import AppShareSheet from '@/components/general/ShareSheet.vue';
import AppNewMessageModal from '@/components/conversations/NewMessageModal.vue';
import AppBlockUserModal from '@/components/general/BlockUserModal.vue';
import AppCreateVideoModal from '@/components/videos/CreateVideoModal.vue';
import AppEditVideoModal from '@/components/videos/EditVideoModal.vue';
import AppCreateMeetingModal from '@/components/meetings/CreateMeetingModal.vue';
import AppAddLeadersModal from '@/components/meetings/AddLeadersModal.vue';
import AppMeetingParticipantsModal from '@/components/meetings/MeetingParticipantsModal.vue';
import AppProfilePreviewModal from '@/components/followCards/ProfilePreviewModal.vue';

export default {
  components: {
    AppShareListModal,
    AppSharePostModal,
    AppReactionListModal,
    AppPostComposerModal,
    AppGifModal,
    AppReportAction,
    AppSharePostSheet,
    AppNewMessageModal,
    AppShareSheet,
    AppEditCommentModal,
    AppBlockUserModal,
    AppCreateVideoModal,
    AppEditVideoModal,
    AppCreateMeetingModal,
    AppAddLeadersModal,
    AppMeetingParticipantsModal,
    AppProfilePreviewModal,
  },
};
</script>
