<template>
  <!-- Embed Code popup -->
  <app-popup
    v-if="shouldShow"
    :show-popup="shouldShow"
    class="popup"
    :no-padding="true"
    @close-popup="backdropClose"
  >
    <div
      :class="[
        getAlertType ? 'popup-content ' + getAlertType : 'popup-content',
      ]"
    >
      <div class="cs-textstyle-detail-heading">
        {{ notification.typeNotification }}
        <cs-button
          :fill="'outline'"
          :size="'small'"
          class="close-icon"
          @click="backdropClose"
        >
          <i class="cs-icons-close"></i>
        </cs-button>
      </div>
      <div class="cs-textstyle-paragraph-small body text-light">
        **&#160;{{ notification.body }}&#160;**
      </div>
      <div
        v-if="notification.url"
        class="cs-textstyle-paragraph link text-light"
      >
        <cs-button
          :variant="getAlertType"
          :fill="'outline'"
          :size="'small'"
          :href="notification.url"
          target="_blank"
          >Learn More</cs-button
        >
      </div>
    </div>
  </app-popup>
</template>

<script>
import CheckLastActiveNotification from '@/gql/notifications/CheckLastActiveNotification.gql';
import SetLastNotification from '@/gql/auth/SetLastNotification.gql';
import $popups from '@/services/popups';
import AppPopup from '@/components/videos/ChannelPopup.vue';

export default {
  components: {
    AppPopup,
  },
  apollo: {
    notification: {
      query: CheckLastActiveNotification,
    },
  },
  data() {
    return {
      alertType: '',
      lastActiveNotification: null,
      lastUserNotification: null,
    };
  },
  computed: {
    getAlertType() {
      let alertType;
      if (!this.notification) {
        return '';
      }
      switch (this.notification.typeNotification) {
        case 'update':
          alertType = 'info';
          break;
        case 'alert':
          alertType = 'danger';
          break;
        case 'warning':
          alertType = 'warning';
          break;
        default:
          alertType = 'secondary';
          break;
      }

      return alertType;
    },
    shouldShow() {
      return !!this.notification;
    }
  },
  methods: {
    backdropClose() {
      this.setLastNotification();
      this.$emit('close-popup');
    },
    async setLastNotification() {
      try {
        await this.$apollo.mutate({
          mutation: SetLastNotification,
          variables: {
            notificationId: this.notification.id,
          },
          refetchQueries: [
            {
              query: CheckLastActiveNotification,
            },
          ],
        });
        this.notification = null;
      } catch (err) {
        $popups.alert(err.message);
      }
    },
  },
};
</script>

<style scoped>
.popup-content {
  padding: 20px 10px;
  border-radius: 5px;
}
.link {
  text-align: center;
}
.body {
  margin-bottom: 1rem;
}
.cs-textstyle-detail-heading {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.4rem;
}
.close-icon {
  border: none;
  position: absolute;
  right: 0;
  top: 1rem;
  color: rgba(102, 102, 102, 1);
}
.danger {
  background: rgba(242, 216, 218, 1);
}
.danger .cs-textstyle-detail-heading,
.danger .body,
.danger .cs-textstyle-button-text {
  color: rgba(103, 35, 38, 1);
  border-color: rgba(103, 35, 38, 1);
}
.warning {
  background: rgba(252, 243, 209, 1);
}
.warning .cs-textstyle-detail-heading,
.warning .body,
.warning .cs-textstyle-button-text {
  color: rgba(127, 101, 32, 1);
  border-color: rgba(127, 101, 32, 1);
}
.info {
  background: rgba(215, 235, 240, 1);
}
.info .cs-textstyle-detail-heading,
.info .body,
.info .cs-textstyle-button-text {
  color: rgba(40, 83, 94, 1);
  border-color: rgba(40, 83, 94, 1);
}
.secondary {
  background: rgba(255, 255, 255, 1);
}
.secondary .cs-textstyle-detail-heading,
.secondary .body,
.secondary .cs-textstyle-button-text {
  color: rgba(57, 61, 65, 1);
  border-color: rgba(57, 61, 65, 1);
}
</style>
