<template>
  <div class="meeting-chat-composer">
    <app-avatar
      v-if="user"
      :name="user.displayname"
      :picture="user.profilePhotoURL"
    />
    <textarea
      ref="meetingChatComposerInput"
      v-model="draftMessage"
      class="meeting-chat-input"
      placeholder="Add comment here"
      :disabled="disableChat"
      @input="resize"
      @keydown.enter.prevent="sendMessage"
    />
    <cs-button
      fill="clear"
      class="cs-button"
      :disabled="emptyMessage"
      @click="sendMessage"
    >
      <i class="cs-icons-send-filled send-icon" />
    </cs-button>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $socket from '@/services/socket';

import AppAvatar from '@/components/general/Avatar.vue';

import SendMeetingMessage from '@/gql/meetings/SendMeetingMessage.gql';

export default {
  components: {
    AppAvatar,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      draftMessage: '',
    };
  },
  computed: {
    emptyMessage() {
      return !this.draftMessage;
    },
    disableChat() {
      return (
        this.meeting.status === 'TRANSCODING' || this.meeting.status === 'ENDED'
      );
    },
  },
  async beforeMount() {
    this.user = await $auth.getUser();
  },
  methods: {
    resize() {
      const { meetingChatComposerInput } = this.$refs;
      meetingChatComposerInput.style.height = '36px';
      meetingChatComposerInput.style.height = `${meetingChatComposerInput.scrollHeight}px`;
    },
    scrollToBottom(selector) {
      const element = document.querySelector(selector);
      element.scrollTop = element.scrollHeight;
    },
    scrollToBottomMeetingBody() {
      this.scrollToBottom('.meeting-body');
      this.scrollToBottom('.cs-page-tabs__content');
    },
    async sendMessage() {
      if (this.emptyMessage) return;
      const vars = {
        meetingId: this.meeting.id,
        bodyText: this.draftMessage,
      };
      this.draftMessage = '';
      try {
        await this.$apollo.mutate({
          mutation: SendMeetingMessage,
          variables: vars,
        });
        $socket.sendRoom('meeting', this.meeting.id, 'meeting-chat-modified');
        this.resize();
        this.scrollToBottomMeetingBody();
      } catch (e) {
        if (e.message === 'Authentication failed!') {
          this.$emit('confirm-modal');
        }
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.meeting-chat-composer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.15);
}
.meeting-chat-composer .send-icon {
  color: var(--cs-primary-base);
  font-size: 20px;
}
.cs-button {
  --cs-button-padding: 0px !important;
}
.meeting-chat-input {
  flex: 1;
  padding: 8px 10px 0;
  height: 36px;
  font-size: 16px;
  background-color: #f5f7f6;
  border-radius: 8px;
  border: none;
}

.meeting-chat-input:focus {
  outline: none;
}
</style>
