<template>
  <app-page-menu title="Explore Events">
    <div class="cs-textstyle-item-heading">Explore your events</div>
    <!-- Events -->
    <app-page-menu-category title="Events">
      <app-page-menu-category-item
        v-for="video in meetings"
        :key="video.view"
        :label="video.label"
        :to="{name: 'MeetingList', params:{'view': video.view}}"
      />
    </app-page-menu-category>
    <!-- Categories -->
    <app-page-menu-category title="Categories">
      <app-page-menu-category-item
        v-for="cat in categories"
        :key="cat.value"
        :label="cat.label"
        :to="{name: 'MeetingCategory', params:{'category': cat.value}}"
      />
    </app-page-menu-category>
  </app-page-menu>
</template>

<script>
import $auth from '@/services/auth';
import categories from '@/assets/config/meetingcategories.json';
// Component
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuCategory from '@/components/general/PageMenuCategory.vue';
import AppPageMenuCategoryItem from '@/components/general/PageMenuCategoryItem.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuCategory,
    AppPageMenuCategoryItem,
  },
  data() {
    return {
      myid: $auth.getUserId(),
      categories,
      meetings: [
        {
          label: 'All',
          view: 'all',
        },
        {
          label: 'Live',
          view: 'live',
        },
        {
          label: 'Upcoming',
          view: 'upcoming',
        },
        {
          label: 'Attending',
          view: 'attending',
        },
        {
          label: 'Attended',
          view: 'attended',
        },
        {
          label: 'Invited',
          view: 'invited',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
