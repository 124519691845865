<template>
  <ion-page>
    <ion-header>
      <app-main-header title="People" :hide-notifications="true" />
      <app-tabs v-model="tabIndex" :tabs="tabs" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />

      <!-- Recommended section -->
      <div v-show="tabIndex === 0" class="connection-tab-content">
        <app-other-user-segment
          ref="recommended"
          title="Recommended"
          :query="recommendedQuery"
          :to="{ name: 'UserList', params: { view: 'recommended' } }"
        />
      </div>
      <!-- Find section -->
      <div v-show="tabIndex === 1" class="connection-tab-content">
        <app-search-bar v-model="search" @input="inputSearch" ref="findInput" />
        <!-- Search results -->
        <app-spinner v-if="$apollo.queries.users.loading" />
        <app-user-card
          v-for="user in users"
          :key="user.id"
          :user="user"
          item-class="col-md-6"
          @status-changed="refreshSearchList"
        />
        <cs-empty-state v-if="users && users.length == 0" title="No results" />
      </div>
      <!-- Friends  -->
      <div v-show="tabIndex === 2" class="connection-tab-content">
        <div class="row">
          <app-search-bar
            v-model="search"
            @input="inputSearch"
            ref="friendsInput"
          />
          <!-- Search results -->
          <app-spinner v-if="$apollo.queries.users.loading" />
          <div v-if="search && users">
            <app-user-card
              v-for="user in users"
              :key="user.id"
              :user="user"
              item-class="col-md-6"
              @status-changed="refreshSearchList"
            />
          </div>
          <app-other-user-segment
            v-if="!search"
            ref="friends"
            title="Friends"
            :query="friendQuery"
            :is-friend="true"
          />
        </div>
      </div>
      <!-- Following  -->
      <div v-show="tabIndex === 3" class="connection-tab-content">
        <div class="row">
          <app-search-bar
            v-model="search"
            @input="inputSearch"
            ref="followingInput"
          />
          <!-- Search results -->
          <app-spinner v-if="$apollo.queries.users.loading" />
          <div v-if="search && users">
            <app-user-card
              v-for="user in users"
              :key="user.id"
              :user="user"
              item-class="col-md-6"
              @status-changed="refreshSearchList"
            />
          </div>
          <app-other-user-segment
            v-if="!search"
            ref="following"
            title="Following"
            :query="followingQuery"
          />
        </div>
      </div>
      <!-- Followers  -->
      <div v-show="tabIndex === 4" class="connection-tab-content">
        <div class="row">
          <app-search-bar
            v-model="search"
            @input="inputSearch"
            ref="followersInput"
          />
          <!-- Search results -->
          <app-spinner v-if="$apollo.queries.users.loading" />
          <div v-if="search && users">
            <app-user-card
              v-for="user in users"
              :key="user.id"
              :user="user"
              item-class="col-md-6"
              @status-changed="refreshSearchList"
            />
          </div>
          <app-other-user-segment
            v-if="!search"
            ref="followers"
            title="Followers"
            :query="followerQuery"
          />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';
// Components
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppUserCard from '@/components/connections/UserSegmentCard.vue';
import AppOtherUserSegment from '@/components/connections/RecommendedSegment.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppSearchBar from '@/components/general/SearchBar.vue';

// GQL
import UserSearch from '@/gql/search/UserSearch.gql';
import ListRecommendedUsers from '@/gql/connections/RecommendedUsers.gql';
import ListFollowers from '@/gql/connections/ListFollowers.gql';
import ListFollowing from '@/gql/connections/ListFollowing.gql';
import ListFriends from '@/gql/connections/ListFriends.gql';

export default {
  components: {
    AppMainHeader,
    AppPullToRefresh,
    AppTabs,
    AppUserCard,
    AppSpinner,
    AppOtherUserSegment,
    AppSearchBar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return !this.search;
      },
    },
  },
  data() {
    return {
      recommendedQuery: ListRecommendedUsers,
      friendQuery: ListFriends,
      followerQuery: ListFollowers,
      followingQuery: ListFollowing,
      tabs: ['Recommended', 'Find', 'Friends', 'Following', 'Followers'],
      tabIndex: 0,
      searchTimer: null,
      search: this.$route.query ? this.$route.query.q : this.query,
    };
  },
  computed: {
    me() {
      return {
        id: $auth.getUserId(),
      };
    },
    noQuery() {
      return this.search ? this.search.trim().length === 0 : true;
    },
  },
  watch: {
    async tabIndex() {
      this.search = this.$route.query ? this.$route.query.q : this.query;
      await this.refreshInputs();
      clearTimeout(this.searchTimer);
    },
  },
  methods: {
    inputSearch(data) {
      data === '' ? (this.users = []) : true;
      const searchData = {
        query: {
          q: data,
        },
      };
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => searchData, 700);
    },
    async refresh($event) {
      await Promise.all([
        this.$refs.recommended.refresh(),
        this.$refs.friends.refresh(),
        this.$refs.followers.refresh(),
        this.$refs.following.refresh(),
      ]);
      this.refreshSearchList();
      $event.target.complete();
    },
    async refreshInputs() {
      await Promise.all([
        this.$refs.findInput.clean(),
        this.$refs.friendsInput.clean(),
        this.$refs.followingInput.clean(),
        this.$refs.followersInput.clean(),
      ]);
    },
    async refreshSearchList() {
      await this.$apollo.queries.users.refetch();
    },
  },
};
</script>

<style scoped>
.find-button {
  min-width: 100px;
  color: var(--cs-gray-05);
}
.connection-tab-content {
  margin-top: 15px;
}
.quickfind-label {
  font-size: 12px;
  font-weight: bold;
}
.search__input-header {
  margin-bottom: 5px;
  padding: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-bottom: solid 1px #efefef;
}
.search__input-header >>> span {
  margin: auto 0;
}
.search__input-header >>> .cs-search-bar {
  background-color: var(--cs-gray-01);
  border-radius: 20px;
  overflow: hidden;
  height: 35px;
}
</style>
