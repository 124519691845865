<template>
  <ion-page>
    <ion-header>
      <div class="ch-video-category__header">
        <app-category-header :title="currentTitle" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-video-list :videos="videos" direction="column"></app-video-list>
      <!-- Floating Button -->
      <app-video-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppVideoList from '@/components/videos/VideoList.vue';
import AppVideoExploreBtn from '@/components/videos/VideoExploreBtn.vue';
// GQL
import ListRecentVideos from '@/gql/videos/ListRecentVideos.gql';
import ListPopularVideos from '@/gql/videos/ListPopularVideos.gql';
import ListFollowingVideos from '@/gql/videos/ListFollowingVideos.gql';
import ListWatchedVideos from '@/gql/videos/ListWatchedVideos.gql';
import ListBookmarkedVideos from '@/gql/videos/ListBookmarkedVideos.gql';
import ListQueuedVideos from '@/gql/videos/ListQueuedVideos.gql';

const viewSettings = {
  latest: {
    title: 'Latest Videos',
    query: ListRecentVideos,
  },
  popular: {
    title: 'Popular Videos',
    query: ListPopularVideos,
  },
  following: {
    title: 'Followed Channels',
    query: ListFollowingVideos,
  },
  history: {
    title: 'Recently Watched',
    query: ListWatchedVideos,
  },
  bookmarked: {
    title: 'Bookmarked Videos',
    query: ListBookmarkedVideos,
  },
  queue: {
    title: 'Watch Later',
    query: ListQueuedVideos,
  },
};
export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppVideoList,
    AppVideoExploreBtn,
  },
  data() {
    return {
      view: this.$route.params.view,
    };
  },
  apollo: {
    videos: {
      query() {
        return this.currentView.query;
      },
    },
  },
  computed: {
    currentTitle() {
      return viewSettings[this.$route.params.view].title;
    },
    currentView() {
      return viewSettings[this.$route.params.view];
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.videos.refetch();
      $event.target.complete();
    },
  },
};
</script>
