<template>
  <div class="live-input">
    <app-avatar
      v-if="user"
      :name="user.displayname"
      :picture="user.profilePhotoURL"
    />
    <textarea
      ref="postChatComposerInput"
      v-model="draftMessage"
      class="live-input__textarea"
      placeholder="Add comment here"
      @input="resize"
      @keydown.enter.prevent="sendMessage"
    />
    <cs-button
      ref="sendMessageButton"
      fill="clear"
      class="cs-button live-input__button"
      :disabled="emptyMessage"
      @click="sendMessage"
    >
      <i class="cs-icons-send-filled send-icon" />
    </cs-button>
  </div>
</template>
<script>
import $auth from '@/services/auth';
import $socket from '@/services/socket';

import AppAvatar from '@/components/general/Avatar.vue';

import InsertPostMessage from '@/gql/live/InsertPostMessage.gql';

export default {
  components: {
    AppAvatar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    user: null,
    draftMessage: '',
  }),
  computed: {
    emptyMessage() {
      return !this.draftMessage;
    },
  },
  async beforeMount() {
    this.user = await $auth.getUser();
  },
  methods: {
    resize() {
      const { postChatComposerInput } = this.$refs;
      postChatComposerInput.style.height = '36px';
      postChatComposerInput.style.height = `${postChatComposerInput.scrollHeight}px`;
    },
    async sendMessage() {
      if (this.emptyMessage) return;
      const variables = {
        postId: this.post.id,
        bodyText: this.draftMessage,
      }
      this.draftMessage = '';
      await this.$apollo.mutate({
        mutation: InsertPostMessage,
        variables,
      });
      $socket.sendRoom('post', this.post.id, 'post-chat-modified');
      this.resize();
    },
  }
}
</script>
<style scoped>
.live-input {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 8px 10px;
  background: #fff;
  border: none;
  z-index: 5;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.15);
}

.live-input__button {
  display: inline-block;
  width: 32px;
  padding-bottom: 3px;
}

.live-input__textarea:focus {
  border: none;
}

.live-input__textarea {
  flex: 1;
  margin-left: 10px;
  padding: 8px 10px 0;
  height: 36px;
  font-size: 16px;
  background-color: #f5f7f6;
  border-radius: 8px;
  border: none;
}

.live-input__textarea:focus {
  outline: none;
}
</style>