<template>
  <action-sheet class="ch-block__actionsheet" :open="show" @close="cancel">
    <div class="ch-block__content">
      <div class="ch-block__subtitle cs-textstyle-paragraph-bold">
        Are you sure you wish to sign out?
      </div>
      <div class="ch-block__buttons">
        <cs-button class="ch-block__button" variant="default" @click="cancel()"
          >Cancel</cs-button
        >
        <cs-button class="ch-block__button" @click="logout()">Sign Out</cs-button>
      </div>
    </div>
  </action-sheet>
</template>

<script>
import ActionSheet from '@/components/general/ActionSheet.vue';
import Logout from '@/gql/general/Logout.gql';

export default {
  components: {
    ActionSheet,
  },
  props: {
    show: {
      type: Boolean,
      value: false,
    },
  },
  computed: {},
  methods: {
    cancel() {
      this.$emit('close');
    },
    async logout() {
      const loggedOut = await this.$apollo.mutate({
        mutation: Logout,
      });
      if (loggedOut) {
        try {
          localStorage.removeItem('access_token');
          localStorage.removeItem('user');
          this.$router.push({ name: 'Login' });
          this.cancel();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.ch-block__actionsheet {
  display: flex;
  flex-direction: column;
}
.ch-block__title,
.ch-block__subtitle {
  color: var(--cs-gray-06);
}
.ch-block__description {
  color: var(--cs-gray-05);
}
.ch-block__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.ch-block__button {
  margin: 0 7.5px;
}
</style>
