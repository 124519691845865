<template>
  <div>
    <ion-row v-for="group in groups" :key="group.id">
      <app-subgroup-item :key="group.id" :group="group" />
    </ion-row>
  </div>
</template>

<script>
// Group subgroup components
import AppSubgroupItem from '@/components/groups/subgroup/GroupSubgroupItem.vue';

export default {
  components: {
    AppSubgroupItem,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
ion-row {
  margin: 15px;
}
</style>
