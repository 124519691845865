<template>
  <div class="ch-message">
    <app-avatar
      class="ch-message__avatar"
      :picture="cdn(conversation.otherParty.profilePhotoURL)"
      :name="conversation.otherParty.displayname"
      size="small"
    />
    <div class="ch-message__body">
      <div class="ch-message__top">
        <div class="ch-message__title cs-textstyle-paragraph-small-bold">
          <app-username :user="conversation.otherParty" type="displayname" />
        </div>
        <div v-if="latestMessage" class="ch-message__timestamp">
          {{ latestMessage.msgCreateTime | moment('DD MMM') }}
        </div>
      </div>
      <div class="ch-message__bottom">
        <div
          class="ch-message__text cs-textstyle-paragraph-small-bold"
          :class="{ 'ch-message__read': conversation.unreadCount === 0 }"
        >
          {{ previewMessage }}
        </div>
        <cs-badge
          v-if="conversation.unreadCount > 0"
          class="ch-message__unread-count"
          >{{ conversation.unreadCount }}</cs-badge
        >
      </div>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $cdn from '@/services/cdn';

import AppUsername from '@/components/general/Username.vue';
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
    AppUsername,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    latestMessage() {
      return JSON.parse(JSON.stringify(this.conversation.latestMessage));
    },
    previewMessage() {
      const latestMessage = this.latestMessage;
      if (!latestMessage) {
        return '(No message)';
      }
      if (
        latestMessage.msgBody === '' &&
        (latestMessage.video || latestMessage.images || latestMessage.document)
      ) {
        latestMessage.msgBody = 'Sent an attachment';
      } else if (latestMessage.msgBody === '' && latestMessage.postId) {
        latestMessage.msgBody = 'Shared post';
      }
      const prefix =
        latestMessage.senderId === $auth.getUserId() ? 'You: ' : '';
      return `${prefix}${latestMessage.msgBody}`;
    },
  },
  methods: {
    cdn(url) {
      if (!url) return;
      // eslint-disable-next-line consistent-return
      return $cdn.optimize(url);
    },
  },
};
</script>

<style scoped>
.ch-message {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-00);
}

.ch-message__avatar {
  margin-right: 10px;
}

.ch-message__body {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.ch-message__top,
.ch-message__bottom {
  display: flex;
  flex-direction: row;
  position: relative;
}
.ch-message__title {
  flex: 1;
  color: var(--cs-gray-07);
}
.ch-message__timestamp {
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 12px;
  color: var(--cs-gray-04);
}

.ch-message__read {
  font-weight: 400;
  color: var(--cs-gray-05);
}
.ch-message__text {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-message__unread-count {
  position: absolute;
  right: 0;
  --cs-badge-color: var(--cs-primary-base);
}
</style>
