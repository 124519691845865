<template>
  <ion-page>
    <ion-header>
      <div class="ch-notification">
        <div class="ch-notification__header">
          <app-category-header title="Notifications" />
        </div>
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div>
        <app-activity-list ref="activity-list" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppActivityList from '@/components/menu/notifications/ActivityList.vue';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppActivityList,
  },
  mounted() {
    localStorage.lastSeenNotificationTime = Date.now();
  },
  methods: {
    async refresh($event) {
      await Promise.all([this.$refs['activity-list'].refresh()]);
      $event.target.complete();
    },
  },
};
</script>

<style>
ion-content {
  --background: var(--cs-gray);
}
</style>
