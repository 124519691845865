<template>
  <div v-show="memberCount > 0" class="card-no-padding group-members-list-card">
    <div v-if="memberCount > 0" class="card-header">
      <span v-if="role" class="cs-textstyle-paragraph-bold">{{
        roles[role]
      }}</span>
    </div>
    <app-group-members-list
      :group="group"
      :role="role"
      @member-count="updateCount"
    />
  </div>
</template>

<script>
import AppGroupMembersList from '@/components/groups/GroupMembersList.vue';

export default {
  components: {
    AppGroupMembersList,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      memberCount: 0,
      roles: {
        admin: 'Admins',
        moderator: 'Moderators',
        'pending-member': 'Pending Members',
        member: 'Members',
      },
    };
  },
  methods: {
    updateCount(count) {
      this.memberCount = count;
    },
  },
};
</script>

<style scoped>
.group-members-list-card {
  margin-top: 20px;
  background-color: var(--cs-gray-00);
}
.card-header {
  display: flex;
  padding: 15px 20px;
  justify-content: space-between;
  color: var(--cs-gray-06);
}
</style>
