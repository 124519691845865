<template>
  <div class="breakout-meeting">
    <div class="breakout-meeting__title">{{ meeting.name }}</div>
    <div class="breakout-meeting__description">{{ meeting.description || 'No Description' }}</div>
    <div class="breakout-bottom">
      <div class="breakout-bottom__first">
        <app-avatar :name="meeting.host.username" :picture="meeting.host.profilePhotoURL" />
        <div class="breakout-bottom__info">
          <div class="breakout-bottom__host">
            Created by {{ meeting.host.username }}
          </div>
          <div class="breakout-bottom__participants">{{ meeting.attendeesCount }} Participants</div>
        </div>
      </div>
      <cs-button size="small" fill="outline">JOIN</cs-button>
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.breakout-meeting {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px 10px;
  margin-bottom: 10px;
  background-color: var(--cs-gray-00);
  border-bottom: solid 1px #eef2f7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.breakout-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;
}
.breakout-bottom__first {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.breakout-bottom__info {
  display: flex;
  flex-direction: column;
}
.breakout-meeting__title {
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
}
.breakout-meeting__description {
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;
}
.breakout-bottom__host {
  font-size: 14px;
  font-weight: 800;
  color: #4a4a4a;
}
.breakout-bottom__participants {
  font-size: 14px;
  color: #4a4a4a;
}
</style>
