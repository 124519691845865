<template>
  <ion-page>
    <ion-content>
      <div class="ch-set-new-password">
        <div class="ch-reset-pw__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <cs-spinner v-if="sending"></cs-spinner>
        <app-register-subheader title="Reset your Password" />
        <form class="ch-register__form" @submit="onContinue()">
          <template v-if="!sending && !sent">
            <app-password-input v-model="password" label="Enter Your New Password" />
            <app-password-input v-model="confirmPassword" label="Confirm Your New Password" />
            <cs-button v-if="!sent" block corners="rounded" :disabled="sending" @click="onContinue"
              >Continue</cs-button
            >
            <!-- !validForm || -->
            <app-password-validator
              v-model="validPassword"
              :password="password"
              :confirm-password="confirmPassword"
            />
          </template>
          <div v-if="sent" class="cs-textstyle-paragraph-bold ch-reset-pw__inform">
            Your password has been changed. Go to
            <router-link to="/login"><strong>Login</strong></router-link>
          </div>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import $popups from '@/services/popups';
import ResetPassword from '@/gql/auth/ResetPassword.gql';
import AppPasswordInput from '@/components/general/PasswordInput.vue';
import AppPasswordValidator from '@/components/general/PasswordValidator.vue';
import AppRegisterSubheader from '@/components/register/Subheader.vue';

export default {
  components: {
    AppPasswordInput,
    AppPasswordValidator,
    AppRegisterSubheader,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      validPassword: false,
      sending: false,
      sent: false,
    };
  },
  computed: {
    validForm() {
      return this.validPassword;
    },
  },
  methods: {
    async onContinue() {
      if (this.validForm) {
        this.sending = true;
        const variables = {
          jwtToken: this.$route.params.token,
          password: this.password,
        };
        await this.$apollo.mutate({
          mutation: ResetPassword,
          variables,
        });
        this.sending = false;
        this.sent = true;
      } else {
        $popups.alert(
          'Your password does not meet one or more password complexity requirements (see bottom of page)'
        );
      }
    },
  },
};
</script>

<style scoped>
.ch-register__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}
.ch-set-new-password {
  padding: 20px;
  background: var(--cs-gray-01);
}
.ch-reset-pw__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-reset-pw__img-class > img {
  width: 250px;
}
a {
  color: var(--cs-primary-base);
  text-decoration: none;
}
.ch-reset-pw__inform {
  width: 70%;
  margin-top: 30px;
  text-align: center;
  align-self: center;
}
</style>
