<template>
  <div class="ch-video-channel-item">
    <div v-router-link="linkValue" class="ch-video-channel-item__body">
      <app-avatar :name="displayName" :picture="displayPicture" size="medium" />
      <div class="ch-video-channel-item__body--info">
        <div class="cs-textstyle-paragraph-bold">
          <span v-if="user.channelNumber">{{ user.channelNumber }} | </span>
          {{ displayName }}
        </div>
        <div class="cs-textstyle-paragraph-small text-light">
          <span
            >{{ user.subscribersCount }}
            {{
              user.subscribersCount == 1 ? 'subscriber' : 'subscribers'
            }}</span
          >
          <!-- <span> &centerdot; </span>
          <span
            >{{ user.videoPosts.count }}
            {{ user.videoPosts.count == 1 ? 'video' : 'videos' }}</span
          > -->
        </div>
        <div class="cs-textstyle-paragraph-small">{{ user.bio }}</div>
        <app-channel-subscribe-button :target="user" size="small" />
      </div>
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
import AppChannelSubscribeButton from '@/components/general/ChannelSubscribeButton.vue';
const defaultAvatar = require('@/assets/images/just_the_news_logo.png');

export default {
  components: {
    AppAvatar,
    AppChannelSubscribeButton,
  },
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      defaultAvatar,
    };
  },
  computed: {
    displayName() {
      return this.user.channelName || `${this.user.displayname}'s Channel`;
    },
    displayPicture() {
      return this.user.channelPicture || this.user.profilePhotoUrl;
    },
    linkValue() {
      return { name: 'VideoProfile', params: this.user };
    },
  },
};
</script>

<style scoped>
.ch-video-channel-item {
  padding: 10px;
}
.ch-video-channel-item__body {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
