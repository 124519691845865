<template>
  <ion-page>
    <ion-header>
      <app-category-header title="Connections" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div class="">
        <div class="mb-4 cs-textstyle-section-heading capitalize">
          {{ currentView.title }}
        </div>
        <app-spinner v-if="!people" />
        <div v-if="people" class="row">
          <app-user-card
            v-for="user in filteredPeople"
            :key="user.id"
            :user="user"
          />
        </div>
      </div>
      <!-- Floating Button -->
      <app-connections-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppUserCard from '@/components/connections/UserCard.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppConnectionsExploreBtn from '@/components/connections/ConnectionsExploreBtn.vue';
// GQL
import ListRecommendedUsers from '@/gql/connections/RecommendedUsers.gql';
import ListFollowers from '@/gql/connections/ListFollowers.gql';
import ListFollowing from '@/gql/connections/ListFollowing.gql';
import ListFriends from '@/gql/connections/ListFriends.gql';

const viewSettings = {
  recommended: {
    title: 'You Should Meet',
    query: ListRecommendedUsers,
  },
  friends: {
    title: 'Your Friends',
    query: ListFriends,
  },
  followers: {
    title: 'Following You',
    query: ListFollowers,
  },
  following: {
    title: 'People You Follow',
    query: ListFollowing,
  },
};

export default {
  apollo: {
    people: {
      query() {
        return this.currentView.query;
      },
    },
  },
  components: {
    AppUserCard,
    AppSpinner,
    AppCategoryHeader,
    AppPullToRefresh,
    AppConnectionsExploreBtn,
  },
  computed: {
    currentView() {
      return viewSettings[this.$route.params.view];
    },
    filteredPeople() {
      return this.people.filter((person) => !person.myRelationship.isBlocked);
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.people.refetch();
      $event.target.complete();
    },
  },
};
</script>

<style scoped>
.capitalize {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
