<template>
  <div class="meeting-member">
    <div v-if="member.online" class="ch-user-online"></div>
    <app-avatar
      :name="nameToShow"
      :picture="member.user.profilePhotoURL"
      size="medium"
      class="meeting-member-avatar"
      :class="{
        speaker: member.role === 'SPEAKER',
        moderator: member.role === 'MODERATOR',
        presenter:
          member.role === 'ADMIN' ||
          member.role === 'PRESENTER' ||
          member.role === 'PRESENTER_QUEUE',
        leader: ['ADMIN', 'MODERATOR', 'PRESENTER', 'PRESENTER_QUEUE'].includes(
          member.role
        ),
      }"
      @click="showRelvantModal"
    ></app-avatar>
    <div class="meeting-member-info">
      <!-- <div class="cs-textstyle-paragraph-bold">{{member.user.displayname}}</div> -->
      <div class="meeting-member-username">
        {{ nameToShow }}
      </div>
      <div v-if="showRole" class="meeting-member__role">
        {{ leaderRole }}
      </div>
    </div>
    <!-- Roles Menu -->
    <app-meeting-roles-menu
      :show-roles="showRoles"
      :member="member"
      :meeting="meeting"
      @close-meeting-roles="closeRolesMenu"
      @show-profile="openProfileModal"
    />
    <!-- User Profile Modal -->
    <app-profile-preview-modal
      v-if="loggedIn"
      :is-meeting="showUser"
      :meeting-user="meetingUser"
      @change-status="changeStatus"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';

import AppAvatar from '@/components/general/Avatar.vue';
import AppMeetingRolesMenu from '@/components/meetings/MeetingRolesMenu.vue';
import AppProfilePreviewModal from '@/components/followCards/ProfilePreviewModal.vue';

const leaderRoles = {
  ADMIN: 'Host',
  PRESENTER: 'Presenter',
  PRESENTER_QUEUE: 'Presenter Queue',
  MODERATOR: 'Moderator',
};

export default {
  components: {
    AppAvatar,
    AppMeetingRolesMenu,
    AppProfilePreviewModal,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    meeting: {
      type: Object,
      required: true,
    },
    showRole: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showRoles: false,
      showUser: false,
      meetingUser: null,
      userId: $auth.getUserId(),
      user: $auth.getUser(),
    };
  },
  computed: {
    nameToShow() {
      let result;
      console.log('ME:', this.user, this.member.user.username);

      if (this.member.user.username === this.user.username) {
        result = this.user.displayname.length
          ? this.user.displayname
          : this.user.username.replace('@', '');
      } else {
        result = this.member.user.displayname.length
          ? this.member.user.displayname
          : this.member.user.username.replace('@', '');
      }
      console.log('RESULT:', result);
      return result;
    },
    leaderRole() {
      return leaderRoles[this.member.role];
    },
    myRole() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    loggedIn() {
      return $auth.isLoggedIn();
    },
  },
  watch: {
    member() {
      console.log('member changed', this.member);
    },
  },

  async created() {
    this.user = await $auth.getUser();
  },
  methods: {
    showRelvantModal() {
      if (this.myRole === 'ADMIN' || this.myRole === 'MODERATOR') {
        this.showRolesMenu();
      } else if (this.userId !== this.member.user.id && this.loggedIn) {
        this.openProfileModal();
      }
    },
    showRolesMenu() {
      this.showRoles = true;
    },
    closeRolesMenu() {
      this.showRoles = false;
    },
    // Profile Modal
    openProfileModal() {
      this.meetingUser = this.member.user;
      this.showUser = true;
    },
    changeStatus() {
      this.showUser = false;
    },
  },
};
</script>

<style scoped>
.meeting-member {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 3px;
  align-items: center;
  text-align: center;
  position: relative;
}
.meeting-member-info {
  text-align: center;
}
.meeting-member-avatar {
  cursor: pointer;
}
.meeting-member-username {
  width: 90px;
  color: #4a4a4a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meeting-member-avatar.speaker {
  border: solid 2px green;
  border-radius: 50%;
}
.meeting-member-avatar.moderator {
  border: solid 2px #e2896e;
  border-radius: 50%;
}
.meeting-member-avatar.presenter {
  border: solid 2px #f7b500;
  border-radius: 50%;
}
.ch-user-online {
  position: absolute;
  top: 5px;
  z-index: 1;
  right: 20px;
  height: 11px;
  width: 11px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.meeting-member__role {
  font-weight: 800;
  color: #000;
}
</style>
