<template>
  <div class="meeting-chat">
    <div v-if="messages" v-chat-scroll class="meeting-chat-messages">
      <app-meeting-chat-message
        v-for="message in displayMessages"
        :key="message.id"
        :message="message"
        :meeting="meeting"
        @click="openProfileModal(message.postedByUser)"
      ></app-meeting-chat-message>
      <app-infinite-simple
        :query="$apollo.queries.messages"
        sort-key="createdAt"
      ></app-infinite-simple>
    </div>
    <!-- User Profile Modal -->
    <app-profile-preview-modal
      :is-meeting="showUser"
      :meeting-user="meetingUser"
      @change-status="changeStatus"
    />
  </div>
</template>
<script>
import $auth from '@/services/auth';
import $socket from '@/services/socket';

import AppMeetingChatMessage from '@/components/meetings/MeetingChatMessage.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppProfilePreviewModal from '@/components/followCards/ProfilePreviewModal.vue';

import ListMeetingMessages from '@/gql/meetings/ListMeetingMessages.gql';

export default {
  components: {
    AppMeetingChatMessage,
    AppInfiniteSimple,
    AppProfilePreviewModal,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showUser: false,
      meetingUser: null,
      userId: $auth.getUserId(),
    };
  },
  apollo: {
    messages: {
      query: ListMeetingMessages,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
    },
  },
  watch: {
    messages() {
      this.scrollToBottomOnMessages();
    },
  },
  computed: {
    emptyMessage() {
      return !this.draftMessage;
    },
    displayMessages() {
      return this.messages && this.messages.slice(0, 100);
    },
  },
  mounted() {
    $socket.onRoom('meeting', this.meeting.id, 'meeting-chat-modified', () => {
      console.log('Refresh Messages');
      this.$apollo.queries.messages.refetch();
    });
  },
  methods: {
    scrollToBottomOnMessages() {
      const el = document.querySelector('.cs-page-tabs__content');
      if (el.scrollHeight - el.scrollTop === el.clientHeight) {
        this.$nextTick(() => {
          el.scrollTop = el.scrollHeight;
        });
      }
    },
    openProfileModal(user) {
      if (this.userId !== user.id) {
        this.meetingUser = user;
        this.showUser = true;
      }
    },
    changeStatus() {
      this.showUser = false;
    },
  },
};
</script>
<style scoped>
.meeting-chat {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
}
.ch-infinite-loader {
  display: none;
}
.meeting-chat-messages {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  background-color: var(--cs-gray-00);
}
</style>
