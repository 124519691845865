<template>
  <div class="ch-group-search">
    <div class="ch-group-search__header">
      <cs-search-bar
        placeholder="Search Groups"
        v-model="query"
        @submit="search"
      />
    </div>
    <div class="ch-group-search__result" v-if="groups">
      <ion-row>
        <app-group-card :group="group" :key="group.id" v-for="group in groups"/>
      </ion-row>
    </div>
  </div>
</template>

<script>
import AppGroupCard from '@/components/groups/GroupCard.vue';
import GroupSearch from '@/gql/search/GroupSearch.gql';

export default {
  components: {
    AppGroupCard,
  },
  data() {
    return {
      searchQuery: null,
      query: '',
    };
  },
  apollo: {
    groups: {
      query: GroupSearch,
      variables() {
        return {
          query: this.searchQuery,
        };
      },
      skip() {
        return !this.searchQuery;
      },
    },
  },
  methods: {
    search() {
      this.searchQuery = this.query;
    },
  },
};
</script>

<style scoped>
.ch-group-search{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-group-search__header >>> .cs-search-bar{
  background-color: var(--cs-gray-00);
}
.ch-group-search__header >>> .cs-search-bar__input{
  background-color: var(--cs-gray-00);
}
</style>
