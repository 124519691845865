<template>
  <!-- Page used for both Parent Groups and SubGroups -->
  <ion-page>
    <ion-header>
      <template v-if="group">
        <!-- Floating back button for Parent Group only -->
        <div
          v-if="!group.parentGroupId"
          class="group-detail-view__banner-content"
        >
          <i
            class="cs-icons-back-arrow group-detail-view__back"
            @click="$router.go(-1)"
          ></i>
        </div>
        <!-- Header for SubGroup only -->
        <app-category-header v-else :title="subgroupTitle" />
      </template>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />

      <cs-spinner v-if="!group"></cs-spinner>

      <!-- Main section -->
      <div v-else>
        <!-- Group info section -->
        <div class="group-detail-view__maincard">
          <app-group-banner-image :group="group" />

          <div class="group-detail-view__maincard-padding">
            <!-- Title section with notification button and menu button -->
            <div class="group-detail-view__title-bar">
              <div class="group-detail-view__title">
                <!-- Show a link and title of the parent group -->
                <div
                  v-if="group.parentGroupId"
                  class="
                    cs-textstyle-paragraph-small-bold
                    group-detail-view__parent-group-title
                  "
                  @click="
                    $router.push({
                      name: 'GroupDetail',
                      params: { id: group.parentGroupId },
                    })
                  "
                >
                  <i class="ch-icons-groups"></i>
                  {{ parentGroup.name }}
                </div>
                <!-- Group title -->
                <div class="cs-textstyle-section-heading">{{ group.name }}</div>
                <div
                  class="group-detail-view__member-count cs-textstyle-paragraph"
                  @click="viewGroupMembers"
                >
                  {{ group.memberCount }} {{ membersLabel }}
                </div>
              </div>

              <!-- Group send notification button -->
              <app-group-send-notification-button
                v-if="userIsModerator"
                class="group-detail-view__share"
                @click="showNotificationModal = true"
              />

              <!-- Group menu button -->
              <app-group-menu :group="group" />
            </div>

            <!-- Group description -->
            <div
              v-if="group.description"
              class="group-detail-view__description"
            >
              <app-expandable-text
                class="
                  cs-textstyle-paragraph-small
                  group-detail-view__description
                "
                :content="groupDescription"
                placeholder="No description"
                :max-lines="7"
              />
            </div>

            <!-- Avatar list of several members -->
            <app-group-members-avatar-list
              v-if="userIsMember || group.privacy === 'public'"
              :group="group"
              @click="viewGroupMembers"
            />

            <div style="display: flex; align-items: center; justify-content: flex-start">
              <!-- Share button -->
              <app-group-share-button
                v-if="userIsMember"
                class="group-detail-view__share"
                :group="group"
              />

              <!-- Join group button -->
              <app-group-join-button :group="group" />

              <div style="flex: 1 1"></div>

              <cs-button
                v-if="group.id === '6196dace-09d1-5cb5-a73d-ebff708c1df1'"
                size="small"
                variant="danger"
                class="group-detail-view__action_button"
                href="https://www.supportcleanelections.com"
                target="_blank"
              >
                Report Fraud
              </cs-button>
            </div>
          </div>
        </div>

        <!-- Tab bar - Discussion, Members, Subgroups -->
        <app-tabs v-model="tabIndex" :tabs="tabs" />

        <!-- Warning for a private group the user is not allowed to view content of -->
        <app-group-private-warning
          v-if="
            !userIsMember &&
            !userIsSuperAdmin &&
            group.privacy &&
            group.privacy !== 'public'
          "
        />

        <!-- Discussion tab content -->
        <div v-show="tabIndex === 0">
          <div
            v-if="
              userIsMember || userIsSuperAdmin || group.privacy === 'public'
            "
          >
            <app-post-prompt
              v-if="userIsMember"
              :for-groups="subgroups.length > 0 ? true : false"
              :group-id="id"
              class="group-detail-view__post-prompt"
            />
            <app-group-post-list
              ref="postList"
              :group="group"
              :is-moderator="userIsModerator"
            />
          </div>
        </div>

        <!-- Members tab content -->
        <div v-show="tabIndex === 1">
          <div v-if="userIsMember || group.privacy === 'public'">
            <div>
              <app-group-members-list-card
                :group="group"
                role="pending-member"
              />
              <app-group-members-list-card :group="group" role="admin" />
              <app-group-members-list-card :group="group" role="moderator" />
              <app-group-members-list-card :group="group" role="member" />
            </div>
          </div>
        </div>

        <!-- Subgroups tab content -->
        <div v-show="tabIndex === 2">
          <template v-if="group.id">
            <app-group-subgroup-card
              v-if="group.privacy === 'public' || userIsMember"
              :can-create-subgroups="canCreateSubgroups"
              :group="group"
              :subgroups="subgroups"
            />
          </template>
        </div>
      </div>
    </ion-content>
    <app-group-send-notification-modal
      :show="showNotificationModal"
      :subgroups="subgroups"
      :group="group"
      @close="showNotificationModal = false"
    />
  </ion-page>
</template>

<script>
import $auth from '@/services/auth';
import $popups from '@/services/popups';

// GQL
import GetGroup from '@/gql/groups/GetGroup.gql';
import ListSubgroupsByGroupId from '@/gql/groups/ListSubgroupsByGroupId.gql';

// General components
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppExpandableText from '@/components/general/ExpandableText.vue';
import AppTabs from '@/components/general/Tabs.vue';

// Group common components
import AppGroupJoinButton from '@/components/groups/GroupJoinButton.vue';
import AppGroupPrivateWarning from '@/components/groups/GroupPrivateWarning.vue';
import AppGroupMembersListCard from '@/components/groups/GroupMembersListCard.vue';

// Group detail view components
import AppGroupBannerImage from '@/components/groups/detail/GroupBannerImage.vue';
import AppGroupMembersAvatarList from '@/components/groups/detail/GroupMembersAvatarList.vue';
import AppGroupMenu from '@/components/groups/detail/GroupMenu.vue';
import AppGroupShareButton from '@/components/groups/detail/GroupShareButton.vue';
import AppGroupSendNotificationButton from '@/components/groups/detail/GroupSendNotificationButton.vue';
import AppGroupSendNotificationModal from '@/components/groups/detail/GroupSendNotificationModal.vue';

// Group subgroup components
import AppGroupSubgroupCard from '@/components/groups/subgroup/GroupSubgroupCard.vue';

// Post components
import AppGroupPostList from '@/components/post/lists/GroupPostList.vue';
import AppPostPrompt from '@/components/post/PostPrompt.vue';

export default {
  components: {
    AppTabs,
    AppPullToRefresh,
    AppGroupBannerImage,
    AppGroupMembersAvatarList,
    AppGroupJoinButton,
    AppGroupMenu,
    AppGroupPrivateWarning,
    AppGroupPostList,
    AppPostPrompt,
    AppGroupShareButton,
    AppGroupSubgroupCard,
    AppGroupMembersListCard,
    AppCategoryHeader,
    AppGroupSendNotificationModal,
    AppGroupSendNotificationButton,
    AppExpandableText,
  },
  data() {
    return {
      id: this.$route.params.id,
      tabIndex: 0,
      parentGroup: {},
      group: {},
      groups: [], // technically subgroups. Named 'groups' due to underlying gql query
      showNotificationModal: false,
    };
  },
  computed: {
    subgroups() {
      return this.groups;
    },
    userIsMember() {
      if (!this.group || !this.group.myMembership) return null;
      const membership = this.group.myMembership.role;
      return membership !== 'pending-member';
    },
    userIsModerator() {
      if (!this.group || !this.group.myMembership) return null;
      const membership = this.group.myMembership.role;
      return membership === 'moderator' || membership === 'admin';
    },
    userIsSuperAdmin() {
      return $auth.isAdmin();
    },
    // Strips HTML from legacy group descriptions
    groupDescription() {
      if (!this.group || !this.group.description) return '';
      const doc = new DOMParser().parseFromString(
        this.group.description,
        'text/html'
      );
      return doc.body.textContent || '';
    },
    membersLabel() {
      if (this.group.memberCount === 1) return 'member';
      return 'members';
    },
    subgroupTitle() {
      if (this.parentGroup) return `Back to ${this.parentGroup.name}`;
      return 'Go Back';
    },
    canHaveSubgroups() {
      if (!this.group) return false;
      if (!this.group.parentGroupId) return true;
      return !this.group.rootGroupId;
    },
    canCreateSubgroups() {
      return (
        this.canHaveSubgroups && this.userIsModerator
        // Removed on client request:|| this.userIsSuperAdmin
      );
    },
    tabs() {
      return this.canHaveSubgroups
        ? ['Discussion', 'Members', 'Subgroups']
        : ['Discussion', 'Members'];
    },
  },
  watch: {
    tabIndex() {
      if (this.tabIndex === 2 && this.group.rootGroupId) {
        this.tabIndex = 0;
      }
    },
  },
  mounted() {
    this.tabIndex = 0;
  },
  apollo: {
    group: {
      query: GetGroup,
      variables() {
        return {
          id: this.id,
        };
      },
      error(error) {
        this.showError(JSON.stringify(error.message), error);
      },
      result(data) {
        if (data.data?.group?.parentGroupId) {
          this.getParentGroup();
        }
      },
    },
    groups: {
      query: ListSubgroupsByGroupId,
      variables() {
        return {
          parentGroupId: this.id,
        };
      },
    },
  },
  methods: {
    async getParentGroup() {
      const parentGroupId = this.group.parentGroupId;
      await this.$apollo
        .query({
          query: GetGroup,
          variables: {
            id: parentGroupId,
          },
        })
        .then((data) => {
          this.parentGroup = data.data.group;
        })
        .catch((error) => {
          this.showError(JSON.stringify(error.message), error);
        });
    },
    async refresh($event) {
      await this.$apollo.queries.group.refetch();
      await this.$apollo.queries.groups.refetch();
      this.$refs.postList.refresh();
      $event.target.complete();
    },
    viewGroupMembers() {
      this.$router.push({
        name: 'GroupMembersView',
        params: { id: this.group.id },
      });
    },
    showError(message, err) {
      $popups.alert(message);
      throw err;
    },
  },
};
</script>

<style>
/* Back button styling */
.group-detail-view__back {
  display: flex;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 10px;
  left: 10px;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  border-radius: 50%;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}
/* Main section styling */
.group-detail-view__maincard {
  position: relative;
  background-color: var(--cs-gray-00);
}
.group-detail-view__maincard-padding {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
}
.group-detail-view__title-bar {
  display: flex;
  align-items: center;
}
.group-detail-view__title {
  flex: 1;
  color: var(--cs-gray-06);
}
.group-detail-view__parent-group-title {
  color: var(--cs-primary-base);
}
.group-detail-view__member-count {
  color: var(--cs-gray-04);
}
.group-detail-view__description {
  color: var(--cs-gray-05);
}
.group-detail-view__invite-button {
  margin-right: 10px;
}
.group-detail-view__post-prompt {
  margin-bottom: 10px;
}
</style>
