<template>
  <div v-if="posts" class="post-list">
    <dynamic-scroller
      v-if="posts && posts.length !== 0"
      :cache-id="listId"
      :items="filteredPosts"
      :min-item-size="1"
      :page-mode="true"
      class="scroller"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :active="active"
          :item="item"
          :data-index="index"
          :data-active="active"
        >
          <app-post-card
            :post="item"
            :can-delete="canDelete"
            :active="active"
          />
          <app-recommended-live-video-list
            v-if="showRecommendedVideosCard(index) && withRecommendedContent"
          />
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>

    <template v-if="posts && posts.length === 0">
      <cs-empty-state title="No posts yet" :description="emptyDescription" />
<!--      <app-recommended-just-the-news-video-list /> -->
      <app-recommended-live-video-list />
    </template>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AppPostCard from '@/components/post/items/PublicPostCard.vue';
import AppFollowCardSegment from '@/components/followCards/FollowCardSegment.vue';
import AppRecommendedLiveVideoList from '@/components/videos/RecommendedLiveVideoList.vue';
import { uuidv4 } from '@/services/utils';

export default {
  components: {
    AppPostCard,
    AppFollowCardSegment,
    AppRecommendedLiveVideoList,
  },
  props: {
    listId: {
      type: String,
      default: uuidv4(),
    },
    emptyDescription: {
      type: String,
      default: '',
    },
    posts: {
      type: Array,
      default: null,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    withRecommendedContent: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    filteredPosts() {
      const filtered = this.posts.filter((post) => {
        if (!post.user) return false;
        /*
        if (post.user.id === $auth.getUserId()) {
          return true;
        }
        */
        const isBlocked =
          post.user.myRelationship && post.user.myRelationship.isBlocked;
        const isSharedPostBlocked =
          post.sharedPost &&
          post.sharedPost.myRelationship &&
          post.sharedPost.myRelationship.isBlocked;
        return (
          !isBlocked &&
          !isSharedPostBlocked &&
          post.profanityStatus !== 'rejected' &&
          post.safe &&
          (!post.sharedPost || post.sharedPost.safe)
        );
      });

      return filtered;
    },
  },
  methods: {
    showFollowCard(index) {
      return index !== 0 && (index === 2 || index % 9 === 0);
    },
    showRecommendedVideosCard(index) {
      return index === 13;
    },
  },
};
</script>
