<template>
  <div class="meeting-stream">
    <div
      v-show="isVideoOn"
      :id="elementId"
      ref="player"
      class="agora-video-player"
    ></div>
  </div>
</template>

<script>
import $popups from '@/services/popups';
import $bus from '@/services/bus';
import $meetingpermissions from './meetingpermissions';

export default {
  props: {
    stream: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      audioLevel: 0,
    };
  },
  computed: {
    isVideoOn() {
      return this.stream && this.stream.video;
    },
    isAudioOn() {
      return this.stream && this.stream.audio;
    },
    audioIntensity() {
      if (this.audioLevel < 0.1) {
        return 'audio-intensity-0';
      }
      if (this.audioLevel < 0.2) {
        return 'audio-intensity-1';
      }
      if (this.audioLevel < 0.4) {
        return 'audio-intensity-2';
      }
      if (this.audioLevel < 0.7) {
        return 'audio-intensity-3';
      }
      return 'audio-intensity-4';
    },
    elementId() {
      return this.stream.uid && `el.${this.stream.uid}`;
    },
  },
  watch: {
    stream() {
      this.play();
    },
  },
  async mounted() {
    this.$nextTick(() => {
      console.log('Mounted');
      if (this.stream && (this.stream.video || this.stream.audio)) {
        this.play();
      }
    });
    $bus.$on('resetAudio', () => {
      console.log('Restart Audio');
      this.restart();
    });
  },
  beforeDestroy() {
    this.stopStream();
  },
  methods: {
    wait() {
      return new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    },
    stopStream() {
      if (this.stream) {
        if (this.stream.video && this.stream.video.isPlaying) {
          this.stream.video.stop();
        }
        if (this.stream.audio && this.stream.audio.isPlaying) {
          this.stream.audio.stop();
        }
      }
    },
    restart() {
      this.stopStream();
      this.play();
    },
    play() {
      if (this.stream) {
        if (this.stream.video && !this.stream.video.isPlaying) {
          this.stream.video.play(this.elementId, { fit: 'contain' });
        }
        if (this.stream.audio && !this.stream.audio.isPlaying) {
          this.stream.audio.play();
        } else {
          // alert('Already Playing');
        }
      }
    },
    // if (err && err.status !== 'aborted') {
    //         $bus.$emit('requireInteraction');
    //       }
  },
};
</script>
<style scoped>
.meeting-stream {
  color: white;
  display: flex;
  flex: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}
.meeting-stream >>> #videolocal-preview {
  position: inherit !important;
}
.agora-video-player {
  width: 100%;
  height: 100%;
  flex: 1;
}
.agora-audio-player {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.avatar {
  border: solid 0px var(--cs-primary-base);
  border-radius: 50px;
  transition: border-width 0.05s ease-in-out;
}
.audio-intensity-0 {
  border-width: 0px;
}
.audio-intensity-1 {
  border-width: 2px;
}
.audio-intensity-2 {
  border-width: 4px;
}
.audio-intensity-3 {
  border-width: 6px;
}
.audio-intensity-4 {
  border-width: 8px;
}
</style>
