<template>
  <div class="group-editor-form">
    <!-- Group name -->
    <cs-input
      v-model="group.name"
      :label="groupNameLabel"
      :placeholder="groupNameLabel"
      required
      @input="onInput"
    />

    <!-- Group category - not for subgroups -->
    <cs-select
      v-if="!subgroup"
      v-model="group.category"
      label="Category"
      :options="category"
    ></cs-select>

    <div>
      <!-- Group description -->
      <app-textarea
        v-model="group.description"
        label="Description"
        placeholder="Description"
        maxlength="2500"
        @input="onInput"
      />
      <div class="group-editor-form__chars cs-textstyle-paragraph-small">
        {{ group.description ? group.description.length : 0 }}/2500 Characters
      </div>

      <!-- Group privacy -->
      <cs-select
        v-model="group.privacy"
        :options="options"
        label="Privacy"
        required
        @input="onInput"
      />

      <!-- Group admin post review -->
      <br />
      <cs-toggle
        v-model="group.enableAdminReview"
        class="group-editor-form__toggle"
        label-checked="Enabled Post review"
        label-unchecked="Require admins to approve all posts"
      />
    </div>

    <!-- Group profile picture -->
    <app-image-input
      v-model="group.picture"
      label="Picture (required ratio: 1 : 1)"
      :enforce-ratio="1 / 1"
      @input="onInput"
    />

    <!-- Group banner picture -->
    <app-image-input
      v-model="group.banner"
      label="Banner (required ratio: 3 : 1)"
      :enforce-ratio="3 / 1"
      @input="onInput"
    />

    <!-- Group slug -->
    <app-slug-input
      :slug="group.slug"
      :name="group.name"
      slug-type="GROUP"
      @slugInput="handleSlugInput"
      @slugChecking="$emit('slugLoading', true)"
    />
  </div>
</template>

<script>
// General components
import AppTextarea from '@/components/general/Textarea.vue';
import AppImageInput from '@/components/general/ImageInput.vue';
import AppSlugInput from '@/components/general/SlugInput.vue';

import groupcategories from '@/assets/config/groupcategories.json';

export default {
  components: {
    AppTextarea,
    AppImageInput,
    AppSlugInput,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    subgroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      group: this.value,
      options: [
        {
          label: 'Public Group',
          value: 'public',
        },
        {
          label: 'Private Group',
          value: 'private',
        },
        {
          label: 'Secret Group',
          value: 'secret',
        },
      ],
      category: groupcategories,
    };
  },
  computed: {
    groupNameLabel() {
      if (this.subgroup) return 'Subgroup Name';
      return 'Group Name';
    },
  },
  mounted() {
    if (this.value && !this.group.slug) {
      this.group.slug = this.value.name;
    }
  },
  methods: {
    handleSlugInput(slug) {
      this.group.slug = slug;
      this.$emit('slugLoading', false);
    },
    onInput() {
      this.$emit('input', this.group);
    },
  },
};
</script>

<style scoped>
.group-editor-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
}
.group-editor-form__chars {
  color: var(--cs-gray-04);
  text-align: right;
}
.group-editor-form__toggle.cs-toggle >>> .cs-toggle__input::after {
  z-index: unset;
}
</style>
