<template>
  <app-form-modal
    :show="showModal"
    :title="header"
    :action="action"
    :disable-action="disableAction"
    :saving="saving"
    class="simple-modal"
    @close="close()"
    @action="relevantAction()"
  >
    <div class="create-video-modal-body">
      <!-- STEP 2 (VIDEO INFORMATION ) -->
      <div v-if="showInfo" class="video-information">
        <div class="video-information-modal">
          <div class="cs-textstyle-item-heading video-main-title">
            Video Information
          </div>
          <div class="cs-textstyle-paragraph-small">
            Add a title and description of your video. You can also select a
            category so viewers can easily search for your video based on topic.
          </div>
        </div>
        <cs-input
          v-model="title"
          label="Title:"
          placeholder="Enter your video title."
          required
          maxlength="50"
        />
        <div class="create-post-character">
          {{ title ? title.length : 0 }}/50 Characters
        </div>
        <app-textarea
          v-model="draft.description"
          label="Description:"
          placeholder="Provide a description for your video."
          maxlength="2500"
        />
        <div class="create-post-character">
          {{ draft.description ? draft.description.length : 0 }}/2500 Characters
        </div>

        <cs-select
          v-model="draft.category"
          label="Category:"
          :options="categories"
        />
      </div>
      <!-- STEP 3 (VIDEO THUMBNAIL) -->
      <div v-if="showThumbnail" class="video-thumbnail">
        <div v-if="!showCrop">
          <div class="video-information-modal">
            <div class="cs-textstyle-item-heading video-main-title">
              Video Thumbnail
            </div>
            <div class="cs-textstyle-paragraph-small">
              Select a thumbnail you would like to use on your video.You can
              also upload an image for your thumbnail by tapping on the Upload
              button.
            </div>
          </div>
          <div class="img-input-wrapper">
            <div class="cs-textstyle-detail-heading video-detail-title">
              Preview
            </div>
            <app-image-input v-if="!thumbnail" @input="onInput" />
            <div v-if="thumbnail" class="thumbnail-container">
              <div v-if="thumbnail" class="create-video-thumbnail-holder">
                <img :src="draft.thumbnail.url || draft.thumbnail.dataurl" />
              </div>
              <cs-button
                size="small"
                class="upload-button"
                @click="uploadThumbnail"
                >Upload</cs-button
              >
            </div>
          </div>
          <div v-if="thumbnails" class="video-thumbnail__option">
            <img
              v-for="(t, index) in thumbnails"
              :key="index"
              class="thumbnail-option"
              :src="t.url"
              @click="selectThumbnail(t)"
            />
          </div>
        </div>
        <div v-if="showCrop" class="cropped-image">
          <app-image-crop
            :image="thumbnailImage"
            :aspect-ratio="16 / 9"
            @cropped="croppedThumbnail"
          />
        </div>
      </div>
    </div>
  </app-form-modal>
</template>

<script>
import $aws from '@/services/aws';
import $bus from '@/services/bus';
import $popups from '@/services/popups';
import $utils from '@/services/utils';
import $videosApi from '@/api/videos';
import AppImageCrop from '@/components/general/ImageCrop.vue';
import AppTextarea from '@/components/general/Textarea.vue';
import AppFormModal from '@/components/general/FormModal.vue';
import videoCategories from '@/assets/config/videocategories.json';
import AppImageInput from '@/components/general/ImageInput.vue';

import videoPlaceholder from '@/assets/images/video-thumbnail-placeholder.jpg';

function onceEventFired(el, type) {
  return new Promise((resolve) => {
    const listener = (...props) => {
      resolve([...props]);
      el.removeEventListener(type, listener);
    };

    el.addEventListener(type, listener);
  });
}

async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function isVideoPlayable(videoFile) {
  const video = document.createElement('video');
  video.setAttribute('src', URL.createObjectURL(videoFile));
  video.currentTime = 1;

  await Promise.race([wait(1000), onceEventFired(video, 'loadeddata')]);

  if (video.readyState === 0) {
    return false;
  }

  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const getBlob = async () =>
    new Promise((resolve) => canvas.toBlob((blob) => resolve(blob)));

  const blob = await getBlob();

  return blob instanceof Blob;
}

export default {
  components: {
    AppFormModal,
    AppTextarea,
    AppImageCrop,
    AppImageInput,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      thumbnails: null,
      thumbnail: null,
      showModal: false,
      saving: false,
      draft: {},
      categories: videoCategories,
      action: 'Next',
      showInfo: true,
      showThumbnail: false,
      header: 'Step 2',
      title: null,
      showCrop: false,
    };
  },
  computed: {
    disableAction() {
      if (this.action === 'Next') {
        if (!this.title || this.title.length > 50) {
          return true;
        }
        if (this.draft.description && this.draft.description.length > 2500) {
          return true;
        }
      }
      if (this.action === 'Publish') {
        if (!this.thumbnail) {
          return true;
        }
        if (this.showCrop) {
          return true;
        }
      }
      return false;
    },
    thumbnailImage() {
      return this.draft.thumbnail.url || this.draft.thumbnail.dataurl;
    },
  },
  mounted() {
    $bus.$on('show-create-video-modal', () => {
      this.selectFile();
    });
  },
  beforeDestroy() {
    $bus.$off('show-create-video-modal');
  },
  methods: {
    close() {
      this.saving = false;
      this.showModal = false;
      this.action = 'Next';
      this.showInfo = true;
      this.showThumbnail = false;
      this.header = 'Step 2';
      this.title = null;
      this.selectedCover = null;
      this.thumbnails = null;
      this.thumbnail = null;
      this.draft = {};
    },
    onInput(val) {
      this.thumbnail = val;
      this.draft.thumbnail = val;
    },
    async uploadThumbnail() {
      const file = await $utils.selectFile('image/*');
      file.dataurl = URL.createObjectURL(file);
      this.thumbnail = file;
      this.draft.thumbnail = file;
      this.showCrop = true;
    },
    croppedThumbnail(image) {
      this.showCrop = false;
      this.draft.thumbnail = image;
    },
    async selectFile() {
      const file = await $utils.selectFile('video/*,audio/mp3');
      this.draft = {};
      this.draft.file = file;
      this.draft.title = file.name;
      this.title = file.name;
      // try {
      //   console.log('D');
      //   this.draft.thumbnail = await $utils.generateVideoThumbnail(file);
      //   this.draft.thumbnail.objectUrl = URL.createObjectURL(
      //     this.draft.thumbnail
      //   );
      // } catch (err) {
      //   console.log('Failed', err);
      //   $popups.alert(`Error: There was a problem uploading the file.`);
      //   console.log('PROBLEM UPLOADING FILE X', err);
      // }
      this.showModal = true;
      this.generateThumbnails();
    },
    relevantAction() {
      if (this.action === 'Next') {
        this.action = 'Publish';
        this.header = 'Step 3';
        this.showThumbnail = true;
        this.showInfo = false;
      } else if (this.action === 'Publish') {
        this.upload();
      }
    },
    blobToThumbnail(blob) {
      const thumbUrl = URL.createObjectURL(blob);
      const file = new File([blob], 'thumb.png', {
        type: 'image/png',
      });

      return { url: thumbUrl, file };
    },
    async generateThumbnails() {
      const file = this.draft.file;

      const videoUrl = URL.createObjectURL(file);

      const video = document.createElement('video');
      video.src = videoUrl;
      // Necessary for ios
      // video.preload = 'metadata';
      // video.autoplay = true;
      // video.pause();
      video.load();

      this.draft.videoUrl = videoUrl;

      if (!(await isVideoPlayable(file))) {
        const res = await fetch(new Request(videoPlaceholder));
        const videThumbnailPlaceholderBlob = await res.blob();
        this.selectThumbnail(
          this.blobToThumbnail(videThumbnailPlaceholderBlob)
        );

        return;
      }

      video.addEventListener('loadedmetadata', async () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const duration = video.duration;

        const thumbnails = [];

        for (let i = 1; i <= 5; i += 1) {
          /* eslint-disable no-await-in-loop */
          const thumbUrl = await this.generateThumbnail(
            video,
            canvas,
            Math.floor((i * duration) / 5)
          );
          thumbnails.push(thumbUrl);
        }
        this.thumbnails = thumbnails.reverse().slice(0, 4);
        this.selectThumbnail(this.thumbnails[0]);
      });
    },
    generateThumbnail(video, canvas, time) {
      return new Promise((resolve, reject) => {
        video.addEventListener('timeupdate', () => {
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          canvas.toBlob((blob) => resolve(this.blobToThumbnail(blob)));
        });
        video.currentTime = time;
      });
    },
    selectThumbnail(t) {
      this.thumbnail = t;
      this.draft.thumbnail = t;
    },
    async upload() {
      this.saving = true;
      const thumbnailInfo = this.draft.thumbnail.file
        ? this.draft.thumbnail.file
        : this.draft.thumbnail;
      try {
        const [videoUrl, thumbUrl] = await $aws.bulkUpload([
          this.draft.file,
          thumbnailInfo,
        ]);
        const videoAttachment = {
          // size, width, height
          url: videoUrl,
          thumbUrl,
          contentType: this.draft.file.type,
          type: 'video',
          size: this.draft.file.size,
          name: this.draft.file.name,
          title: this.title,
          description: this.draft.description,
        };
        const vars = {
          bodyText: this.title || this.draft.description,
          video: videoAttachment,
          category: this.draft.category,
        };
        const resp = await $videosApi.createVideo(vars);
        this.saving = false;
        this.$router.push({
          name: 'VideoDetail',
          params: resp.data.create_post,
        });
        // await this.nextTick();
        this.close();
      } catch (e) {
        this.saving = false;
        $popups.alert(`Error: There was a problem uploading the file.`);
        console.log('PROBLEM UPLOADING FILE', e);
      }
    },
  },
};
</script>

<style scoped>
.thumbnail-container {
  position: relative;
}
.create-video-thumbnail-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  background-color: black;
  margin-top: 10px;
}
.create-video-thumbnail-holder img {
  max-height: 100%;
  max-width: 100%;
}
.create-video-modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.video-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-thumbnail__option {
  margin-top: 10px;
  display: grid;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
}

.thumbnail-option {
  border-radius: 2px;
}

.video-information-modal {
  text-align: center;
  color: var(--cs-gray-05);
  margin-bottom: 15px;
}
.img-input-wrapper {
  width: 100%;
}
.img-input-wrapper >>> .group-editor-image-input__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-post-character {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  color: var(--cs-gray-04);
  justify-content: flex-end;
}
.create-character-cnt {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.video-main-title {
  margin-bottom: 5px;
}
.video-detail-title {
  text-align: center;
  margin-top: 10px;
}
.upload-button {
  --cs-button-color: var(--cs-gray-00) !important;
  --cs-button-text-color: var(--cs-gray-07) !important;
  position: absolute;
  bottom: 15px;
  right: 5px;
}

* >>> .ch-modal__header-button {
  width: auto;
}

.simple-modal >>> .ch-modal-form__overlay-progress {
  color: var(--cs-gray-00);
}

.simple-modal >>> .cs-spinner {
  --cs-spinner-color: var(--cs-gray-00) !important;
}
</style>
