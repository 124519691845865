<template>
  <div class="meeting-file">
    <div class="meeting-file__files">
      <i class="meeting-file__icon" :class="icon"></i>
      <div class="meeting-file__name">
        {{ file.name }}
      </div>
    </div>
    <div class="meeting-file__btns">
      <cs-button
        v-if="isAdmin"
        size="small"
        :disabled="disableAction"
        @click="deleteFile"
        >Remove</cs-button
      >
      <cs-button
        size="small"
        fill="outline"
        target="_blank"
        :href="handoutLink"
        >View</cs-button
      >
    </div>
  </div>
</template>

<script>
import $cdn from '@/services/cdn';

import DeleteMeetingFile from '@/gql/meetings/DeleteMeetingFile.gql';

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      const map = {
        'application/pdf': 'cs-icons-pdf',
      };
      return map[this.file.contentType] || 'cs-icons-application-file';
    },
    displaySize() {
      return `${Math.floor(this.file.size / 1000)} kb`;
    },
    handoutLink() {
      return $cdn.optimize(this.file.url);
    },
  },
  methods: {
    async deleteFile() {
      await this.$apollo.mutate({
        mutation: DeleteMeetingFile,
        variables: {
          id: this.file.id,
        },
        update: (cache) => {
          const normalizedId = cache.identify(this.file);
          if (normalizedId) {
            cache.evict({ id: normalizedId });
            cache.gc();
          }
        },
      });
      this.$emit('deleted');
    },
  },
};
</script>
<style scoped>
.meeting-file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px 10px;
  background: #ffffff;
}
.meeting-file i {
  font-size: 24px;
}
.meeting-file-info {
  flex: 1;
}
.meeting-file__files {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.meeting-file__btns {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.meeting-file__icon {
  color: #999999;
}
.meeting-file__name {
  font-size: 14px;
  color: #4a4a4a;
  word-break: break-word;
}
</style>
