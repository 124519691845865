<template>
  <app-action-sheet :open="showShareSheet" @close="showShareSheet = false">
    <div class="cs-textstyle-item-heading share-heading">Share Options</div>
    <app-action-sheet-button @click="shareAsPost()">
      <i class="ch-icons-share"></i>Share as Post
    </app-action-sheet-button>
    <app-action-sheet-button @click="shareAsMessage()">
      <i class="cs-icons-send-filled"></i>Share as Message
    </app-action-sheet-button>
    <app-action-sheet-button @click="shareExternally()">
      <i class="ch-icons-external-link"></i>Share Externally
    </app-action-sheet-button>
  </app-action-sheet>
</template>

<script>
import $bus from '@/services/bus';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
  },
  data() {
    return {
      showShareSheet: false,
      post: null,
    };
  },
  async mounted() {
    $bus.$on('show-share-post-sheet', (post) => {
      this.post = post;
      this.showShareSheet = true;
    });
  },
  methods: {
    shareAsPost() {
      $bus.$emit('show-share-post-modal', this.post);
    },
    shareAsMessage() {
      $bus.$emit('new-message-modal', { post: this.post });
    },
    shareExternally() {
      const link = `/p/${this.post.slug || this.post.id}`;
      $bus.$emit('show-share-sheet', {
        title: 'Share Post',
        link,
        post: this.post,
      });
    },
  },
};
</script>
