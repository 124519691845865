<template>
  <div>
    <div class="meeting-create-breakout">
      <cs-button size="small" @click="openCreateMeeting">Create Breakout</cs-button>
    </div>
    <app-create-meeting-modal :isBreakout="showModal" :meetingMain="meeting" @close="closeCreateMeeting" />
  </div>
</template>

<script>
import AppCreateMeetingModal from '@/components/meetings/CreateMeetingModal.vue';

export default {
  components: {
    AppCreateMeetingModal,
  },
  props: {
    meeting: {
      type: Object,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openCreateMeeting() {
      this.showModal = true;
    },
    closeCreateMeeting() {
      this.showModal = false;
    }
  },
};
</script>

<style scoped>
.meeting-create-breakout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}
* >>> .ch-modal__content {
  position: fixed;
}
</style>
