<template>
  <ion-page>
    <ion-header>
      <div class="news-profile__banner-content">
        <i class="cs-icons-back-arrow news-profile__back" @click="$router.go(-1)"></i>
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div>
        <cs-spinner v-if="!user"></cs-spinner>
        <div v-if="user">
          <app-profile-card :user="user" />
        </div>
        <app-news-item
          v-for="post in posts"
          :key="post.id"
          :post="post"
        ></app-news-item>
        <app-infinite-simple
          :query="$apollo.queries.posts"
          sort-key="createdAt"
        ></app-infinite-simple>
        <cs-empty-state
          v-if="posts && posts.length === 0"
          title="This user has not posted any articles yet."
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import GetProfile from '@/gql/general/GetProfile.gql';
import UserNews from '@/gql/news/UserNews.gql';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppProfileCard from '@/components/connections/ProfileCard.vue';
import AppNewsItem from '@/components/news/NewsItem.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppPullToRefresh,
    AppProfileCard,
    AppNewsItem,
    AppInfiniteSimple,
  },
  apollo: {
    user: {
      query: GetProfile,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    posts: {
      query: UserNews,
      variables() {
        return {
          userId: this.$route.params.id,
        };
      },
    },
  },
  methods: {
    async refresh($event) {
      await Promise.all([
        this.$apollo.queries.user.refetch(),
        this.$apollo.queries.posts.refetch(),
      ]);
      $event.target.complete();
    },
  },
};
</script>

<style scoped>
.news-profile__banner-content {
  position: relative;
}
.news-profile__back {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  align-items: center;
  color: white;
}
</style>
