<template>
  <ion-page>
    <ion-header>
      <app-category-header :uses-slot="true">
        <app-livestream-button v-if="!!isLogged && !hasNoChannel" />
      </app-category-header>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div class="ch-video-profile">
        <cs-spinner v-if="!user" class="ch-spinner" />
        <!-- If the user doesn't have a channel -->
        <div v-if="hasNoChannel">
          <div class="create-channel-placeholder">
            <img class="profile-banner" :src="placeholderImg" />
            <div class="create-channel-placeholder-content">
              <div class="cs-textstyle-item-heading">
                Create a CloutHub Channel
              </div>
              <div class="mt-4 cs-textstyle-paragraph-small text-light">
                It looks like your channel isn't set up yet. Create your channel
                in 6 easy steps so you can share your videos to your friends,
                followers and groups!
              </div>
              <div class="mt-4">
                <cs-button @click="createChannel()">+ Create Channel</cs-button>
              </div>
            </div>
          </div>
        </div>
        <!-- If the user already has a channel -->
        <div v-if="hasChannel && !user.myRelationship.isBlocked">
          <app-video-profile-header
            :user="user"
            class="profile-header"
            @afterSubscribeClick="changeReloadUserFlag()"
            @edit="editChannel()"
          />
          <cs-page-tabs
            v-model="selectedTab"
            align="justify"
            class="page-tab-shadow"
          >
            <cs-page-tab label="Videos"> </cs-page-tab>
            <cs-page-tab label="About"></cs-page-tab>
          </cs-page-tabs>
          <div v-if="selectedTab == 0">
            <app-video-item
              v-for="video in pinnedVideos"
              :key="video.id"
              :video="video"
              direction="column"
              class="video-snapshot"
            ></app-video-item>
            <app-video-item-mini
              v-for="video in allVids"
              :key="video.id"
              :video="video"
              show-snapshot
              @reloadVideosList="reloadVideosList()"
            />
            <app-infinite-simple
              :query="$apollo.queries.videos"
              sort-key="createdAt"
            />
          </div>
          <div v-if="selectedTab == 1">
            <app-expandable-text
              :content="user.channelDescription"
              :max-lines="7"
              placeholder="No Description"
              class="description-block"
            />
            <div>
              <div class="cs-textstyle-paragraph-bold similar-text">
                Similar Channels
              </div>
              <app-channel-segment :query="recommendedChannels" show-button />
            </div>
          </div>
        </div>
      </div>
      <template v-if="user && !!isLogged">
        <app-edit-channel-modal
          :user="user"
          :show="showEditChannelModal"
          @close="showEditChannelModal = false"
        />
        <app-create-channel-modal
          :user="user"
          :show="showCreateChannelModal"
          @close="closeCreateChannel"
        />
        <!-- Channel Popup -->
        <app-channel-popup
          :show-popup="showPopup"
          class="popup"
          @close-popup="closePopup"
        >
          <div class="popup-content">
            <div class="cs-textstyle-detail-heading">Congratulations!</div>
            <div class="cs-textstyle-paragraph-small text-light">
              Your Channel has been successfully set up! You can now upload your
              videos to your channel for viewers to watch. If you want to upload
              videos now, tap on the upload button.To do livestream, please
              contact support at support@clouthub.com.
            </div>
            <div class="video-buttons">
              <app-upload-video>
                <cs-button
                  size="small"
                  class="ch-buttons"
                  block
                  @click="closePopup"
                  >Upload Video</cs-button
                >
              </app-upload-video>
              <cs-button
                size="small"
                variant="info"
                fill="clear"
                uppercase
                @click="closePopup"
                >Skip</cs-button
              >
            </div>
          </div>
        </app-channel-popup>
      </template>
      <div v-if="user && user.myRelationship.isBlocked" fluid="xl" class="px-0">
        <app-blocked-user-profile :user="user" @unblock="unblock" />
      </div>
    </ion-content>
    <app-create-button v-if="hasChannel" @click="showCreate()" />
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';
import $bus from '@/services/bus';
// Component
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppVideoProfileHeader from '@/components/videos/VideoProfileHeader.vue';
import AppVideoItemMini from '@/components/videos/VideoItemMini.vue';
import AppEditChannelModal from '@/components/videos/EditChannelModal.vue';
import AppCreateChannelModal from '@/components/videos/CreateChannelModal.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppLivestreamButton from '@/components/videos/LivestreamButton.vue';
import AppCreateButton from '@/components/general/CreateButton.vue';
import AppChannelPopup from '@/components/videos/ChannelPopup.vue';
import AppUploadVideo from '@/components/videos/UploadVideo.vue';
import AppChannelSegment from '@/components/videos/new/ChannelSegmentNew.vue';
import AppVideoItem from '@/components/videos/VideoItem.vue';
import AppExpandableText from '@/components/general/ExpandableText.vue';
import AppBlockedUserProfile from '@/components/connections/BlockedUserProfile.vue';

// GQL
import GetChannel from '@/gql/videos/GetChannel.gql';
import ListUserVideos from '@/gql/videos/ListUserVideos.gql';
import ListFeaturedChannels from '@/gql/videos/ListFeaturedChannels.gql';

const placeholderImg = require('@/assets/images/placeholders/profile-banner.png');

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppVideoProfileHeader,
    AppVideoItemMini,
    AppEditChannelModal,
    AppChannelPopup,
    AppInfiniteSimple,
    AppLivestreamButton,
    AppCreateButton,
    AppCreateChannelModal,
    AppUploadVideo,
    AppChannelSegment,
    AppVideoItem,
    AppExpandableText,
    AppBlockedUserProfile,
  },
  data() {
    return {
      id: this.$route.params.id,
      showEditChannelModal: false,
      placeholderImg,
      selectedTab: 0,
      showCreateChannelModal: false,
      showPopup: false,
      recommendedChannels: ListFeaturedChannels,
      reloadAfterClickingSubscribeButton: false,
    };
  },
  apollo: {
    user: {
      query: GetChannel,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !!this.user && !this.reloadAfterClickingSubscribeButton;
      },
    },
    videos: {
      query: ListUserVideos,
      variables() {
        return {
          userId: this.id,
        };
      },
    },
  },
  computed: {
    isLogged() {
      return this.user && this.user.id === $auth.getUserId();
    },
    hasNoChannel() {
      return this.user && !this.user.channelName && !this.user.videoPosts.count;
    },
    hasChannel() {
      return this.user && (this.user.channelName || this.user.videoPosts.count);
    },
    userIsLiveStreaming() {
      return this.user && this.user.isLiveStreaming;
    },
    allVids() {
      return this.videos
        ? this.videos.filter(video => !video.ispinnedonchannel)
        : null;
    },
    pinnedVideos() {
      return this.videos
        ? this.videos.filter(video => video.ispinnedonchannel)
        : null;
    }
  },
  watch: {
    user() {
      this.reloadAfterClickingSubscribeButton = false;
    },
  },
  methods: {
    editChannel() {
      this.showEditChannelModal = true;
    },
    changeReloadUserFlag() {
      this.reloadAfterClickingSubscribeButton = true;
    },
    createChannel() {
      this.showCreateChannelModal = true;
    },
    closeCreateChannel(val) {
      this.showCreateChannelModal = false;
      if (val) {
        this.showPopup = true;
      }
    },
    closePopup() {
      this.showPopup = false;
      window.location.reload();
    },
    async refresh($event) {
      await Promise.all([
        this.$apollo.queries.user.refetch(),
        this.$apollo.queries.videos.refetch(),
      ]);
      $event.target.complete();
    },
    showCreate() {
      $bus.$emit('show-create-video-modal');
    },
    reloadVideosList() {
      this.$apollo.queries.videos.refetch();
    }
  },
  // mounted(){
  //   if(this.user.myRelationship.isBlocked){
  //     this.$router.push({ name: 'Profile', params: this.user});
  //   }
  // }
};
</script>

<style scoped>
/* Back button styling */
.ch-video-profile__back {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  height: 50px;
  width: 50px;
  font-size: 24px;
  backdrop-filter: blur(3px);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}
.ch-live-video-frame {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
}
.ch-live-video {
  border-radius: 10px;
}
.ch-video-profile__extras {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ch-video-profile__extras--options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.ch-icons {
  font-size: 16px;
}
.create-channel-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: dashed 1px var(--cs-gray-03);
  overflow: hidden;
  text-align: center;
}
.create-channel-placeholder-content {
  padding: 30px;
}
.live-badge {
  position: absolute;
  color: white;
  z-index: 1000;
  margin-left: 15px;
  margin-top: 10px;
}
.profile-banner {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.mt-4 {
  margin-top: 20px;
}
.text-light {
  color: var(--cs-gray-04);
}
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 5px;
  text-align: center;
}
.video-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.description-block {
  background: var(--cs-gray-00);
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 3px 8px -8px black;
  margin-bottom: 8px;
  padding: 10px 10px;
}
.similar-text {
  padding: 0px 10px;
  margin: 10px 0px;
}
.page-tab-shadow {
  border-bottom: 3px solid var(--cs-gray-03);
}
* >>> .ch-header__buttons-right {
  margin-right: 8px;
}
.video-snapshot {
  margin-bottom: 3px;
}
.profile-header {
  padding-bottom: 5px;
}
.see-more-button {
  color: var(--cs-gray-04);
}
</style>
