import { io } from 'socket.io-client';
// import agorachat from './agorachat';

let socket = null;

function connect (){
  const baseUrl = process.env.VUE_APP_SOCKETIO_ENDPOINT;
  // socket = io(baseUrl, {
  //   reconnectionDelayMax: 10000,
  //   transports : ['websocket'],
  //   auth: {
  //     token: localStorage.access_token
  //   },
  // });
  socket = io(baseUrl);
}

function joinRoom(roomType, roomId) {
  socket.emit('join-room', { roomType, roomId });
}
function leaveRoom(roomType, roomId) {
  socket.emit('leave-room', { roomType, roomId });
}
function sendRoom(event, roomType, roomId) {
  socket.emit('send-room', { event, roomType, roomId });
}
function sendRoomPayload(payload) {
  socket.emit(payload.emitKey, payload);
}

function emit(key, body) {
  socket.emit(key, body);
}
function on(key, handler) {
  socket.on(key, handler);
}

function onRoom(roomType, roomId, key, handler) {
  socket.on(key, (msg) => {
    if (msg.roomType == roomType && msg.roomId == roomId) {
      handler(msg);
    }
  });
}

export default {
  connect,
  joinRoom,
  leaveRoom,
  sendRoom,
  sendRoomPayload,
  onRoom,
  emit,
  on,
  // agorachat
}