<template>
  <div class="not-started">
    <div v-if="canModerate" class="not-started__control">
      <cs-button
        v-if="canModerate"
        variant="primary"
        class="not-started__button"
        size="small"
        @click="$emit('manageMeeting')"
      >
        Manage Event
      </cs-button>
      <cs-button
        v-if="canModerate"
        size="small"
        class="not-started__button"
        @click="startMeeting"
      >
        Start Event
      </cs-button>
      <app-agora-rtmp-button
        v-if="canGoLiveViaRTMP"
        class="not-started__button"
        text="Get RTMP Code"
        fill="solid"
        size="small"
        :meeting="meeting"
      />
    </div>
    <div class="not-started__social">
      <app-meeting-invite-button :meeting="meeting" />
      <app-meeting-share-button :meeting="meeting" />
    </div>
    <div
      class="not-started__image"
      :style="`background-image:url(${meeting.picture})`"
    ></div>
    <div class="not-started__meeting-details">
      <h1 class="not-started__title">{{ meeting.name }}</h1>
      <p v-if="meeting.description" class="not-started__description">
        {{ meeting.description }}
      </p>
      <p class="not-started__start-text">Meeting will begin</p>
      <p class="not-started__start-time">{{ displayTime }}</p>
      <div v-if="isMeetingLive" class="not-started__is-live-label">MEETING IS LIVE</div>
      <div v-if="isMeetingLive" class="join-button-wrapper">
        <cs-button size="small" class="cs-button" uppercase block @click="switchModalVisibilityTo('join', true)">
          Join
        </cs-button>
      </div>
      <div class="hosts">
        <p class="hosts__title">Hosts</p>
        <div class="hosts__wrapper">
          <div v-for="{ user } in leaderMembers" :key="user.id" class="host">
            <app-avatar
              :picture="user.profilePhotoURL"
              :name="user.displayname"
              variant="primary"
              size="medium"
            />
            <p class="host__name">{{ user.username }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMember" class="not-started__bottom-control">
      <app-meeting-leave-button />
    </div>
    <app-modal :show="modalsVisibility['join']" title="Join Event" hideClose showBackIcon @back="switchModalVisibilityTo('join')">
      <div class="join-modal">
        <div class="join-modal__title">
          To attend this meeting, choose one of the following options.
        </div>
        <div class="join-modal__buttons">
          <cs-button size="small" class="join-modal__buttons__button" :to="`/login?redirect=${this.$router.currentRoute.path}`">Sign In</cs-button>
          <cs-button size="small" class="join-modal__buttons__button" :to="`/signup?redirect=${this.$router.currentRoute.path}`">Create an Account</cs-button>
          <!--<cs-button size="small" class="join-modal__buttons__button" @click="switchModalVisibilityTo('asGuest', true)">Join as a Guest</cs-button>-->
        </div>
      </div>
    </app-modal>
    <app-modal :show="modalsVisibility['asGuest']" title="Join as a Guest" hideClose showBackIcon @back="switchModalVisibilityTo('asGuest')">
      <div class="as-guest-modal">
        <p class="as-guest-modal__description">
          To join the meeting as a Guest, enter the name you would like to use, select and avatar and enter your email down below. Once you�re set up, you can interact in the event.
        </p>
        <div class="as-guest-modal__name-input">
          <cs-input
            v-model.trim="guest.displayName"
            placeholder="Enter your display name"
            label="Name"
            :maxLength="10"
            required
          />
          <span class="as-guest-modal__name-input__chars-counter">
            {{ guestNameLengthTitle }}
          </span>
        </div>
        <div class="as-guest-modal__avatar">
          <div class="guest-avatar">
            <img v-if="guest.avatarURL" :src="guest.avatarURL" alt="guest-avatar">
          </div>
          <cs-button size="small" fill="outline" @click="switchModalVisibilityTo('guestAvatar', true)">
            Select an Avatar
          </cs-button>
        </div>
        <div class="as-guest-modal__email-input">
          <cs-input
            v-model.trim="guest.email"
            placeholder="Enter your email"
            label="Email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
        </div>
        <div class="as-guest-modal__done-btn">
          <cs-button
            v-if="!isGuestSigningUp"
            size="small"
            uppercase
            :disabled="isGuestDoneBtnDisabled"
            @click="signupGuest"
          >
            Done
          </cs-button>
          <app-spinner v-if="isGuestSigningUp"/>
        </div>
      </div>
    </app-modal>
    <app-modal :show="modalsVisibility['guestAvatar']" title="Select Avatar" hideClose showBackIcon @back="switchModalVisibilityTo('guestAvatar')">
      <div class="avatar-modal">
        <div class="avatar-modal__avatar" :class="{'avatar-modal__avatar--chosen': guest.avatarURL === avatarURL}" v-for="avatarURL in GUEST_AVATARS" @click="chooseAvatar(avatarURL)">
          <img :src="avatarURL" alt="guest avatar">
        </div>
      </div>
    </app-modal>
    <app-modal :show="modalsVisibility['welcome']" :showHeader="false">
      <div class="welcome-modal">
        <div class="welcome-title">Welcome {{ guest.displayName }}!</div>
        <div class="welcome-description">
          We hope you will enjoy this CloutHub meeting! We offer many features designed to give you a great experience.<br /><br />
          During the meeting you will be able to text chat with other audience members, and if the host is taking questions, you can raise your hand to ask a question or make a comment and have two way interaction with the presenters. There are also handouts and breakout rooms available if they host chose to use them.<br /><br />
          Thank you for attending this meeting. If you have any problems, you can contact our support team at <a href="mailto:support@clouthub.com">support@clouthub.com</a>.<br /><br />
        </div>
        <cs-button size="small" @click="reload">
          Go into Meeting
        </cs-button>
      </div>
    </app-modal>
  </div>
</template>

<script>

import $socket from '@/services/socket';
import AppAvatar from '@/components/general/Avatar.vue';
import AppAgoraRtmpButton from '@/components/meetings/buttons/AgoraRTMPButton.vue';
import AppMeetingShareButton from '@/components/meetings/buttons/MeetingShareButton.vue';
import AppMeetingLeaveButton from '@/components/meetings/buttons/MeetingLeaveButton.vue';
import AppMeetingInviteButton from '@/components/meetings/buttons/MeetingInviteButton.vue';
import AppSpinner from '@/components/general/Spinner'

import GetMe from '@/gql/general/GetMe.gql';
import StartMeeting from '@/gql/meetings/StartMeeting.gql';
import ListMeetingMembers from '@/gql/meetings/ListMeetingMembers.gql';
import GuestSignIn from '@/gql/general/GuestSignIn.gql'
import AppModal from "@/components/general/Modal";
import Img from "@/components/general/Img";
import { gReCaptchaSiteKey } from '@/constants';
import { VueRecaptcha } from 'vue-recaptcha';
import $popups from '@/services/popups';

export default {
  components: {
    Img,
    AppAvatar,
    AppAgoraRtmpButton,
    AppMeetingLeaveButton,
    AppMeetingShareButton,
    AppMeetingInviteButton,
    AppModal,
    AppSpinner,
    VueRecaptcha,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    user: {
      query: GetMe,
      skip() {
        return !this.isMember;
      }
    },
    members: {
      query: ListMeetingMembers,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
    },
  },
  data() {
    return {
      showInstructions: false,
      showMenu: false,
      showShareSheet: false,
      modalsVisibility: {
        join: false,
        asGuest: false,
        guestAvatar: false,
        welcome: false,
      },
      GUEST_AVATARS: [
        'https://chforum.backendcdn.com/291f0e8d-212f-4cee-8b42-7c57bc2b48d7?',
        'https://chforum.backendcdn.com/61a9da40-7011-4815-a5bd-7c787a2bb980?',
        'https://chforum.backendcdn.com/604afb77-f851-4fa2-b80e-cc62e8208d91?',
        'https://chforum.backendcdn.com/5af4bb25-9845-4fa5-a8f8-9a60111d0402?',
        'https://chforum.backendcdn.com/a0538e8f-c581-48fd-8cca-66cc9081bc96?',
        'https://chforum.backendcdn.com/b017dd6c-dade-4c3a-8723-b1f9dda37c0b?',
        'https://chforum.backendcdn.com/aa542d0e-8ddb-402a-a180-147359929df7?',
        'https://chforum.backendcdn.com/a42e545f-87a4-49fb-a0d5-f8a41bfedf3e?',
        'https://chforum.backendcdn.com/f414e0fe-fc1a-472a-872a-f4b190e30e0b?',
        'https://chforum.backendcdn.com/758c5b4f-0b61-4a8b-bd8c-e1fd596f6d82?',
        'https://chforum.backendcdn.com/cd36b9c1-bd97-4ac9-b1ab-1d9bf22c4cc5?',
        'https://chforum.backendcdn.com/a75f15d2-8a5c-4a76-b794-a95e822b4f25?',
      ],
      guest: {
        displayName: '',
        email: '',
        avatarURL: null,
      },
      isGuestSigningUp: false,
      sitekey: gReCaptchaSiteKey,
    };
  },
  computed: {
    canGoLiveViaRTMP() {
      return this.meeting.liveStreamMode && this.role === 'ADMIN';
    },
    displayTime() {
      return (
        this.meeting &&
        this.$moment
          .utc(this.meeting.startTime)
          .local()
          .format('MMM D [|] h:mmA')
      );
    },
    leaderMembers() {
      if (this.members) {
        const leaders = this.members.filter((m) =>
          ['ADMIN', 'MODERATOR', 'PRESENTER'].includes(m.role)
        );
        return leaders.sort((a, b) => (a.role < b.role ? -1 : 1));
      }
      return [];
    },
    role() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    canModerate() {
      return this.role === 'ADMIN' || this.role === 'MODERATOR';
    },
    isMember() {
      return this.meeting && !!this.meeting.myMembership;
    },
    guestNameLengthTitle() {
      return `${this.guest.displayName.length}/10 characters`
    },
    isGuestDoneBtnDisabled() {
      return !this.guest.displayName || !this.guest.email || !this.guest.avatarURL;
    },
    isMeetingLive() {
      return this.meeting.status !== 'NOT_STARTED';
    }
  },
  mounted() {
    if (window.location !== window.parent.location) {
      window.parent.location.href = window.location.href;
    }
  },
  methods: {
    async startMeeting() {
      await this.$apollo.mutate({
        mutation: StartMeeting,
        variables: {
          meetingId: this.meeting.id,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-status-changed');
    },
    openMeetingMenu() {
      this.showMenu = true;
    },
    closeOption() {
      this.showMenu = false;
    },
    switchModalVisibilityTo(modalName, operation) {
      this.modalsVisibility[modalName] = operation;
    },
    chooseAvatar(avatarURL) {
      this.guest.avatarURL = avatarURL;
    },
    async signupGuest() {
      try {
        this.isGuestSigningUp = true;
        const res = await this.$apollo.mutate({
          mutation: GuestSignIn,
          variables: {
            email: this.guest.email,
            displayName: this.guest.displayName,
            avatarURL: this.guest.avatarURL,
          },
        })
        localStorage.access_token = res.data.token;
        this.switchModalVisibilityTo('welcome', true)
      } catch (e) {
        console.error(e);
        await $popups.alert(e.message);
      } finally {
        this.isGuestSigningUp = false;
      }
    },
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
:root {
  --cs-font-primary: 'Lato';
}
.not-started {
  padding: 10px;
  height: 90vh;
  overflow: scroll;
}
.not-started__button {
  --cs-button-padding: 6px 10px;
}
.not-started__control {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.not-started__social {
  margin: 17px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.not-started__image {
  height: 100%;
  max-height: 355px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.not-started__meeting-details {
  padding: 5px;
}
.not-started__title {
  display: -webkit-box;
  margin: 5px 0 0;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.not-started__description {
  display: -webkit-box;
  max-height: 200px;
  font-size: 14px;
  color: #4a4a4a;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.not-started__start-text {
  margin: 0;
  text-align: center;
  font-size: 16px;
  color: #4a4a4a;
}
.not-started__start-time {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
}
.hosts {
  text-align: center;
}
.hosts__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.hosts__title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
}
.host__name {
  margin: 0;
  font-size: 18px;
  color: #4a4a4a;
}
.not-started__bottom-control {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.join-button-wrapper {
  margin: 10px auto;
  max-width: 134px;
}

.join-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 0;
}
.join-modal__title {
  font-size: 20px;
  text-align: center;
  width: 278px;
  margin-bottom: 60px;
}
.join-modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.join-modal__buttons__button {
  width: 150px;
  margin-bottom: 40px;
}
.as-guest-modal {
  display: flex;
  flex-direction: column;
}
.as-guest-modal__description {
  font-size: 14px;
}
.as-guest-modal__name-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.as-guest-modal__name-input__chars-counter {
  font-size: 12px;
  align-self: flex-end;
  color: #4a4a4a;
}
.as-guest-modal__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.guest-avatar {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #d8d8d8;
  margin-bottom: 15px;
  text-align: center;
  line-height: 128px;
}
.as-guest-modal__done-btn {
  align-self: center;
  margin-top: 20px;
  width: 91px;
}
.avatar-modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.avatar-modal__avatar {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 7px solid transparent;
}
.avatar-modal__avatar--chosen {
  padding: 3px;
  border: solid 4px #e2896e;
}
.welcome-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin: 56px 0 15px;
}
.welcome-description {
  font-size: 16px;
  text-align: center;
}
.not-started__is-live-label {
  color: #e02020;
  text-align: center;
  font-weight: 900;
}
</style>
