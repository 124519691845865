<template>
  <div>
    <div v-if="groups && groups.length" class="groups-row">
      <div
        v-for="(group, index) in groups"
        v-router-link="
          hasMoreItem(index)
            ? { name: 'MyGroupsList' }
            : { name: 'GroupDetail', params: group }
        "
        :class="{ 'group-item': true, 'group-item-more': hasMoreItem(index) }"
      >
        <div
          class="group-item-img"
          :style="{
            'background-image': `url(${group.picture || defaultPicture})`,
          }"
        ></div>
        <span
          v-if="hasMoreItem(index)"
          class="group-item-label group-item-count"
          >+{{ myGroupsQty - gridSize }}</span
        >
        <span v-else class="group-item-label group-item-name">{{
          group.name
        }}</span>
      </div>
    </div>

    <my-groups-post-list />
  </div>
</template>

<script>
import MyGroupsPostList from '@/components/post/lists/MyGroupsPostList.vue';
import AppImg from '@/components/general/Img.vue';

import ListMyGroups from '@/gql/groups/ListMyGroups.gql';
import CountMyGroups from '@/gql/groups/CountMyGroups.gql';

const defaultPicture = require('@/assets/images/placeholders/group-picture.png');
const gridSize = 4;

export default {
  components: { MyGroupsPostList, AppImg },
  apollo: {
    groups: {
      query: ListMyGroups,
      variables: {
        limit: gridSize,
        sortBy: 'name',
      },
    },
    myGroupsQty: {
      query: CountMyGroups,
    },
  },
  data() {
    return {
      defaultPicture,
      gridSize,
    };
  },
  methods: {
    hasMoreItem(index) {
      return index + 1 === gridSize && this.myGroupsQty > gridSize;
    },
  },
};
</script>

<style scoped>
.groups-row {
  display: flex;
  padding: 15px 4px;
}

.group-item {
  position: relative;
  overflow: hidden;
  flex: 1 1 25%;
  margin: 0 4px;
  border-radius: 8px;
}

.group-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 238, 238, 0),
    rgba(0, 0, 0, 0.5)
  );
}

.group-item.group-item-more::before {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.group-item-img {
  width: 100%;
  padding-bottom: 100%;
  background-size: 100% 100%;
  background-position: center;
}

.group-item-label {
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 7px;
}

.group-item-name {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.group-item-count {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}

.group-item-more {
  font-size: 25px;
  font-weight: 900;
  color: #fff;
}
</style>
