<template>
  <ion-page class="ch-home">
    <ion-header>
      <app-category-header :title="title" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-tag-post-list ref="tag-post-list" />
    </ion-content>
  </ion-page>
</template>

<script>
import $bus from '@/services/bus';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppTagPostList from '@/components/post/lists/TagPostList.vue';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppTagPostList,
  },
  computed: {
    title() {
      return `#${this.$route.params.tag}`;
    },
  },
  methods: {
    createPost() {
      $bus.$emit('show-post-composer-modal', {
        room: 'main',
      });
    },
    async refresh($event) {
      await this.$refs['tag-post-list'].refresh();
      $event.target.complete();
    },
  },
};
</script>

<style scoped></style>
