<template>
  <app-img
    class="group-detail-view__banner"
    auto-optimize
    :src="source"
    :alt="alt"
  />
</template>

<script>
// General components
import AppImg from '@/components/general/Img.vue';

const defaultBannerUrl = require('@/assets/images/placeholders/group-banner.png');

export default {
  components: {
    AppImg,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    source() {
      return this.group.banner ? this.group.banner : defaultBannerUrl;
    },
    alt() {
      const defaultAlt = `Default group banner picture`;
      const groupAlt = `${this.group.name} banner picture`;
      return this.group.banner ? groupAlt : defaultAlt;
    },
  },
};
</script>

<style scoped>
.group-detail-view__banner {
  height: 100%;
  width: 100%;
}
.group-detail-view__banner:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
</style>
