const selectFiles = (filetype, single) =>
  new Promise((resolve) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = !single;
    fileInput.accept = filetype; // === 'image' ? 'image/*, video/*' : 'application/pdf';
    fileInput.style.display = 'none';
    fileInput.addEventListener('change', () => {
      const output = single ? fileInput.files[0] : fileInput.files;
      resolve(output);
      document.body.removeChild(fileInput); // Required for iOS
    });
    document.body.appendChild(fileInput); // Required for iOS
    fileInput.click();
  });

const selectFile = (filetype) => selectFiles(filetype, true);

const getSelectedFileInfo = (file) => {
  const dataurl = URL.createObjectURL(file);
  const fileinfo = {
    type: file.type,
    size: file.size,
    name: file.name,
  };
  const attachment = { ...fileinfo, dataurl };
  return attachment;
};

function blobToFile2(theBlob, fileName) {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

// Returns a blob pretending to be a file but seems to be more reliable than blobToFile2 code
function blobToFile(blob, filename) {
  if (!blob.name) {
    blob.filename = filename || 'Untitled';
    blob.lastModifiedDate = new Date();
  }
  return blob;
}

function generateVideoThumbnail(videoFile) {
  return new Promise((resolve, reject) => {
    let video = document.createElement('video');
    video.src = URL.createObjectURL(videoFile);
    const onCanPlay = async (ex) => {
      if (video.currentTime !== 1) video.currentTime = 1;
    };
    const onSeeked = (ev) => {
      console.log('Seeked', ev);
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const d = canvas.toDataURL();
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      canvas.toBlob((blob) => {
        const file = blobToFile(blob, 'thumb.jpg');
        resolve(file);
        if (!video) return;
        video = null;
      }, 'image/jpeg');
    };
    video.addEventListener('canplay', onCanPlay);
    video.addEventListener('seeked', onSeeked);
    video.load();
  });
}

function getCordovaMedia(sourceType, mediaType) {
  if (window.cordova) {
    return new Promise((resolve, reject) => {
      function handlePicture(localUrl) {
        resolveLocalFileSystemURL(
          localUrl,
          (fileEntry) => {
            fileEntry.file((file) => {
              // file isn't a real file for some reason
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (ev) => {
                const blob = new Blob([fileReader.result], { type: file.type });
                const exportFile = blobToFile(blob, 'image.jpg');
                resolve(exportFile);
              };
            }, console.error);
          },
          console.error
        );
      }
      navigator.camera.getPicture(handlePicture, reject, {
        destinationType: window.Camera.DestinationType.FILE_URI,
        sourceType,
        mediaType,
      });
    });
  }
}

const dataURLToFile = async (dataURL, filename) => {
  const blob = await fetch(dataURL).then((r) => r.blob());
  if (!blob.name) {
    blob.filename = filename || 'Untitled';
    blob.lastModifiedDate = new Date();
  }
  const file = blobToFile(blob, filename);
  file.dataurl = URL.createObjectURL(blob);
  return file;
};

export function sortByName(a, b) {
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
}

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export default {
  selectFile,
  selectFiles,
  getSelectedFileInfo,
  blobToFile,
  dataURLToFile,
  generateVideoThumbnail,
  getCordovaMedia,
  uuidv4,
};
