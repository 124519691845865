<template>
  <app-button-plain>
    <img class="icon-action" src="@/assets/icons/sort.svg" alt="Sort" />
  </app-button-plain>
</template>

<script>
import AppButtonPlain from '@/components/general/ButtonPlain.vue';

export default {
  components: { AppButtonPlain },
};
</script>

<style scoped>
.icon-action {
  height: 24px;
  width: 24px;
}
</style>
