<template>
  <div class="ch-conversation-list">
    <div
      v-for="(conversation, $index) in list"
      :key="conversation.id + $index"
      class="ch-conversation"
    >
      <app-message-item
        v-router-link="{
          name: 'ConversationDetail',
          params: { id: conversation.conversationId },
        }"
        :conversation="conversation"
      ></app-message-item>
    </div>

    <cs-empty-state v-if="list.length === 0" class="ch-conversation-list-empty">
      <div slot="title" class="empty-slot-title">
        <app-img
          class="ch-conversation-list__empty-slot-img"
          :src="require('@/assets/images/groups/no-message.svg')"
        />
        <br />
        No Messages Found
      </div>
      <div slot="description" class="empty-slot-desc">{{ description }}</div>
      <div slot="button">
        <cs-button @click="openNewMessageModal">Start Conversation</cs-button>
      </div>
    </cs-empty-state>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import AppImg from '@/components/general/Img.vue';
import AppMessageItem from '@/components/conversations/MessageItem.vue';
import ListConversations from '@/gql/conversations/ListConversations.gql';
import NewMessageSubscription from '@/gql/conversations/NewMessageSubscription.gql';

export default {
  components: {
    AppImg,
    AppMessageItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: 'You have not any messages yet',
    },
  },
  apollo: {
    conversations: {
      query: ListConversations,
    },
  },
  data() {
    return {
      conversationList: [],
      subscription: null,
    };
  },
  created() {
    this.subscription = this.$apollo.queries.conversations.subscribeToMore({
      document: NewMessageSubscription,
      variables: { conversationId: this.$route.params.conversationId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.newMessage;
        const updatedConversations = prev.conversations.map((conversation) => {
          if (conversation.conversationId === newMessage.conversationId) {
            conversation.messages.push(newMessage);
          }
          return conversation;
        });
        this.conversationList = updatedConversations;
        return { conversations: updatedConversations };
      },
    });
  },
  beforeDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  methods: {
    openNewMessageModal() {
      $bus.$emit('new-message-modal', {});
    },
  },
};
</script>

<style scoped>
.ch-conversation-list {
  padding: 12px 20px 0;
  background-color: var(--cs-gray-00);
}
.ch-conversation-list-empty {
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ch-conversation-list__empty-slot-img {
  width: 80%;
  margin-bottom: 10px;
}
.empty-slot-desc {
  color: var(--cs-gray-04);
}
</style>