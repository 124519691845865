<template>
  <ion-page>
    <ion-header>
      <app-category-header title="Announcement" />
    </ion-header>
    <ion-content>
      <cs-spinner v-if="!announcement" />
      <div v-if="announcement" class="announcement-view">
        <div class="announcement-view__header">
          <app-avatar
            :picture="announcement.group.picture"
            :name="announcement.group.name"
            size="large"
            class="announcement-view__avatar"
          />
          <div>
            <h2>{{ announcement.group.name }}</h2>
            <span class="announcement-view__author">
              by {{ announcement.user.username }}
            </span>
          </div>
        </div>
        <p class="announcement-view__description">
          {{ announcement.description }}
        </p>
        <cs-button
          v-if="announcement.link"
          block
          @click="openUrl(announcement.link)"
        >
          More Info
        </cs-button>
        <cs-button fill="outline" block @click="visitGroup">
          Visit group
        </cs-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import GetAnnouncement from '@/gql/groups/GetAnnouncement.gql';

import $browser from '@/services/browser';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppCategoryHeader,
    AppAvatar,
  },
  apollo: {
    announcement: {
      query: GetAnnouncement,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  methods: {
    visitGroup() {
      this.$router.push({
        name: 'GroupDetail',
        params: { id: this.announcement.group.id },
      });
    },
    openUrl(url) {
      $browser.open(url);
    },
  },
};
</script>

<style scoped>
.announcement-view {
  padding: 2rem;
}
.announcement-view__description {
  color: var(--cs-gray-05);
  font-size: var(--cs-font-size-400);
  margin-bottom: 2rem;
}
.announcement-view__header {
  display: flex;
  margin-bottom: 2rem;
}
.announcement-view__avatar {
  margin-right: 1rem;
}
.announcement-view__author {
  color: var(--cs-gray-04);
}
</style>
