<template>
  <div class="ch-tabs-container">
    <div class="ch-tabs">
      <router-link
        v-for="link in links"
        :key="link.title"
        class="ch-tabs-link ch-tabs-item"
        :to="link.to"
        >{{ link.title }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>

<style scoped>
.ch-tabs-container {
  padding: 0 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: black;
}

.ch-tabs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  overflow-x: auto;
}

.ch-tabs-item {
  position: relative;
  display: block;
  padding: 10px 6px;
}

.ch-tabs-link {
  white-space: nowrap;
  text-decoration: none;
  font-size: 16px;
  color: var(--cs-gray-06);
}

.ch-tabs-item:after {
  content: '';
  opacity: 0;
  transition: opacity linear 0.25s;
  position: absolute;
  left: 9px;
  bottom: 0;
  width: calc(100% - 18px);
  height: 4px;
  background-color: var(--cs-primary-base);
}

.ch-tabs-item.router-link-exact-active:after {
  opacity: 1;
}
</style>
