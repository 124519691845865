<template>
  <div class="ch-new-msg">
    <app-modal
      :show="showModal"
      class="ch-new-msg__modal"
      title="New Message"
      :class="{ 'ch-custom-modal': isMeeting }"
      @close="close()"
    >
      <div class="ch-new-msg__modal--body">
        <div class="body-header-input">
          <!-- If the user is known -->
          <div v-if="user" class="input-box">
            <app-avatar
              v-if="user"
              :picture="user.profilePhotoURL"
              :name="user.displayname"
              size="small"
            >
            </app-avatar>
            <cs-input disabled :value="user.username" class="input" />
          </div>
          <!-- If the user is not known -->
          <div v-else class="input-box">
            <app-avatar
              v-if="selectedUser"
              :picture="selectedUser.profilePhotoURL"
              :name="selectedUser.displayname"
              size="small"
            >
            </app-avatar>
            <app-user-select-search
              placeholder="To: Enter username"
              :options="options"
              custom-search
              @query="findPeople"
              @select="onSelect"
            >
            </app-user-select-search>
          </div>
        </div>
        <div v-if="!loading" class="body-content-scrollable">
          <app-post-card
            v-if="post && post.user"
            :post="post"
            :preview="true"
          />
        </div>
        <div v-if="loading"><cs-spinner /></div>
        <div class="body-footer-fixed">
          <app-conversation-composer
            :shared-post="post"
            :shared-link="sharedLink"
            :receiver="selectedUser"
            new-msg
            @closeNew="close"
            v-model="closeModal"
          />
        </div>
      </div>
    </app-modal>
  </div>
</template>
<script>
import $bus from '@/services/bus';
// Components
import AppConversationComposer from '@/components/conversations/ConversationComposer.vue';
import AppModal from '@/components/general/Modal.vue';
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppUserSelectSearch from '@/components/conversations/UserSelectSearch.vue';
// GQL
import UserSearch from '@/gql/search/UserSearch.gql';

export default {
  components: {
    AppConversationComposer,
    AppModal,
    AppPostCard,
    AppAvatar,
    AppUserSelectSearch,
  },
  props: {
    isMeeting: {
      type: Boolean,
      required: false,
      default: false,
    },
    meetingLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      newMessage: '',
      selectedUser: {},
      post: null,
      sharedLink: null,
      user: null,
      showModal: false,
      loading: false,
      searchQuery: null,
      closeModal: false,
    };
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: this.searchQuery,
        };
      },
      skip() {
        return !this.searchQuery;
      },
      result() {
        if (this.users) {
          this.options = this.users.map((result) => ({
            value: result,
            label: result.username,
          }));
        }
      },
    },
  },
  watch: {
    isMeeting() {
      this.meetingConfig();
    },
    closeModal(newValue) {
      if (newValue) {
        this.close();
      }
    },

  },
  mounted() {
    $bus.$on('new-message-modal', (params) => {
      this.options = [];
      this.newMessage = '';
      this.selectedUser = null;
      this.post = null;
      this.user = null;
      this.sharedLink = null;
      this.searchQuery = null;
      if (params.user) {
        this.user = params.user;
        this.selectedUser = this.user;
      }
      if (params.post) {
        this.post = params.post;
      }
      if (params.link) {
        this.sharedLink = params.link;
      }
      this.showModal = true;
    });
    $bus.$on('message-start', () => {
      this.loading = true;
    });
    $bus.$on('message-end', () => {
      this.loading = false;
    });
    $bus.$on('message-sent', () => {
      this.close();
    });

  },
  methods: {
    reset() {
      this.options = [];
      this.newMessage = '';
      this.selectedUser = {};
      this.post = null;
      this.user = null;
      this.sharedLink = null;
      this.searchQuery = null;
    },
    findPeople(query) {
      this.searchQuery = query;
    },
    onSelect(user) {
      this.selectedUser = user.value;
    },
    close() {
      window.location.reload();
      this.showModal = false;
      this.$emit('close-share');
      this.reset();
    },
    meetingConfig() {
      this.showModal = true;
      this.selectedUser = null;
      this.sharedLink = this.meetingLink;
    },
  },
};
</script>

<style scoped>
.ch-new-msg__modal--body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  margin: 0 auto;
}

.body-header-input {
  margin-bottom: 10px;
}

.body-header-input >>> .cs-select-search__results {
  z-index: 1000;
  position: relative;
}

.input-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.input {
  width: 100%;
}

.body-content-scrollable {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  flex: 1;
}

.body-footer-fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-00);
}
.ch-custom-modal >>> .ch-modal__content {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
