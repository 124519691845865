<template>
  <ion-page class="video-search">
    <ion-header>
      <app-category-header title="Search Channels & Videos" />
      <div class="video-search-input">
        <cs-search-bar
          v-model="search"
          placeholder="Search..."
          @input="onInput"
        />
      </div>
      <cs-page-tabs v-model="selectedView" align="justify">
        <cs-page-tab label="Channels"></cs-page-tab>
        <cs-page-tab label="Videos"></cs-page-tab>
      </cs-page-tabs>
    </ion-header>
    <ion-content>
      <div v-if="selectedView == 0">
        <app-spinner v-if="$apollo.queries.users.loading" />
        <app-video-channel-item
          v-for="user in users"
          :key="user.id"
          :user="user"
        />
        <cs-empty-state
          v-if="showEmptyStateChannel"
          :title="emptyStateTitleChannel"
          :description="emptyStateDescription"
        />
      </div>
      <div v-if="selectedView == 1">
        <app-spinner v-if="$apollo.queries.videos.loading" />
        <app-video-item
          v-for="video in videos"
          :key="video.id"
          :video="video"
          direction="column"
          class="search-video-item"
        ></app-video-item>
        <cs-empty-state
          v-if="showEmptyState"
          :title="emptyStateTitle"
          :description="emptyStateDescription"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import VideoSearch from '@/gql/search/VideoSearch.gql';
import ChannelSearch from '@/gql/search/ChannelSearch.gql';
import hideKeyboard from '@/mixins/hideKeyboard.mixin';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppVideoItem from '@/components/videos/VideoItem.vue';
import AppVideoChannelItem from '@/components/videos/VideoChannelItem.vue';

export default {
  components: {
    AppCategoryHeader,
    AppSpinner,
    AppVideoItem,
    AppVideoChannelItem,
  },
  mixins: [hideKeyboard],
  apollo: {
    users: {
      query: ChannelSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
    videos: {
      query: VideoSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      selectedView: 0,
      search: this.$route.query ? this.$route.query.q : this.query,
      timer: null,
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.videos && this.videos.length === 0) return true;
      return this.noQuery && (!this.videos || this.videos.length === 0);
    },
    showEmptyStateChannel() {
      if (this.users && this.users.length === 0) return true;
      return this.noQuery && (!this.users || this.users.length === 0);
    },
    emptyStateTitle() {
      return this.noQuery ? 'Search for Videos' : 'No Results';
    },
    emptyStateTitleChannel() {
      return this.noQuery ? 'Search for Channels' : 'No Results';
    },
    emptyStateDescription() {
      return this.noQuery
        ? 'Try entering a term in the search box'
        : 'Try searching for something else';
    },
  },
  methods: {
    onInput(query) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search = query;
      }, 400);
    },
  },
};
</script>

<style scoped>
.video-search__input-header {
  padding: 10px;
  background-color: var(--cs-gray-01);
}
.search-video-item {
  margin-top: 10px;
}
.video-search-input {
  padding: 10px;
  background-color: white;
}
</style>
