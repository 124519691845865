<template>
  <div :class="{ 'post-card-custom': preview }">
    <div
      v-if="showPost && post.profanityStatus == 'pendingReview'"
      class="profanityClass cs-textstyle-paragraph-small-bold"
    >
      <!--
      <i class="cs-icons-warning"></i>
      <template v-if="post.video">
        The content of a video you are posting was not properly recognized by
        the Artificial Intelligence we use to prevent inappropriate videos. It
        has been sent to a staff person to review it. This should only take a
        moment. Thank you for your understanding.
      </template>
      <template v-else>
        The content of an image you are posting was not properly recognized by
        the Artificial Intelligence we use to prevent inappropriate images. It
        has been sent to a staff person to review it. This should only take a
        moment. Thank you for your understanding.
      </template>
    -->
    </div>
    <div
      v-if="showPost && post.profanityStatus == 'rejected'"
      class="profanityClass cs-textstyle-paragraph-small-bold"
    >
      <i class="cs-icons-warning"></i>
      This post violates our community policies, and is not visible to anyone
      else.
    </div>
    <cs-card v-if="isViewRootPostVisible" class="post-card">
      <div slot="body" class="post-mention">
        <p v-if="wasMentioned" class="post-mention-message cs-textstyle">
          You were mentioned in a comment by
          <span
            class="ch-user-mention"
          >
            {{ post.user.username }}
          </span>
        </p>
        <p v-else class="post-mention-message cs-textstyle">Open post page</p>
        <cs-button
          size="small"
          class="post-mention-button"
          fill="outline"
          @click="redirectToPostDetailedPage"
          >View Post</cs-button
        >
      </div>
    </cs-card>
    <cs-card v-if="showPost" class="post-card">
      <div slot="body" class="post-card__content">
        <div class="post-padding">
          <div v-if="post.sharedPost" class="post-card-share-header">
            <div class="cs-textstyle-paragraph-small">
              <app-share-post-header
                v-if="post.sharedPost"
                :post="post"
                :preview="preview"
              ></app-share-post-header>
            </div>
            <div
              v-if="post.bodyText && post.bodyText != 'Shared Post'"
              class="cs-textstyle-paragraph post-share-comment pre-line"
            >
              <span v-for="(data, i) in shareBodyText" :key="i">
                <span v-if="data.type === 'text'">{{ data.word }} </span>
                <span
                  v-if="data.type === 'mention'"
                  class="ch-user-mention"
                >
                  {{ data.word }}
                </span>
              </span>
              <!-- {{ post.shareBodyText }} -->
            </div>
            <img
              v-if="isTranslatingSharedPost"
              src="@/assets/images/translation_icon-orange.svg"
              class="translate-shared-button"
              @click="showTranslatedPost"
            />
            <div
              v-if="isShowingTranslation && post.sharedPost"
              class="ch-translation-wrapper"
            >
              <img
                v-if="isHidingTranslateSharedPost"
                class="translate-shared-button"
                src="@/assets/images/translation_icon-orange.svg"
                @click="hideTranslatedPost"
              />
              <div class="post-message cs-textstyle-paragraph message-margin">
                <span
                  v-for="(data, i) in displayTranslatedBody"
                  :key="i + data.word"
                >
                  <span v-if="data.type === 'text'">{{ data.word }} </span>

                  <a
                    v-if="data.type === 'link'"
                    class="ch-link"
                    :href="data.link"
                    target="_blank"
                  >
                    {{ data.word }}
                  </a>

                  <span
                    v-if="data.type === 'mention'"
                    class="ch-user-mention"
                    >{{ data.word }}
                  </span>
                </span>
              </div>
              <app-spinner
                v-if="isLoadingTranslation"
                size="small"
                class="ch-spinner"
              />
            </div>
          </div>
          <div
            v-if="post.group"
            v-router-link="{ name: 'PublicGroupDetail', params: post.group }"
            class="post-card-share-header"
          >
            <div class="cs-textstyle-paragraph-small">
              <i class="ch-icons-groups"></i>
              Shared in {{ post.group.name }}
            </div>
          </div>
          <app-post-header
            :post="targetPost"
            :can-delete="canDelete"
            :preview="preview"
            @pinned="$emit('pinned')"
          ></app-post-header>
          <div
            v-if="targetPost.bodyText"
            @click="redirectToPostDetailedPage"
            class="
              post-card__body-text
              cs-textstyle-paragraph
              selectable-text
              break-space
            "
          >
            <span v-for="(data, i) in displayBody" :key="i">
              <!-- Normal text in body -->
              <span v-if="data.type === 'text'">{{ data.word }} </span>
              <!-- Link in post poby -->
              <a
                v-if="data.type === 'link'"
                class="ch-link"
                :href="data.link"
                target="_blank"
              >
                {{ data.word + 'ss' }}
              </a>
              <!-- mentions in post boby -->
              <span
                v-if="data.type === 'mention'"
                class="ch-user-mention"
              >
                {{ data.word }}
              </span>
            </span>
          </div>
          <img
            v-if="isTranslatingPost"
            src="@/assets/images/translation_icon-orange.svg"
            class="translate-button"
            @click="showTranslatedPost"
          />
          <div
            class="ch-translation-wrapper"
            v-if="isShowingTranslation && !post.sharedPost"
          >
            <img
              v-if="isHidingTranslatePost"
              src="@/assets/images/translation_icon-orange.svg"
              class="translate-button"
              @click="hideTranslatedPost"
            />
            <div class="post-message cs-textstyle-paragraph message-margin">
              <span v-for="(data, i) in displayTranslatedBody" :key="i">
                <!-- Normal text in body -->
                <span v-if="data.type === 'text'">{{ data.word }} </span>
                <!-- Link in post poby -->
                <a
                  v-if="data.type === 'link'"
                  class="ch-link"
                  :href="data.link"
                  target="_blank"
                >
                  {{ data.word }}
                </a>
                <!-- mentions in post boby -->
                <span
                  v-if="data.type === 'mention'"
                  class="ch-user-mention"
                  >{{ data.word }}
                </span>
              </span>
            </div>
            <app-spinner
              v-if="isLoadingTranslation"
              size="small"
              class="ch-spinner"
            />
          </div>
          <app-post-tags
            v-if="targetPost.tags && targetPost.tags.length > 0"
            :tags="targetPost.tags"
          />
          <cs-small-tag v-if="targetPost.location" variant="default">
            <i class="cs-icons-marker-filled"></i>
            {{ targetPost.location }}
          </cs-small-tag>
        </div>
        <app-post-media
          v-if="
            targetPost.linkPreview ||
            targetPost.images ||
            targetPost.document ||
            targetPost.video
          "
          :link-preview="!targetPost.sharedPost ? targetPost.linkPreview : null"
          :images="targetPost.images"
          :document="targetPost.document"
          :video="targetPost.video"
          :post-id="targetPost.id"
          :autoplay="autoplay"
        />
        <app-post-response
          v-if="!preview"
          class="post-padding"
          :post="targetPost"
          @refresh="emitRefresh"
        ></app-post-response>
      </div>
    </cs-card>
  </div>
</template>

<script>
import linkifyStr from 'linkifyjs/string';
import $browser from '@/services/browser';
import $auth from '@/services/auth';
import IncrementViewCount from '@/gql/videos/IncrementViewCount.gql';
import AppSharePostHeader from '@/components/post/items/PublicSharePostHeader.vue';
import AppPostHeader from '@/components/post/items/PublicPostHeader.vue';
import AppPostTags from '@/components/post/items/PublicPostTags.vue';
import AppPostMedia from '@/components/post/items/PublicPostMedia.vue';
import AppPostResponse from '@/components/post/items/PublicPostResponse.vue';
import AppSpinner from '@/components/general/Spinner.vue';

import GetTranslatedPost from '@/gql/posts/GetTranslatedPost.gql';

export default {
  components: {
    AppPostHeader,
    AppPostTags,
    AppPostMedia,
    AppPostResponse,
    AppSharePostHeader,
    AppSpinner,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      translatedPost: null,
      isShowingTranslation: false,
      isLoadingTranslation: false,
      disableTranslateButton: false,
    };
  },
  watch: {
    active() {
      this.hideTranslatedPost();
    },
  },
  computed: {
    isTranslatingSharedPost() {
      return (
        this.post.bodyText &&
        this.post.bodyText !== 'Shared Post' &&
        !this.isShowingTranslation &&
        this.post.sharedPost &&
        !this.disableTranslateButton
      );
    },
    isTranslatingPost() {
      return (
        this.targetPost.bodyText &&
        !this.post.sharedPost &&
        !this.isShowingTranslation &&
        !this.disableTranslateButton
      );
    },
    isHidingTranslateSharedPost() {
      return this.targetPost.bodyText && !this.disableTranslateButton;
    },
    isHidingTranslatePost() {
      return (
        this.targetPost.bodyText &&
        !this.post.sharedPost &&
        !this.disableTranslateButton
      );
    },
    isViewRootPostVisible() {
      if (!this.post.rootPostId) return false;
      return this.post.id !== this.post.rootPostId;
    },
    async wasMentioned() {
      const me = await $auth.getUser();
      return (
        this.post.id !== this.post.rootPostId &&
        this.post.bodyText.includes(me.username)
      );
    },
    shareBodyText() {
      if (!this.post.sharedPost) return;
      if (!this.post.bodyText || this.post.bodyText == 'Shared Post') return;
      const bodyArray = this.post.bodyText.split(' ');
      const finalArray = [];
      bodyArray.forEach((data) => {
        if (data.startsWith('@')) {
          let matched = false;
          this.post.mentions.forEach((mention) => {
            if (mention.username === data) {
              finalArray.push({
                type: 'mention',
                word: data,
              });
              matched = true;
            }
          });
          if (!matched) {
            finalArray.push({
              type: 'text',
              word: data,
            });
          }
        } else {
          finalArray.push({
            type: 'text',
            word: data,
          });
        }
      });
      this.checkSharedTranslationDemand(finalArray);
      return finalArray;
    },
    displayBody() {
      const finalArray = [];
      const postLines = this.targetPost.bodyText.split(/\n/g);
      const previewLink = this.targetPost.linkPreview
        ? this.targetPost.linkPreview.originalLink
        : null;

      postLines.forEach((line, index) => {
        const isNotTheLastLine = index < postLines.length - 1;
        const bodyArray = line.split(' ');
        bodyArray.forEach((data) => {
          const link = data.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          );
          if (link) {
            const email = data.match(/.*@.*/);
            if (data !== previewLink || email) {
              let clicableLink;
              if (email) clicableLink = `mailto:${data}`;
              else clicableLink = this.getClickableLink(data);
              finalArray.push({
                type: 'link',
                word: data,
                link: clicableLink,
              });
            }
          } else if (data.startsWith('@')) {
            let matched = false;
            this.targetPost.mentions.forEach((mention) => {
              if (mention.username === data) {
                finalArray.push({
                  type: 'mention',
                  word: data,
                });
                matched = true;
              }
            });
            if (!matched) {
              finalArray.push({
                type: 'text',
                word: data,
              });
            }
          } else {
            finalArray.push({
              type: 'text',
              word: data,
            });
          }
        });

        if (isNotTheLastLine) {
          finalArray.push({
            type: 'text',
            word: '\n',
          });
        }
      });
      this.checkTranslationDemand(finalArray);
      return finalArray;
    },
    displayTranslatedBody() {
      const finalArray = [];
      if (this.translatedPost) {
        const postLines = this.translatedPost.translated.result.split(/\n/g);
        const previewLink = this.targetPost.linkPreview
          ? this.targetPost.linkPreview.originalLink
          : null;
        postLines.forEach((line, index) => {
          const isNotLastLine = index < postLines.length - 1;
          const bodyArray = line.split(' ');
          bodyArray.forEach((data) => {
            const link = data.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
            );
            if (link) {
              const email = data.match(/.*@.*/);
              if (data !== previewLink || email) {
                let clicableLink;
                if (email) clicableLink = `mailto:${data}`;
                else clicableLink = this.getClickableLink(data);
                finalArray.push({
                  type: 'link',
                  word: data,
                  link: clicableLink,
                });
              }
            } else if (data.startsWith('@')) {
              let matched = false;
              this.targetPost.mentions.forEach((mention) => {
                if (mention.username === data) {
                  finalArray.push({
                    type: 'mention',
                    word: data,
                  });
                  matched = true;
                }
              });
              if (!matched) {
                finalArray.push({
                  type: 'text',
                  word: data,
                });
              }
            } else {
              finalArray.push({
                type: 'text',
                word: data,
              });
            }
          });

          if (isNotLastLine) {
            finalArray.push({
              type: 'text',
              word: '\n',
            });
          }
        });
      }
      return finalArray;
    },
    isDetailPage() {
      //return this.$route.name === 'PublicPostDetail';
    },
    isReadMore() {
      return this.isDetailPage || !this.targetPost.bodyText
        ? false
        : this.targetPost.bodyText.length > 100;
    },
    postBody() {
      const targetPostBody = this.isDetailPage
        ? this.targetPost.bodyText
        : this.targetPost.bodyText.slice(0, 100);
      const displayArray = [];
      const parts = targetPostBody.split('@');
      displayArray.push({ type: 'text', value: parts[0] });
      for (let i = 1; i < parts.length; i += 1) {
        const part = `@${parts[i]}`;
        let match = false;
        const mentions = this.targetPost.mentions;
        for (let j = 0; j < mentions.length; j += 1) {
          const mention = mentions[j];
          if (part.indexOf(mention.username) === 0 && !match) {
            match = true;
            const subparts = part.split(mention.username);
            displayArray.push({ type: 'mention', value: mention.username });
            displayArray.push({ type: 'text', value: subparts[1] });
          }
        }
        if (!match) {
          displayArray.push({ type: 'text', value: part });
        }
      }

      return displayArray;
    },
    showPost() {
      // if (this.post.video && this.post.video.title) {
      //   return false; // Don't show channel videos in forum
      // }
      if (this.post.user.id === $auth.getUserId()) {
        return true;
      }
      const showAllRoutes = ['PublicPostDetail', 'UProfile', 'Profile'];
      if (showAllRoutes.includes(this.$router.currentRoute.name)) {
        return true;
      }
      if (this.preview) return true;
      if (this.post.canReviewPost) {
        return true;
      }
      const isBlocked =
        this.post.user.myRelationship &&
        this.post.user.myRelationship.isBlocked;
      const isSharedPostBlocked =
        this.post.sharedPost &&
        this.post.sharedPost.myRelationship &&
        this.post.sharedPost.myRelationship.isBlocked;
      return (
        !isBlocked &&
        !isSharedPostBlocked &&
        this.post.safe &&
        (!this.post.sharedPost || this.post.sharedPost.safe) &&
        (!this.post.sharedPost ||
          (this.post.sharedPost.user && !this.post.sharedPost.user.suspended))
      );
    },
    targetPost() {
      return this.post.sharedPost || this.post;
    },
    displayText() {
      return (
        this.targetPost &&
        this.targetPost.bodyText &&
        linkifyStr(this.targetPost.bodyText, {
          target: {
            url: '_blank',
          },
        })
      );
    },
    hideLinks() {
      return !!this.targetPost.linkPreview;
    },
  },
  mounted() {
    this.incrementViewCount();
  },
  updated() {
    this.incrementViewCount();
  },
  methods: {
    checkSharedTranslationDemand(finalArray) {
      this.disableTranslateButton =
        finalArray && finalArray.length
          ? finalArray.reduce(
              (accumulator, postElement) =>
                accumulator && !!(postElement.word === '' || postElement.link),
              true
            )
          : false;
    },
    checkTranslationDemand(finalArray) {
      this.disableTranslateButton = finalArray.reduce(
        (accumulator, postElement) =>
          accumulator && !!(postElement.word === '' || postElement.link),
        true
      );
    },
    formatUsername(username) {
      return username.replace('@', '');
    },
    async showTranslatedPost() {
      this.isShowingTranslation = true;
      this.isLoadingTranslation = true;
      try {
        const translatedObject = await this.$apollo.query({
          query: GetTranslatedPost,
          variables: {
            id: this.post.id,
            languageCode: localStorage.getItem('currentLanguageCode'),
          },
          fetchPolicy: 'network-only',
        });
        this.translatedPost = translatedObject.data.post;
        this.isLoadingTranslation = false;
      } catch (error) {
        console.log('Error when translating post:', error);
        this.isShowingTranslation = false;
        this.isLoadingTranslation = false;
      }
    },
    hideTranslatedPost() {
      this.isShowingTranslation = false;
      this.isLoadingTranslation = false;
      this.translatedPost = null;
    },
    redirectToPostDetailedPage() {
    },
    async emitRefresh() {
      this.$emit('refresh');
    },
    incrementViewCount() {
      if (this.post.video || this.post.type === 'news') {
        this.$apollo.mutate({
          mutation: IncrementViewCount,
          variables: {
            postId: this.post.id,
          },
        });
      }
    },
    handleTextClick(e) {
      e.preventDefault();
      if (e.target && e.target.href) {
        $browser.open(e.target.href);
      }
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `http://${link}`;
    },
  },
};
</script>

<style>
.break-space {
  white-space: pre-line;
}
.post-card {
  box-shadow: 0 3px 6px rgb(0 0 0 / 5%) !important;
  border: none;
  border-radius: 0;
  --cs-card-border-radius: 0;
  margin: 0px !important;
  margin-bottom: 8px !important; /* CloutHub requested */
}
.post-card__content {
  display: flex;
  flex-direction: column;
  gap: 9px; /* CloutHub requested */
}
.post-card__message {
  color: var(--cs-gray-07);
  white-space: pre-line;
}
.post-card__body-text,
.post-card__body-text a {
  line-height: 1.3 !important; /* CloutHub requested */
}
.post-share-comment {
  margin-top: 10px;
  margin-bottom: -10px;
  white-space: pre-line;
}
.post-card-share-header {
  border-bottom: 1px solid var(--cs-gray-02);
  padding-top: 8px; /* CloutHub requested */
  padding-bottom: 6px; /* CloutHub requested */
  /* margin: -8px -16px 0; */
  /* padding: 5px 16px; */
}
.ch-read-more {
  color: var(--cs-primary-base);
  cursor: pointer;
}
.ch-user-mention {
  margin-right: 5px;
  padding: 3px;
  background: var(--cs-gray-01);
  color: var(--cs-primary-dark);
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.ch-user-mention--comment {
  background: var(--cs-gray-00);
}
.post-card .cs-card__info {
  padding: 0px !important;
}
.post-padding {
  padding: 0px 10px; /* CloutHub requested */
}
.hideLinks a {
  display: none;
}
.profanityClass {
  background: var(--cs-gray-02);
  padding: 5px 5px;
  border-bottom: 1px solid var(--cs-gray-01);
}
.ch-link {
  color: var(--cs-gray-04);
  margin-right: 5px;
  text-decoration: none;
}
.post-card-custom {
  pointer-events: none;
}

.post-mention {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;
}

.post-mention-message {
  margin: 0;
  font-size: 14px;
}

.post-mention-button {
  padding: 5px 0 !important;
  width: 80px;
}

.translate-button {
  font-weight: normal !important;
  cursor: pointer;
  width: 37px;
  height: 37px;
  padding: 5px 0 !important;
}

.translate-shared-button {
  font-weight: normal;
  width: 37px;
  height: 37px;
  margin-left: 10px;
  margin-bottom: 5px;
  padding: 2px 0 !important;
}

.message-margin {
  margin: 5px 0px 5px 10px;
}

.ch-translation-wrapper {
  display: flex;
  padding: 5px;
}

.ch-spinner {
  margin: 0;
}
</style>
