import $apollo from '@/services/apollo';
import $auth from '@/services/auth';
import $videosApi from '@/api/videos';
import $newsApi from '@/api/news';

import BlockUser from '@/api/connections/BlockUser.gql';
import UnblockUser from '@/api/connections/UnblockUser.gql';
import ListBlockedUsers from '@/gql/connections/ListBlockedUsers.gql';

import FollowUser from '@/api/connections/FollowUser.gql';
import UnfollowUser from '@/api/connections/UnfollowUser.gql';

import ListNewsFollowing from '@/gql/news/ListNewsFollowing.gql';
import ListFollowingVideos from '@/gql/videos/ListFollowingVideos.gql';
import ListSubscribedChannels from '@/gql/videos/ListSubscribedChannels.gql';
import ListFeaturedChannels from '@/gql/videos/ListFeaturedChannels.gql';

import SubscribeChannel from '@/gql/connections/SubscribeChannel.gql';
import UnsubscribeChannel from '@/gql/connections/UnsubscribeChannel.gql';

import GetChannelByUserName from '@/gql/videos/GetChannelByUserName.gql';
import GetChannel from '@/gql/videos/GetChannel.gql';

async function subscribeChannel(user) {
  await $apollo.mutate({
    mutation: SubscribeChannel,
    variables: {
      channelId: user.id,
    },
    refetchQueries: [
      {
        query: GetChannelByUserName,
        variables: { username: user.username },
      },
      {
        query: GetChannel,
        variables: { id: user.id },
      },
    ],
    update: async (cache) => {
      cache.modify({
        id: cache.identify(user),
        fields: {
          subscribedToChannel() {
            return true;
          },
        },
      });
      const subsCache = cache.readQuery({
        query: ListSubscribedChannels,
      });
      cache.writeQuery({
        query: ListSubscribedChannels,
        data: {
          users: [user, ...subsCache.users],
        },
      });
      const recommendedCache = cache.readQuery({
        query: ListFeaturedChannels,
      });
      const list = recommendedCache.users.slice(0);
      const item = list.find((u) => u.id === user.id);
      const index = list.indexOf(item);
      list.splice(index, 1);
      cache.writeQuery({
        query: ListFeaturedChannels,
        data: {
          users: list,
        },
      });
    },
  });
}

async function unsubscribeChannel(user) {
  await $apollo.mutate({
    mutation: UnsubscribeChannel,
    variables: {
      channelId: user.id,
    },
    refetchQueries: [
      {
        query: GetChannelByUserName,
        variables: { username: user.username },
      },
      {
        query: GetChannel,
        variables: { id: user.id },
      },
    ],
    update: (cache) => {
      cache.modify({
        id: cache.identify(user),
        fields: {
          subscribedToChannel() {
            return false;
          },
        },
      });
    },
  });
}

async function blockUser(user) {
  await $apollo.mutate({
    mutation: BlockUser,
    variables: {
      userId: user.id,
    },
    update: (cache, mutationResponse) => {
      const query = ListBlockedUsers;
      const queryCache = cache.readQuery({
        query,
      });
      if (queryCache && queryCache.people) {
        cache.writeQuery({
          query,
          data: {
            people: [user, ...queryCache.people],
          },
        });
      }
      cache.modify({
        id: cache.identify(user),
        fields: {
          myRelationship(existingFieldData, { toReference }) {
            return toReference(
              cache.identify(mutationResponse.data.block_user)
            );
          },
        },
      });
    },
  });
}

async function unblockUser(user) {
  await $apollo.mutate({
    mutation: UnblockUser,
    variables: {
      userId: user.id,
    },
    update: (cache, mutationResponse) => {
      const query = ListBlockedUsers;
      const queryCache = cache.readQuery({
        query,
      });
      if (queryCache && queryCache.people) {
        cache.writeQuery({
          query,
          data: {
            people: queryCache.people.filter((p) => p.id !== user.id),
          },
        });
      }
      cache.modify({
        id: cache.identify(user),
        fields: {
          myRelationship(existingFieldData, { toReference }) {
            return toReference(
              cache.identify(mutationResponse.data.unblock_user)
            );
          },
        },
      });
    },
  });
}

const followUser = async (target) =>
  $apollo.mutate({
    mutation: FollowUser,
    variables: {
      userId: target.id,
    },
    refetchQueries: [
      { query: ListFollowingVideos },
      { query: ListNewsFollowing },
    ],
    optimisticResponse: {
      follow_user: {
        id: `temp.${Date.now()}`,
        __typename: 'Relationship',
        isFollower: true,
        isFollowing: true,
      },
    },
    update: (cache, mutationResponse) => {
      // Update relationship
      const currentRelationship = { ...target.myRelationship };
      cache.modify({
        id: cache.identify(target),
        fields: {
          myRelationship(existingFieldData, { toReference }) {
            if (currentRelationship) {
              currentRelationship.isFollower = true;
              return currentRelationship;
            }
            return toReference(
              cache.identify(mutationResponse.data.follow_user)
            );
          },
          followerCount(existingFieldData = 0) {
            return existingFieldData + 1;
          },
        },
      });
      // Update your following count
      cache.modify({
        id: cache.identify({ __typename: 'User', id: $auth.getUserId() }),
        fields: {
          followingCount(existingFieldData = 0) {
            return existingFieldData + 1;
          },
        },
      });
      $videosApi.followChannelCacheUpdate(target, cache, mutationResponse);
      $newsApi.followNewsCacheUpdate(target, cache, mutationResponse);
    },
  });

const unfollowUser = async (target) =>
  $apollo.mutate({
    mutation: UnfollowUser,
    variables: {
      userId: target.id,
    },
    refetchQueries: [
      { query: ListFollowingVideos },
      { query: ListNewsFollowing },
    ],
    optimisticResponse: {
      unfollow_user: true,
    },
    update: (cache, mutationResponse) => {
      // Update the relationship
      const currentRelationship = { ...target.myRelationship };
      cache.modify({
        id: cache.identify(target),
        fields: {
          myRelationship(existingFieldData, { toReference }) {
            currentRelationship.isFollower = false;
            return currentRelationship;
          },
          followerCount(existingFieldData = 1) {
            return existingFieldData - 1;
          },
        },
      });
      // Update your following count
      cache.modify({
        id: cache.identify({ __typename: 'User', id: $auth.getUserId() }),
        fields: {
          followingCount(existingFieldData = 1) {
            return existingFieldData - 1;
          },
        },
      });
      $videosApi.unfollowChannelCacheUpdate(target, cache, mutationResponse);
      $newsApi.unfollowNewsCacheUpdate(target, cache, mutationResponse);
    },
  });

export default {
  blockUser,
  unblockUser,
  followUser,
  unfollowUser,
  subscribeChannel,
  unsubscribeChannel,
};
