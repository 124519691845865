<template>
  <div
    v-if="!user.myRelationship || !user.myRelationship.isBlocked"
    class="request-item"
    @click.stop="viewProfile()"
  >
    <div class="request-item-avatar">
      <app-avatar
        :name="user.user ? user.user.displayname : user.displayname"
        :picture="user.user ? user.user.profilePhotoURL : user.profilePhotoURL"
        class="request-item-avatar"
        size="small"
        @click="viewProfile()"
      ></app-avatar>
    </div>
    <div
      v-if="
        user.myRelationship &&
        (user.myRelationship.friendStatus == 'rsvp' ||
          !user.myRelationship.friendStatus)
      "
      class="cs-textstyle-paragraph ml-2 first-col"
    >
      <div>
        <span class="cs-textstyle-paragraph-bold">
          {{ user.username }}
        </span>
        <span>
          requested to be friends with you. {{ user.friendStatus }}
          {{ user.isFriend }}</span
        >
      </div>
      <div class="buttons">
        <cs-button
          icon="cs-icons-check-success"
          corners="rounded"
          variant="primary"
          @click.stop="acceptRequest()"
        ></cs-button>
        <cs-button
          corners="rounded"
          variant="default"
          icon="cs-icons-close-filled"
          class="ml-1"
          size="small"
          @click.stop="rejectRequest()"
        ></cs-button>
      </div>
    </div>
    <div
      v-if="user.myRelationship && user.myRelationship.friendStatus == 'accept'"
      class="cs-textstyle-paragraph ml-2"
    >
      <div>
        <span class="cs-textstyle-paragraph-bold">
          {{ user.username }}
        </span>
        <span> is now friends with you.</span>
        <div
          v-router-link="{ name: 'Profile', params: { userId: user.id } }"
          class="cs-textstyle-paragraph-bold"
        >
          View profile
        </div>
      </div>
    </div>
    <div
      v-if="!user.myRelationship || user.myRelationship.friendStatus == 'none'"
      class="cs-textstyle-paragraph ml-2"
    >
      <div>
        <span class="cs-textstyle-paragraph-bold">
          {{ user.username }}
        </span>
        <span> declined.</span>
      </div>
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
// GQL
import AcceptFriendRequest from '@/api/connections/AcceptFriendRequest.gql';
import CancelFriend from '@/api/connections/CancelFriend.gql';

export default {
  components: {
    AppAvatar,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    viewProfile() {
      this.$router.push({ name: 'Profile', params: this.user });
    },
    async acceptRequest() {
      await this.$apollo.mutate({
        mutation: AcceptFriendRequest,
        variables: {
          userId: this.user.id,
        },
        optimisticResponse: {
          accept_friend: {
            id: Date.now(),
            friendStatus: 'accept',
            isFriend: true,
            __typename: 'Relationship',
          },
        },
        update: (cache, mutationResponse) => {
          cache.modify({
            id: cache.identify(this.user),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                return toReference(
                  cache.identify(mutationResponse.data.accept_friend)
                );
              },
            },
          });
        },
      });
    },
    async rejectRequest() {
      await this.$apollo.mutate({
        mutation: CancelFriend,
        variables: {
          userId: this.user.id,
        },
        update: (cache, mutationResponse) => {
          cache.modify({
            id: cache.identify(this.user),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                return toReference(
                  cache.identify(mutationResponse.data.cancel_friend)
                );
              },
            },
          });
        },
      });
    },
  },
};
</script>

<style scoped>
.request-item-avatar {
  margin-right: 5px;
}
.request-item {
  display: flex;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px var(--cs-gray-02);
  background-color: var(--cs-gray-00);
}
.buttons {
  display: flex;
  gap: 5px;
  margin-left: 5px;
}
.buttons >>> .cs-button {
  height: 39px;
  width: 50px;
  margin-top: 3px;
}

.first-col {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
</style>
