<template>
  <div class="friend-button">
    <cs-button v-if="status=='accept'" :size="size"
      variant="secondary" fill="outline"
      @click="unfriendUser"
      :title="unfriendTip"
    >Unfriend</cs-button>
    <cs-button v-if="status=='none'" :size="size"
      @click="requestFriend()"
      :title="requestTip"
    >Add Friend</cs-button>
    <cs-button v-if="status=='pending'" :size="size"
      variant="secondary" fill="outline"
      @click="cancelFriendSheet"
      :title="pendingTip"
    >Sent</cs-button>
    <cs-button v-if="status=='rsvp'" :size="size"
      variant="secondary" fill="outline"
      @click="rsvpActionsheet"
    >RSVP</cs-button>
    <app-action-sheet :open="openUnfriendAction" @close="closeUnfriendAction()">
      <div class="ch-share__actionsheet">
        <div class="ch-share-modal__header">
          <span>Are you sure you want to unfriend <app-username :user="target" /> ?</span>
          <i class="cs-icons-close"  @click="closeUnfriendAction()" />
        </div>
        <div class="ch-share-modal__body">
          <div @click="sureUnfriend" class="cs-textstyle-informative-paragraph first-option">Yes</div>
          <div @click="closeUnfriendAction" class="cs-textstyle-informative-paragraph second-option">No</div>
        </div>
      </div>
    </app-action-sheet>
    <app-action-sheet :open="openFriendCancelAction" @close="closeFriendCancelAction()">
      <div class="ch-share__actionsheet">
        <div class="ch-share-modal__header">
          <span>Cancel Friend Request?</span>
          <i class="cs-icons-close"  @click="closeFriendCancelAction()" />
        </div>
        <div class="ch-share-modal__body">
          <div @click="cancelFriendRequest" class="cs-textstyle-informative-paragraph first-option">Yes</div>
          <div @click="closeFriendCancelAction" class="cs-textstyle-informative-paragraph second-option">No</div>
        </div>
      </div>
    </app-action-sheet>
    <app-action-sheet :open="openRsvpAction" @close="closeRsvpAction()">
      <div class="ch-share__actionsheet">
        <div class="ch-share-modal__header">
          <span>Choose an option</span>
          <i class="cs-icons-close"  @click="closeRsvpAction()" />
        </div>
        <div class="ch-share-modal__body">
          <div @click="acceptFriendReq" class="cs-textstyle-informative-paragraph first-option">Accept Friend Request</div>
          <div @click="rejectFriendReq" class="cs-textstyle-informative-paragraph second-option">Reject Friend Request</div>
        </div>
      </div>
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppUsername from '@/components/general/Username.vue';

import InsertFriendRequest from '@/api/connections/InsertFriendRequest.gql';
import AcceptFriendRequest from '@/api/connections/AcceptFriendRequest.gql';
import Unfriend from '@/api/connections/Unfriend.gql';
import CancelFriend from '@/api/connections/CancelFriend.gql';

export default {
  components: {
    AppActionSheet,
    AppUsername,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    target: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openFriendCancelAction: false,
      openRsvpAction: false,
      openUnfriendAction: false,
    };
  },
  computed: {
    status() {
      return this.target.myRelationship && this.target.myRelationship.friendStatus || 'none';
    },
    unfriendTip() {
      return `Unfriend ${this.target.username} to stop sharing private content`;
    },
    requestTip() {
      return `Friend ${this.target.username} to share private content`;
    },
    pendingTip() {
      return `Your friendship request with ${this.target.username} is pending`;
    },
  },
  methods: {
    unfriendUser() {
      this.openUnfriendAction = true;
    },
    closeUnfriendAction() {
      this.openUnfriendAction = false;
    },
    sureUnfriend() {
      this.unfriend();
      this.openUnfriendAction = false;
    },
    // Used to terminate an existing friendship
    async unfriend() {
      await this.$apollo.mutate({
        mutation: Unfriend,
        variables: {
          userId: this.target.id,
        },
        optimisticResponse: {
          end_friend: {
            id: `temp.${Date.now()}`,
            __typename: 'Relationship',
            isFriend: false,
            friendStatus: 'none',
          },
        },
        update: (cache, mutationResponse) => {
          // Update relationship
          const currentRelationship = { ...this.target.myRelationship };
          cache.modify({
            id: cache.identify(this.target),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                if (currentRelationship) {
                  currentRelationship.isFriend = false;
                  currentRelationship.friendStatus = 'none';
                  return currentRelationship;
                }
                return toReference(cache.identify(mutationResponse.data.end_friend));
              },
            },
          });

          // Update user's profile
          cache.modify({
            id: cache.identify({ __typename: 'User', id: $auth.getUserId() }),
            fields: {
              friendCount(existingFieldData = 1) {
                return existingFieldData - 1;
              },
            },
          });
        },
      });
    },
    async requestFriend() {
      await this.$apollo.mutate({
        mutation: InsertFriendRequest,
        variables: {
          userId: this.target.id,
        },
        optimisticResponse: {
          request_friend: {
            id: `temp.${Date.now()}`,
            __typename: 'Relationship',
            isFriend: false,
            friendStatus: 'pending',
          },
        },
        update: (cache, mutationResponse) => {
          const currentRelationship = { ...this.target.myRelationship };
          cache.modify({
            id: cache.identify(this.target),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                if (currentRelationship) {
                  currentRelationship.friendStatus = 'pending';
                  return currentRelationship;
                }
                return toReference(cache.identify(mutationResponse.data.request_friend));
              },
            },
          });
        },
      });
    },
    async acceptFriend() {
      await this.$apollo.mutate({
        mutation: AcceptFriendRequest,
        variables: {
          userId: this.target.id,
        },
        optimisticResponse: {
          accept_friend: {
            id: `temp.${Date.now()}`,
            __typename: 'Relationship',
            isFriend: true,
            friendStatus: 'accept',
          },
        },
        update: (cache, mutationResponse) => {
          // Update friendship
          const currentRelationship = { ...this.target.myRelationship };
          cache.modify({
            id: cache.identify(this.target),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                if (currentRelationship) {
                  currentRelationship.isFriend = true;
                  currentRelationship.friendStatus = 'accept';
                  return currentRelationship;
                }
                return toReference(cache.identify(mutationResponse.data.accept_friend));
              },
            },
          });

          // Update user profile
          cache.modify({
            id: cache.identify({ __typename: 'User', id: $auth.getUserId() }),
            fields: {
              friendCount(existingFieldData = 1) {
                return existingFieldData + 1;
              },
            },
          });
        },
      });
    },
    cancelFriendSheet() {
      this.openFriendCancelAction = true;
    },
    cancelFriendRequest() {
      this.cancelFriend();
      this.openFriendCancelAction = false;
    },
    closeFriendCancelAction() {
      this.openFriendCancelAction = false;
    },
    rsvpActionsheet() {
      this.openRsvpAction = true;
    },
    closeRsvpAction() {
      this.openRsvpAction = false;
    },
    rejectFriendReq() {
      this.cancelFriend();
      this.openRsvpAction = false;
    },
    acceptFriendReq() {
      this.acceptFriend();
      this.openRsvpAction = false;
    },
    async cancelFriend() {
      await this.$apollo.mutate({
        mutation: CancelFriend,
        variables: {
          userId: this.target.id,
        },
        optimisticResponse: {
          end_friend: {
            id: `temp.${Date.now()}`,
            __typename: 'Relationship',
            isFriend: false,
            friendStatus: 'none',
          },
        },
        update: (cache, mutationResponse) => {
          // Update relationship
          const currentRelationship = { ...this.target.myRelationship };
          cache.modify({
            id: cache.identify(this.target),
            fields: {
              myRelationship(existingFieldData, { toReference }) {
                if (currentRelationship) {
                  currentRelationship.isFriend = false;
                  currentRelationship.friendStatus = 'none';
                  return currentRelationship;
                }
                return toReference(cache.identify(mutationResponse.data.end_friend));
              },
            },
          });
          // No need to update user's profile since it wasn't a relationship to start with
        },
      });
    },
  },
};
</script>
<style scoped>
  .friend-button .cs-button
  {
    min-width: 100px;
    width: 100px;
    white-space: nowrap;
    margin-right: 3px;
  }

  .ch-share__actionsheet{
  display: flex;
  flex-direction: column;
}
.ch-share-modal__header{
    display: flex;
    padding-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-size: 700;
}
.ch-share-modal__body{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.first-option{
  padding: 10px 0px;
  border-bottom: 1px solid var(--cs-gray-01);
}
.second-option{
  padding: 10px 0px
}
</style>
