<template>
  <ion-page>
    <ion-header>
      <app-main-header hide-notifications title="Channels" :create="true">
        <!--
        <app-my-channel-btn />
        -->
      </app-main-header>
      <app-tabs v-model="tabIndex" :tabs="tabs" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />

      <!-- Latest section -->
      <div v-show="tabIndex === 0" class="video-tab-content">
        <app-new-video-segment ref="recentQuery" :query="recentQuery" />
      </div>

      <!-- Live section -->
      <div v-show="tabIndex === 1" class="video-tab-content__search">
        <div class="row">
          <app-new-video-segment ref="liveQuery" :query="liveQuery" />
        </div>
      </div>
<!--
      <div v-show="tabIndex === 3" class="video-tab-content">
        <app-explore-videos />
      </div>

      <div v-show="tabIndex === 4" class="video-tab-content__search">
        <div class="row">
          <app-search-bar v-model="query" />
          <app-video-search-results :query="query" />
        </div>
      </div>
-->
      <!-- Recommended section -->
      <!-- <div v-show="tabIndex === 4" class="video-tab-content">
        <div class="row">
          <app-channel-segment
            ref="recommendedChannels"
            :query="recommendedChannels"
            show-button
            @refresh-recommendation="refreshRecommendedChannels"
          />
        </div>
      </div> -->

    </ion-content>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
//import $auth from '@/services/auth';

// Component
import AppVideoSearchResults from '@/components/videos/new/VideoSearchResults.vue';
import AppSearchBar from '@/components/general/SearchBar.vue';
import AppMainHeader from '@/components/general/PublicMainHeader.vue';
import AppMyChannelBtn from '@/components/general/MyChannelBtn.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppChannelSegment from '@/components/videos/new/ChannelSegmentNew.vue';
import AppTabs from '@/components/general/PublicTabs.vue';
import AppNewVideoSegment from '@/components/videos/new/PublicVideoSegmentNew.vue';
import AppExploreVideos from '@/components/videos/new/ExploreVideos.vue';
import AppVideoListMini from '@/components/videos/PublicVideoListMini.vue';

// GQL
//import ListBookmarkedVideos from '@/gql/videos/ListBookmarkedVideos.gql';
//import ListFollowingVideos from '@/gql/videos/ListFollowingVideos.gql';
import ListRecentVideos from '@/gql/videos/PublicListRecentVideos.gql';
import ListPopularVideos from '@/gql/videos/PublicListPopularVideos.gql';
//import ListQueuedVideos from '@/gql/videos/PublicListQueuedVideos.gql';
import ListLiveVideos from '@/gql/videos/PublicListLiveVideos.gql';
//import ListWatchedVideos from '@/gql/videos/PublicListWatchedVideos.gql';
//import ListFeaturedChannels from '@/gql/videos/ListFeaturedChannels.gql';
//import ListSubscribedChannels from '@/gql/videos/PublicListSubscribedChannels.gql';
import ListLiveChannels from '@/gql/videos/PublicListLiveChannels.gql';

export default {
  components: {
    AppVideoSearchResults,
    AppMainHeader,
    AppSearchBar,
    AppMyChannelBtn,
    AppPullToRefresh,
    AppChannelSegment,
    AppTabs,
    AppExploreVideos,
    AppNewVideoSegment,
    AppVideoListMini,
  },
  data() {
    return {
      //bookmarksQuery: ListBookmarkedVideos,
      //followingQuery: ListFollowingVideos,
      recentQuery: ListRecentVideos,
      popularQuery: ListPopularVideos,
      //queuedQuery: ListQueuedVideos,
      //historyQuery: ListWatchedVideos,
      liveQuery: ListLiveVideos,
      //subscribedQuery: ListSubscribedChannels,
      // recommendedChannels: ListFeaturedChannels,
      liveChannels: ListLiveChannels,
      //id: $auth.getUserId(),
      tabIndex: this.$route.params.view ? Number(this.$route.params.view) : 0,
      query: '',
      tabs: [
        'Latest',
        // 'Subscribed',
        'Live',
        //'Channels',
        //'Search',
        //'History',
        // 'Bookmarked',
        // 'Watch Later',
      ],
    };
  },
  methods: {
    // goToRelevantView() {
    //   if (this.user && !this.user.channelName && !this.user.videoPosts.count) {
    //     this.$router.push({
    //       name: 'VideoProfile',
    //       params: { id: this.id },
    //     });
    //   } else {
    //     $bus.$emit('show-create-video-modal');
    //   }
    // },
    async refresh($event) {
      await Promise.all([
        this.$refs.recentQuery.refresh(),
        //this.$refs.queuedQuery.refresh(),
        //this.$refs.bookmarksQuery.refresh(),
        //this.$refs.historyQuery.refresh(),
        this.$refs.liveQuery.refresh(),
        //this.$refs.subscribedQuery.refresh(),
        //this.$refs.recommendedChannels.refresh(),
      ]);
      $event.target.complete();
    },
    // async refreshRecommendedChannels() {
    //   await this.$refs.recommendedChannels.refresh();
    // },
  },
};
</script>
<style scoped>
.video-tab-content {
  margin-top: 15px;
}
</style>
