<template>
  <div class="ch-conversation-composer">
    <!-- Attachments/Links Container -->
    <div
      v-if="showAttachmentsContainer"
      class="ch-conversation-composer__attachments"
    >
      <div v-if="link" class="composer-link">
        <app-link-preview
          v-if="link"
          :title="link.title"
          :src="link.url"
          :description="link.details"
          class="app-link-preview"
          :picture="link.image"
          :preview="true"
        />
        <i class="cs-icons-delete link-delete" @click="removeLink"></i>
      </div>
      <app-attachments
        :attached-image="attachedImages"
        :attachment="attachment"
        @remove="removeAttachments"
      />
    </div>
    <div v-if="loadingLink"><cs-spinner class="ch-spinner" /></div>
    <div class="ch-conversation-composer__content">
      <!-- Attachment Icons -->
      <div v-if="!sharedPost" class="composer__icons">
        <i class="ch-icons-attach" @click="openActionSheet"></i>
      </div>
      <!-- Composer -->
      <!-- TODO: do we need a character count on the message? -->
      <app-textarea
        v-model="message"
        :placeholder="
          allowMessaging || !receiver
            ? sharedLink
              ? 'Select user first to share link'
              : 'Type a new message...'
            : `${receiver.displayname} does not allow non-friends to send private messages`
        "
        class="composer-textarea"
        :disabled="loadingAttachment || !allowMessaging"
        :maxlength="1000"
        rows="1"
        @input="handleInput(false)"
        @keydown.native.enter.exact.prevent="checkIfEmpty"
      />
      <!-- Send Button -->
      <cs-button
        fill="clear"
        class="cs-button"
        :disabled="emptyMessage"
        @click="sendMessage"
      >
        <i class="cs-icons-send-filled"></i>
      </cs-button>
    </div>

    <app-upload-progress v-if="loadingAttachment" />

    <!-- Action sheet -->
    <!-- NOTE: keep actionsheet as last item, when I put the upload-progress as last the actionsheet misbehaved after uploading -->
    <app-action-sheet :open="showActionSheet" @close="closeActionSheet">
      <!-- Image -->
      <app-action-sheet-button @click="attach('image/*')">
        <i class="ch-icons-add-media"></i>Image
      </app-action-sheet-button>
      <!-- GIFS -->
      <app-action-sheet-button @click="openGIFsModal">
        <i class="ch-icons-gif"></i>GIF
      </app-action-sheet-button>
      <!-- Video -->
      <app-action-sheet-button @click="attach('video/*')">
        <i class="cs-icons-video-file"></i>Video
      </app-action-sheet-button>
      <!-- Document -->
      <app-action-sheet-button @click="attach('application/pdf')">
        <i class="cs-icons-doc"></i>Document
      </app-action-sheet-button>
    </app-action-sheet>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $utils from '@/services/utils';
import $upload from '@/services/upload';
// Components
import AppAttachments from '@/components/general/Attachments.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppTextarea from '@/components/general/Textarea.vue';
import AppLinkPreview from '@/components/general/LinkPreview.vue';
import AppUploadProgress from '@/components/general/UploadProgress.vue';

// GQL
import InsertConversationMessage from '@/gql/conversations/InsertConversationMessage.gql';
import InsertNewConversationMessage from '@/gql/conversations/InsertNewConversationMessage.gql';
import GetLinkPreview from '@/gql/general/GetLinkPreview.gql';

export default {
  components: {
    AppAttachments,
    AppActionSheet,
    AppActionSheetButton,
    AppTextarea,
    AppLinkPreview,
    AppUploadProgress,
  },
  props: {
    receiver: {
      type: Object,
      required: false,
      default: null,
    },
    conversationId: {
      type: String,
      required: false,
      default: '',
    },
    sharedPost: {
      type: Object,
      required: false,
      default: null,
    },
    sharedLink: {
      type: String,
      required: false,
      default: '',
    },
    newMsg: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      showActionSheet: false,
      loadingAttachment: false,
      showGifsModal: false,
      message: '',
      attachment: null,
      attachedImages: [],
      messageData: {},
      link: null,
      loadingLink: false,
      ignoredLinks: [],
      linkTimer: null,
    };
  },
  computed: {
    showAttachmentsContainer() {
      return (
        !!this.attachment ||
        this.attachedImages.length > 0 ||
        !!this.link ||
        !!this.sharedPost
      );
    },
    inputPlaceholder() {
      return this.allowMessaging || !this.receiver
        ? 'Type a new message...'
        : `${this.receiver.displayname} does not allow non-friends to send private messages`;
    },
    emptyMessage() {
      if (!this.loadingAttachment) {
        return !(
          this.allowMessaging &&
          (this.message ||
            this.attachedImages.length ||
            this.attachment ||
            this.link ||
            this.sharedPost ||
            this.sharedLink)
        );
      }
      return true;
    },
    allowMessaging() {
      return (
        this.receiver &&
        (this.receiver.allowUnsolicitedMessages ||
          (this.receiver.myRelationship &&
            (this.receiver.myRelationship.isFriend ||
              this.receiver.myRelationship.isFollowing)))
      );
    },
  },
  watch: {
    $route() {
      this.reset();
    },
    receiver() {
      if (this.receiver && this.sharedLink && this.allowMessaging) {
        this.message = this.sharedLink;
      }
    },
  },

  methods: {
    reset() {
      this.message = '';
      this.attachment = null;
      this.attachedImages = [];
      this.loadingAttachment = false;
      this.messageData = {};
      this.link = null;
    },
    checkIfEmpty() {
      if (!this.emptyMessage) {
        this.sendMessage();
      }
    },
    // ActionSheet for Attachments
    closeActionSheet() {
      this.showActionSheet = false;
    },
    openActionSheet() {
      if (this.allowMessaging) {
        this.showActionSheet = true;
      }
    },
    // GIFs
    openGIFsModal() {
      $bus.$emit('show-select-gif-modal');
      $bus.$once('gif-modal-select', this.selectedGIF);
    },
    selectedGIF(gif) {
      const gifImage = {
        url: gif.url,
        name: 'Embedded Gif',
        type: 'image',
        contentType: 'image/gif',
      };
      this.attachedImages = [...this.attachedImages, gifImage];
    },
    // Links
    handleInput() {
      if (!this.message.length) {
        this.ignoredLinks = [];
        this.link = '';
      }
      if (!this.link) {
        clearTimeout(this.linkTimer);
        const links = this.message.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g
        );
        if (links) {
          this.loadingLink = true;
          const notIgnoredLinks = links.filter(
            (link) => !this.ignoredLinks.includes(link)
          );
          if (notIgnoredLinks.length)
            this.linkTimer = setTimeout(
              () => this.detectLink(notIgnoredLinks[0]),
              700
            );
        }
      }
    },
    async detectLink(url) {
      this.link = null;
      const { data } = await this.$apollo.query({
        query: GetLinkPreview,
        variables: {
          url,
        },
      });
      const link = {
        ...data.link_preview,
        url,
      };
      delete link.__typename;
      this.loadingLink = false;
      this.link = link;
    },
    removeLink() {
      this.link = null;
    },

    // Attachments
    async attach(type) {
      this.closeActionSheet();
      try {
        if (type === 'image/*') {
          const files = await $utils.selectFiles(type);
          [...files].forEach((file) => {
            file.dataurl = URL.createObjectURL(file);
            this.attachedImages.push(file);
          });
          this.$emit('attachImage');
        } else {
          this.file = await $utils.selectFile(type);
          this.file.dataurl = URL.createObjectURL(this.file);
          this.attachment = this.file;
          this.$emit('attach');
        }
      } catch (error) {
        this.attachment = null;
      }
    },
    // Remove Docs and Videos
    removeAttachments(image) {
      if (image) {
        this.attachedImages = this.attachedImages.filter(
          (attachment) => image !== attachment
        );
      } else {
        this.attachment = null;
      }
    },

    /** **************** QUERIES AND MUTATIONS ***************************** */
    // Send Message (Queries and Mutation)
    async sendMessage() {
      if (this.emptyMessage) return;
      this.loadingAttachment = true;

      try {
        if (this.newMsg) {
          $bus.$emit('message-start');
        }
        this.messageData = {
          msgBody: this.message,
          receiverId: this.receiver.id,
        };

        // Shared Post
        if (this.sharedPost) {
          this.messageData.postId = this.sharedPost.id;
        }

        // Links
        if (this.link) {
          this.messageData.linkPreview = { ...this.link };
        }

        // Videos and Documents
        if (this.attachment) {
          delete this.messageData.document;
          delete this.messageData.video;
          if (this.attachment.type === 'application/pdf') {
            this.messageData.document = await $upload.uploadDocument(this.file);
          }
          if (this.attachment.type.startsWith('video/')) {
            this.messageData.video = await $upload.uploadVideo(this.file);
          }
        }

        // Images
        if (this.attachedImages.length > 0) {
          this.messageData.images = await $upload.uploadImages(
            this.attachedImages
          );
        }
        if (this.conversationId) {
          await this.sendMsgToOldReceiver();
          this.reset();
        } else {
          this.$emit('input', true);
          await this.sendMsgToNewReceiver();
          this.reset();
          this.$emit('closeNew');
        }
      } catch (e) {
        // TODO: handle this error
        this.loadingAttachment = false;
      }
    },

    async sendMsgToNewReceiver() {
      await this.$apollo.mutate({
        mutation: InsertNewConversationMessage,
        variables: this.messageData,
        update: (cache, { data: { newMessage } }) => {
          const cacheId = cache.identify(newMessage);
          cache.modify({
            fields: {
              list_conversation_messages: (
                existingFieldData,
                { toReference }
              ) => [...existingFieldData, toReference(cacheId)],
              list_conversations: (_existingFieldData, { toReference }) =>
                toReference(cacheId),
            },
          });
          cache.modify({
            __typename: 'Post',
            id: cache.identify(this.sharedPost.id),
            fields: {
              sharedPostCount(prevCount) {
                return prevCount + 1;
              },
            },
          });
          $bus.$emit('message-end');
          this.loadingAttachment = false;
          this.$router.push({
            name: 'ConversationDetail',
            params: {
              id: newMessage.conversationId,
            },
          });
        },
      });
    },

    async sendMsgToOldReceiver() {
      await this.$apollo.mutate({
        mutation: InsertConversationMessage,
        variables: this.messageData,
        update: (cache, { data: { message } }) => {
          const cacheId = cache.identify(message);
          cache.modify({
            fields: {
              list_conversation_messages: (
                existingFieldData,
                { toReference }
              ) => [...existingFieldData, toReference(cacheId)],
              list_conversations: (_existingFieldData, { toReference }) =>
                toReference(cacheId),
            },
          });
        },
      });
    },
  },
};
</script>

<style scoped>
.ch-conversation-composer {
  display: flex;
  position: relative;
  flex-direction: column;
}
.ch-conversation-composer__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15px;
  align-items: flex-end;
  gap: 10px;
}
.composer__icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  margin-right: 7px;
  color: var(--cs-gray-05);
}
.composer__icons,
.cs-button {
  margin-bottom: 18px;
}
.ch-conversation-composer__content >>> .cs-textarea__textarea {
  max-height: 150px;
  background-color: var(--cs-gray-01);
}
.composer-textarea {
  flex: 1;
}
.cs-button {
  --cs-button-padding: 0px !important;
}
.cs-action-button {
  --cs-button-color: var(--cs-gray-05);
}
.ch-conversation-composer__attachments {
  max-height: 250px;
  overflow: auto;
  padding: 5px;
}
.shared-post {
  padding: 20px;
}
.composer-link {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.app-link-preview {
  flex: 1;
}
.link-delete {
  font-size: 20px;
  cursor: pointer;
}
.attachment-state {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-left: -5px;
  margin-top: -5px;
  z-index: 2;
  background-color: rgba(27, 38, 51, 0.6);
}
</style>
