<template>
  <div>
    <div class="search-header">Groups</div>
    <div v-if="groups">
      <cs-empty-state
        v-if="showEmptyState"
        title="No matching groups"
        class="cs-textstyle-detail-heading"
      />
      <div v-else>
        <app-group-grid :groups="groups" />
        <cs-button
          v-if="mayHaveMore && !$apollo.queries.groups.loading"
          block
          fill="outline"
          size="small"
          @click="loadMore"
        >
          More Groups
        </cs-button>
      </div>
    </div>
    <app-spinner v-if="$apollo.queries.groups.loading" />
  </div>
</template>

<script>
import AppGroupGrid from '@/components/groups/GroupGrid.vue';
import AppSpinner from '@/components/general/Spinner.vue';

import GroupSearch from '@/gql/search/GroupSearch.gql';

export default {
  components: {
    AppGroupGrid,
    AppSpinner,
  },
  props: {
    search: String,
  },
  apollo: {
    groups: {
      query: GroupSearch,
      variables() {
        return {
          query: this.search,
          limit: 4,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.groups && !this.groups.length) return true;
      return false;
    },
  },
  methods: {
    loadMore() {
      const offset = this.groups.length;
      this.$apollo.queries.groups.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMore = !!fetchMoreResult.groups.length;
          return {
            groups: [...previousResult.groups, ...fetchMoreResult.groups],
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
