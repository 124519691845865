<template>
  <ion-page>
    <ion-header>
      <div class="ch-meeting-category__header">
        <app-category-header :title="modifiedTitle" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-meeting-list
        :meetings="meetings"
        direction="column"
      ></app-meeting-list>
      <app-infinite-offset :query="$apollo.queries.meetings" />
      <!-- Floating Button -->
      <app-meeting-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppMeetingExploreBtn from '@/components/meetings/MeetingExploreBtn.vue';
import AppMeetingList from '@/components/meetings/MeetingList.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

import ListCategoryMeetings from '@/gql/meetings/ListCategoryMeetings.gql';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppMeetingList,
    AppMeetingExploreBtn,
    AppInfiniteOffset,
  },
  data() {
    return {
      category: this.$route.params.category,
    };
  },
  computed: {
    modifiedTitle() {
      const titleMap = {
        news: 'News',
        faith: 'Faith',
        politics: 'Politics & Civic',
        music: 'Music',
        finance: 'Finance & Investing',
        business: 'Business',
        health: 'Health & Fitness',
        movies: 'Movies & Shows',
        sports: 'Sports',
        technology: 'Technology',
        games: 'Games & Gaming',
        education: 'Education',
        personalgrowth: 'Personal Growth',
        fashion: 'Fashion & Beauty',
        humor: 'Humor & Memes',
      };
      const type = this.category;

      return titleMap[type] || type;
    },
  },
  watch: {
    $route() {
      this.category = this.$route.params.category;
    },
  },
  apollo: {
    meetings: {
      query: ListCategoryMeetings,
      variables() {
        return {
          category: this.category,
        };
      },
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.meetings.refetch();
      $event && $event.target.complete();
    },
  },
};
</script>

<style scoped></style>
