<template>
  <div>
    <ion-tabs>
      <!-- Home Page -->
      <ion-tab tab="/public/home">
        <ion-vue-router name="home"></ion-vue-router>
      </ion-tab>
      <!-- News Page -->
      <!--
      <ion-tab tab="/public/news">
        <ion-vue-router name="news"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/public/just-the-news">
        <ion-vue-router name="just-the-news"></ion-vue-router>
      </ion-tab>
      -->
      <!-- Groups Page -->
      <ion-tab tab="/public/groups">
        <ion-vue-router name="groups"></ion-vue-router>
      </ion-tab>
      <!-- Videos Page -->
      <ion-tab tab="/public/videos">
        <ion-vue-router name="videos"></ion-vue-router>
      </ion-tab>
      <!-- Events page -->
      <ion-tab tab="/public/meetings">
        <ion-vue-router name="meetings"></ion-vue-router>
      </ion-tab>
      <!-- Connections Page -->
      <ion-tab tab="/public/connections">
        <ion-vue-router name="connections"></ion-vue-router>
      </ion-tab>
      
      <ion-tab tab="/public/menu">
        <ion-vue-router name="menu"></ion-vue-router>
      </ion-tab>

      <!-- Tab Bar -->
      <!-- <ion-tab-bar slot="bottom" :class="hideTabShadow"> -->
      <ion-tab-bar v-show="showTabs" slot="bottom" :style="hideTabShadow">
        
        <ion-tab-button tab="/public/home" @click="checkScrollTop('/public/home')">
          <i class="ch-icons-feeds"></i>
          <span>Community</span>
        </ion-tab-button>

        <ion-tab-button tab="/public/videos" @click="checkScrollTop('/public/videos')">
          <i class="ch-icons-videos"></i>
          <span>Channels</span>
        </ion-tab-button>

        <ion-tab-button tab="/public/videos/2" @click="checkScrollTop('/public/videos/2')">
          <i class="ch-icons-news"></i>
          <span>Events</span>
        </ion-tab-button>

        <ion-tab-button tab="/start" @click="checkScrollTop('/start')">
          <i class="ch-icons-login"></i>
          <span>Login/Register</span>
        </ion-tab-button>

        <!--
        <ion-tab-button tab="/public/connections" @click="checkScrollTop('/public/connections')">
          <i class="ch-icons-users"></i>
          <span>People</span>
        </ion-tab-button>
        -->
        <!--
        <ion-tab-button tab="/public/news" @click="checkScrollTop('/public/news')">
          <i class="ch-icons-news"></i>
          <span>News</span>
        </ion-tab-button>
      -->
        <!--
        <ion-tab-button tab="/public/groups" @click="checkScrollTop('/public/groups')">
          <i class="ch-icons-groups"></i>
          <span>Groups</span>
        </ion-tab-button>
        
        <ion-tab-button tab="/public/meetings" @click="checkScrollTop('/public/meetings')">
          <i class="cs-icons-marker"></i>
          <span>Meetings</span>
        </ion-tab-button>
        -->
        <!-- Menu -->
        <!-- <ion-tab-button tab="/public/menu" @click="checkScrollTop('/public/menu')">
          <i class="cs-icons-more"></i>
          <span>More</span>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
    <app-modal-manager></app-modal-manager>
<!--    <app-hamburger-menu /> -->
  </div>
</template>

<script>
import $bus from '@/services/bus';
import AppModalManager from '@/components/general/PublicModalManager.vue';
//import AppHamburgerMenu from '@/components/general/PublicHamburgerMenu.vue';
//import RegisterPushToken from '@/gql/general/RegisterPushToken.gql';
import * as linkify from 'linkifyjs';

export default {
  components: {
    AppModalManager,
//    AppHamburgerMenu,
  },
  data() {
    return {
      showTabs: true,
      hideTabShadow: {},
    };
  },
  created() {
    this.checkTabs();
    this.$router.afterEach(this.checkTabs);

    // Handle Push Notifications and Deep Links for Cordova Apps
    document.addEventListener('deviceready', async () => {
      console.log('Device Ready');
      try {

      } catch (err) {
        console.error('Push Token Error', err);
      }

      try {
        const dynamicLinkData =
          await window.cordova.plugins.firebase.dynamiclinks.getDynamicLink();
        if (dynamicLinkData) {
          this.navigate(dynamicLinkData);
        } else {
          console.log('No Dynamic Link');
        }
        cordova.plugins.firebase.dynamiclinks.onDynamicLink(
          this.navigate,
          console.error
        );
      } catch (err) {
        console.log('Dynamic Link Error', err);
      }

      try {
        if (window.cordova && window.cordova.openwith) {
          console.log('Init Open With');
          cordova.openwith.init(console.log, console.error);
          cordova.openwith.addHandler(async (intent) => {
            console.log('Intent', intent);
            console.log('Items', intent.items);
            const item = intent.items[0];
            if (item.type == 'url' || item.type == 'public.url') {
              console.log('URL', item.data);
              $bus.$emit('show-post-composer-modal', { link: item.data });
            } else if (
              item.type.includes('image/') ||
              item.type == 'public.image'
            ) {
              console.log('File', item.type);
              console.log('File URL', item.fileUrl);
              const localBlob = await this.getLocalUrlAsBlob(
                item.fileUrl || item.data
              );
              $bus.$emit('show-post-composer-modal', { images: [localBlob] });
            } else if (item.type.includes('video/')) {
              console.log('File', item.type);
              console.log('File URL', item.fileUrl);
              const localBlob = await this.getLocalUrlAsBlob(
                item.fileUrl || item.data
              );
              $bus.$emit('show-post-composer-modal', { video: localBlob });
            } else if (
              item.type == 'public/text' ||
              item.type == 'public/plain-text' ||
              item.type == 'text/plain'
            ) {
              let link = null;
              try {
                const links = linkify.find(item.data);
                if (links[0]) {
                  link = links[0].href;
                }
              } catch (err) {}
              $bus.$emit('show-post-composer-modal', {
                bodyText: item.data,
                link,
              });
            } else {
              alert(`Unknown type:${item.type}`);
              if (item.data && item.data.length < 100) {
                alert(item.data);
              }
            }
          });
        }
      } catch (err) {
        console.log('Side Link Error', err);
      }
      try {
        document.addEventListener(
          'backbutton',
          (e) => {
            e.preventDefault();
          },
          false
        );
      } catch (err) {}
    });
  },
  methods: {
    checkTabs() {
      const meta = this.$router.currentRoute.meta;
      this.showTabs = !meta.hideTabs;
      this.hideTabShadow = meta.hideTabShadow
        ? { 'box-shadow': 'none', 'border-top': 'solid 1px var(--cs-gray-02)' }
        : {};
    },
    async checkScrollTop(path) {
      if (
        path === this.$route.path ||
        path === this.$route.redirectedFrom ||
        `${path}/home` === this.$route.path
      ) {
        $bus.$emit('ch-tabs-scroll-top', { route: this.$route });
      }
    },
    navigate(dynamicLinkData) {
      console.log('Dynamic Link', dynamicLinkData);
      const parts = dynamicLinkData.deepLink.split('/');
      const id = parts.pop();
      const type = parts.pop();
      console.log('ID', id);
      console.log('Type', type);
      // type: [group, channel, user, video, post];
      const path = {
        groups: 'GroupDetail',
        channels: 'VideoProfile',
        users: 'Profile',
        video: 'VideoDetail',
        posts: 'PostDetail',
        marketplace: 'MarketplaceHome',
      };
      const routeName = path[type];
      console.log('Route Name', routeName);
      this.$router.push({ name: routeName, params: { id } });
    },
    getLocalUrlAsBlob(localUrl) {
      return new Promise((resolve, reject) => {
        resolveLocalFileSystemURL(
          localUrl,
          (fileEntry) => {
            fileEntry.file((file) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (ev) => {
                const blob = new Blob([fileReader.result], { type: file.type });
                blob.dataurl = URL.createObjectURL(blob);
                blob.filename = 'Untitled';
                blob.lastModifiedDate = new Date();
                resolve(blob);
              };
            }, console.error);
          },
          console.error
        );
      });
    },
  },
};
</script>

<style scoped>
ion-tab-button {
  color: var(--cs-gray-05);
}
ion-tab-button.tab-selected {
  color: var(--cs-primary-base);
}
ion-tab-button i {
  font-size: 3em;
}
ion-tab-button span {
  font-weight: var(--cs-font-weight-bold);
  font-size: 12px;
}
ion-tab-bar {
  --ion-color-primary: var(--cs-primary-base);
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
  --border: none;
  height: 60px;
}
</style>
