<template>
  <div>
    <!-- Only appears in detail views -->
    <div v-if="isDetailView" >

      <!-- Start Live Chat Button -->
      <transition name="ch-action-sheet-fade">
        <div class="live-chat__header">
          <h3 class="live-chat__title" @click="startLiveChat">Live Chat</h3>
          <div class="display-flex">
            <div>
              <i class="ch-icons-reaction"></i>
              <span> {{ reactionsCount }}</span>
            </div>
            <div>
              <i class="ch-icons-comments"></i>
              <span> {{ commentsCount }}</span>
            </div>
            <div v-if="liveRoomCount !== 0">
              <i class="ch-icons-groups"></i>
              <span> {{ liveRoomCount }}</span>
            </div>
          </div>
        </div>
      </transition>
      
      <div v-if="isLiveChatEnabled" class="comments-container">
        <!-- spinner to load old comments -->
        <app-spinner v-if="$apollo.queries.comments.loading" />

        <!-- button to load old comments -->
        <div
          v-if="!this.$apollo.queries.comments.loading"
            class="load-more-comments cs-textstyle-paragraph-small-bold detail-load-more-comments"
            @click="loadMore()"
          >
            Load previous comments
        </div>

        <!-- Comments List -->
        <div v-if="comments" >
          <!-- Comments List -->
          <post-comment
            v-for="comment in displayCommentsList"
            :id="'comment.' + comment.id"
            :key="comment.id"
            :parent="comment"
            :comment="comment"
            post-id="post.id"
            @refreshComments="refreshComments"
          ></post-comment>
        </div>

        <!-- spinner to load new comments -->
        <app-spinner v-if="$apollo.queries.comments.loading" />

        <!-- Post Comments - Comments Composer -->
        <div v-if="isLiveChatEnabled && isLoggedIn" class="comment-composer sticky-bottom">
          <app-post-comment-composer
            ref="commentComposer"
            :parent="post"
            @refreshComments="refreshComments"
          ></app-post-comment-composer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AppSpinner from '@/components/general/Spinner.vue';
import ListLivePostComments from '@/gql/posts/ListLivePostComments.gql';

import PostComment from '@/components/post/response/PostComment.vue';
import AppPostCommentComposer from '@/components/post/composer/PostCommentComposer.vue';

import $socketComments from '@/services/socket-comments';

export default {
  components: {
    PostComment,
    AppSpinner,
    AppPostCommentComposer, 
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mayHaveMore: true,
      isLiveChatEnabled: false,
      reactionsCount: 0,
      commentsCount: 0,
      liveRoomCount: 0,
      displayCommentsList: [],
      dbCommentsList: [],
      liveCommentsList: [],
    };
  },
  apollo: {
    comments: {
      query: ListLivePostComments,
      variables() {
        return {
          postId: this.post.id,
        };
      },
      skip() {
        return !this.isDetailView;
      },
      result(response) {
        this.dbCommentsList = response.data.comments;
        this.displayCommentsList = [...this.dbCommentsList, ...this.liveCommentsList]
      }
    },
  },
  computed: {
    isDetailView() {
      return this.$route.name === 'PostDetail' || 'VideoDetail';
    },
    isLoggedIn() {
      return $auth.isLoggedIn();
    },
  },
  async mounted() {
    if (this.$route.query.comment) {
      while (this.mayHaveMore) {
        await this.loadMore();
      }
      setTimeout(() => {
        const key = `comment.${this.$route.query.comment}`;
        const commentElement = document.getElementById(key);
        commentElement.scrollIntoView();
      }, 1000);
    }
    this.reactionsCount = this.post.reactionCount;
    this.commentsCount= this.post.commentsCount;
  },
  async beforeDestroy() {
    await $socketComments.leaveRoom('post', this.post.id);
  },
  methods: {
    async emitRefresh() {
      // this.$emit('refresh');
    },
    async scrollToBottom(completeBottom) {
      if(completeBottom) {
        this.$parent.$refs['content'].scrollToBottom(500);
      } else {
        this.$parent.$refs['content'].scrollByPoint(0, 200, 500);
      }
    },
    async startLiveChat() {
      this.isLiveChatEnabled = true;
      this.scrollToBottom(true);
      await $socketComments.connect();
      await $socketComments.joinRoom('post', this.post.id);
      $socketComments.on('new-comment', (payload) => {
        this.reactionsCount = payload.counts.reactionscount;
        this.commentsCount = payload.counts.commentscount;
        this.liveRoomCount = payload.counts.roomCount;
        if(payload.newComment.parentPostId === this.post.id) {
          this.liveCommentsList.push(payload.newComment);
        } else {
          const parentCommentIndex = this.displayCommentsList.findIndex(x => x.id === payload.newComment.parentPostId);
          if(parentCommentIndex !== -1) {
            const commentIndex = this.displayCommentsList[parentCommentIndex].comments.findIndex(x => x.id === payload.newComment.id);
            if(commentIndex === -1) {
              this.displayCommentsList[parentCommentIndex].comments.push(payload.newComment);
              this.displayCommentsList[parentCommentIndex].comments.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
            }
          }
        }
        this.displayCommentsList = [...this.dbCommentsList, ...this.liveCommentsList];
        this.scrollToBottom(false);
      });
    },
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        let before = null;
        if (this.comments && this.comments.length) {
            before = this.comments[0].createdAt;
        }
        this.$apollo.queries.comments.fetchMore({
          variables: {
            postId: this.post.id,
            before,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.comments.length;
            return {
              comments: [
                ...fetchMoreResult.comments,
                ...previousResult.comments,
              ],
            };
          },
        });
      }
    },
    async refreshComments(data) {
      const payload = {
        emitKey: 'livepost-comment-events',
        event: 'new-comment',
        roomType: 'post',
        roomId: this.post.id,
        newComment: data.newComment,
      };
      $socketComments.sendRoomPayload(payload);
    },
  },
  shareVideo() {
    const link = `/v/${this.post.id}`;
    $bus.$emit('show-share-sheet', {
      link,
      post: this.post,
      title: 'Share Video',
    });
  },
};
</script>

<style>
.reverse-comments {
  display: flex;
  flex-direction: column-reverse;
}
.load-more-comments {
  cursor: pointer;
  color: var(--cs-primary-base);
}
.detail-load-more-comments {
  padding-top: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-top: solid 1px var(--cs-gray-01);
  border-bottom: solid 1px var(--cs-gray-01);
}
.list-load-more-comments {
  text-align: left;
  padding-top: 5px; /* CloutHub requested */
  padding-bottom: 5px; /* CloutHub requested */
  margin-top: -5px; /* CloutHub requested */
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}

.comments-container {
  padding: 0px 8px;
  margin-bottom: 60px;
}

.live-chat__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .live-chat__title {
    margin: 0;
    font-size: 14px;
    font-weight: 800;
  }
  .live-chat__messages {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .display-flex {
    display: flex;
    gap: 10px;
  }
</style>
