<template>
  <ion-page>
    <ion-header>
      <app-main-header
        title="More"
        :hide-notifications="true"
        :search-index="0"
      />
    </ion-header>
    <ion-content>
      <div v-if="!!user" class="ch-menu__body-content">
        <cs-profile
          v-router-link="{ name: 'Profile', params: { id: userId } }"
          :picture="cdn(user.profilePhotoURL)"
          :name="user.displayname"
          :detail="user.username"
          avatar-size="medium"
          avatar-position="left"
          size="large"
        />
        <div class="ch-menu__buttons">
          <app-menu-nav-item
            v-router-link="{ name: 'Profile', params: { id: userId } }"
            icon="ch-icons-user"
            label="My Profile"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Messages' }"
            icon="ch-icons-message"
            label="Messages"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Notifications' }"
            icon="ch-icons-notification"
            label="Notifications"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Requests' }"
            icon="ch-icons-add-user"
            label="Requests"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'BookmarkPostList' }"
            icon="cs-icons-bookmark"
            label="My Bookmarked Posts"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Friends', params: { id: userId } }"
            icon="cs-icons-members"
            label="Friends/Followers"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'connections' }"
            icon="ch-icons-connections"
            label="Discover People"
          />
          <app-menu-nav-item
            v-router-link="{ name: 'Settings' }"
            icon="cs-icons-settings"
            label="Settings"
          />
          <app-menu-nav-item
            icon="ch-icons-privacy"
            label="Privacy Policy"
            @click="privacy"
          />
          <app-menu-nav-item
            icon="ch-icons-secure"
            label="Terms of Use"
            @click="terms"
          />
          <!-- <app-menu-nav-item
            icon="ch-icons-help"
            label="Help"
            @click="help"
          /> -->
          <app-menu-nav-item
            icon="cs-icons-help"
            label="Help / Report a Bug"
            @click="reportBug"
          />
          <app-menu-nav-item
            icon="ch-icons-logout"
            label="Sign Out"
            @click="logOut"
          />
        </div>
      </div>
      <app-logout-user
        :show="showLogoutPopup"
        @close="showLogoutPopup = false"
      />
    </ion-content>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';
import $cdn from '@/services/cdn';
import $browser from '@/services/browser';

import AppMainHeader from '@/components/general/MainHeader.vue';
import AppLogoutUser from '@/components/general/Logout.vue';
import AppMenuNavItem from '@/components/menu/MenuNavItem.vue';
import { policyPagesUrls } from '@/constants';

export default {
  components: {
    AppMainHeader,
    AppLogoutUser,
    AppMenuNavItem,
  },
  data() {
    return {
      userId: $auth.getUserId(),
      showLogoutPopup: false,
      user: null,
    };
  },
  async mounted() {
    this.user = await $auth.getUser();
  },
  methods: {
    cdn(url) {
      if (!url) return '';
      return $cdn.optimize(url);
    },
    async logOut() {
      this.showLogoutPopup = true;
    },
    privacy() {
      $browser.open(policyPagesUrls.privacyPolicyUrl);
    },
    help() {
      $browser.open('https://www.clouthub.com/help');
    },
    terms() {
      $browser.open(policyPagesUrls.termsOfUseUrl);
    },
    async reportBug() {
      let device = 'mobile';
      if (window.cordova) {
        device = `mobile.installed.${window.cordova.platformId}`;
      }
      const feedbackLink = `https://app.clouthub.com/feedback.html?uid=${this.user.id}&email=${this.user.email}&device=${device}`;
      $browser.open(feedbackLink);
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--cs-gray-00);
}
.ch-menu__body-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ch-menu__body-content >>> .cs-profile__content-detail {
  margin-top: 0px;
  color: var(--cs-gray-05);
}
.ch-menu__body-content >>> .cs-profile__main--left .cs-profile__content {
  margin-left: 10px;
}
.ch-menu__buttons {
  margin: 0 20px;
  border-top: 1px solid var(--cs-gray-02);
  width: 100%;
}
.ch-menu__search_icon {
  font-size: 20px;
  color: var(--cs-gray-05);
}
.ch-menu__topbar {
  justify-content: space-between;
  background-color: white;
  align-items: center;
}
.ch-menu__topbar-title {
  color: #020414;
  font-size: 22px;
  font-weight: bold;
}
</style>
