<template>
  <div class="ch-sub-header">
    <cs-subcategory-header>
      <div class="ch-message-header">
        <div class="ch-message-header__user" @click="viewAuthor(user)">
          <app-avatar
            :picture="user.profilePhotoURL"
            :name="user.displayname"
          />
          <div class="ch-message-user__info">
            <div
              class="
                ch-sub-header__title
                cs-textstyle-informative-paragraph-bold
              "
            >
              <app-username :user="user" type="displayname" />
            </div>
            <div class="ch-sub-header__subtitle cs-textstyle-paragraph-small">
              {{ user.username }}
            </div>
          </div>
        </div>
        <div class="ch-message-header__option" @click="openOptionSheet">
          <i class="cs-icons-options"></i>
        </div>
      </div>
    </cs-subcategory-header>
    <!-- Action Sheet -->
    <app-action-sheet :open="showOptionSheet" @close="closeOptionSheet">
      <app-action-sheet-button @click="blockUser"
        >Block <app-username :style="{ marginLeft: '4px' }" :user="user"
      /></app-action-sheet-button>
      <app-action-sheet-button @click="report"
        >Report <app-username :style="{ marginLeft: '4px' }" :user="user"
      /></app-action-sheet-button>
      <app-action-sheet-button @click="showConfirmDelete = true"
        >Delete conversation</app-action-sheet-button
      >
    </app-action-sheet>
    <!-- Delete Conversation Confirm Popup -->
    <app-popup
      title="Delete Conversation"
      message="Are you sure you want to delete this conversation?  This can't be undone."
      type="confirm"
      variant="danger"
      ok-label="Delete"
      :show="showConfirmDelete"
      @ok="$emit('deleteConversation')"
      @close="showConfirmDelete = false"
    />
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $cdn from '@/services/cdn';
// Components
import AppAvatar from '@/components/general/Avatar.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppPopup from '@/components/general/Popup.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    AppAvatar,
    AppActionSheet,
    AppActionSheetButton,
    AppPopup,
    AppUsername,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOptionSheet: false,
      showConfirmDelete: false,
    };
  },
  methods: {
    cdn(url) {
      if (!url) return;
      // eslint-disable-next-line consistent-return
      return $cdn.optimize(url);
    },
    report() {
      const data = {
        report: this.user,
        reportType: 'user',
      };
      $bus.$emit('show-report-action', data);
    },
    blockUser() {
      $bus.$emit('show-block-user', this.user, 'Conversation');
    },
    // Go to author's profile
    viewAuthor(user) {
      this.$router.push({
        name: 'Profile',
        params: user,
      });
    },
    // ActionSheet
    closeOptionSheet() {
      this.showOptionSheet = false;
    },
    openOptionSheet() {
      this.showOptionSheet = true;
    },
  },
};
</script>

<style scoped>
.ch-sub-header {
  border-bottom: 1px solid var(--cs-gray-01);
}
.ch-sub-header >>> .back-button {
  top: 14px;
}
.ch-message-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ch-message-header__user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ch-message-user__info {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.ch-message-header__option {
  color: var(--cs-gray-04);
  font-size: 24px;
}
.ch-sub-header .subcategory-header {
  background-color: white;
}
.ch-sub-header__title {
  color: var(--cs-gray-06);
}
.ch-sub-header__subtitle {
  color: var(--cs-gray-05);
  margin-top: -2px;
}
</style>
