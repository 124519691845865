<template>
  <div>
    <ion-tabs>
      <!-- Home Page -->
      <ion-tab tab="/tabs/home">
        <ion-vue-router name="home"></ion-vue-router>
      </ion-tab>
      <!-- News Page -->
      <!--
      <ion-tab tab="/tabs/news">
        <ion-vue-router name="news"></ion-vue-router>
      </ion-tab>
      <ion-tab tab="/tabs/just-the-news">
        <ion-vue-router name="just-the-news"></ion-vue-router>
      </ion-tab>
      -->
      <!-- Groups Page -->
      <ion-tab tab="/tabs/groups">
        <ion-vue-router name="groups"></ion-vue-router>
      </ion-tab>
      <!-- Videos Page -->
      <ion-tab tab="/tabs/videos">
        <ion-vue-router name="videos"></ion-vue-router>
      </ion-tab>
      <!-- Events page -->
      <ion-tab tab="/tabs/meetings">
        <ion-vue-router name="meetings"></ion-vue-router>
      </ion-tab>
      <!-- Connections Page -->
      <ion-tab tab="/tabs/connections">
        <ion-vue-router name="connections"></ion-vue-router>
      </ion-tab>
      <!-- Menu Page -->
      <ion-tab tab="/tabs/menu">
        <ion-vue-router name="menu"></ion-vue-router>
      </ion-tab>

      <!-- Tab Bar -->
      <!-- <ion-tab-bar slot="bottom" :class="hideTabShadow"> -->
      <ion-tab-bar v-show="showTabs" slot="bottom" :style="hideTabShadow">
        <!-- Forum -->
        <ion-tab-button tab="/tabs/home" @click="checkScrollTop('/tabs/home')">
          <i class="ch-icons-feeds"></i>
          <span>Community</span>
        </ion-tab-button>
        <!-- Videos -->
        <ion-tab-button
          tab="/tabs/videos"
          @click="checkScrollTop('/tabs/videos')"
        >
          <i class="ch-icons-videos"></i>
          <span>Channels</span>
        </ion-tab-button>
        <!-- Connections -->
        <ion-tab-button
          tab="/tabs/connections"
          @click="checkScrollTop('/tabs/connections')"
        >
          <i class="ch-icons-users"></i>
          <span>People</span>
        </ion-tab-button>
        <!-- News -->
        <!--
        <ion-tab-button tab="/tabs/news" @click="checkScrollTop('/tabs/news')">
          <i class="ch-icons-news"></i>
          <span>News</span>
        </ion-tab-button>
      -->
        <!-- Groups -->
        <ion-tab-button
          tab="/tabs/groups"
          @click="checkScrollTop('/tabs/groups')"
        >
          <i class="ch-icons-groups"></i>
          <span>Groups</span>
        </ion-tab-button>
        <!-- Events -->
        <ion-tab-button
          @click="openInBrowser('https://meetings.clouthub.com/')"
        >
          <i class="cs-icons-marker"></i>
          <span>Meetings</span>
        </ion-tab-button>
          <!-- Events -->
          <ion-tab-button
          tab="/tabs/videos/2"
          @click="checkScrollTop('/tabs/meetings')"
        >
          <i class="cs-icons-marker"></i>
          <span>Events</span>
        </ion-tab-button>

        <!-- Menu -->
        <!-- <ion-tab-button tab="/tabs/menu" @click="checkScrollTop('/tabs/menu')">
          <i class="cs-icons-more"></i>
          <span>More</span>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
    <app-modal-manager></app-modal-manager>
    <app-hamburger-menu />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import AppModalManager from '@/components/general/ModalManager.vue';
import AppHamburgerMenu from '@/components/general/HamburgerMenu.vue';
import RegisterPushToken from '@/gql/general/RegisterPushToken.gql';
import * as linkify from 'linkifyjs';
import $browser from '@/services/browser';

export default {
  components: {
    AppModalManager,
    AppHamburgerMenu,
  },
  data() {
    return {
      showTabs: true,
      hideTabShadow: {},
    };
  },
  created() {
    this.checkTabs();
    this.$router.afterEach(this.checkTabs);

    // Handle Push Notifications and Deep Links for Cordova Apps
    document.addEventListener('deviceready', async () => {
      console.log('Device Ready');
      try {
        // Not sure this is needed since I think this view only appears to logged in users
        if ($auth.isLoggedIn()) {
          await cordova.plugins.firebase.messaging.requestPermission();
          const token = await cordova.plugins.firebase.messaging.getToken();

          // RegisterPushToken
          console.log('Got Token', token);

          this.$apollo.mutate({
            mutation: RegisterPushToken,
            variables: {
              token,
            },
          });

          cordova.plugins.firebase.messaging.onMessage((msg) => {
            console.log('Foreground Message', msg);
          });

          cordova.plugins.firebase.messaging.onBackgroundMessage((msg) => {
            console.log('Background Message', msg);
            const postTypes = [
              'notice-react',
              'notice-comment',
              'notice-share',
              'notice-new-video',
            ];
            const userTypes = ['notice-follower', 'connection-accept'];
            if (postTypes.includes(msg.type)) {
              console.log('Post', msg.type, msg.id);
              this.$router.push({ name: 'PostDetail', params: { id: msg.id } });
            } else if (userTypes.includes(msg.type)) {
              console.log('User', msg.type, msg.id);
              this.$router.push({ name: 'Profile', params: { id: msg.id } });
            } else if (msg.type === 'message') {
              console.log('Conversation', msg.id);
              this.$router.push({ name: 'Messages' });
            } else if (msg.type === 'notice-new-announcement') {
              this.$router.push({
                name: 'Announcement',
                params: { id: msg.id },
              });
            }
          }, console.error);
        } else {
          console.log('Not logged in');
        }
      } catch (err) {
        console.error('Push Token Error', err);
      }

      try {
        const dynamicLinkData =
          await window.cordova.plugins.firebase.dynamiclinks.getDynamicLink();
        if (dynamicLinkData) {
          this.navigate(dynamicLinkData);
        } else {
          console.log('No Dynamic Link');
        }
        cordova.plugins.firebase.dynamiclinks.onDynamicLink(
          this.navigate,
          console.error
        );
      } catch (err) {
        console.log('Dynamic Link Error', err);
      }

      try {
        if (window.cordova && window.cordova.openwith) {
          console.log('Init Open With');
          cordova.openwith.init(console.log, console.error);
          cordova.openwith.addHandler(async (intent) => {
            console.log('Intent', intent);
            console.log('Items', intent.items);
            const item = intent.items[0];
            if (item.type == 'url' || item.type == 'public.url') {
              console.log('URL', item.data);
              $bus.$emit('show-post-composer-modal', { link: item.data });
            } else if (
              item.type.includes('image/') ||
              item.type == 'public.image'
            ) {
              console.log('File', item.type);
              console.log('File URL', item.fileUrl);
              const localBlob = await this.getLocalUrlAsBlob(
                item.fileUrl || item.data
              );
              $bus.$emit('show-post-composer-modal', { images: [localBlob] });
            } else if (item.type.includes('video/')) {
              console.log('File', item.type);
              console.log('File URL', item.fileUrl);
              const localBlob = await this.getLocalUrlAsBlob(
                item.fileUrl || item.data
              );
              $bus.$emit('show-post-composer-modal', { video: localBlob });
            } else if (
              item.type == 'public/text' ||
              item.type == 'public/plain-text' ||
              item.type == 'text/plain'
            ) {
              let link = null;
              try {
                const links = linkify.find(item.data);
                if (links[0]) {
                  link = links[0].href;
                }
              } catch (err) {}
              $bus.$emit('show-post-composer-modal', {
                bodyText: item.data,
                link,
              });
            } else {
              alert(`Unknown type:${item.type}`);
              if (item.data && item.data.length < 100) {
                alert(item.data);
              }
            }
          });
        }
      } catch (err) {
        console.log('Side Link Error', err);
      }
      try {
        document.addEventListener(
          'backbutton',
          (e) => {
            e.preventDefault();
          },
          false
        );
      } catch (err) {}
    });
  },
  methods: {
    checkTabs() {
      const meta = this.$router.currentRoute.meta;
      this.showTabs = !meta.hideTabs;
      this.hideTabShadow = meta.hideTabShadow
        ? { 'box-shadow': 'none', 'border-top': 'solid 1px var(--cs-gray-02)' }
        : {};
    },
    async checkScrollTop(path) {
      if (
        path === this.$route.path ||
        path === this.$route.redirectedFrom ||
        `${path}/home` === this.$route.path
      ) {
        $bus.$emit('ch-tabs-scroll-top', { route: this.$route });
      }
    },
    async openInBrowser(path) {
      $browser.open(path);
    },
    navigate(dynamicLinkData) {
      console.log('Dynamic Link', dynamicLinkData);
      const parts = dynamicLinkData.deepLink.split('/');
      const id = parts.pop();
      const type = parts.pop();
      console.log('ID', id);
      console.log('Type', type);
      // type: [group, channel, user, video, post];
      const path = {
        groups: 'GroupDetail',
        channels: 'VideoProfile',
        users: 'Profile',
        video: 'VideoDetail',
        posts: 'PostDetail',
        marketplace: 'MarketplaceHome',
      };
      const routeName = path[type];
      console.log('Route Name', routeName);
      this.$router.push({ name: routeName, params: { id } });
    },
    getLocalUrlAsBlob(localUrl) {
      return new Promise((resolve, reject) => {
        resolveLocalFileSystemURL(
          localUrl,
          (fileEntry) => {
            fileEntry.file((file) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (ev) => {
                const blob = new Blob([fileReader.result], { type: file.type });
                blob.dataurl = URL.createObjectURL(blob);
                blob.filename = 'Untitled';
                blob.lastModifiedDate = new Date();
                resolve(blob);
              };
            }, console.error);
          },
          console.error
        );
      });
    },
  },
};
</script>

<style scoped>
ion-tab-button {
  color: var(--cs-gray-05);
}
ion-tab-button.tab-selected {
  color: var(--cs-primary-base);
}
ion-tab-button i {
  font-size: 3em;
}
ion-tab-button span {
  font-weight: var(--cs-font-weight-bold);
  font-size: 12px;
}
ion-tab-bar {
  --ion-color-primary: var(--cs-primary-base);
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
  --border: none;
  height: 60px;
}
</style>
