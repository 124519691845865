<template>
  <div>
    <i
      class="cs-icons-options-filled post-card-header-menu"
      @click="showMenu = true"
    ></i>
    <app-action-sheet :open="showMenu" @close="showMenu = false">
      <template v-if="userIsAuthor || userIsModerator">
        <app-action-sheet-button v-if="canPinPost" @click="togglePin()">
          <i class="cs-icons-lock"></i>
          {{ post.isPinned ? 'Unpin post' : 'Pin post' }}
        </app-action-sheet-button>
        <app-action-sheet-button v-if="userIsAuthor" @click="editPost">
          <i class="cs-icons-edit"></i>
          Edit Post
        </app-action-sheet-button>
        <app-action-sheet-button @click="showConfirmDelete = true">
          <i class="cs-icons-delete"></i>
          Delete Post
        </app-action-sheet-button>
      </template>

      <template v-if="!userIsAuthor">
        <app-action-sheet-button @click="report('post')"
          >Report this Post</app-action-sheet-button
        >
        <app-action-sheet-button @click="report('user')"
          >Report
          <app-username :style="{ marginLeft: '4px' }" :user="post.user"
        /></app-action-sheet-button>
        <app-action-sheet-button @click="blockAuthor()"
          >Block <app-username :style="{ marginLeft: '4px' }" :user="post.user"
        /></app-action-sheet-button>
      </template>

      <app-action-sheet-button v-if="isSuperAdmin" @click="deletePost()"
        >[SA] Delete Post</app-action-sheet-button
      >
    </app-action-sheet>

    <app-popup
      title="Delete Post"
      message="Are you sure you want to delete this post?  This can't be undone."
      type="confirm"
      variant="danger"
      ok-label="Delete"
      :show="showConfirmDelete"
      @ok="deletePost()"
      @close="showConfirmDelete = false"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppPopup from '@/components/general/Popup.vue';
import AppUsername from '@/components/general/Username.vue';
import DeletePost from '@/gql/posts/DeletePost.gql';
import $popups from '@/services/popups';
import $posts from '@/api/posts';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
    AppPopup,
    AppUsername,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      showConfirmDelete: false,
    };
  },
  computed: {
    userIsAuthor() {
      const userId = $auth.getUserId();
      return userId === this.post.user.id;
    },
    userIsModerator() {
      return this.canDelete;
    },
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    canPinPost() {
      return (
        this.post.group &&
        this.post.group.myMembership &&
        (this.post.group.myMembership.role === 'admin' ||
          this.post.group.myMembership.role === 'moderator')
      );
    },
  },
  methods: {
    // Go to author's profile
    trigger(name) {
      $bus.$emit(name, this.post);
    },
    editPost() {
      const data = {
        postData: this.post,
      };
      $bus.$emit('show-post-composer-modal', data);
    },
    async deletePost() {
      await this.$apollo.mutate({
        mutation: DeletePost,
        variables: {
          postId: this.post.id,
        },
        optimisticResponse: {},
        update: (cache) => {
          const normalizedId = cache.identify(this.post);
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    report(reportType) {
      const data = {
        reportType,
      };
      if (reportType === 'user') data.report = this.post.user;
      if (reportType === 'post') data.report = this.post;
      $bus.$emit('show-report-action', data);
    },
    blockAuthor() {
      $bus.$emit('show-block-user', this.post.user);
    },
    async togglePin() {
      const message = this.post.isPinned
        ? 'Are you sure you want to unpin the post?'
        : 'Are you sure you want to pin the post on the top?';
      const shouldTogglePost = await $popups.confirm({
        title: 'Please Confirm',
        message,
        okLabel: 'Yes',
      });

      if (shouldTogglePost) {
        await $posts.togglePostPin(this.post);
        this.$emit('pinned');
      }
    },
  },
};
</script>

<style scoped>
.post-card-header-menu {
  font-size: 16px; /* CloutHub requested */
}
</style>
