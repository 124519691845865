import $apollo from './apollo';
import $bus from './bus';
import SignUploadComplete from '@/gql/general/SignUploadComplete.gql';

const forumBucket = 'forum-clouthub';
const profileBucket = 'profile-clouthub';

const _upload = async (file, bucketType, progressCallback, fileIndex) => {
  // if (bucketType === 'profile') bucketType = profileBucket;
  bucketType = bucketType || 'forum';
  const bucket = (bucketType === 'forum') ? forumBucket : profileBucket;
  const resp = await $apollo.query({
    query: SignUploadComplete,
    fetchPolicy: 'network-only',
    variables: {
      bucket: bucket,
      type: file.type,
      r: Date.now() + fileIndex,
    },
  });
  const uploadInfo = resp.data.sign_upload_complete;
  console.log('Upload Info', uploadInfo);
  await new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('PUT', uploadInfo.upload_url);
    request.upload.addEventListener('progress', (progress) => {
      let percentage = Math.round(progress.loaded / progress.total * 100);
      progressCallback(percentage, fileIndex);
    });
    request.addEventListener('load', (e) => {
      if (request.status == 200) {
        resolve();
      } else {
        reject();
      }
    });
    request.send(file);
  });
  return uploadInfo.download_url;
};

const uploadFile = async(file, progressCallback, bucketType) => {
  const progressListener = (percentage) => {
    $bus.$emit('aws-upload-progress', {type: file.type, value:percentage});
    progressCallback && progressCallback(percentage);
  }
  const url = await _upload(file, bucketType, progressListener, 0);
  return url;
};

const uploadDataURL = async (dataURL, filename, progressCallback, bucketType) => {
  const blob = await fetch(dataURL).then(r => r.blob());
  const file = new File([blob], filename, {
    lastModified: Date.now(),
    type: blob.type,
  });
  return uploadFile(file, progressCallback, bucketType);
}

const bulkUpload = async (files, progressCallback, bucketType) => {
  const progresses = files.map(f => 0);
  const progressListener = (percentage, index) => {
    progresses[index] = percentage;
    let totalProgress = 0;
    progresses.forEach(prog => {
      totalProgress += prog/progresses.length;
    });
    totalProgress = Math.round(totalProgress);
    const fileType = files[0] ? files[0].type : null;
    $bus.$emit('aws-upload-progress', {type: fileType, value:totalProgress, multiple: files.length > 1});
    progressCallback && progressCallback(totalProgress);
  }
  const urls = await Promise.all(Object.values(files).map((file, index) => _upload(file, bucketType, progressListener, index)));
  return urls;
};

export default {
  uploadFile,
  uploadDataURL,
  bulkUpload,
};
