<template>
  <div class="ch-profile__card">
    <app-user-list v-if="people" :users="filteredPeople"></app-user-list>
    <app-infinite-offset :query="$apollo.queries.people" />
  </div>
</template>

<script>
import ListFriends from '@/gql/connections/ListFriends.gql';
import AppUserList from '@/components/general/UserList.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    AppUserList,
    AppInfiniteOffset,
  },
  apollo: {
    people: {
      query: ListFriends,
    },
  },
  computed: {
    filteredPeople() {
      return this.people.filter((person) => !person.myRelationship.isBlocked);
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.people.refetch();
      return true;
    },
  },
};
</script>
