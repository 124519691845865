<template>
  <app-modal
    v-bind="$attrs"
    :disable-action="saving || disableAction"
    :with-closing-alert="withClosingAlert"
    @close="close"
    @back="back"
    @action="$emit('action', $event)"
  >
    <slot />
    <app-upload-progress v-if="saving" class="ch-form-modal__upload-progress" />
  </app-modal>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import AppUploadProgress from '@/components/general/UploadProgress.vue';

export default {
  components: {
    AppModal,
    AppUploadProgress,
  },
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    withClosingAlert: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async close() {
      // if (this.saving) {
      //   const confirmClose = await $popups.confirm({
      //     title: 'Hold On!',
      //     message:
      //       'Saving in progress - if you close now you may lose your changes. Are you sure you wish to close?',
      //     okLabel: 'Close',
      //     variant: 'danger',
      //   });
      //   if (!confirmClose) return;
      // }
      this.$emit('close');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped>
/* Fixed position is required to prevent scrolling the progress indicator */
.ch-form-modal__upload-progress {
  position: fixed;
  top: calc(env(safe-area-inset-top) + 56px);
}
</style>
