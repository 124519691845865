<template>
  <div class="ch-upload-btns">
    <cs-button
      :disabled="isDisabled || !canImages"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attachImage"
    >
      <i class="ch-icons-add-media mr-2" />
      Photo
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canVideo"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attachVideo"
    >
      <i class="cs-icons-video-file-filled"></i>
      Video
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canImages"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="openGIFsModal"
    >
      <i class="ch-icons-gif cs-upload-icons"></i>
      GIF
    </cs-button>
    <cs-button
      :disabled="isDisabled || !canDocument"
      variant="default"
      size="small"
      corners="round"
      class="ch-upload-btns"
      @click="attachFile"
    >
      <i class="cs-icons-doc cs-upload-icons"></i>
      PDF
    </cs-button>
    <app-action-sheet :open="showPhotoOptions" @close="showPhotoOptions = false">
      <app-action-sheet-button @click="takePhoto()">Take Photo</app-action-sheet-button>
      <app-action-sheet-button @click="selectFromAlbums()"
        >Select from Albums</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $utils from '@/services/utils';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPhotoOptions: false,
    };
  },
  computed: {
    hasImages() {
      return this.value.images && this.value.images.length > 0;
    },
    hasVideo() {
      return !!this.value.video;
    },
    hasDocument() {
      return !!this.value.document;
    },
    canImages() {
      return !this.hasVideo && !this.hasDocument;
    },
    canVideo() {
      return !this.hasImages && !this.hasDocument;
    },
    canDocument() {
      return !this.hasImages && !this.hasVideo;
    },
    isDisabled() {
      return this.disabled;
    },
  },
  methods: {
    async attachImage() {
      if (window.cordova) {
        this.showPhotoOptions = true;
      } else {
        this.selectFromAlbums();
      }
    },
    async takePhoto() {
      const file = await $utils.getCordovaMedia(
        window.Camera.PictureSourceType.CAMERA,
        window.Camera.MediaType.PICTURE
      );
      file.dataurl = URL.createObjectURL(file);
      const images = this.value.images || [];
      this.$emit('input', { ...this.value, images: [...images, file] });
    },
    async selectFromAlbums() {
      const files = await $utils.selectFiles('image/*');
      files.forEach((file) => {
        file.dataurl = URL.createObjectURL(file);
      });
      const images = this.value.images || [];
      this.$emit('input', { ...this.value, images: [...images, ...files] });
    },
    async attachVideo() {
      // if (window.cordova) {
      //   file = await $utils.getCordovaMedia(window.Camera.PictureSourceType.CAMERA, window.Camera.MediaType.VIDEO);
      // } else {
      // }
      const file = await $utils.selectFile('video/*');
      file.dataurl = URL.createObjectURL(file);
      // file.thumbnail = await $utils.generateVideoThumbnail(file);
      this.$emit('input', { ...this.value, video: file });
    },
    async attachFile() {
      const type = 'application/pdf';
      const file = await $utils.selectFile(type);
      file.dataurl = URL.createObjectURL(file);
      this.$emit('input', { ...this.value, document: file });
    },

    // GIFS
    openGIFsModal() {
      $bus.$emit('show-select-gif-modal');
      $bus.$once('gif-modal-select', this.selectedGIF);
    },
    selectedGIF(gif) {
      const gifImage = {
        url: gif.url,
        name: 'Embedded Gif',
        type: 'image',
        contentType: 'image/gif',
      };
      const images = this.value.images || [];
      this.$emit('input', { ...this.value, images: [...images, gifImage] });
    },
  },
};
</script>

<style scoped>
.ch-upload-btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
  --cs-button-color: var(--cs-gray-01) !important;
  --cs-button-text-color: var(--cs-gray-05) !important;
}
.cs-upload-icons {
  margin-right: 5px;
}
</style>
