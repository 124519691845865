<template>
  <div class="header-control-bar">
    <div class="header-control-bar__row">
      <template v-if="showQueueButtons">
        <cs-button
          v-if="canLeaveQueue"
          class="header-control-bar__hand-btn"
          size="small"
          @click="setRole('VIEWER')"
        >
          Leave Queue
        </cs-button>
        <cs-button
          v-if="canRiseHand"
          class="header-control-bar__hand-btn"
          size="small"
          @click="setRole('QUEUE')"
        >
          ✋Raise Hand
        </cs-button>
      </template>
      <app-meeting-control-button @click="openShareModal">
        <i class="header-control-bar__icon ch-icons-share" /> Share
      </app-meeting-control-button>
      <app-meeting-control-button @click="openInviteModal">
        <i class="header-control-bar__icon ch-icons-users" /> Invite
      </app-meeting-control-button>
      <app-meeting-control-button @click="leave">
        <i class="header-control-bar__icon ch-icons-logout" />
        {{ leaveButtonText }}
      </app-meeting-control-button>
      <app-meeting-control-button
        v-if="canOpenSettings"
        @click="openSettingsModal"
      >
        <i class="header-control-bar__icon cs-icons-settings" />
      </app-meeting-control-button>
    </div>
    <app-meeting-share-sheet
      :meeting="meeting"
      :show-modal="isShareModalOpen"
      @close="closeShareModal"
    />
    <app-invite-modal
      :meeting="meeting"
      :show="isInviteModalOpen"
      @close="closeInviteModal"
    />
    <app-meeting-settings-menu
      :show-meeting-options="isSettingsModalOpen"
      :meeting="meeting"
      :can-open-settings="canOpenSettings"
      @close="closeSettingsModal"
    />
  </div>
</template>
<script>
import $auth from '@/services/auth';
import $socket from '@/services/socket';
import $browser from '@/services/browser';

import AppMeetingControlButton from '@/components/meetings/buttons/MeetingControlButton.vue';
import AppMeetingShareSheet from '@/components/meetings/MeetingShareSheet.vue';
import AppInviteModal from '@/components/meetings/InviteModal.vue';
import AppMeetingSettingsMenu from '@/components/meetings/MeetingSettingsMenu.vue';

import LeaveMeeting from '@/gql/meetings/LeaveMeeting.gql';
import SetMeetingMemberRole from '@/gql/meetings/SetMeetingMemberRole.gql';

export default {
  components: {
    AppMeetingControlButton,
    AppMeetingShareSheet,
    AppInviteModal,
    AppMeetingSettingsMenu,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isShareModalOpen: false,
    isInviteModalOpen: false,
    isSettingsModalOpen: false,
  }),
  computed: {
    role() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    canOpenSettings() {
      return (
        this.role === 'ADMIN' || this.role === 'MODERATOR' || $auth.isAdmin()
      );
    },
    isQueueAble() {
      return this.meeting.status === 'LIVE' && this.meeting.enableQueue;
    },
    isAudience() {
      return this.role === 'VIEWER' || this.role === 'ATTENDING' || !this.role;
    },
    isInQueue() {
      return this.role === 'QUEUE';
    },
    showQueueButtons() {
      return (this.isInQueue || this.isAudience) && this.isQueueAble;
    },
    canRiseHand() {
      return this.isAudience && this.isQueueAble;
    },
    canLeaveQueue() {
      return this.role === 'QUEUE';
    },
    leaveButtonText() {
      return this.meeting.parentMeeting ? 'To Event' : 'Leave';
    },
  },
  methods: {
    openShareModal() {
      this.isShareModalOpen = true;
    },
    closeShareModal() {
      this.isShareModalOpen = false;
    },
    openInviteModal() {
      this.isInviteModalOpen = true;
    },
    closeInviteModal() {
      this.isInviteModalOpen = false;
    },
    openSettingsModal() {
      this.isSettingsModalOpen = true;
    },
    closeSettingsModal() {
      this.isSettingsModalOpen = false;
    },
    async exitRoom() {
      await this.$router.push({
        name: 'MeetingDetail',
        params: this.meeting.parentMeeting,
      });
    },
    async leaveMeeting() {
      window.close();
      alert('Please close this window to leave this event');
    },
    async leave() {
      await this.$apollo.mutate({
        mutation: LeaveMeeting,
        variables: {
          meetingId: this.meeting.id,
        },
      });
      await $socket.leaveRoom('meeting', this.meeting.id);
      if (this.meeting.parentMeeting) {
        await this.exitRoom();
      } else {
        await this.leaveMeeting();
      }
    },
    async setRole(role, userId) {
      userId = userId || $auth.getUserId();
      await this.$apollo.mutate({
        mutation: SetMeetingMemberRole,
        variables: {
          meetingId: this.meeting.id,
          userId,
          role,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-members-modified');
    },
  },
};
</script>
<style scoped>
.header-control-bar__row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 11px 0;
}
.header-control-bar__icon {
  font-size: 20px;
}
.header-control-bar__hand-btn {
  --cs-button-padding: 6px 10px;
}
</style>
