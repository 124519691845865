<template>
  <div>
    <div>
    <div class="search-header">Channels</div>
      <div v-if="users">
        <cs-empty-state
          v-if="showEmptyStateChannel"
          :title="emptyStateTitleChannel"
          :description="emptyStateDescription"
        />
        <div v-else>
          <app-video-channel-item v-for="user in users" :key="user.id" :user="user" />
          <cs-button
            v-if="mayHaveMoreChannel && !$apollo.queries.users.loading"
            block
            fill="outline"
            size="small"
            @click="loadMoreChannel"
          >
            More Channels
          </cs-button>
        </div>
      </div>
      <app-spinner v-if="$apollo.queries.users.loading" />
    </div>
    <div class="search-header">Videos</div>
    <div v-if="videos">
      <cs-empty-state
        v-if="showEmptyState"
        title="No matching videos"
        class="cs-textstyle-detail-heading"
      />
      <div v-else>
        <app-video-item
          v-for="video in searchedVideos"
          :key="video.id"
          :video="video"
          direction="column"
          class="search-video-item"
        />
        <cs-button
          v-if="mayHaveMore && !$apollo.queries.videos.loading"
          block
          fill="outline"
          size="small"
          @click="loadMore"
        >
          More Videos
        </cs-button>
      </div>
    </div>
    <app-spinner v-if="$apollo.queries.videos.loading" />
  </div>
</template>

<script>
import AppVideoItem from '@/components/videos/VideoItem.vue';
import AppVideoChannelItem from '@/components/videos/VideoChannelItem.vue';
import AppSpinner from '@/components/general/Spinner.vue';

import VideoSearch from '@/gql/search/VideoSearch.gql';
import ChannelSearch from '@/gql/search/ChannelSearch.gql';

export default {
  components: {
    AppVideoItem,
    AppSpinner,
    AppVideoChannelItem
  },
  props: {
    search: String,
  },
  apollo: {
    videos: {
      query: VideoSearch,
      variables() {
        return {
          query: this.search,
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
    users: {
      query: ChannelSearch,
      variables() {
        return {
          query: this.search,
          limit: 3
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      mayHaveMore: true,
      mayHaveMoreChannel: true
    };
  },
  computed: {
    
    searchedVideos() {
      const safeVideos = this.videos.filter((post) => post.safe && post.user);
      if (safeVideos.length) return safeVideos;
      this.loadMore();
    },
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyStateChannel() {
      if(this.users && !this.users.length) return true
      return false
    },
    showEmptyState() {
      if (this.videos && !this.videos.length) return true;
      return false;
    },
  },
  methods: {
    loadMoreChannel() {
      const offset = this.users.length;
      this.$apollo.queries.users.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMoreChannel = !!fetchMoreResult.users.length;
          return {
            users: [...previousResult.users, ...fetchMoreResult.users],
          };
        },
      });
    },
    loadMore() {
      const offset = this.videos.length;
      this.$apollo.queries.videos.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMore = !!fetchMoreResult.videos.length;
          return {
            videos: [...previousResult.videos, ...fetchMoreResult.videos],
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
