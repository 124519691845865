import Vue from 'vue';
import { IonicVueRouter } from '@ionic/vue';

import $auth from '@/services/auth';
import $bus from '@/services/bus';
// import $analytics from '@/services/analytics';
import $amplitude from '@/services/amplitude';

// Importing Views

// Login/Register/Onboarding/Report problem,
import OnboardingWelcome from '@/views/onboarding/Welcome.vue';
import Login from '@/views/onboarding/Login.vue';
import Start from '@/views/onboarding/Start.vue';
import ReportProblem from '@/views/onboarding/ReportProblem.vue';
import SetNewPassword from '@/views/onboarding/SetNewPassword.vue';
import ResetPassword from '@/views/onboarding/ResetPassword.vue';
import Signup from '@/views/onboarding/Signup.vue';

// Main Views
import Tabs from '@/views/Tabs.vue';

// Events
import MeetingHome from '@/views/meetings/MeetingHome.vue';
import MeetingDetail from '@/views/meetings/NewMeetingDetail.vue';
import MeetingList from '@/views/meetings/MeetingList.vue';
import MeetingCategory from '@/views/meetings/MeetingCategory.vue';
import MeetingSearch from '@/views/meetings/MeetingSearch.vue';

// Search
import Search from '@/views/search/Search.vue';
import universalSearch from '@/views/search/universalSearch.vue';

// Forum (Post)
import Home from '@/views/home/Home.vue';
import ForumSearch from '@/views/home/ForumSearch.vue';
import PostDetail from '@/views/home/PostDetail.vue';
// Post list based on Tags
import Tag from '@/views/home/Tag.vue';

// Public View
import Public from '@/views/Public.vue';
import PublicHome from '@/views/public/PublicHome.vue';
// Public Video
import PublicVideoHome from '@/views/public/PublicVideoHome.vue';
import PublicVideosSearch from '@/views/public/PublicVideosSearch.vue';
import PublicVideoDetail from '@/views/public/PublicVideoDetail.vue';
import PublicVideoProfile from '@/views/public/PublicVideoProfile.vue';
import PublicVideoCategory from '@/views/public/PublicVideoCategory.vue';
import PublicVideoList from '@/views/public/PublicVideoList.vue';
import PublicVideoChannelList from '@/views/public/PublicVideoChannelList.vue';

//  News
import NewsHome from '@/views/news/NewsHome.vue';
import NewsDetail from '@/views/news/NewsDetail.vue';
import NewsPublisherRecommended from '@/views/news/NewsPublisherRecommended.vue';
import NewsProfile from '@/views/news/NewsProfile.vue';

// import NewsCategory from '@/views/news/NewsCategory.vue';
// import NewsSearch from '@/views/news/NewsSearch.vue';
// import NewsList from '@/views/news/NewsList.vue';

// GROUPS
import ForMe from '@/views/groups/ForMe.vue';
import GroupHome from '@/views/groups/GroupHome.vue';
import GroupDetailView from '@/views/groups/GroupDetailView.vue';
import GroupMembersView from '@/views/groups/GroupMembersView.vue';
import RecommendedGroupsList from '@/views/groups/RecommendedGroupsList.vue';
import MyGroupsList from '@/views/groups/MyGroupsList.vue';
import FindGroup from '@/views/groups/FindGroup.vue';
import GroupsCategory from '@/views/groups/GroupsCategory.vue';

// Videos
import VideoHome from '@/views/videos/VideoHome.vue';
import VideosSearch from '@/views/videos/VideosSearch.vue';
import VideoDetail from '@/views/videos/VideoDetail.vue';
import VideoProfile from '@/views/videos/VideoProfile.vue';
import VideoCategory from '@/views/videos/VideoCategory.vue';
import VideoList from '@/views/videos/VideoList.vue';
import VideoChannelList from '@/views/videos/VideoChannelList.vue';

//Marketplace
import MarketplaceHome from '@/views/marketplace/MarketplaceHome.vue';

// Menu
import Menu from '@/views/menu/Menu.vue';
import Notifications from '@/views/menu/Notifications.vue';
import Announcement from '@/views/menu/Announcement.vue';
import Requests from '@/views/menu/Requests.vue';
import BookmarkPostList from '@/components/post/lists/BookmarkedPostList.vue';

// Menu - Message
import Messages from '@/views/conversations/Messages.vue';
import ConversationDetail from '@/views/conversations/ConversationDetail.vue';

// Menu - Profile
import Profile from '@/views/connections/Profile.vue';
import Friends from '@/views/connections/Friends.vue';
import ConnectionSearch from '@/views/connections/ConnectionSearch.vue';
import ConnectionsContainer from '@/views/connections/ConnectionsContainer.vue';
import UserList from '@/views/connections/UserList.vue';
import BadgeUserList from '@/views/connections/BadgeUserList.vue';
// import UserSearch from '@/views/connections/UserSearch.vue';

// Menu - Settings
import Settings from '@/views/settings/Settings.vue';

// Vue Zoomer
import VueZoomer from 'vue-zoomer';
import jsonwebtoken from 'jsonwebtoken';

Vue.use(VueZoomer);

Vue.use(IonicVueRouter, { animated: true });
/*
let video_detail_route = any;
if (localStorage.access_token) {
  video_detail_route = [{
    path: 'videos/videos/:id',
    name: 'VideoDetail',
    components: {
      videos: VideoDetail,
    },
    meta: {
      hideTabs: true,
    }
  }];
} else {
  video_detail_route = [{
    path: 'videos/videos/:id',
    name: 'VideoDetail',
    redirect: '/public/videos/videos/:id',
  }];
}
*/
let routes = [
  {
    path: '/welcome',
    name: 'OnboardingWelcome',
    component: OnboardingWelcome,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reportproblem',
    name: 'ReportProblem',
    component: ReportProblem,
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/setnewpassword/:token',
    name: 'SetNewPassword',
    component: SetNewPassword,
  },
  {
    path: '/public',
    name: 'Public',
    component: Public,
    children: [
      {
        path: 'home',
        name: 'PublicHome',
        components: {
          home: PublicHome,
        },
      },
      {
        path: 'videos',
        name: 'PublicVideoHome',
        redirect: 'videos/0',
      },
      {
        path: 'videos/:view',
        name: 'PublicVideoHomeTab',
        components: {
          videos: PublicVideoHome,
        },
      },
      {
        path: 'videos/search',
        name: 'PublicVideosSearch',
        components: {
          videos: PublicVideosSearch,
        },
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: 'videos/videos/:id',
        name: 'PublicVideoDetail',
        components: {
          videos: PublicVideoDetail,
        },
        meta: {
          hideTabs: true,
        },
      },
      {
        path: 'videos/category/:category',
        name: 'PublicVideoCategory',
        components: {
          videos: PublicVideoCategory,
        },
      },
      {
        path: 'videos/list/:view',
        name: 'PublicVideoList',
        components: {
          videos: PublicVideoList,
        },
      },
      {
        path: 'videos/channel/:id',
        name: 'PublicVideoProfile',
        components: {
          videos: PublicVideoProfile,
        },
      },
      {
        path: 'videos/channels/:view',
        name: 'PublicVideoChannelList',
        components: {
          videos: PublicVideoChannelList,
        },
      },
    ],
  },
  {
    path: '/tabs',
    name: 'tabs',
    component: Tabs,
    children: [
      {
        path: 'home',
        name: 'Home',
        components: {
          home: Home,
        },
      },
      {
        path: 'home/announcement/:id',
        name: 'Announcement',
        components: {
          home: Announcement,
        },
      },
      {
        path: 'home/search',
        name: 'ForumSearch',
        components: {
          home: ForumSearch,
        },
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: 'home/search/:index',
        name: 'Search',
        components: {
          home: Search,
        },
      },
      {
        path: 'home/universalsearch',
        name: 'universalsearch',
        components: {
          home: universalSearch,
        },
      },
      {
        path: 'home/posts/:id',
        name: 'PostDetail',
        components: {
          home: PostDetail,
        },
      },
      {
        path: 'home/tag/:tag',
        name: 'Tag',
        components: {
          home: Tag,
        },
        props: true,
      },
      {
        path: 'news',
        name: 'News',
        redirect: 'news/home',
      },
      {
        path: 'news/home',
        name: 'NewsHome',
        components: {
          news: NewsHome,
        },
      },
      {
        path: 'news/publishers/recommended',
        name: 'NewsPublisherRecommended',
        components: {
          news: NewsPublisherRecommended,
        },
      },
      {
        path: 'news/:id',
        name: 'NewsDetail',
        components: {
          news: NewsDetail,
        },
      },
      {
        path: 'news/user/:id/news',
        name: 'NewsProfile',
        components: {
          news: NewsProfile,
        },
      },
      {
        name: 'Groups',
        path: 'groups',
        redirect: 'groups/my',
      },
      {
        path: 'groups',
        name: 'GroupHome',
        components: {
          groups: GroupHome,
        },
        children: [
          {
            path: 'my',
            name: 'MyGroupsList',
            component: MyGroupsList,
          },
          {
            path: 'for-me',
            name: 'ForMe',
            component: ForMe,
          },
          {
            path: 'recommended',
            name: 'RecommendedGroupsList',
            component: RecommendedGroupsList,
          },
          {
            path: 'find',
            name: 'FindGroup',
            component: FindGroup,
          },
        ],
      },
      {
        path: 'groups/category/:category',
        name: 'GroupsCategory',
        components: {
          groups: GroupsCategory,
        },
      },
      {
        path: 'groups/:id',
        name: 'GroupDetail',
        components: {
          groups: GroupDetailView,
        },
      },
      {
        path: 'groups/:id/members',
        name: 'GroupMembersView',
        components: {
          groups: GroupMembersView,
        },
      },
      {
        path: 'connections',
        components: {
          connections: ConnectionsContainer,
        },
        name: 'connections',
      },
      {
        path: 'connections/search',
        components: {
          connections: ConnectionSearch,
        },
        name: 'ConnectionSearch',
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: 'connections/badge/:badge',
        components: {
          connections: BadgeUserList,
        },
        name: 'BadgeUserList',
      },
      {
        path: 'connections/users/:view',
        components: {
          connections: UserList,
        },
        name: 'UserList',
      },
      {
        path: 'connections/profile/:id',
        name: 'Profile',
        components: {
          connections: Profile,
        },
      },
      {
        path: 'connections/users/:username',
        name: 'UProfile',
        components: {
          connections: Profile,
        },
      },
      {
        path: 'connections/friends/:id',
        name: 'Friends',
        components: {
          connections: Friends,
        },
      },
      {
        path: 'videos',
        name: 'VideoHome',
        redirect: 'videos/0',
      },
      {
        path: 'videos/:view',
        name: 'VideoHomeTab',
        components: {
          videos: VideoHome,
        },
      },
      {
        path: 'videos/search',
        name: 'VideosSearch',
        components: {
          videos: VideosSearch,
        },
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: 'videos/videos/:id',
        name: 'VideoDetail',
        components: {
          videos: VideoDetail,
        },
        meta: {
          hideTabs: true,
        }
      },
      {
        path: 'videos/category/:category',
        name: 'VideoCategory',
        components: {
          videos: VideoCategory,
        },
      },
      {
        path: 'videos/list/:view',
        name: 'VideoList',
        components: {
          videos: VideoList,
        },
      },
      {
        path: 'videos/channel/:id',
        name: 'VideoProfile',
        components: {
          videos: VideoProfile,
        },
      },
      {
        path: 'videos/channels/:view',
        name: 'VideoChannelList',
        components: {
          videos: VideoChannelList,
        },
      },
      {
        path: 'marketplace',
        name: 'MarketplaceHome',
        components: {
          marketplace: MarketplaceHome,
        },
      },
      {
        path: 'menu',
        name: 'Menu',
        components: {
          menu: Menu,
        },
      },
      {
        path: 'menu/notifications',
        name: 'Notifications',
        components: {
          menu: Notifications,
        },
      },
      {
        path: 'menu/requests',
        name: 'Requests',
        components: {
          menu: Requests,
        },
      },
      {
        path: 'menu/bookmarkedposts',
        components: {
          menu: BookmarkPostList,
        },
        name: 'BookmarkPostList',
      },
      {
        path: 'menu/messages',
        name: 'Messages',
        components: {
          menu: Messages,
        },
      },
      {
        path: 'menu/messages/conversation/:id',
        name: 'ConversationDetail',
        components: {
          menu: ConversationDetail,
        },
        meta: {
          hideTabShadow: true,
        },
      },
      {
        path: 'menu/settings',
        name: 'Settings',
        components: {
          menu: Settings,
        },
      },
      {
        path: 'meetings',
        name: 'MeetingHome',
        components: {
          meetings: MeetingHome,
        },
      },
      {
        path: 'meetings/search',
        name: 'MeetingSearch',
        components: {
          meetings: MeetingSearch,
        },
        props: (route) => ({ query: route.query.q }),
      },
      {
        path: 'meetings/category/:category',
        name: 'MeetingCategory',
        components: {
          meetings: MeetingCategory,
        },
      },
      {
        path: 'meetings/list/:view',
        name: 'MeetingList',
        components: {
          meetings: MeetingList,
        },
      },
    ],
  },
  {
    path: '/meetings/:id',
    name: 'MeetingDetail',
    component: MeetingDetail,
  },
  {
    path: '/', // Sets the default path for when you load the app
    redirect: '/tabs/home',
  },
];

const router = new IonicVueRouter({ routes });

const unprotectedRoutes = [
  'OnboardingWelcome',
  'Signup',
  'Start',
  'Login',
  'Public',
  'Profile',
  'PublicHome',
  'PublicVideoHome',
  'PublicVideoHomeTab',
  'PublicVideosSearch',
  'PublicVideoDetail',
  'VideoDetail',
  'PublicVideoProfile',
  'PublicVideoCategory',
  'PublicVideoList',
  'PublicVideoChannelList',
  'ResetPassword',
  'SetNewPassword',
  'VerifyOTP',
  'UpdatePassword',
  'ReportProblem',
  'CreateAccount',
  'SetPassword',
  'SetPhone',
  'SetEmail',
  'SetUsername',
  'VerificationCode',
  'EnterCode',
  'MeetingDetail',
];

const exceptionRoutes = ['/tabs/videos/videos',''];

const positions = {};
const lastTabs = {};

function getTabNumber(route, tab) {
  const tabIndex =
    typeof tab !== 'undefined' ? tab : getTabIndex(getComponent(route));
  return `${route.path}-${tabIndex}`;
}

async function trackLeave(route, tab) {
  const content = document.querySelector(`ion-content`);
  if (!content) return;
  const scrollEl = await content.getScrollElement();
  const index = getTabNumber(route, tab);
  positions[index] = scrollEl.scrollTop;
}

async function trackEnter(route, tab) {
  await Vue.nextTick(); // ensure the new DOM exists
  const content = document.querySelector(`ion-content`);

  if (!content) return;

  const scrollEl = await content.getScrollElement();
  const index = getTabNumber(route, tab);
  scrollEl.scrollTop = positions[index];
}

router.beforeEach(async (to, from, next) => {
  await trackLeave(from);
  const toUnprotectedRoute = unprotectedRoutes.includes(to.name);
  if(to.name=='VideoDetail') {
    if (localStorage.access_token) {
      next();
    } else {
      next({redirect: '/public/videos/videos/:id'});
      return;
    }
  }

  if (toUnprotectedRoute) {
    next();
//  } else if (exceptionRoutes.includes(to.path)) {
//    next();
//    return;
  } else if (localStorage.access_token) {
    const decodedToken = jsonwebtoken.decode(localStorage.access_token);

    if (decodedToken.role === 'USER') {
      const isLoggedIn = $auth.isLoggedIn();
      if (!localStorage.lastSeenMessageTime)
        localStorage.lastSeenMessageTime = Date.now();
      if (!localStorage.lastSeenNotificationTime)
        localStorage.lastSeenNotificationTime = Date.now();
      if (!localStorage.lastSeenRequestTime)
        localStorage.lastSeenRequestTime = Date.now();
      if (isLoggedIn) {
        if (to.query.redirect && router.resolve(to.query.redirect).route.name) {
          next(to.query.redirect);
        } else {
          next();
        }
      }
    } else {
      await $auth.logout();
      next({
        name: 'Start',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next({
      name: 'Start',
      query: {
        redirect: to.fullPath,
      },
    });
  }
});

// Code for saving and restoring scroll position
router.afterEach(async (to) => {
  trackEnter(to);
//  $analytics.trackEvent('ScreenView', to.name);
//  gtag('config', 'G-Q0014B3L6M', { page_path: to.path });
  $amplitude.logEvent(`screen_view`, { name: to.name });
});

function getComponent(route) {
  const matchedPage = route.matched.length
    ? route.matched[route.matched.length - 1]
    : false;
  if (!matchedPage) return null;
  const component = Object.values(matchedPage.components)[0];
  if (!component.scrollPositions) component.scrollPositions = {};
  return component;
}

function getTabIndex(component) {
  let tabIndex = 0;
  try {
    const scopeId = component.components?.AppTabs?._scopeId;
    const appTabsEl = document.querySelector(`[${scopeId}]`);
    if (appTabsEl) {
      tabIndex = appTabsEl.getAttribute('data-tab-index') * 1;
    }
  } catch (e) {
    console.log(e);
  }
  return tabIndex || 0;
}

function saveLastTabViewed(path, index) {
  lastTabs[path] = index;
}

async function reload(ionContent) {
  try {
    const scroller = await ionContent.getScrollElement();
    const refresher = ionContent.querySelector('ion-refresher');
    if (!scroller || !refresher) return;
    refresher.classList.add('refresher-active');
    refresher.classList.add('refresher-refreshing');
    scroller.style.transition = 'transform 0.28s';
    scroller.style.transform = 'translateY(60px)';
    refresher.__vue__.$emit('refresh', { target: refresher });
  } catch (e) {
    console.log(e);
  }
}

$bus.$on('ch-tabs-leave', ($event) => {
  const { route, index } = $event;
  trackLeave(route, index);
});

$bus.$on('ch-tabs-enter', ($event) => {
  const { route, index } = $event;
  trackEnter(route, index);
  saveLastTabViewed(route.path, index);
});

$bus.$on('ch-tabs-scroll-top', async ($event) => {
  try {
    const { route } = $event;
    const component = getComponent(route);
    let ionContent = document.querySelector(
      `[${component._scopeId}] ion-content`
    );
    if (!component._scopeId) ionContent = document.querySelector('ion-content');
    const scrollEl = await ionContent.getScrollElement();
    if (scrollEl.scrollTop < 10) {
      reload(ionContent);
    } else {
      ionContent.scrollToTop(300);
    }
  } catch (e) {
    console.log(e);
  }
});

$bus.$on('ch-tabs-mounted', ($event) => {
  try {
    const { route, eventID } = $event;
    const path = route.path;
    if (lastTabs[path]) {
      $bus.$emit(eventID, lastTabs[path]);
    }
  } catch (e) {
    $bus.$emit($event.eventID, null);
  }
});

export default router;
export { unprotectedRoutes };
