<template>
  <div>
    <keep-alive>
      <app-post-list :posts="sortedPosts" list-id="everyonePostList" />
    </keep-alive>
    <app-infinite-simple
      :query="$apollo.queries.posts"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import ListPublicPosts from '@/gql/posts/ListPublicPosts.gql';
import AppPostList from '@/components/post/lists/PostList.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import $auth from '@/services/auth';

export default {
  components: {
    AppPostList,
    AppInfiniteSimple,
  },
  props: {
    pinPost: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  apollo: {
    posts: {
      query: ListPublicPosts,
    },
  },
  data() {
    return {
      pinToRemove: false,
    };
  },
  computed: {
    sortedPosts() {
      if (!this.pinPost) {
        return this.posts;
      }
      const myId = $auth.getUserId();
      const lastOwnPost = this.posts.find((post) => post.user.id === myId);
      if (!lastOwnPost) {
        return this.posts;
      }

      const postList = this.posts.slice();
      const index = postList.indexOf(lastOwnPost);
      postList.splice(index, 1);
      postList.unshift(lastOwnPost);
      return postList;
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.posts.refetch();
      if (this.pinToRemove) {
        this.$emit('removePin');
        this.pinToRemove = false;
      }
      if (this.pinPost) {
        this.pinToRemove = true;
      }
      return true;
    },
  },
};
</script>
