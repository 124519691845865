<template>
  <div class="ch-streaming-details">
    <cs-button
      variant="primary"
      size="small"  
      @click="showDetails"
    > 
      Streaming Details
    </cs-button>
     <app-modal
      v-if="showModal"
      class="detail-modal"
      :show="showModal"
      title="Streaming Details"
      @close="closeModal"
    >
      <div class="cs-textstyle-paragraph">
        Please use the following settings with your preferred live-streaming software:
      </div>
      <cs-input
        label="Stream URL"
        value="rtmp://rtmp.clouthub.com:5222/app"
      ></cs-input>
      <cs-input label="Stream Key" :value="get_meeting_livestream_key"></cs-input>
     </app-modal>
  </div>
</template>

<script>
import GetMeetingLivestreamKey from '@/gql/meetings/GetMeetingLivestreamKey.gql';
import AppModal from '@/components/general/Modal.vue';

export default {
  components: {
    AppModal
  },
  props: {
    meetingId: {
      type: String,
    }
  },
  apollo: {
    get_meeting_livestream_key: {
      query: GetMeetingLivestreamKey,
      variables() {
        return {
          meetingId: this.meetingId
        }
      }
    },
  },
  data() {
    return {
      liveStreamKey: null,
      showModal: false,
    }
  },
  methods: {
    async showDetails() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style scoped>
.ch-streaming-details{
  text-align: center;
  padding: 10px;
}
.detail-modal{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
}
</style>
