<template>
  <cs-button
    @click="share"
    size="small"
    variant="secondary"
    fill="clear"
    class="group-share-button"
  >
    <i class="ch-icons-share group-share-button__icon"></i>
    <span class="cs-textstyle-paragraph-small-bold"> Share </span>
  </cs-button>
</template>

<script>
import $bus from '@/services/bus';

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  methods: {
    share() {
      const link = `/g/${this.group.slug || this.group.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Group',
      });
    },
  },
};
</script>

<style scoped>
.group-share-button {
  margin-right: 10px;
  --cs-button-padding: 0px;
}
.group-share-button__icon {
  color: var(--cs-gray-04);
}
</style>
