<template>
  <div>
    <div class="post-response-summary cs-textstyle-paragraph-small-bold">
      <div class="ch-reactions" @click="trigger('show-reaction-list-modal')">
        <img
          v-for="reaction in userReactions"
          :key="reaction"
          class="emoji"
          :src="'./assets/emojis/' + reaction + '.png'"
          :alt="reaction"
        />
        <span>{{ post.reactionCount }}</span>
        <span v-if="!userReactions || !userReactions.length"
          ><i class="ch-icons-reaction"></i>
        </span>
      </div>
      <span @click="$router.push({ name: 'PostDetail', params: post })">
        {{ post.commentsCount }} <i class="ch-icons-comments"></i>
      </span>
      <span @click="trigger('show-share-list-modal')">
        {{ post.sharedPostCount || 0 }} <i class="cs-icons-share2"></i>
      </span>
    </div>

    <div class="post-response-buttons">
      <cs-action-bar-button
        :icon="myReaction ? '' : 'ch-icons-reaction'"
        style="text-transform: capitalize"
        class="ch-reactions"
        @click="handleEmojiClick"
      >
        <img
          v-if="myReaction"
          class="emoji"
          :src="'./assets/emojis/' + myReaction.value + '.png'"
          :alt="myReaction.name"
        />
        <span>{{ myReaction.name || 'React' }}</span>
      </cs-action-bar-button>
      <cs-action-bar-button icon="ch-icons-comments" @click="commentClick">
        Comment
      </cs-action-bar-button>
      <cs-action-bar-button icon="cs-icons-share2" @click="share()">
        Share
      </cs-action-bar-button>
    </div>
    <app-post-comments @refresh="emitRefresh" :post="post"></app-post-comments>
    <div class="comment-composer">
      <app-post-comment-composer
        ref="commentComposer"
        :parent="post"
        @refresh="emitRefresh"
      ></app-post-comment-composer>
    </div>
    <app-post-reaction
      :event="emojiClickEvent"
      :show="showEmojies"
      :post="post"
      @close="showEmojies = false"
    ></app-post-reaction>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $postsApi from '@/api/posts';
import $reactions from '@/assets/emojis/reactions.json';

import AppPostComments from '@/components/post/response/PostComments.vue';
import AppPostCommentComposer from '@/components/post/composer/PostCommentComposer.vue';
import AppPostReaction from '@/components/post/response/PostReaction.vue';

export default {
  components: {
    AppPostComments,
    AppPostCommentComposer,
    AppPostReaction,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEmojies: false,
      showMsgModal: false,
      emojiClickEvent: null,
      isRemovingReaction: false,
    };
  },
  computed: {
    userReactions() {
      return this.post.reactionTotals
        .filter((data) => data.count > 0)
        .map((reaction) => {
          if (reaction.count < 1) return null;
          const emojiData = $reactions.find(
            (data) =>
              data.value === reaction.type || data.name === reaction.type
          );
          // return emojiData && emojiData.emoji ? emojiData.emoji : null;
          return (emojiData && emojiData.value) || null;
        })
        .filter((emoji) => emoji);
    },
    myReaction() {
      if (this.post.myReaction) {
        const myReactionType = this.post.myReaction.type;
        const myReaction = $reactions.find(
          (reaction) => reaction.value === myReactionType
        );
        // return `${myReaction.emoji} ${myReaction.name}`;
        return myReaction;
      }
      return false;
    },
  },
  methods: {
    async emitRefresh() {
      this.$emit('refresh');
    },
    async handleEmojiClick($event) {
      if (!this.post.myReaction && !this.isRemovingReaction) {
        // NOTE: emojiClickEvent is used to position the reaction popover in the right place
        this.emojiClickEvent = $event;
        this.showEmojies = true;
      } else if (this.post.myReaction) {
        // Added isRemovingReaction to stop user from choosing another reaction when removing reaction is in proress
        this.isRemovingReaction = true;
        await $postsApi.unreactPost(this.post);
        this.isRemovingReaction = false;
      }
    },
    share() {
      const link = `/p/${this.post.slug || this.post.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Post',
        post: this.post,
      });
    },
    commentClick() {
      this.$refs.commentComposer.$el.querySelector('textarea').focus();
      this.$router.push({
        name: 'PostDetail',
        params: { id: this.post.rootPostId },
      });
    },
    trigger(name) {
      $bus.$emit(name, this.post);
    },
  },
};
</script>

<style scoped>
.post-response-summary {
  justify-content: space-between;
  display: flex;
  gap: 20px;
  font-size: 15px;
  color: var(--cs-gray-04);
  border-bottom: solid 1px var(--cs-gray-01);
  padding-top: 5px; /* CloutHub requested */
  padding-bottom: 5px; /* CloutHub requested */
}
.post-response-summary__reactions {
  flex: 1;
}
.post-response-buttons {
  display: flex;
  justify-content: space-around; /* CloutHub requested */
  border-bottom: solid 1px var(--cs-gray-01);
  margin-bottom: 7px; /* CloutHub requested */
  padding-top: 4px; /* CloutHub requested */
  padding-bottom: 4px; /* CloutHub requested */
}
.post-response-summary span {
  cursor: pointer;
}
.share-heading {
  margin-bottom: 10px;
}
.emoji {
  width: 20px;
  height: 20px;
  margin: 2px;
}
.ch-reactions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
</style>
