<template>
  <div class="meeting-invite-button">
    <app-meeting-control-button @click="openInviteModal">
      <i class="meeting-invite-button__icon ch-icons-users" /> Invite
    </app-meeting-control-button>
    <app-invite-modal
      :meeting="meeting"
      :show="isInviteModalOpen"
      @close="closeInviteModal"
    />
  </div>
</template>
<script>
import AppInviteModal from '@/components/meetings/InviteModal.vue';
import AppMeetingControlButton from '@/components/meetings/buttons/MeetingControlButton.vue';

export default {
  components: {
    AppInviteModal,
    AppMeetingControlButton,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isInviteModalOpen: false,
  }),
  methods: {
    openInviteModal() {
      this.isInviteModalOpen = true;
    },
    closeInviteModal() {
      this.isInviteModalOpen = false;
    },
  },
};
</script>
<style>
.meeting-invite-button__icon {
  font-size: 20px;
}
</style>
