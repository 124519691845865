<template>
  <app-form-modal
    :show="show"
    :title="title"
    :action="buttonText"
    :disable-action="invalidForm"
    :saving="saving"
    @close="close"
    @action="save"
  >
    <!-- Disable the save button when "slug" (custom url) is being checked -->
    <app-group-editor-form
      v-model="group"
      @slugLoading="checkingSlug = $event"
    />
  </app-form-modal>
</template>

<script>
import $popups from '@/services/popups';
import $upload from '@/services/upload';
import $groupsApi from '@/api/groups';

// General components
import AppFormModal from '@/components/general/FormModal.vue';

// Group common components
import AppGroupEditorForm from '@/components/groups/GroupEditorForm.vue';

export default {
  components: {
    AppFormModal,
    AppGroupEditorForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    groupData: {
      type: Object,
      default() {
        return { privacy: 'public' };
      },
    },
  },

  data() {
    return {
      group: JSON.parse(JSON.stringify(this.groupData)),
      saving: false,
      checkingSlug: false,
    };
  },
  computed: {
    isCreate() {
      return !this.group.id;
    },
    title() {
      return this.isCreate ? 'Create Group' : 'Edit Group';
    },
    buttonText() {
      return this.isCreate ? 'Create' : 'Save';
    },
    invalidForm() {
      if (!this.group) return true;
      if (!this.group.privacy) return true;
      if (!this.group.name || !this.group.name.trim()) return true;
      if (this.checkingSlug) return true;
      return false;
    },
  },
  methods: {
    async save() {
      this.saving = true;
      this.uploadImages()
        .then(() => (this.isCreate ? this.createGroup() : this.updateGroup()))
        .catch((e) => {
          this.error(
            'There was an error saving.  Please try again later or contact support',
            e
          );
        });
    },
    uploadImages() {
      return $upload
        .uploadImages([this.group.picture, this.group.banner])
        .then((images) => {
          const picture =
            images[0] && images[0].url ? images[0].url : this.group.picture;
          const banner =
            images[1] && images[1].url ? images[1].url : this.group.banner;
          this.$set(this.group, 'picture', picture);
          this.$set(this.group, 'banner', banner);
        })
        .catch((e) => {
          this.error(
            'There was an error uploading.  Please try again later or contact support',
            e
          );
        });
    },
    createGroup() {
      return $groupsApi.createGroup(this.group).then((result) => {
        this.done(result.data.create_group.id);
      });
    },
    updateGroup() {
      return $groupsApi
        .updateGroup(this.group.id, this.group)
        .then(() => this.done());
    },
    done(groupId) {
      this.saving = false;
      if (groupId) {
        this.$router.push({ name: 'GroupDetail', params: { id: groupId } });
      }
      this.close();
    },
    close() {
      this.$emit('close');
    },
    error(message, err) {
      this.saving = false;
      $popups.alert(message);
      throw err;
    },
  },
};
</script>
