<template>
  <div class="group-menu">
    <!-- Actionsheet icon and options -->
    <i
      class="cs-icons-options-filled group-menu__icon"
      @click="showGroupOptions = true"
    ></i>
    <app-action-sheet
      :open="showGroupOptions"
      @close="showGroupOptions = false"
    >
      <app-action-sheet-button
        v-if="userIsAdmin"
        @click="showGroupEditor = true"
      >
        Edit Group
      </app-action-sheet-button>
      <app-action-sheet-button @click="reportGroup">
        Report Group
      </app-action-sheet-button>
      <app-action-sheet-button
        v-if="userIsMember || userIsPending"
        @click="leaveGroup"
      >
        Leave Group
      </app-action-sheet-button>
      <app-action-sheet-button v-if="userIsAdmin" @click="deleteGroup">
        Delete Group
      </app-action-sheet-button>
      <app-action-sheet-button v-if="userIsSuperAdmin" @click="deleteGroup">
        [SA] Delete Group
      </app-action-sheet-button>
    </app-action-sheet>

    <template v-if="userIsAdmin">
      <app-group-editor-modal
        :show="showGroupEditor"
        :group-data="group"
        @close="showGroupEditor = false"
      />
    </template>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $popups from '@/services/popups';
import $groups from '@/services/groups';
import $groupsApi from '@/api/groups';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppGroupEditorModal from '@/components/groups/GroupEditorModal.vue';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
    AppGroupEditorModal,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showGroupEditor: false,
      showGroupOptions: false,
    };
  },
  computed: {
    userRole() {
      return $groups.getUserGroupRole(this.group);
    },
    userIsAdmin() {
      return this.userRole === 'admin';
    },
    userIsModerator() {
      if (this.userIsAdmin) return true;
      return this.userRole === 'moderator';
    },
    userIsMember() {
      if (this.userIsModerator) return true;
      return this.userRole === 'member';
    },
    userIsPending() {
      return this.userRole === 'pending-member';
    },
    userIsSuperAdmin() {
      return $auth.isAdmin();
    },
  },
  methods: {
    reportGroup() {
      const data = {
        report: this.group,
        reportType: 'group',
      };
      $bus.$emit('show-report-action', data);
    },
    async deleteGroup() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Group',
        message: `Are you sure you wish to delete ${this.group.name}? This can't be undone.`,
      });
      if (!confirmDelete) return;
      await $groupsApi.deleteGroup(this.group);
      if (this.group.parentGroupId) {
        this.$router.push({
          name: 'GroupDetail',
          params: { id: this.group.parentGroupId },
        });
      } else {
        this.$router.replace({ name: 'MyGroupsList' });
      }
    },
    async leaveGroup() {
      await $groups.leaveGroup(this.group);
    },
  },
};
</script>

<style lang="css" scoped>
.group-menu__icon {
  font-size: 24px;
  color: var(--cs-gray-03);
}
</style>
