<template>
  <div class="meeting-roles">
    <!-- Action sheet icon and roles -->
    <app-action-sheet
      :open="showRoles && hasMenu"
      @close="$emit('close-meeting-roles')"
    >
      <app-action-sheet-button
        v-for="(option, $index) in menuOptions"
        :key="$index"
        @click="setRole(option.value)"
        >{{ option.label }}</app-action-sheet-button
      >
      <app-action-sheet-button @click="$emit('show-profile')"
        >View Profile</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $meetingsApi from '@/api/meetings';
import $socket from '@/services/socket';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';

const roles = {
  admin: {
    label: 'Make Host',
    description:
      'Can speak, share camera, share screen, and manage participants',
    value: 'ADMIN',
  },
  presenter: {
    label: 'Make Presenter',
    description: 'Can speak, share camera, share screen',
    value: 'PRESENTER',
  },
  moderator: {
    label: 'Make Moderator',
    description:
      'Can speak, share camera, share screen, and manage participants',
    value: 'MODERATOR',
  },
  speaker: {
    label: 'Make Speaker',
    description: 'Can speak',
    value: 'SPEAKER',
  },
  audience: {
    label: 'Make Audience',
    description: 'Watch / listen only',
    value: 'VIEWER',
  },
  cancelQueue: {
    label: 'Cancel Request',
    description: 'Cancel the request to speak',
    value: 'VIEWER',
  },
  banned: {
    label: 'Ban from Event',
    description: 'Ban the user from the meeting',
    value: 'BANNED',
  },
};

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    showRoles: {
      type: Boolean,
      value: false,
    },
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    isLoggedInUser() {
      return this.member.userId === $auth.getUserId();
    },
    myRole() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    userIsAdmin() {
      return this.myRole === 'ADMIN';
    },
    hasMenu() {
      return (
        this.mounted &&
        ['ADMIN', 'MODERATOR'].includes(this.myRole) &&
        !this.isLoggedInUser &&
        this.member.role !== 'ADMIN' &&
        this.meeting.status !== 'TRANSCODING' &&
        this.meeting.status !== 'ENDED'
      );
    },
    menuOptions() {
      const role = this.member.role;
      if (role === 'ADMIN') {
        return [roles.presenter, roles.moderator, roles.audience];
      }
      if (role === 'MODERATOR') {
        return [roles.admin, roles.presenter, roles.audience, roles.banned];
      }
      if (role === 'PRESENTER') {
        return [roles.admin, roles.moderator, roles.audience, roles.banned];
      }
      if (role === 'SPEAKER') {
        return [roles.presenter, roles.moderator, roles.audience, roles.banned];
      }
      if (role === 'QUEUE') {
        return [roles.speaker, roles.cancelQueue, roles.banned];
      }
      if (role === 'VIEWER') {
        return [roles.moderator, roles.presenter, roles.speaker, roles.banned];
      }
      return [];
    },
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async setRole(role) {
      await $meetingsApi.setRole(this.meeting.id, this.member.userId, role);
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-members-modified');
      console.log('Sent Room', this.meeting.id, 'meeting-members-modified');
    },
  },
};
</script>

<style scoped>
.meeting-menu__icon {
  font-size: 24px;
  color: var(--cs-gray-03);
}
* >>> .ch-action-sheet__content {
  position: fixed;
  width: 100%;
  left: 0;
}
* >>> .ch-action-sheet-fade-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-enter,
.ch-action-sheet-fade-leave-to {
  opacity: 0;
}
* >>> .ch-action-sheet-slide-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-enter {
  transform: none;
}
* >>> .ch-action-sheet-slide-leave-to {
  transform: none;
}
</style>
