// All these functions help to clean up and standardize some of the mutations used
// And then update the caches of items

import $apollo from '@/services/apollo';

import CreateGroup from '@/gql/groups/CreateGroup.gql';
import UpdateGroup from '@/gql/groups/UpdateGroup.gql';
import DeleteGroup from '@/gql/groups/DeleteGroup.gql';

import ListMyGroups from '@/gql/groups/ListMyGroups.gql';
import ListManagedGroups from '@/gql/groups/ListManagedGroups.gql';
import ListRecommendedGroups from '@/gql/groups/ListRecommendedGroups.gql';

import JoinGroup from '@/gql/groups/JoinGroup.gql';
import LeaveGroup from '@/gql/groups/LeaveGroup.gql';

import ListGroupMembers from '@/gql/groups/ListGroupMembers.gql';

import UpdateGroupMember from '@/gql/groups/UpdateGroupMember.gql';
import RemoveGroupMember from '@/gql/groups/RemoveGroupMember.gql';
import ListSubgroupsByGroupId from '@/gql/groups/ListSubgroupsByGroupId.gql';
import { uuidv4 } from '@/services/utils';

const createGroup = async (group) => {
  return await $apollo.mutate({
    mutation: CreateGroup,
    variables: {
      ...group,
    },
    optimisticResponse: {
      create_group: {
        id: uuidv4(),
        ...group,
        myMembership: {
          id: uuidv4(),
          role: 'admin',
          createdAt: new Date().toISOString(),
        },
        __typename: 'Group',
      },
    },
    update: (cache, mutationResponse) => {
      const updateLists = [
        ListMyGroups,
        ListManagedGroups,
        ListSubgroupsByGroupId,
      ];
      updateLists.forEach((query) => {
        const variables = {
          parentGroupId: group.parentGroupId,
        };
        const queryCache = cache.readQuery({
          query,
          variables,
        });
        if (queryCache && queryCache.groups) {
          cache.writeQuery({
            query,
            variables,
            data: {
              groups: [
                {
                  ...mutationResponse.data.create_group,
                  myMembership: { id: 'xx', role: 'admin' },
                },
                ...queryCache.groups,
              ],
            },
          });
        }
      });
    },
  });
};

const updateGroup = async (id, group) => {
  return await $apollo.mutate({
    mutation: UpdateGroup,
    variables: {
      id,
      ...group,
    },
    optimisticResponse: {
      update_group: {
        __typename: 'Group',
        id,
        ...group,
      },
    },
  });
};

const deleteGroup = async (group) => {
  return await $apollo.mutate({
    mutation: DeleteGroup,
    variables: {
      id: group.id,
    },
    update: (cache) => {
      const updateLists = [
        ListMyGroups,
        ListRecommendedGroups,
        ListManagedGroups,
      ];
      updateLists.forEach(() => {
        const normalizedId = cache.identify(group);
        if (normalizedId) {
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      });
    },
  });
};

const joinGroup = async (group) => {
  const optimisticRole =
    group.privacy === 'public' ? 'member' : 'pending-member';

  return await $apollo.mutate({
    mutation: JoinGroup,
    variables: {
      groupId: group.id,
    },
    optimisticResponse: {
      join_group: {
        id: uuidv4(),
        role: optimisticRole,
        createdAt: new Date().toISOString(),
        __typename: 'GroupMembership',
      },
    },
    update: (cache, mutationResponse) => {
      // NOTE: The order matters: update the lists before using the other cache.modify method
      const updateLists = [ListMyGroups];
      updateLists.forEach((query) => {
        const variables = {};
        const queryCache = cache.readQuery({
          query,
          variables,
        });
        if (queryCache && queryCache.groups) {
          cache.writeQuery({
            query,
            variables,
            data: {
              groups: [group, ...queryCache.groups],
            },
          });
        }
      });
      cache.modify({
        id: cache.identify(group),
        fields: {
          myMembership(existingFieldData, { toReference }) {
            return toReference(
              cache.identify(mutationResponse.data.join_group)
            );
          },
          memberCount(existingFieldData) {
            return existingFieldData + 1;
          },
        },
      });
    },
  });
};

const leaveGroup = async (group) => {
  return await $apollo.mutate({
    mutation: LeaveGroup,
    variables: {
      groupId: group.id,
    },
    optimisticResponse: {
      leave_group: true,
    },
    refetchQueries: ['ListMyGroups'],
    update: (cache) => {
      cache.modify({
        id: cache.identify(group),
        fields: {
          myMembership() {
            return null;
          },
          memberCount(existingFieldData) {
            return existingFieldData - 1;
          },
        },
      });
    },
  });
};

const updateMember = async (group, member, role) => {
  return await $apollo.mutate({
    mutation: UpdateGroupMember,
    variables: {
      groupId: group.id,
      userId: member.user.id,
      role,
    },
    optimisticResponse: {
      update_group_member_role: {
        id: member.id,
        role,
        __typename: 'GroupMembership',
      },
    },
    update: (cache, mutationResponse) => {
      // Update old memberlist (remove member)
      _removeMemberFromCache(group, member, cache);
      // Update new memberlist (add member)
      const variables = {
        id: group.id,
        role,
      };
      const queryCache = cache.readQuery({
        query: ListGroupMembers,
        variables,
      });
      if (queryCache && queryCache.members) {
        const newMembership = cache.writeQuery({
          query: ListGroupMembers,
          variables,
          data: {
            members: [
              mutationResponse.data.update_group_member_role,
              ...queryCache.members,
            ],
          },
        });
      }
    },
  });
};

const removeMember = async (group, member) => {
  return await $apollo.mutate({
    mutation: RemoveGroupMember,
    variables: {
      groupId: group.id,
      userId: member.user.id,
    },
    update: (cache) => {
      // Update old memberlist (remove member)
      _removeMemberFromCache(group, member, cache);
    },
  });
};

const _removeMemberFromCache = (group, member, cache) => {
  const variables = {
    id: group.id,
    role: member.role,
  };
  const queryCache = cache.readQuery({
    query: ListGroupMembers,
    variables,
  });
  if (queryCache && queryCache.members) {
    cache.writeQuery({
      query: ListGroupMembers,
      variables,
      data: {
        members: queryCache.members.filter((m) => m.user.id !== member.user.id),
      },
    });
  }
};

export default {
  createGroup,
  updateGroup,
  deleteGroup,
  joinGroup,
  leaveGroup,
  updateMember,
  removeMember,
};
