<template>
  <div class="ch-post-search">
    <div class="ch-post-search__header">
      <cs-search-bar
        placeholder="Search Posts"
        v-model="query"
        @submit="search"
      />
    </div>
    <div class="ch-post-search__result" v-if="posts">
      <app-post-card :post="post" :key="post.id" v-for="post in posts"/>
    </div>
  </div>
</template>

<script>
import AppPostCard from '@/components/post/items/PostCard.vue';
import PostSearch from '@/gql/search/PostSearch.gql';

export default {
  components: {
    AppPostCard,
  },
  data() {
    return {
      searchQuery: null,
      query: '',
    };
  },
  apollo: {
    posts: {
      query: PostSearch,
      variables() {
        return {
          query: this.searchQuery,
        };
      },
      skip() {
        return !this.searchQuery;
      },
    },
  },
  methods: {
    search() {
      this.searchQuery = this.query;
    },
  },
};
</script>

<style scoped>
.ch-post-search{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-post-search__header >>> .cs-search-bar{
  background-color: var(--cs-gray-00);
}
.ch-post-search__header >>> .cs-search-bar__input{
  background-color: var(--cs-gray-00);
}
</style>
