<template lang="html">
  <div class="action-sheet-button cs-textstyle-informative-paragraph" @click="$parent.$emit('close')">
    <slot/>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="css" scoped>
.action-sheet-button {
  color: var(--cs-gray-07);
  padding: 12px 0;
  display: flex;
  align-items: center;
}
.action-sheet-button i {
  font-size: 20px;
  margin-right: 16px;
  display: inline-flex;
}
</style>
