<template>
  <ion-page>
    <ion-header>
      <app-main-header title="Groups" :search="{ name: 'GroupSearch' }" />

      <app-route-tabs :links="links" />
    </ion-header>

    <ion-content class="group-home-content">
      <app-home-group-search
        v-if="showSearchBar"
        :search-query="searchQuery"
        @searching="showOutlet = !$event"
      />

      <router-view
        ref="groupsList"
        :class="{ hide: !showOutlet }"
      ></router-view>

      <!-- Group Editor modal for creating groups -->
      <app-group-editor-modal
        :show="showEditModal"
        @close="showEditModal = false"
      />
    </ion-content>

    <!-- Floating + button -->
    <app-create-button @click="create()" />
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppCreateButton from '@/components/general/CreateButton.vue';
import AppRouteTabs from '@/components/general/RouteTabs.vue';
import AppHomeGroupSearch from '@/components/groups/HomeGroupSearch.vue';
import AppGroupEditorModal from '@/components/groups/GroupEditorModal.vue';
import MyGroupSearch from '@/gql/search/MyGroupSearch.gql';

export default {
  components: {
    AppMainHeader,
    AppGroupEditorModal,
    AppCreateButton,
    AppRouteTabs,
    AppHomeGroupSearch,
  },
  data() {
    return {
      showOutlet: true,
      showEditModal: false,
      links: [
        {
          title: 'My Groups',
          to: { name: 'MyGroupsList' },
        },
        {
          title: 'For Me',
          to: { name: 'ForMe' },
        },
        {
          title: 'Find a Group',
          to: { name: 'FindGroup' },
        },
        {
          title: 'Recommended',
          to: { name: 'RecommendedGroupsList' },
        },
      ],
    };
  },
  computed: {
    showSearchBar() {
      return this.$route.name !== 'ForMe';
    },
    searchQuery() {
      return this.$route.name === 'MyGroupsList' ? MyGroupSearch : undefined;
    },
  },
  methods: {
    async create() {
      this.showEditModal = true;
    },
    async refresh($event) {
      if (this.$refs.groupsList?.refresh instanceof Function) {
        await this.$refs.groupsList.refresh();
      }

      $event.target.complete();
    },
  },
};
</script>

<style scoped>
.group-home-content {
  --background: inherit;
}

.hide {
  display: none;
}
</style>
