<template>
  <div class="meeting-card" :class="direction">
    <app-img
      class="meeting-item-thumbnail cursor-pointer"
      :src="meeting.picture"
      @click="openMeeting(meeting)"
    ></app-img>
    <div class="meeting-item-body">
      <div class="meeting-info">
        <!-- <div
          v-if="meeting.category"
          class="meeting-cat cs-textstyle-paragraph-small"
        >
          {{ category }}
        </div> -->
        <div class="meeting-heading">
          <div
            class="meeting-title cs-textstyle-paragraph-bold no-wrap"
            @click="openMeeting(meeting)"
          >
            <i
              v-if="meeting.privacy === 'private'"
              class="cs-textstyle-paragraph cs-icons-lock meeting-private-icon"
            />
            {{ meeting.name }}
          </div>
          <div class="meeting-item__icons">
            <span class="ch-options">
              <i
                v-if="isAdmin || isSuperAdmin"
                class="ch-icons-options meeting-color"
                @click="openMeetingMenu"
              />
            </span>
            <i class="ch-icons-share meeting-color" @click="shareMeeting" />
          </div>
        </div>
        <div class="cs-textstyle-paragraph-small meeting-color">
          {{ displayTime }}
        </div>

        <div
          v-if="filteredPeople && filteredPeople.length"
          class="meeting-meta"
        >
          <div class="ch-user-mention-info">
            <div class="cs-textstyle-paragraph-small text-light">Hosted by</div>
          </div>
          <div class="meeting-meta-info">
            <div>
              <div
                v-if="filteredPeople.length > 1"
                class="
                  cs-textstyle-paragraph-small
                  meeting-color
                  cursor-pointer
                "
              >
                <app-username
                  class="ch-user-mention"
                  v-router-link="{
                    name: 'Profile',
                    params: filteredPeople[0].user,
                  }"
                  :user="filteredPeople[0].user"
                />&nbsp; + {{ filteredPeople.length - 1 }}
                {{ filteredPeople.length - 1 > 1 ? 'others' : 'other' }}
              </div>
              <div v-else class="cs-textstyle-paragraph-small cursor-pointer">
                <app-username
                  class="ch-user-mention"
                  v-router-link="{
                    name: 'Profile',
                    params: filteredPeople[0].user,
                  }"
                  :user="filteredPeople[0].user"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="truncate-overflow cs-textstyle-paragraph-small text-light"
          @click="openMeeting(meeting)"
        >
          {{ meeting.description || 'No description' }}
        </div>
        <div class="meeting-btns">
          <div
            v-if="members && members.length"
            class="cs-textstyle-paragraph-small meeting-color"
            @click="showMeetingParticipants"
          >
            {{ members.length }} Participants
          </div>
          <!-- Card Buttons -->
          <div
            v-if="
              meeting.status !== 'ENDED' && meeting.status !== 'TRANSCODING'
            "
            class="meeting-button-class"
          >
            <cs-button
              v-if="meeting.status === 'LIVE'"
              size="small"
              fill="clear"
              class="cs-button"
              @click="openMeeting(meeting)"
              >Join</cs-button
            >
            <cs-button
              v-if="
                isScheduled &&
                !meeting.myMembership &&
                meeting.status !== 'LIVE'
              "
              size="small"
              fill="clear"
              class="cs-button"
              @click="openMeeting(meeting)"
              >Attend</cs-button
            >
            <cs-button
              v-if="
                isScheduled && meeting.myMembership && meeting.status !== 'LIVE'
              "
              fill="clear"
              size="small"
              class="cs-button"
              @click="openMeeting(meeting)"
              >Attending</cs-button
            >
          </div>
          <div v-else class="meeting-button-class">
            <cs-button
              fill="clear"
              size="small"
              class="cs-button"
              @click="openMeeting(meeting)"
              >Watch</cs-button
            >
            <cs-button
              v-if="!isScheduled && isAdmin && meeting.recordingUrlMp4 !== null"
              fill="clear"
              size="small"
              class="cs-button"
              @click="downloadMeeting"
            >
              Download
            </cs-button>
          </div>
        </div>
      </div>
    </div>
    <app-meeting-menu
      :show-meeting-options="showMenu"
      :meeting="meeting"
      :is-admin="isAdmin"
      list-view
      @close-meeting-menu="closeOption"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $popups from '@/services/popups';
import $browser from '@/services/browser';
import $meetingsApi from '@/api/meetings';
import AppImg from '@/components/general/Img.vue';
import AppUsername from '@/components/general/Username.vue';
import AppMeetingMenu from '@/components/meetings/MeetingMenu.vue';
import ListMeetingMembers from '@/gql/meetings/ListMeetingMembers.gql';

export default {
  components: {
    AppImg,
    AppUsername,
    AppMeetingMenu,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      default: 'row',
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  apollo: {
    members: {
      query: ListMeetingMembers,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
    },
  },
  computed: {
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    category() {
      const categoryMap = {
        news: 'News',
        faith: 'Faith',
        politics: 'Politics & Civic',
        music: 'Music',
        finance: 'Finance & Investing',
        business: 'Business',
        health: 'Health & Fitness',
        movies: 'Movies & Shows',
        sports: 'Sports',
        technology: 'Technology',
        games: 'Games & Gaming',
        education: 'Education',
        personalgrowth: 'Personal Growth',
        fashion: 'Fashion & Beauty',
        humor: 'Humor & Memes',
      };
      const type = this.meeting.category;

      const category = categoryMap[type] || type;
      return category.toUpperCase();
    },
    isScheduled() {
      return (
        this.meeting.startTime > new Date().toISOString() ||
        this.meeting.status === 'NOT_STARTED'
      );
    },
    filteredPeople() {
      return (
        this.members && this.members.filter((member) => member.role === 'ADMIN')
      );
    },
    displayTime() {
      return (
        this.meeting &&
        this.$moment
          .utc(this.meeting.startTime)
          .local()
          .format('h:mm A [|] MMM D, YYYY')
      );
    },
    isAdmin() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role === 'ADMIN'
      );
    },
  },
  methods: {
    downloadMeeting() {
      const a = document.createElement('a');
      a.href = this.meeting.recordingUrlMp4;
      a.download = true;
      a.click();
    },
    // Share Event
    shareMeeting() {
      const link = `/m/${this.meeting.slug || this.meeting.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Event',
      });
    },
    showMeetingParticipants() {
      $bus.$emit('show-meeting-participants-modal', this.members);
    },
    openMeeting(meeting) {
      let domain = 'https://m.clouthub.com';
      if (window.location.host === 'localhost:2222') {
        domain = 'http://localhost:2222';
      }
      if (window.location.host === 'm.clouthub.dev') {
        domain = 'https://m.clouthub.dev';
      }
      if (window.location.host === 'mobile-agora4.clouthub.dev') {
        domain = 'https://mobile-agora4.clouthub.dev';
      }
      const accessToken = encodeURIComponent(localStorage.access_token);
      const next = encodeURIComponent(`/meetings/${meeting.id}`);
      const url = `${domain}/go.html?access_token=${accessToken}&next=${next}`;
      $browser.open(url);
    },
    // Signup/Attend
    async signup() {
      try {
        await $meetingsApi.signup(this.meeting.id);
      } catch (e) {
        $popups.alert(
          'There was an error.  Please try again or contact support.'
        );
        throw e;
      }
    },
    // UnSignup/UnAttend
    async unsignup() {
      try {
        await $meetingsApi.unsignup(this.meeting.id);
      } catch (e) {
        $popups.alert(
          'There was an error.  Please try again or contact support.'
        );
        throw e;
      }
    },
    // Open Event Menu
    openMeetingMenu() {
      this.showMenu = true;
    },
    closeOption() {
      this.showMenu = false;
    },
  },
};
</script>

<style scoped>
.meeting-card {
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  border: solid 1px var(--cs-gray-02);
  display: flex;
  flex-direction: row;
}
.meeting-card.row {
  max-height: 380px;
  width: 340px;
}
.meeting-item-thumbnail {
  width: 156px;
  object-fit: cover;
}
.meeting-item-body {
  padding: 10px;
  flex: 1;
  width: 50%;
}
.meeting-cat {
  color: var(--cs-primary-base);
}
.meeting-meta {
  display: flex;
  align-items: center;
  gap: 10px;
}
.meeting-meta-info button {
  width: 145px;
  height: 30px;
}
.meeting-color {
  color: var(--cs-gray-05);
}
.cursor-pointer {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
}
.meeting-button-class {
  display: flex;
}
.meeting-private-icon {
  color: var(--cs-primary-base);
  margin-right: 1px;
}
.meeting-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.meeting-title {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-calc(100% - 32px);
}
.meeting-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.cs-btns {
  padding: 0;
}
.truncate-overflow {
  display: -webkit-box;
  max-height: 65px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cs-button {
  padding-right: 0;
}
.meeting-item__icons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 30px;
  position: absolute;
  right: 0;
  align-items: center;
}
.ch-options {
  width: 14px;
}
</style>
