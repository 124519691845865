<template>
  <span>
    <transition name="channel-popup__fade">
      <div
        v-if="showPopup"
        class="channel-popup__backdrop"
        @click="backdropClose"
      >
        <div class="channel-popup__backdrop-opacity"></div>
      </div>
    </transition>
    <transition name="channel-popup__fade">
      <div
        v-if="showPopup"
        :class="[noPadding ? 'channel-popup no-padding' : 'channel-popup']"
      >
        <slot></slot>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    backdropClose() {
      this.$emit('close-popup');
    },
  },
};
</script>

<style scoped>
.channel-popup__fade-enter-active,
.channel-popup__fade-leave-active {
  transition: all 0.3s;
}
.channel-popup__fade-enter,
.channel-popup__fade-leave-to {
  opacity: 0;
}
.channel-popup__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(4px);
  z-index: 1;
}
.channel-popup__backdrop-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;
  background-color: var(--cs-gray-06);
}

.channel-popup {
  background-color: var(--cs-gray-00);
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  max-height: 80%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: var(--cs-gray-00);
  border-radius: 5px;
  border: 1px solid var(--cs-gray-01);
  padding: 20px 10px;
}
.no-padding {
  padding: 0;
  border: none;
}
</style>
