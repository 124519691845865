<template>
  <ion-page class="ch-settings">
    <ion-header class="ch-settings__header">
      <app-category-header title="Settings" notifications />
    </ion-header>
    <ion-content>
      <div class="ch-setting__options">
        <div
          v-for="setting in settings"
          :key="setting.modal"
          class="ch-settings__nav"
          @click="openModal(setting.modal)"
        >
          <i :class="setting.icon" class="ch-settings__nav--icons"></i>
          <span>{{ setting.title }}</span>
        </div>
      </div>
      <app-change-password-modal
        :show="showChangePasswordModal"
        @close="closeModal"
      />
      <app-change-email-modal
        :show="showChangeEmailModal"
        @close="closeModal"
      />
      <app-change-username-modal
        :show="showChangeUsernameModal"
        @close="closeModal"
      />
      <app-contact-us-modal :show="showContactUsModal" @close="closeModal" />
      <app-notification-modal
        :show="showNotificationModal"
        @close="closeModal"
      />
      <app-blocked-members-modal
        :show="showBlockedMembersModal"
        @close="closeModal"
      />
      <app-delete-user-modal :show="showDeleteUserModal" @close="closeModal" />
      <app-translation-modal :show="showTranslationModal" @close="closeModal" />
      <app-verification-modal
        :show="showVerificationModal"
        @close="closeModal"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppChangePasswordModal from '@/components/setting/ChangePasswordModal.vue';
import AppChangeEmailModal from '@/components/setting/ChangeEmailModal.vue';
import AppChangeUsernameModal from '@/components/setting/ChangeUsernameModal.vue';
import AppContactUsModal from '@/components/setting/ContactUsModal.vue';
import AppNotificationModal from '@/components/setting/NotificationModal.vue';
import AppBlockedMembersModal from '@/components/setting/BlockedMembersModal.vue';
import AppDeleteUserModal from '@/components/setting/DeleteUserModal.vue';
import AppVerificationModal from '@/components/setting/VerificationModal.vue';
import AppTranslationModal from '@/components/setting/TranslationModal.vue';

export default {
  components: {
    AppCategoryHeader,
    AppChangePasswordModal,
    AppChangeEmailModal,
    AppChangeUsernameModal,
    AppContactUsModal,
    AppNotificationModal,
    AppBlockedMembersModal,
    AppDeleteUserModal,
    AppVerificationModal,
    AppTranslationModal,
  },
  data() {
    return {
      settings: [
        {
          title: 'Change Password',
          icon: 'ch-icons-secure',
          modal: 'showChangePasswordModal',
        },
        {
          title: 'Change Email',
          icon: 'ch-icons-change-email',
          modal: 'showChangeEmailModal',
        },
        {
          title: 'Change Username',
          icon: 'ch-icons-change-username',
          modal: 'showChangeUsernameModal',
        },
        {
          title: 'Contact Us',
          icon: 'ch-icons-message',
          modal: 'showContactUsModal',
        },
        {
          title: 'Notification Options',
          icon: 'ch-icons-notification',
          modal: 'showNotificationModal',
        },
        {
          title: 'Language',
          icon: 'ch-icons-globe',
          modal: 'showTranslationModal',
        },
        {
          title: 'Blocked Members',
          icon: 'ch-icons-block-user',
          modal: 'showBlockedMembersModal',
        },
        {
          title: 'Delete Account',
          icon: 'ch-icons-delete-user',
          modal: 'showDeleteUserModal',
        },
        {
          title: 'Verify Account',
          icon: 'ch-icons-verified',
          modal: 'showVerificationModal',
        },
      ],
      currentTab: null,
      showChangeEmailModal: false,
      showChangeUsernameModal: false,
      showChangePasswordModal: false,
      showBlockedMembersModal: false,
      showContactUsModal: false,
      showDeleteUserModal: false,
      showNotificationModal: false,
      showTranslationModal: false,
      showVerificationModal: false,
    };
  },
  methods: {
    openModal(id) {
      this[id] = true;
    },
    closeModal() {
      this.showChangeEmailModal = false;
      this.showChangeUsernameModal = false;
      this.showChangePasswordModal = false;
      this.showBlockedMembersModal = false;
      this.showContactUsModal = false;
      this.showDeleteUserModal = false;
      this.showNotificationModal = false;
      this.showTranslationModal = false;
      this.showVerificationModal = false;
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--cs-gray-00);
}
.ch-setting__options {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-settings__nav {
  display: flex;
  gap: 15px;
  color: var(--cs-gray-05);
  padding: 10px;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
}
.ch-settings__nav--icons {
  font-size: 24px;
}
</style>
