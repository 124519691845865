<template>
  <div>
    <div class="search-header">People</div>
    <div v-if="users">
      <cs-empty-state
        v-if="showEmptyState"
        title="No matching people"
        class="cs-textstyle-detail-heading"
      />
      <div v-else>
        <app-user-search-result
          v-for="user in users"
          :key="user.id"
          :user="user"
        ></app-user-search-result>
        <cs-button
          v-if="mayHaveMore && !$apollo.queries.users.loading"
          fill="outline"
          block
          size="small"
          @click="loadMore"
        >
          More People
        </cs-button>
      </div>
      <app-spinner v-if="$apollo.queries.users.loading" />
    </div>
  </div>
</template>

<script>
import UserSearch from '@/gql/search/UserSearch.gql';
import AppUserSearchResult from '@/components/search/UserSearchResult.vue';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  components: {
    AppUserSearchResult,
    AppSpinner,
  },
  props: {
    search: String,
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: this.search,
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.users && !this.users.length) return true;
      return false;
    },
  },
  methods: {
    loadMore() {
      const offset = this.users.length;
      this.$apollo.queries.users.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMore = !!fetchMoreResult.users.length;
          return {
            users: [...previousResult.users, ...fetchMoreResult.users],
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
