<template>
  <div class="meeting-header">
    <app-img class="meeting-header__logo" :src="meeting.picture" />
    <div class="meeting-header__info">
      <div class="meeting-header__title">{{ meeting.name }}</div>
      <button class="meeting-header__description-btn" @click="openDetails">
        About this meeting
      </button>
    </div>
    <app-avatar
      v-if="user"
      class="meeting-header__avatar"
      :name="user.displayname"
      :picture="user.profilePhotoURL"
      size="small"
    />
    <app-action-sheet :open="isDetailsOpen" @close="closeDetails">
      <app-meeting-header-details :meeting="meeting" />
    </app-action-sheet>
  </div>
</template>
<script>
import AppImg from '@/components/general/Img.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppMeetingHeaderDetails from '@/components/meetings/MeetingHeaderDetails.vue';

export default {
  components: {
    AppImg,
    AppAvatar,
    AppActionSheet,
    AppMeetingHeaderDetails,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    isDetailsOpen: false,
  }),
  computed: {
    user() {
      return this.meeting.host;
    },
  },
  methods: {
    openDetails() {
      this.isDetailsOpen = true;
    },
    closeDetails() {
      this.isDetailsOpen = false;
    },
  }
}
</script>
<style scoped>
.meeting-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom: solid 1px #9B9B9B7F;
}
.meeting-header__title {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}
.meeting-header__description-btn {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #0091ff;
  background: none;
}
.meeting-header__logo {
  max-width: 50px;
}
.meeting-header__avatar {
  margin-left: auto;
}
</style>