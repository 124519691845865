<template>
  <ion-page>
    <template v-if="meeting && !cannotJoin">
      <div v-if="!canView">
        <app-not-paid-view :meeting="meeting" @refetchMeeting="refresh" />
      </div>
      <div v-else>
        <div class="meeting-detail__main">
          <app-not-started-view
            v-if="!sendToPreview || !isUser"
            :meeting="meeting"
            @manageMeeting="isManageMeeting = true"
          />
        </div>
        <app-meeting-detail
          v-if="sendToPreview && joined && isUser"
          :id="id"
          :meeting="meeting"
          @exitMAnage="isManageMeeting = false"
          @refresh="refresh"
        />
      </div>
    </template>
    <template v-if="cannotJoin && members">
      <cs-empty-state
        title="This is a private event"
        description="You must be invited to participate"
      />
      <div>
        <app-meeting-leave-button
          size="small"
          class="ch-leave-btn"
          :meeting-id="meeting.id"
        />
      </div>
    </template>
    <app-profile-preview-modal />
  </ion-page>
</template>

<script>
import $socket from '@/services/socket';
import $auth from '@/services/auth';

import AppNotStartedView from '@/components/meetings/detailView/NewNotStartedView.vue';
import AppMeetingDetail from '@/components/meetings/detailView/MeetingDetail.vue';
import AppProfilePreviewModal from '@/components/followCards/ProfilePreviewModal.vue';
import AppNotPaidView from '@/components/meetings/detailView/NotPaidView.vue';
import AppMeetingLeaveButton from '@/components/meetings/buttons/MeetingLeaveButton.vue';

// GQL
import GetMeeting from '@/gql/meetings/GetMeeting.gql';
import ListMeetingMembers from '@/gql/meetings/ListMeetingMembers.gql';

export default {
  components: {
    AppNotStartedView,
    AppMeetingDetail,
    AppProfilePreviewModal,
    AppNotPaidView,
    AppMeetingLeaveButton,
  },
  apollo: {
    meeting: {
      query: GetMeeting,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      fetchPolicy: 'network-only',
      // pollInterval: 5000,
    },
    members: {
      query: ListMeetingMembers,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
      skip() {
        return !this.meeting;
      },
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      meeting: null,
      socketReady: false,
      isManageMeeting: false,
      joined: false,
      userId: null,
    };
  },
  watch: {
    async meeting() {
      if (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role === 'BANNED'
      ) {
        alert('You are not permitted to participate in this meeting.');
        await this.$router.push({ name: 'MeetingHome' });
        return false;
      }
    },
  },
  async mounted() {
    this.userId = $auth.getUserId();
    console.log('Mounted Start');
    await $socket.connect();
    await $socket.joinRoom('meeting', this.id);
    console.log('Set Joined');
    this.joined = true;
    $socket.onRoom('meeting', this.id, 'meeting-edited', () => {
      this.refresh();
    });
    $socket.onRoom('meeting', this.id, 'meeting-status-changed', () => {
      this.refresh();
    });
    $socket.onRoom('meeting', this.id, 'meeting-members-modified', () => {
      this.refresh();
    });
    $socket.onRoom('meeting', this.id, 'meeting-deleted', () => {
      window.close();
      alert('Please close this window to leave this event');
    });
    $socket.onRoom('meeting', this.id, 'meeting-force-refresh', () => {
      const timeout = Math.floor(Math.random() * 5 * 60000);
      console.log('Will refresh in', timeout);
      setTimeout(() => {
        window.location.reload();
      }, timeout);
    });
    this.socketReady = true;
  },
  computed: {
    isModerator() {
      const role =
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role;
      return role === 'ADMIN' || role === 'MODERATOR';
    },
    canView() {
      if (this.isModerator) return true;
      if (!this.meeting.ticketPrice || this.meeting.ticketPrice < 1)
        return true;
      if (!this.meeting.myMembership) return false;

      return (
        this.meeting.myMembership.ticketStatus === 'PURCHASED' ||
        this.meeting.myMembership.ticketStatus === 'FREE'
      );
    },
    sendToPreview() {
      return this.isManageMeeting || !(this.meeting.status === 'NOT_STARTED');
    },
    cannotJoin() {
      if (!this.meeting) return true;

      return !this.meeting.myMembership && this.meeting.privacy === 'private';
    },
    isMember() {
      return !!this.meeting && !!this.meeting.myMembership;
    },
    isUser() {
      return $auth.isLoggedIn();
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.meeting.refetch();
      await this.$apollo.queries.members.refetch();
      $event && $event.target.complete();
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$refs.main) {
      await this.$refs.main.leave();
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.$refs.main) {
      await this.$refs.main.leave();
    }
    next();
  },
};
</script>

<style>
.meeting-detail__main {
  display: flex;
  flex-direction: column;
}

.ch-leave-btn {
  display: flex !important;
  margin: auto !important;
  margin-top: 10px !important;
}
</style>
