<template>
  <ion-page>
    <ion-header>
      <div v-if="post" class="category-header">
        <category-header
          :title="post.linkPreview.title"
          icon="cs-icons-share2"
          @action="shareNews"
        />
        <app-tabs v-model="tabIndex" :tabs="tabs" />
      </div>
    </ion-header>
    <ion-content class="news-content">
      <app-pull-to-refresh @refresh="refresh" />
      <!-- Article section -->
      <div v-show="tabIndex === 0" class="news-tab-content">
        <cs-spinner v-if="state === 'loading'" class="news-spinner"></cs-spinner>
        <div v-if="post" class="news-frame">
          <iframe
            v-if="post.linkPreview"
            ref="myframe"
            :src="post.linkPreview.url"
            :class="state"
            @load="handleLoad()"
          ></iframe>
          <cs-input
            v-if="!state == 'failed'"
            v-model="post.linkPreview.url"
            class="urlbar"
            type="text"
            :selectable="false"
          ></cs-input>
          <cs-empty-state
            v-if="state == 'failed'"
            class="news-frame-failed"
            title="This article can't be displayed on CloutHub"
            description="Some news articles only allow articles to be read on their website."
          >
            <cs-button @click="readInBrowser()">Read on {{ domain }}</cs-button>
          </cs-empty-state>
        </div>
      </div>

      <!-- Discussion section -->
      <div v-if="tabIndex === 1" class="news-tab-content">
        <cs-spinner v-if="!post"></cs-spinner>
        <div v-if="post" class="news-post-response">
          <app-news-publisher :post="post" />
          <app-post-response :post="post" @refresh="refresh" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import $bus from '@/services/bus';
import $newsApi from '@/api/news';
import $browser from '@/services/browser';
import GetNews from '@/gql/news/GetNews.gql';
import CategoryHeader from '@/components/general/CategoryHeader.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppNewsPublisher from '@/components/news/NewsPublisher.vue';
import AppPostResponse from '@/components/post/items/PostResponse.vue';

export default {
  apollo: {
    post: {
      query: GetNews,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result() {
        this.markPostViewed();
      },
    },
  },
  components: {
    CategoryHeader,
    AppTabs,
    AppNewsPublisher,
    AppPostResponse,
    AppPullToRefresh,
  },
  data() {
    return {
      state: 'loading',
      tabs: ['Article', 'Discussion'],
      tabIndex: 0,
    };
  },
  computed: {
    domain() {
      if (!this.post || !this.post.linkPreview) return '';
      try {
        const url = new URL(this.post.linkPreview.url);
        return url.hostname;
      } catch (e) {
        return this.post.linkPreview.url;
      }
    },
  },
  methods: {
    shareNews() {
      const link = `/p/${this.post.slug || this.post.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        post: this.post,
        title: 'Share Article',
      });
    },
    handleLoad() {
      if (this.$refs.myframe.contentWindow.length) {
        this.state = 'loaded';
      } else {
        this.state = 'failed';
      }
    },
    async refresh($event) {
      await this.$apollo.queries.post.refetch();
      $event.target.complete();
    },
    markPostViewed() {
      // NOTE: this check is essential to avoid an infinite loop
      // When the cache updates the viewCount it will call this function again
      if (!this.markViewed) {
        this.markViewed = true;
        $newsApi.markPostViewed(this.post);
      }
    },
    readInBrowser() {
      $browser.open(this.post.linkPreview.url);
    },
  },
};
</script>

<style scoped>
.news-tab-content {
  margin-top: 15px;
  background-color: var(--cs-gray-00);
}
.news-frame {
  display: flex;
  position: fixed;
  top: 120px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-top: solid 1px var(--cs-gray-05);
}
.loaded {
  flex: 1;
}
.failed {
  display: none;
}
.loading {
  flex: 1;
}
.news-frame iframe {
  flex: 1;
  border: dashed 1px var(--cs-gray-04);
}
.news-frame-failed {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  border: dashed 1px var(--cs-gray-04);
  align-items: center;
  background-color: var(--cs-gray-00);
}
.news-post-response {
  padding: 15px 20px;
  padding-top: 5px;
}
</style>
