<template>
  <div class="user-list">
    <cs-search-bar :placeholder="userType" v-model="query" class="user-list__search" />
    <div v-if="users && users.length">
      <div v-for="person in users" :key="person.id" class="user-list-person">
        <div class="user-list__checkbox">
          <input
            type="checkbox"
            v-model="selected"
            :value="person.id"
            number
            class="user-list__check user-list__checkbox"
          />
        </div>
        <app-avatar
          class="user-list-person-avatar"
          size="small"
          :name="person.user ? person.user.displayname : person.displayname"
          :picture="person.user ? person.user.profilePhotoURL : person.profilePhotoURL"
        />
        <div class="user-list-person-names">
          <div class="cs-textstyle-paragraph-bold">
            <app-username :user="personInfo(person)" type="displayname" />
          </div>
          <div class="cs-textstyle-paragraph-small">
            {{ person.user ? person.user.username : person.username }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-user-list">
      <cs-empty-state title="No Results"></cs-empty-state>
    </div>
    <div class="meeting-bottom">
      <cs-checkbox
        v-model="selectAll"
        label="Select All"
      /> 
      <cs-checkbox
        v-if="isPaidMeeting"
        v-model="grantFreeTicket"
        label="Free Ticket"
      /> 
      <div class="meeting-bottom-button">
        <cs-button size="small" @click="sendInvites">Send</cs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import $socket from '@/services/socket';

import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';

import SendInvite from '@/gql/meetings/SendInvite.gql';


export default {
  components: {
    AppAvatar,
    AppUsername,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    userType: {
      type: String,
      required: true,
    },
    meeting: Object,
  },
  data() {
    return {
      query: '',
      selected: [],
      selectedPeople: [],
      grantFreeTicket: false,
      filteredPeople: this.users,
    };
  },
  computed: {
    isPaidMeeting() {
      return this.meeting.ticketPrice && this.meeting.ticketPrice > 0 ? true: false
    },
    selectAll: {
      get() {
        return this.users ? this.selected.length === this.users.length : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.users.forEach((user) => {
            selected.push(user.id);
          });
        }
        this.selected = selected;
      },
    },
  },
  watch: {
    query(query) {
      this.onQueryChange(query);
    },
  },
  methods: {
    onQueryChange: _.debounce(function (query) {
      this.$emit('on-query-change', query);
    }, 300),
    personInfo(person) {
      if (person.user) {
        return person.user;
      }
      return person;
    },
    async sendInvites() {
      const users = this.selected.join(',');
      console.log('Users', users);
      let mutationVar = {
          meetingId: this.meeting.id,
          users,
        }
      if(this.isPaidMeeting)
        mutationVar.grantFreeTicket = this.grantFreeTicket
      await this.$apollo.mutate({
        mutation: SendInvite,
        variables: mutationVar,
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-members-modified');
      this.$toast.open({
        message: 'Sent',
        type: 'success',
        duration: 2000,
        dismissible: true,
        position: 'top',
      });
      this.selected = [];
      this.selectedPeople = [];
    },
  },
};
</script>

<style scoped>
.user-list {
  padding-bottom: 80px;
}
.user-list__head {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  background: var(--cs-gray-02);
}
.user-list__select-all {
  display: flex;
  margin-top: 10px;
}
.user-list-search-bar {
  margin-bottom: 10px;
}
.user-list-person {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
}
.user-list-person-avatar,
.user-list-person-names {
  cursor: pointer;
}
.user-list-person-names {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--cs-primary-base);
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: white;
}
input[type='checkbox']:checked {
  background: white;
}
input[type='checkbox']:checked:after {
  content: '';
  position: absolute;
  width: 12px;
  height: 6px;
  background: rgba(0, 0, 0, 0);
  top: 3px;
  left: 2px;
  border: 2px solid var(--cs-primary-base);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.user-list__checkbox {
  margin-right: 5px;
  margin-top: 5px;
}
.user-list__search {
  width: 100%;
}
.user-list__first-checkbox {
  margin: 0px 10px 0px 10px;
}
* >>> .cs-search-bar__input {
  background: var(--cs-gray-00) !important;
}
* >>> .cs-search-bar {
  background: var(--cs-gray-00) !important;
  border: none;
  border-bottom: 1px solid var(--cs-gray-02);
}
.meeting-bottom {
  position: fixed;
  bottom: 0px;
  background: white;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.meeting-bottom__first {
  margin-top: 15px !important;
  display: flex;
}

.meeting-bottom__first-text {
  margin-top: 2px !important;
  font-weight: 450;
  color: var(--cs-gray-05);
}
.meeting-why {
  margin-top: 0px;
}
</style>
