<template>
  <div class="ch-users-search">
    <div class="ch-users-search__header">
      <cs-search-bar
        placeholder="Search users"
        v-model="query"
        @submit="search"
      />
    </div>
    <div class="ch-users-search__result" v-if="users">
      <app-user-search-result v-for="user in users" :key="user.id" :user="user" />
    </div>
  </div>
</template>

<script>
// TODO: Implement Advance Search
import AppUserSearchResult from '@/components/search/UserSearchResult.vue';
import UserSearch from '@/gql/search/UserSearch.gql';

export default {
  components: {
    AppUserSearchResult,
  },
  data() {
    return {
      searchQuery: null,
      query: '',
    };
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: this.searchQuery,
        };
      },
      skip() {
        return !this.searchQuery;
      },
    },
  },
  methods: {
    search() {
      this.searchQuery = this.query;
    },
  },
};
</script>

<style scoped>
.ch-users-search{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-users-search__header >>> .cs-search-bar{
  background-color: var(--cs-gray-00);
}
.ch-users-search__header >>> .cs-search-bar__input{
  background-color: var(--cs-gray-00);
}

</style>
