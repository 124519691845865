<template>
  <div class="ch-create-modal">
    <app-modal
      :show="showMeetingCreate"
      :title="parentMeeting ? 'Create Breakout' : 'Create Event'"
      action="Create"
      :disable-action="saving || slugLoading"
      @close="close"
      @action="createMeeting"
    >
      <div v-if="saving" class="progress-indicator">
        <app-spinner />
        <app-aws-progress />
      </div>
      <!-- Tabs  -->
      <cs-page-tabs v-if="!saving" align="justify">
        <!-- First Tab - Details  -->
        <cs-page-tab label="Details">
          <validation-observer
            ref="meetingPayment"
            tag="div"
            class="ch-create-meeting__fields"
          >
            <div class="cs-textstyle-paragraph-small info-text">
              Enter the details below to help people discover your event.
            </div>
            <!-- Title -->
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true }"
            >
              <cs-input v-model="draftMeeting.name" label="Name"></cs-input>
              <div v-if="errors[0]" class="meeting-referral-code__error">
                The ticket name is required
              </div>
            </validation-provider>
            <!-- Category -->
            <cs-select
              v-model="draftMeeting.category"
              label="Category"
              :options="categoryOptions"
            >
            </cs-select>
            <!-- Description -->
            <cs-textarea
              v-model="draftMeeting.description"
              label="Description"
            ></cs-textarea>
            <!-- Cover Image -->
            <app-image-input
              v-model="draftMeeting.picture"
              :enforce-ratio="1 / 1"
              label="Picture (required ratio 1 : 1)"
            />
            <!-- paid event price -->
            <label v-if="hasPaymentId" class="cs-textstyle-label-text"
              >Tickets</label
            >
            <cs-checkbox
              v-if="hasPaymentId"
              v-model="enableTickets"
              @input="clearTicketPrice"
              >Charge for access</cs-checkbox
            >
            <div v-if="enableTickets">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true, min_value: 1 }"
              >
                <cs-input
                  v-model="draftMeeting.ticketPrice"
                  class="input-size"
                  label="Ticket Price (USD)"
                  type="number"
                >
                </cs-input>
                <div v-if="errors[0]" class="meeting-referral-code__error">
                  The ticket price cannot be 0 or less than 0 or empty
                </div>
              </validation-provider>
              <div
                v-for="(referralCode, index) in referralCodes"
                :key="index"
                class="meeting-referral-code"
              >
                <div
                  class="meeting-referral-code__title cs-textstyle-label-text date-label"
                >
                  Referral code #{{ index + 1 }}
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ required: true }"
                >
                  <cs-input
                    v-model="referralCode.name"
                    class="mt-3 input-size"
                    label="Name"
                    type="text"
                  >
                  </cs-input>
                  <div v-if="errors[0]" class="meeting-referral-code__error">
                    Referral code name is required
                  </div>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value:
                      referralCode.type === 'USD'
                        ? draftMeeting.ticketPrice
                        : 100,
                  }"
                >
                  <cs-input
                    v-model="referralCode.amount"
                    class="mt-3 input-size"
                    :label="`Discount in ${referralCode.type}`"
                    type="number"
                  >
                  </cs-input>
                  <div v-if="errors[0]" class="meeting-referral-code__error">
                    Discount is required and it should be lower than Ticket
                    price
                  </div>
                </validation-provider>
                <div class="meeting-referral-code__footer">
                  <cs-toggle
                    label-checked="Return to USD"
                    label-unchecked="Switch to %"
                    @change="changeReferralCodeType($event, index)"
                  ></cs-toggle>
                  <cs-button
                    title="Delete ReferralCode"
                    @click="deleteReferralCode(index)"
                  >
                    <i class="cs-icons-delete"></i> #{{ index + 1 }}
                  </cs-button>
                </div>
              </div>
              <cs-button
                class="meeting-referral-code__add mt-5 input-size"
                @click="addNewReferralCode"
              >
                <i class="cs-icons-plus"></i> Referral code
              </cs-button>
            </div>
            <!-- Event Start/End date -->
            <label class="cs-textstyle-label-text date-label">Event Date</label>
            <cs-radio-group
              :options="dateOptions"
              :value="value"
              @input="selectDateOption"
            />
            <div v-if="!startsImmediately" class="start-input">
              <cs-date-input
                v-model="draftMeeting.startDate"
                class="datetime-input"
                label="Start Date"
              ></cs-date-input>
              <cs-time-input
                v-model="draftMeeting.startTime"
                class="datetime-input"
                label="Start Time"
              ></cs-time-input>
            </div>
            <!-- Privacy -->
            <cs-radio-group
              v-model="draftMeeting.privacy"
              label="Who can view and attend?"
              :options="privacyOptions"
            />
            <!-- Enable Queue -->
            <cs-radio-group
              v-model="draftMeeting.enableQueue"
              label="Allow audience to raise hand?"
              :options="enableQueueOption"
            />
            <cs-radio-group
              v-model="draftMeeting.liveStreamMode"
              label="Where do you want to host speakers?"
              :options="liveStreamModeOptions"
            />
            <!-- slug -->
            <app-slug-input
              :name="draftMeeting.name"
              slug-type="MEETING"
              @slugInput="handleSlugInput"
              @slugChecking="slugLoading = true"
            />
          </validation-observer>
        </cs-page-tab>
        <!-- Second Tab - Custom Buttons  -->
        <cs-page-tab label="Custom Buttons">
          <div
            class="cs-textstyle-paragraph-small info-text custom-button-text"
          >
            You can add up to 3 custom buttons to your event, to help attendees
            discover your other content
          </div>
          <!-- First Button  -->
          <cs-input
            v-model="button1Label"
            class="input-size"
            label="Custom Button 1 Label"
            placeholder="e.g. My First Button"
          />
          <cs-input
            v-model="button1Link"
            class="input-size"
            label="Custom Button 1 Link"
            placeholder="e.g. http://example.com/"
          />
          <!-- second button  -->
          <cs-input
            v-model="button2Label"
            class="input-size"
            label="Custom Button 2 Label"
            placeholder="e.g. My Second Button"
          />
          <cs-input
            v-model="button2Link"
            class="input-size"
            label="Custom Button 2 Link"
            placeholder="e.g. http://example.com/"
          />
          <!-- third button  -->
          <cs-input
            v-model="button3Label"
            class="input-size"
            label="Custom Button 3 Label"
            placeholder="e.g. My Third Button"
          />
          <cs-input
            v-model="button3Link"
            class="input-size"
            label="Custom Button 3 Link"
            placeholder="e.g. http://example.com/"
          />
        </cs-page-tab>
      </cs-page-tabs>
    </app-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import $bus from '@/services/bus';
import $upload from '@/services/upload';
import $socket from '@/services/socket';
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $browser from '@/services/browser';
import meetingcategories from '@/assets/config/meetingcategories.json';
import InsertMeeting from '@/gql/meetings/InsertMeeting.gql';
import AppModal from '@/components/general/Modal.vue';
import AppImageInput from '@/components/general/ImageInput.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppAwsProgress from '@/components/general/AwsProgress.vue';
import AppSlugInput from '@/components/general/SlugInput.vue';

export default {
  components: {
    AppModal,
    AppImageInput,
    AppSpinner,
    AppAwsProgress,
    AppSlugInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isBreakout: {
      type: Boolean,
      default: false,
    },
    meetingMain: {
      type: Object,
    },
  },
  data() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tempDate = tomorrow.toISOString().split('T')[0];
    return {
      referralCodes: [],
      draftMeeting: {
        startDate: tempDate.replace(/-/g, '/'),
        startTime: '12:00:00',
        privacy: 'public',
        enableQueue: 'true',
        liveStreamMode: 'true',
        ticketPrice: 0,
      },
      privacyOptions: [
        { label: 'Anyone', value: 'public' },
        { label: 'Only people invited by hosts', value: 'private' },
      ],
      enableQueueOption: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      liveStreamModeOptions: [
        { label: 'CloutHub', value: 'false' },
        { label: 'External Software', value: 'true' },
      ],
      showMeetingCreate: false,
      categoryOptions: meetingcategories,
      startsImmediately: true,
      saving: false,
      hasPaymentId: false,
      dateOptions: ['Start Now', 'Schedule'],
      value: 'Start Now',
      formattedDate: null,
      parentMeeting: null,
      button1Label: null,
      button1Link: null,
      button2Label: null,
      button2Link: null,
      button3Label: null,
      button3Link: null,
      buttons: [],
      slugLoading: true,
      enableTickets: false,
    };
  },
  computed: {
    defaultMeetingPicture() {
      if (this.draftMeeting.category === 'news')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/72a76cac-0a84-49e2-a214-8b905c08fb21.jpg';
      if (this.draftMeeting.category === 'faith')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/f7d9e5bb-dc61-4b0a-b944-c350e4511721.jpg';
      if (this.draftMeeting.category === 'politics')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/accdfc90-cf02-40be-afac-2969e210e471.jpg';
      if (this.draftMeeting.category === 'music')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/9f3a762c-54c5-4c0f-b403-646fea404390.jpg';
      if (this.draftMeeting.category === 'finance')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/110335ab-41da-4f67-8337-4db2626e3853.jpg';
      if (this.draftMeeting.category === 'business')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/07f2824b-b4aa-459c-9d46-9df627633967.jpg';
      if (this.draftMeeting.category === 'health')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/0cf89b2a-9db0-4bde-8d68-ea100928ddc8.jpg';
      if (this.draftMeeting.category === 'movies')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/2b7c4959-fb9e-4875-baef-ed12a1a28a38.jpg';
      if (this.draftMeeting.category === 'sports')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/36310ca6-95d8-4637-bf6f-92fdbd9185dc.jpg';
      if (this.draftMeeting.category === 'technology')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/4a5aa8b0-f119-4686-a6e1-917942b89798.jpg';
      if (this.draftMeeting.category === 'games')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/69779d41-a83e-4c2e-9803-593b78648006.jpg';
      if (this.draftMeeting.category === 'education')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/885f2e84-eecd-444a-9a09-655dddbc5f4b.jpg';
      if (this.draftMeeting.category === 'personalgrowth')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/da1e0f0b-6a45-4d91-bb97-43ea4ffc4fa4.jpg';
      if (this.draftMeeting.category === 'fashion')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/6542d9b4-b0c3-4d37-bacc-ea278a0b4033.jpg';
      if (this.draftMeeting.category === 'humor')
        return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/b9305028-a6ea-4789-91f8-1d8c229019d6.jpg';
      return 'https://chforum.backendcdn.com/us-west-2:1323b126-2579-4eba-bfe1-7d01b2e75c56/acad50d2-1315-4f00-995f-49110e899f08.jpg';
    },
  },
  watch: {
    isBreakout() {
      if (this.isBreakout) {
        this.breakoutConfig();
      }
    },
  },
  async mounted() {
    const user = await $auth.getUser();
    if (user?.enablePayments) this.hasPaymentId = true;
    $bus.$on('show-create-meeting-modal', () => {
      this.reset();
      this.showMeetingCreate = true;
    });
  },
  methods: {
    deleteReferralCode(index) {
      this.referralCodes.splice(index, 1);
    },
    changeReferralCodeType({ value }, index) {
      this.referralCodes[index].type = value ? '%' : 'USD';
    },
    async addNewReferralCode() {
      const valid = await this.$refs.meetingPayment.validate();
      if (!valid) return;
      this.referralCodes.push({
        name: '',
        amount: '',
        type: 'USD',
      });
    },
    handleSlugInput(slug) {
      this.draftMeeting.slug = slug;
      this.slugLoading = false;
    },
    reset() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tempDate = tomorrow.toISOString().split('T')[0];
      this.draftMeeting = {
        startDate: tempDate.replace(/-/g, '/'),
        startTime: '12:00:00',
        privacy: 'public',
        enableQueue: 'true',
        slug: '',
        liveStreamMode: 'true',
      };
      this.referralCodes = [];
      this.parentMeeting = null;
      this.formattedDate = null;
    },
    close() {
      this.showMeetingCreate = false;
      this.$emit('close');
    },
    breakoutConfig() {
      this.showMeetingCreate = true;
      this.parentMeeting = this.meetingMain;
    },
    selectDateOption(val) {
      if (val === 'Start Now') {
        this.startsImmediately = true;
      } else {
        this.startsImmediately = false;
      }
    },
    async createMeeting() {
      const valid = await this.$refs.meetingPayment.validate();
      if (!valid) return;
      this.saving = true;
      if (this.button1Label || this.button1Link) {
        const link =
          this.button1Link &&
          this.button1Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button1Link}`
            : this.button1Link === '' ||
              this.button1Link === null ||
              this.button1Link.value === 0
            ? null
            : `http://${this.button1Link}`;
        this.buttons = [{ label: this.button1Label, link }];
      }
      if (this.button2Label || this.button2Link) {
        const link =
          this.button2Link &&
          this.button2Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button2Link}`
            : this.button2Link === '' ||
              this.button2Link === null ||
              this.button2Link.value === 0
            ? null
            : `http://${this.button2Link}`;
        this.buttons = [...this.buttons, { label: this.button2Label, link }];
      }
      if (this.button3Label || this.button3Link) {
        const link =
          this.button3Link &&
          this.button3Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button3Link}`
            : this.button3Link === '' ||
              this.button3Link === null ||
              this.button3Link.value === 0
            ? null
            : `http://${this.button3Link}`;
        this.buttons = [...this.buttons, { label: this.button3Label, link }];
      }
      if (!this.startsImmediately) {
        this.formattedDate = this.$moment(
          `${this.draftMeeting.startDate} ${this.draftMeeting.startTime}`,
          'YYYY-MM-DD HH:mm:ss'
        ).format();
      } else {
        this.formattedDate = this.$moment().format();
      }
      if (this.draftMeeting.picture) {
        const picture = await $upload.uploadImages([this.draftMeeting.picture]);
        this.draftMeeting.picture = picture[0].url;
      }
      const resp = await this.$apollo.mutate({
        mutation: InsertMeeting,
        variables: {
          name: this.draftMeeting.name,
          description: this.draftMeeting.description,
          picture: this.draftMeeting.picture || this.defaultMeetingPicture,
          category: this.draftMeeting.category,
          privacy: this.draftMeeting.privacy,
          startTime: this.formattedDate,
          buttons: this.buttons,
          slug: this.draftMeeting.slug,
          parentMeetingId: this.parentMeeting ? this.parentMeeting.id : null,
          enableQueue: this.draftMeeting.enableQueue === 'true',
          liveStreamMode: this.draftMeeting.liveStreamMode === 'true',
          ticketPrice: parseFloat(this.draftMeeting.ticketPrice),
          referralCodes: this.referralCodes,
        },
      });
      const meeting = resp.data.insert_meeting;
      this.buttons = [];
      this.saving = false;
      this.close();
      if (this.parentMeeting) {
        $socket.sendRoom(
          'meeting',
          this.parentMeeting.id,
          'meeting-breakouts-modified'
        );
      } else {
        this.openMeeting(meeting);
      }
    },
    openMeeting(meeting) {
      const accessToken = encodeURIComponent(localStorage.access_token);
      const next = encodeURIComponent(`/meetings/${meeting.id}`);
      $browser.open(
        `https://m.clouthub.com/go.html?access_token=${accessToken}&next=${next}`
      );
    },
    clearTicketPrice() {
      this.draftMeeting.ticketPrice = 0;
    },
  },
};
</script>

<style scoped>
.ch-create-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--cs-gray);
}
* >>> .ch-modal__body {
  padding: 1px 0px;
}
.ch-create-meeting__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px auto 20px;
}
.progress-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.custom-button-text {
  margin-bottom: 10px;
}
.meeting-referral-code__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0;
}

.meeting-referral-code__title {
  margin: 15px 0;
}

.meeting-referral-code__add {
  width: 100%;
  margin: 15px 0;
}

.meeting-referral-code__error {
  color: var(--cs-danger-base);
}
</style>
