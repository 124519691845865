<template>
  <action-sheet :open="showModal" @close="close()">
    <div class="ch-share__actionsheet">
      <div class="ch-share-modal__header">
        <span>{{ title }}</span>
        <i class="cs-icons-close" @click="close()" />
      </div>
      <div class="ch-share-modal__body">
        <div class="ch-share-btns">
          <div class="ch-share-external">
            <div class="ch-share-btn internal" @click="sharePost()">
              <i class="ch-icons-forum"></i>
            </div>
            <div class="ch-share-external-label">CloutHub Forum</div>
          </div>
          <div class="ch-share-external">
            <div class="ch-share-btn internal" @click="shareMessage()">
              <i class="ch-icons-message"></i>
            </div>
            <div class="ch-share-external-label">CloutHub Message</div>
          </div>
          <div v-if="!isCordova && link" class="ch-share-external">
            <a
              class="ch-share-btn"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${link}`"
              target="_blank"
              @click="close()"
            >
              <i class="cs-icons-facebook"></i>
            </a>
            <div class="ch-share-external-label">Facebook</div>
          </div>
          <div v-if="!isCordova && link" class="ch-share-external">
            <a
              class="ch-share-btn"
              :href="`https://twitter.com/intent/tweet?url=${link}&text=`"
              target="_blank"
              @click="close()"
            >
              <i class="cs-icons-twitter"></i>
            </a>
            <div class="ch-share-external-label">Twitter</div>
          </div>
          <div v-if="!isCordova && link" class="ch-share-external">
            <a
              class="ch-share-btn"
              :href="`mailto:info@example.com?&subject=&cc=&bcc=&body=${link}`"
              target="_blank"
              @click="close()"
            >
              <i class="cs-icons-email"></i>
            </a>
            <div class="ch-share-external-label">Email</div>
          </div>
          <div v-if="!isCordova && link" class="ch-share-external">
            <div class="ch-share-btn" @click="copyLink()">
              <i class="ch-icons-clone"></i>
            </div>
            <div class="ch-share-external-label">Copy Link</div>
          </div>
          <div v-if="isCordova && link" class="ch-share-external">
            <div class="ch-share-btn" @click="shareCordova()">
              <i class="ch-icons-clone"></i>
            </div>
            <div class="ch-share-external-label">Other</div>
          </div>
        </div>
      </div>
    </div>
  </action-sheet>
</template>

<script>
import $bus from '@/services/bus';
import ActionSheet from '@/components/general/ActionSheet.vue';

export default {
  components: {
    ActionSheet,
  },
  data() {
    return {
      showModal: false,
      link: null,
      title: '',
      post: null,
    };
  },
  computed: {
    isCordova() {
      return !!window.cordova;
    },
  },
  mounted() {
    $bus.$on('show-share-sheet', async (params) => {
      // params: title, post, link, embedCode
      this.showModal = true;
      this.title = params.title;
      this.post = params.post;
      this.embedCode = params.embedCode;
      this.link = `${process.env.VUE_APP_SHARE_DOMAIN}${params.link}`;
    });
  },
  methods: {
    close() {
      this.showModal = false;
    },
    sharePost() {
      if (this.post) {
        $bus.$emit('show-share-post-modal', this.post);
      } else {
        $bus.$emit('show-post-composer-modal', { link: this.link });
      }
      this.close();
    },
    shareMessage() {
      if (this.post) {
        $bus.$emit('new-message-modal', { post: this.post });
      } else if (this.link) {
        $bus.$emit('new-message-modal', { link: this.link });
      }
      this.close();
    },
    async copyLink() {
      await navigator.clipboard.writeText(this.link);
      this.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
      this.close();
    },
    async shareCordova() {
      window.plugins.socialsharing.shareWithOptions({
        message: `${this.link}`,
      });
      this.close();
    },
  },
};
</script>

<style scoped>
.ch-share__actionsheet {
  display: flex;
  flex-direction: column;
}
.ch-share-modal__header {
  display: flex;
  padding-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-size: 700;
}
.ch-share-modal__body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ch-share-btns {
  text-align: center;
}
.ch-share-external {
  text-align: center;
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-bottom: 20px;
}
.ch-share-btn {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: var(--cs-gray-03);
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.ch-share-external-label {
  font-size: 14px;
  margin-top: 5px;
}
.ch-share-btn.internal {
  background-color: var(--cs-primary-base);
}
</style>
