<template>
  <div class="navigation-bar">
    <div class="navigation-bar__back">
      <div class="navigation-bar__back__detailed" @click="$router.go(-1)">
        <i class="cs-icons-back-angle" />
        <span> Back</span>
      </div>
      <app-video-detail-option-sheet v-model="isOptionsOpened" :post="post" />
      <app-video-detail-handouts v-model="isHandoutsOpened" :post="post" />
    </div>
    <transition name="ch-action-sheet-fade">
      <div class="navigation-bar__control" v-if="isOptionVisible">
        <i class="ch-icons-share" @click="shareVideo"></i>
        <app-bookmark-button :post="post" size="large" />
        <app-queue-button :post="post" size="large" />
        <i
          class="navigation-bar__handouts cs-icons-download"
          @click="openHandouts"
        />
        <i
          class="ch-icons-options navigation-bar__options"
          @click="openOptions"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import $bus from '@/services/bus';

import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppVideoDetailHandouts from '@/components/videos/detail/VideoDetailHandouts.vue';
import AppVideoDetailOptionSheet from '@/components/videos/detail/VideoDetailOptionSheet.vue';

export default {
  components: {
    AppQueueButton,
    AppBookmarkButton,
    AppVideoDetailHandouts,
    AppVideoDetailOptionSheet,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOptionVisible: false,
    isOptionsOpened: false,
    isHandoutsOpened: false,
  }),
  mounted() {
    $bus.$on('video-detail-live-messages-scroll-up', () => {
      this.isOptionVisible = true;
    });
    $bus.$on('video-detail-live-messages-scrolled-bottom', () => {
      this.isOptionVisible = false;
    });
  },
  methods: {
    openOptions() {
      this.isOptionsOpened = true;
    },
    openHandouts() {
      this.isHandoutsOpened = true;
    },
    shareVideo() {
      const link = `/v/${this.post.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        post: this.post,
        title: 'Share Video',
      });
    },
  },
};
</script>
<style scoped>
.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 51px;
  padding: 11px 15px;
  border: solid 1px #e7eaea;
}
.navigation-bar__back {
  font-size: 16px;
  font-weight: bold;
}
.navigation-bar__back__detailed {
  padding: 5px 20px 5px 0;
}
.navigation-bar__control {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: auto;
  color: #4a5359;
}
.navigation-bar__handouts {
  font-size: 21px;
}
</style>
