<template>
  <ion-list>
    <ion-list-header v-if="managedGroups.length" class="group-list-header">
      Groups I Manage
      <app-group-sort-icon class="group-sort-icon" @click="showSortBy = true" />
    </ion-list-header>

    <app-group-list-item
      v-for="group in managedGroups"
      :key="group.id"
      :group="group"
      show-menu
    />

    <hr v-if="managedGroups.length > 0" class="group-divider" />

    <ion-list-header v-if="joinedGroups.length" class="group-list-header">
      Groups I’ve Joined
      <app-group-sort-icon class="group-sort-icon" @click="showSortBy = true" />
    </ion-list-header>

    <app-group-list-item
      v-for="group in joinedGroups"
      :key="group.id"
      :group="group"
      show-menu
    />

    <app-group-sort-menu
      :value="sortBy"
      :open="showSortBy"
      @input="$emit('changeSort', $event); showSortBy = false"
      @close="showSortBy = false"
    />
  </ion-list>
</template>

<script>
import AppGroupListItem from '@/components/groups/GroupListItem.vue';
import AppGroupSortIcon from '@/components/groups/GroupSortIcon.vue';
import AppGroupSortMenu from '@/components/groups/GroupSortMenu.vue';

export default {
  components: {
    AppGroupSortMenu,
    AppGroupSortIcon,
    AppGroupListItem,
  },
  props: {
    groups: {
      type: Array,
      required: true,
      default: () => [],
    },
    sortBy: {
      type: String,
    },
  },
  emits: ['changeSort'],
  data() {
    return {
      showSortBy: false,
    };
  },
  computed: {
    managedGroups() {
      return this.groups.filter((group) =>
        ['admin', 'moderator'].includes(group.myMembership?.role)
      );
    },
    joinedGroups() {
      return this.groups.filter(
        (group) =>
          ['admin', 'moderator'].includes(group.myMembership?.role) === false
      );
    },
  },
};
</script>

<style scoped>
.group-sort-icon {
  margin-inline-end: 6px;
}

.group-list-header {
  padding-inline-end: 8px;
  text-transform: none;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.group-divider {
  margin: 10px 0;
  height: 1px;
  background: #e7eaea;
}
</style>
