<template>
  <div>
    <app-modal
      :show="showReactions"
      class="ch-user-reactions-modal"
      title="Reactions"
      @close="onClose"
    >
      <div v-if="showReactions" class="ch-user-reactions">
        <div class="tab-bar">
          <div
            v-for="(tab, index) in tabs"
            :key="tab.value"
            class="tab"
            :class="{ tab__selected: index === selectedIndex }"
            @click="activeTab(tab.value, index)"
          >
            <img
              v-if="tab.value"
              class="emoji"
              :src="'./assets/emojis/' + tab.value + '.png'"
              :alt="tab.value"
            />
            <span>{{ tab.label }}</span>
          </div>
        </div>
        <cs-spinner
          v-if="!showList && $apollo.queries.list_post_reactions.loading"
        />
        <div v-if="showList" class="ch-user-list">
          <app-user-list :users="reactionUsers" @onClose="onClose" />
          <app-infinite-simple
            v-if="list_post_reactions.length > 9"
            :variables="variables"
            :query="$apollo.queries.list_post_reactions"
            sort-key="createdAt"
          ></app-infinite-simple>
        </div>
        <cs-empty-state
          v-if="showList && list_post_reactions.length === 0"
          description="No reactions yet"
        />
      </div>
    </app-modal>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import AppUserList from '@/components/general/UserList.vue';
import AppModal from '@/components/general/Modal.vue';
import ListPostReactions from '@/gql/posts/ListPostReactions.gql';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppUserList,
    AppModal,
    AppInfiniteSimple,
  },
  apollo: {
    list_post_reactions: {
      query: ListPostReactions,
      variables() {
        return {
          id: this.post.id,
        };
      },
      skip() {
        return !this.post;
      },
    },
  },
  data() {
    return {
      showReactions: false,
      post: null,
      totalcount: 0,
      tabEmoji: {
        pray: '🙏',
        like: '👍',
        dislike: '👎',
        smile: '😊',
        laugh: '🤣',
        love: '❤️',
        cry: '😢',
        angry: '😡',
        block: '🚫',
      },
      tabs: [],
      refetch: false,
      variables: null,
      selectedIndex: 0,
    };
  },
  computed: {
    showList() {
      return (
        !this.refetch &&
        this.list_post_reactions &&
        this.list_post_reactions.length
      );
    },
    reactionUsers() {
      return (
        this.list_post_reactions && this.list_post_reactions.map((r) => r.user)
      );
    },
  },
  mounted() {
    $bus.$on('show-reaction-list-modal', (post) => {
      this.post = post;
      this.tabs = [
        {
          label: `All ${this.post.reactionCount}`,
        },
      ];
      this.post.reactionTotals.forEach((reactionType) => {
        if (reactionType.count > 0) {
          this.tabs.push({
            label: `${reactionType.count}`,
            value: reactionType.type,
          });
        }
      });
      this.tabs = [...this.tabs].sort(
        (a, b) => a.label.split(' ')[1] - b.label.split(' ')[1]
      );

      this.showReactions = true;
    });
  },
  methods: {
    reset() {
      this.totalcount = 0;
      this.tabs = [];
    },
    activeTab(val, index) {
      this.selectedIndex = index;
      this.fetchReactions(val);
    },
    onClose() {
      this.reset();
      this.showReactions = false;
    },
    async fetchReactions(type) {
      this.variables = {
        type,
      };
      this.refetch = true;
      await this.$apollo.queries.list_post_reactions.refetch({
        id: this.post.id,
        type,
      });
      this.refetch = false;
    },
  },
};
</script>

<style scoped>
.ch-user-reactions {
  width: 100%;
  height: 100%;
}
.ch-user-list {
  height: 92%;
  overflow: scroll;
}
.body-header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--cs-gray-02);
  margin-bottom: 20px;
}
.ch-create-post__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tab-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 5px;
  background-color: white;
  margin-bottom: 5px;
  flex-wrap: wrap;
}
.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-size: 16px;
  font-weight: bold;
}
.emoji {
  height: 20px;
  width: 20px;
}
.tab__selected {
  color: var(--cs-primary-base);
}
</style>
