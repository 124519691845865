<template>
  <span class="ch-modal">
    <transition name="ch-modal-fade">
      <div
        v-if="isOpen"
        class="ch-modal__background"
        @click="
          isOpen = false;
          $emit('close');
        "
      ></div>
    </transition>
    <transition name="ch-modal-slide">
      <div v-if="isOpen" class="ch-modal__content">
        <div v-if="showHeader" class="ch-modal__header">
          <div class="ch-modal__header-close-wrapper">
            <i
              v-if="!hideClose"
              class="ch-modal__header-icon cs-icons-close-filled"
              @click="closeFunction"
            />
            <i
              v-if="showBackIcon"
              class="ch-modal__header-icon cs-icons-back-arrow"
              @click="$emit('back')"
            />
          </div>
          <div class="ch-modal__header-title cs-textstyle-section-heading">
            {{ title }}
          </div>
          <div
            v-if="!(action && action.includes('icon'))"
            class="ch-modal__header-button cs-textstyle-paragraph-bold"
            :disabled="disableAction"
            @click="$emit('action')"
          >
            {{ action }}
          </div>
          <a v-if="action && action.includes('icon')" :href="link" download
            ><i
              class="ch-modal__header-icon cs-textstyle-paragraph-bold"
              :class="action"
              :disabled="disableAction"
              @click="$emit('action')"
            ></i
          ></a>
        </div>
        <div
          class="ch-modal__body"
          :class="{ 'ch-modal__body--without-header': !showHeader }"
        >
          <slot />
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
import $popups from '@/services/popups';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    action: {
      type: String,
    },
    withClosingAlert: {
      type: Boolean,
      default: false,
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    showBackIcon: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: this.show,
      appendedToBody: false,
    };
  },
  methods: {
    async requestClosing() {
      const confirmClose = await $popups.confirmClose({
        title: 'Do you want to close without posting?',
        message:
          'Your post will be lost if you click OK or press CANCEL to continue with your post.',
      });
      if (confirmClose) this.close();
    },
    close() {
      this.$emit('close');
    },
    closeFunction() {
      this.withClosingAlert ? this.requestClosing() : this.close();
    },
  },
  watch: {
    async show() {
      const tabsPage = document.querySelector('ion-tab-bar');
      if (tabsPage && this.$el && !this.appendedToBody) {
        tabsPage.parentElement.appendChild(this.$el);
        this.appendedToBody = true;
        await this.$nextTick();
      }
      this.isOpen = this.show;
    },
  },
  mounted() {
    if (this.$parent) {
      this.$parent.$once('hook:beforeDestroy', () => {
        this.$emit('close');
        if (this.$el) this.$el.remove();
        this.$destroy();
      });
    }
    // document.addEventListener('backbutton', (e) => {
    //   if (this.isOpen) {
    //     console.log('Modal Block');
    //     e.preventDefault();
    //     this.$emit('close');
    //   }
    // }, false );
  },
};
</script>

<style scoped>
.ch-modal-fade-enter-active,
.ch-modal-fade-leave-active {
  transition: all 0.3s;
}
.ch-modal-fade-enter,
.ch-modal-fade-leave-to {
  opacity: 0;
}
.ch-modal-slide-enter-active,
.ch-modal-slide-leave-active {
  transition: all 0.3s;
}
.ch-modal-slide-enter,
.ch-modal-slide-leave-to {
  transform: translateX(100%);
}

.ch-modal__background {
  z-index: 1000;
  background-color: rgba(27, 38, 51, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ch-modal__content {
  background-color: var(--cs-gray-01);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  width: 100%;
}
/* HEADER */
.ch-modal__header {
  position: relative;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  padding-bottom: 5px;
  background-color: var(--cs-gray-00);
  border-bottom: solid 1px var(--cs-gray-02);
  z-index: 1;
  box-sizing: border-box;
  height: 56px;
}
.ch-modal__header-icon {
  font-size: 22px;
  color: var(--cs-gray-05);
  padding: 5px;
  margin-top: -5px;
  margin-left: -5px;
  cursor: pointer;
}
.ch-modal__header-title {
  flex: 1;
  color: var(--cs-gray-06);
  text-align: center;
  padding: 5px 10px;
  margin-top: -10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ch-modal__header-close-wrapper {
  margin-top: -10px;
  width: 80px;
}
.ch-modal__header-button {
  text-align: right;
  padding: 5px;
  margin-top: -10px;
  margin-right: -5px;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--cs-primary-base);
  width: 80px;
}
.ch-modal__header-button[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
/* BODY */
.ch-modal__body {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 15px 20px;
}
.ch-modal__body--without-header {
  top: 0;
}
</style>
