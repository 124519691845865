<template>
  <div
    v-if="
      notification &&
      notification.lastActor &&
      group &&
      !notification.lastActor.myRelationship.isBlocked
    "
    class="notification-item"
  >
    <app-avatar
      v-router-link="{ name: 'Profile', params: notification.lastActor }"
      :picture="
        notification.lastActor ? notification.lastActor.profilePhotoURL : ''
      "
      :name="notification.lastActor ? notification.lastActor.username : ''"
      size="small"
      class="notification-item-avatar"
    />
    <div class="wrapper">
      <div class="content" @click="viewDetails()">
        <div>
          <span class="cs-textstyle-paragraph-small-bold">{{
            notification.lastActor ? notification.lastActor.username : ''
          }}</span>
          <span class="text-light cs-textstyle-paragraph-small">
            {{ more }}
            <template v-if="!loading">
              {{ description }}
            </template>
            <template v-else> Loading... </template>
          </span>
        </div>
        <div class="text-light cs-textstyle-paragraph-small">
          {{ notification.updatedAt | moment('from') }}
        </div>
      </div>
      <button
        v-if="showExpandButton"
        class="expand-collapse-btn"
        @click.prevent="toggleExpand"
      >
        {{ collapsed ? 'Expand' : 'Collapse' }}
      </button>
    </div>
    <div v-if="!collapsed">
      <!-- Add this wrapper div -->
      <!-- Put the expandable content here -->
    </div>
  </div>
</template>


<script>
import $cdn from '@/services/cdn';
import $browser from '@/services/browser';
import $apollo from '@/services/apollo';

import AppAvatar from '@/components/general/Avatar.vue';
import GetPostType from '@/gql/posts/GetPostType.gql';

async function getPostType(postId) {
  const resp = await $apollo.query({
    query: GetPostType,
    variables: {
      id: postId,
    },
  });
  let postType = resp.data.post.type;

  // Videos posted on the timeline do not have correct type field
  if (resp.data.post.video) {
    postType = 'video';
  }

  return postType;
}

export default {
  components: {
    AppAvatar,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    expandable: {    // Add this new prop
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    showExpandButton: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      showAnnouncementPopup: false,
      visibleAnnouncementModal: false,
      group: {},
      loading: false,
      postType: undefined,
    };
  },
  computed: {
    isModerationNotification() {
      return ['pendingReview', 'rejectedReview'].includes(
        this.notification.type
      );
    },
    description() {
      const descriptionMap = {
        follower: 'followed you.',
        like: 'liked your post.',
        postMention: 'mentioned you in a post.',
        newMention: 'mentioned you in a post.',
        newComment: 'commented on your post.',
        replyComment: 'replied to your comment.',
        share: 'shared your post.',
        glimpseLike: 'liked your video.',
        newVideo: 'uploaded a new video',
        liveStreaming: 'is livestreaming',
        react: 'reacted to your post.',
        reaction: 'reacted to your post.', // For backwards compatibility
        connectionAccepted: 'accepted your friend request.',
        pendingReview:
          'The content of a video you are posting was not properly recognized by the Artificial Intelligence we use to prevent inappropriate videos. It has been sent to a staff person to review it. This should only take a moment. Thank you for your understanding.',
        pendingReview_images:
          'The content of an image you are posting was not properly recognized by the Artificial Intelligence we use to prevent inappropriate images. It has been sent to a staff person to review it. This should only take a moment. Thank you for your understanding.',
        pendingReview_video:
          'The content of a video you are posting was not properly recognized by the Artificial Intelligence we use to prevent inappropriate videos. It has been sent to a staff person to review it. This should only take a moment. Thank you for your understanding.',
        completedReview:
          'Your image or video reviewed by our AI was fine. Thank you for contributing to our site!',
        rejectedReview: 'Your post has been rejected by our safety team.',
        rejectedReview_images:
          'It appears the image you are attempting to post may not comply with CloutHub’s Terms of Use. Please choose a different image for your post and try again. For questions, contact support@clouthub.com',
        rejectedReview_video:
          'It appears the video you are attempting to post may not comply with CloutHub’s Terms of Use. Please choose a different video for your post and try again. For questions, contact support@clouthub.com',
        newStoryPublished: 'published a story.',
        channelSubscribeInvitation: this.notification.lastActor
          ? `has invited you to subscribe to ${this.notification.lastActor.channelName}`
          : 'invited you to subscribe to their channel',
        newAnnouncement: `added a new announcement to "${
          this.notification.announcement
            ? this.notification.announcement.group.name
            : ''
        }"`,
        meetingInvitation: this.notification.meetingId
          ? ` has invited you to the meeting ${this.notification.meeting.name} which starts on ${this.displayTime} `
          : 'invited you',
        meetingStarted: this.notification.meetingId
          ? `has started a meeting  ${this.notification.meeting.name} on ${this.displayTime}  `
          : 'meeting started',
        leaderMeetingInvitation: this.notification.meetingId
          ? `has made you leader of a meeting ${this.notification.meeting.name} which starts on ${this.displayTime} `
          : 'has made you leader meeting',
      };
      let { type } = this.notification;
      if (this.isModerationNotification && this.postType) {
        type = `${type}_${this.postType}`;
      }

      const description = descriptionMap[type] || type;

      return description;
    },
    more() {
      return this.notification.itemCount > 1
        ? `and ${this.notification.itemCount - 1} others`
        : '';
    },
    displayTime() {
      const currentUserTimezone = this.$moment.tz.guess();
      return (
        this.notification.meeting &&
        this.$moment
          .utc(this.notification.meeting.startTime)
          .local()
          .tz(currentUserTimezone)
          .format('MMM D [|] h:mmA z')
      );
    },
    isAnnouncement() {
      return this.notification.type === 'newAnnouncement';
    },
  },
  async created() {
    if (this.notification.postId && this.isModerationNotification) {
      this.loading = true;
      this.postType = await getPostType(this.notification.postId);
      this.loading = false;
    }
  },
  methods: {
    toggleExpand(event) {
      event.stopPropagation(); // Add this line
      this.$emit('toggleExpand');
    },
    cdn(url) {
      if (!url) return '';
      return $cdn.optimize(url);
    },
    showAnnouncement() {
      this.$router.push({
        name: 'Announcement',
        params: {
          id: this.notification.announcement.id,
        },
      });
    },
    viewDetails() {
      if (this.expandable && !this.collapsed) {
        this.$emit('toggleExpand');
        return;
      }
      
      console.log('Notification', this.notification);
      if (this.notification.type === 'liveStreaming') {
        this.$router.push({
          name: 'VideoDetail',
          params: { id: this.notification.postId },
        });
      } else if (this.notification.postId) {
        this.$router.push({
          name: 'PostDetail',
          params: { id: this.notification.postId },
        });
      } else if (this.notification.type === 'newAnnouncement') {
        this.showAnnouncement(this.notification);
      } else if (this.notification.type === 'newMessage') {
        this.$router.push({ name: 'Messages' });
      } else if (this.notification.meetingId) {
        let domain = 'https://m.clouthub.com';
        if (window.location.host === 'localhost:2222') {
          domain = 'http://localhost:2222';
        }
        if (window.location.host === 'm.clouthub.dev') {
          domain = 'https://m.clouthub.dev';
        }
        const accessToken = encodeURIComponent(localStorage.access_token);
        const next = encodeURIComponent(
          `/meetings/${this.notification.meeting.id}`
        );
        const url = `${domain}/go.html?access_token=${accessToken}&next=${next}`;
        $browser.open(url);
      } else if (this.notification.type === 'channelSubscribeInvitation') {
        this.$router.push({
          name: 'VideoProfile',
          params: { id: this.notification.actorId },
        });
      } else {
        this.$router.push({
          name: 'Profile',
          params: { id: this.notification.actorId },
        });
      }
    },
  },
};
</script>

<style scoped>
.notification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-00);
  cursor: pointer;
}
.notification-item-avatar {
  margin-right: 16px;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.content {
  flex-grow: 1;
  margin-right: 10px;
}
.expand-collapse-btn {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  padding: 2px 8px;
  transition: background-color 0.3s ease;
}
.expand-collapse-btn:hover {
  background-color: #e0e0e0;
}
</style>
