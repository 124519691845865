<template>
  <ion-page class="connection-search">
    <ion-header>
      <app-category-header title="Search People" />
      <div class="connection-search__input-header">
        <cs-search-bar v-model="search" placeholder="Search..." />
      </div>
    </ion-header>
    <ion-content>
      <app-spinner v-if="$apollo.queries.users.loading" />
      <app-user-search-result
        v-for="user in users"
        :key="user.id"
        :user="user"
      ></app-user-search-result>
      <cs-empty-state
        v-if="showEmptyState"
        :title="emptyStateTitle"
        :description="emptyStateDescription"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import hideKeyboard from '@/mixins/hideKeyboard.mixin';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppUserSearchResult from '@/components/search/UserSearchResult.vue';
// GQL
import UserSearch from '@/gql/search/UserSearch.gql';

export default {
  components: {
    AppCategoryHeader,
    AppSpinner,
    AppUserSearchResult,
  },
  mixins: [hideKeyboard],
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  apollo: {
    users: {
      query: UserSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return !this.search;
      },
    },
  },
  data() {
    return {
      search: this.$route.query? this.$route.query.q: this.query,
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.users && this.users.length === 0) return true;
      if (this.noQuery) return true;
      return false;
    },
    emptyStateTitle() {
      return this.noQuery ? 'Search for Users' : 'No Results';
    },
    emptyStateDescription() {
      return this.noQuery
        ? 'Try entering a term in the search box'
        : 'Try searching for something else';
    },
  },
};
</script>

<style scoped>
.connection-search__input-header {
  padding: 10px;
  background-color: var(--cs-gray-01);
}
.connection-search__input-header >>> .cs-search-bar {
  background-color: var(--cs-gray-00);
}
.connection-search__input-header >>> .cs-search-bar__input {
  background-color: var(--cs-gray-00);
}
</style>
