<template>
  <div class="ch-activities">
    <div v-for="(group, $index) in filteredNotifications" :key="$index">
      <app-activity-item
          :notification="group"
          :expandable="true"
          :showExpandButton="group.notifications.length > 1"
          :collapsed="collapsed[group.lastActor.username]"
          @toggleExpand="collapsed[group.lastActor.username] = !collapsed[group.lastActor.username]"
        />
      <div v-if="!collapsed[group.lastActor.username]">
        <app-activity-item
        v-for="(notification, innerIndex) in group.notifications.slice(1)"
        :key="`inner-${innerIndex}`"
        :notification="notification"
        :collapsed="collapsed[group.lastActor.username]"
        />

      </div>
    </div>
    <app-infinite-simple
      :query="$apollo.queries.notifications"
      sort-key="updatedAt"
    />
  </div>
</template>


<script>
import $auth from '@/services/auth';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppActivityItem from '@/components/menu/notifications/ActivityItem.vue';
import ListNotifications from '@/gql/notifications/ListNotifications.gql';
import NewNotificationSubscription from '@/gql/notifications/NewNotificationSubscription.gql';

export default {
  components: {
    AppActivityItem,
    AppInfiniteSimple,
  },
  apollo: {
    notifications: {
      query: ListNotifications,
      variables() {
        return {
          viewerId: $auth.getUserId(),
        };
      },
    },
  },
  data() {
    return {
      collapsed: {},
    };
  },
  computed: {
    filteredNotifications() {
      if (!this.notifications) {
        return [];
      }

      const grouped = this.notifications.reduce((acc, notif) => {
        if (!notif.lastActor) { // Add this line to check for null lastActor
          return acc;
        }
        
        const key = notif.lastActor.username;
        if (!acc[key]) {
          acc[key] = {
            ...notif,
            notifications: [notif],
          };
          this.$set(this.collapsed, key, true); // Add this line to collapse by default
        } else {
          acc[key].notifications.push(notif);
        }

        return acc;
      }, {});

      return Object.values(grouped);
    },
  },
  async mounted() {
    localStorage.lastSeenNotificationTime = Date.now();
    this.subscribeToNewNotifications();
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.notifications.refetch();
      return true;
    },
    async subscribeToNewNotifications() {
      this.$apollo.subscribe({
        query: NewNotificationSubscription,
      }).subscribe({
        next: ({ data }) => {
          const newNotification = data.newNotification;
          this.handleNewNotification(newNotification);
        },
        error: (error) => {
          console.error('Error subscribing to new notifications:', error);
        },
      });
    },
    handleNewNotification(newNotification) {
      this.notifications = [newNotification, ...this.notifications];
    },
  },
};
</script>
