<template>
  <div>
    <div ref="anchor"></div>
    <cs-empty-state
      v-if="posts && posts.length === 0"
      title="There are no posts yet"
      description="Be the first one to start a discussion!"
    />
    <div v-if="posts" class="ch-profile-post-list">
      <div v-for="post in displayedPost" :key="post.id" :class="{ 'post-pinned': post.isPinned }">
        <div v-if="post.isPinned" class="ch-post-card-label-pinned">Pinned</div>
        <div v-if="post.canReviewPost" class="ch-pending-review">
          This post is pending {{ isModerator ? 'your' : 'Admin' }} approval
          <div>
            <i
              class="cs-icons-options-filled group-menu__icon"
              v-if="isModerator"
              @click="showReviewAction = true"
            ></i>
            <app-action-sheet
              :open="showReviewAction"
              @close="showReviewAction = false"
            >
              <app-action-sheet-button @click="approvePost(post)"
                >Approve Post</app-action-sheet-button
              >
              <app-action-sheet-button @click="deletePost(post)"
                >Delete Post</app-action-sheet-button
              >
            </app-action-sheet>
          </div>
        </div>
        <app-post-card :post="post" :can-delete="isModerator" @pinned="scrollTop()" />
      </div>
    </div>
    <app-infinite-simple
      :query="$apollo.queries.posts"
      sortKey="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import ListGroupPosts from '@/gql/posts/ListGroupPosts.gql';
import MarkGroupPostReviewed from '@/gql/posts/MarkGroupPostReviewed.gql';
import DeletePost from '@/gql/posts/DeletePost.gql';

import AppPostCard from '@/components/post/items/PostCard.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import $auth from '@/services/auth';
import $apollo from '@/services/apollo';
import $popups from '@/services/popups';

export default {
  components: {
    AppPostCard,
    AppInfiniteSimple,
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showReviewAction: false,
    };
  },
  async mounted() {
    await this.$apollo.queries.posts.refetch();
  },
  apollo: {
    posts: {
      query: ListGroupPosts,
      variables() {
        return {
          id: this.group.id,
        };
      },
    },
  },
  computed: {
    displayedPost() {
      return this.posts.map((data) => {
        if (
          this.group.enableAdminReview &&
          (this.isModerator || data.user.id === $auth.getUserId()) &&
          data.pendingAdminReview
        ) {
          return {
            ...data,
            canReviewPost: true,
          };
        }
        return data;
      });
    },
  },
  methods: {
    scrollTop() {
      this.$refs.anchor.scrollIntoView({ block: 'center', inline: 'center' });
    },
    async refresh() {
      await this.$apollo.queries.posts.refetch();
    },
    async deletePost(post) {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Post',
        message: 'Are you sure you wish to delete this post?',
      });
      if (!confirmDelete) return;
      await this.$apollo.mutate({
        mutation: DeletePost,
        variables: {
          postId: post.id,
        },
        optimisticResponse: {},
        update: (cache) => {
          const normalizedId = cache.identify(post);
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
    approvePost(post) {
      return $apollo.mutate({
        mutation: MarkGroupPostReviewed,
        variables: {
          postId: post.id,
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify(post),
            fields: {
              pendingAdminReview() {
                return false;
              },
              safe() {
                return true;
              },
            },
          });
        },
      });
    },
  },
};
</script>

<style>
.post-pinned {
  position: relative;
  outline: 2px solid rgb(160, 167, 173);
}

.ch-post-card-label-pinned {
  position: absolute;
  top: 0;
  left: 50%;
  width: 120px;
  margin-left: -60px;
  padding-bottom: 2px;
  background: rgb(160, 167, 173);
  border-radius: 0 0 10px 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  color: white;
}

.ch-profile-post-list {
  padding: 0 8px;
}

.ch-pending-review {
  background: var(--cs-gray-02);
  padding: 5px 5px;
  border-bottom: 1px solid var(--cs-gray-01);
  justify-content: space-between;
  display: flex;
}
</style>
