<template>
  <div class="ch-meeting-people-tab">
    <cs-spinner v-if="!members" />
    <div v-if="members">
      <div class="ch-meeting-people-tab__header-wrapper">
        <div
          slot="title"
          class="
            ch-meeting-people-tab__header
            cs-textstyle-paragraph-small-bold
          "
        >
          Leaders
        </div>
        <app-add-leader-button
          v-if="isAdmin"
          :meeting="meeting"
          :members="members"
        />
      </div>
      <app-meeting-people-tab-segment
        type="leaders"
        :members="leaderMembers"
        :meeting="meeting"
        no-line
      />

      <app-meeting-people-tab-segment
        type="speakers"
        title="Speakers"
        :members="speakerMembers"
        :meeting="meeting"
      />
      <app-meeting-people-tab-segment
        type="queued"
        title="Speaker Queue"
        :members="queuedMembers"
        :meeting="meeting"
      />
      <app-meeting-people-tab-segment
        type="audience"
        title="Audience"
        :members="audienceMembers"
        :meeting="meeting"
      />
      <app-meeting-people-tab-segment
        type="attending"
        title="Attending"
        :members="attendingMembers"
        :meeting="meeting"
        no-line
      />
      <app-meeting-people-tab-segment
        type="invited"
        title="Invited"
        :members="invitedMembers"
        :meeting="meeting"
        no-line
      />
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $socket from '@/services/socket';
import AppMeetingPeopleTabSegment from '@/components/meetings/MeetingPeopleTabSegment.vue';
import AppAddLeaderButton from '@/components/meetings/AddLeaderButton.vue';

// GQL
import ListMeetingMembers from '@/gql/meetings/ListMeetingMembers.gql';
import SetMeetingMemberRole from '@/gql/meetings/SetMeetingMemberRole.gql';

export default {
  components: {
    AppMeetingPeopleTabSegment,
    AppAddLeaderButton,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    members: {
      query: ListMeetingMembers,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
    },
  },
  computed: {
    leaderMembers() {
      const leaders = this.members.filter((m) =>
        ['ADMIN', 'MODERATOR', 'PRESENTER', 'PRESENTER_QUEUE'].includes(m.role)
      );
      return leaders.sort((a, b) => (a.role < b.role ? -1 : 1));
    },
    speakerMembers() {
      return this.members.filter((m) => 'SPEAKER'.indexOf(m.role) > -1);
    },
    queuedMembers() {
      return this.members.filter((m) => 'QUEUE'.indexOf(m.role) > -1);
    },
    audienceMembers() {      
      //TODO: temporary fix for performance reason
      const audienceLimitedList = this.members.filter((m) => ('VIEWER'.indexOf(m.role) > -1));
      if (audienceLimitedList.length > 100) {
        audienceLimitedList.length = 100
      }
      return audienceLimitedList;
    },
    attendingMembers() {
      return this.members.filter((m) => 'ATTENDING'.indexOf(m.role) > -1);
    },
    invitedMembers() {
      return this.members.filter((m) => 'INVITED'.indexOf(m.role) > -1);
    },
    myRole() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    isAdmin() {
      return this.myRole === 'ADMIN';
    },
    isQueued() {
      return this.myRole === 'QUEUE';
    },
    participantsCount() {
      return (
        this.members &&
        !this.speakerMembers.length &&
        !this.queuedMembers.length &&
        !this.audienceMembers.length &&
        !this.attendingMembers.length &&
        !this.invitedMembers.length
      );
    },
  },
  mounted() {
    $socket.onRoom(
      'meeting',
      this.meeting.id,
      'meeting-members-modified',
      this.refresh
    );
  },
  methods: {
    refresh() {
      console.log('Refresh member list');
      this.$apollo.queries.members.refetch();
    },
    async setRole(role, userId) {
      userId = userId || $auth.getUserId();
      await this.$apollo.mutate({
        mutation: SetMeetingMemberRole,
        variables: {
          meetingId: this.meeting.id,
          userId,
          role,
        },
      });
      this.$emit('notify', 'members');
    },
    openAddLeadersModal() {
      const meetingInfo = {
        meeting: this.meeting,
        members: this.members,
      };
      $bus.$emit('show-add-leaders-modal', meetingInfo);
    },
  },
};
</script>

<style scoped>
.ch-meeting-people-tab__header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.ch-meeting-people-tab__header-wrapper:nth-child(1) {
  margin-top: 0;
}
.ch-meeting-people-tab__header {
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #7b7b7b;
}

.queue-notice {
  border: dashed 1px var(--cs-primary-base);
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
}
.queue-notice i {
  font-size: 20px;
  color: var(--cs-primary-base);
}
</style>
