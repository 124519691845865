<template>
  <action-sheet class="ch-block__actionsheet" :open="show" @close="cancel">
    <div class="ch-block__content" v-if="show">
      <div class="ch-block__title cs-textstyle-section-heading">
        Block <app-username :user="user" />
      </div>
      <app-avatar
        size="medium"
        :name="user.displayname"
        :picture="user.profilePhotoURL"
      />
      <div class="ch-block__subtitle cs-textstyle-paragraph-bold">
        Are you sure you wish to block this member?
      </div>
      <div class="ch-block__description cs-textstyle-paragraph">
        From this point forward you will no longer see their posts, comments, or be able to message or search this member.
      </div>
      <div class="ch-block__buttons">
        <cs-button class="ch-block__button" variant="default" @click="cancel()">Cancel</cs-button>
        <cs-button class="ch-block__button" @click="block(user)">Block</cs-button>
      </div>
    </div>
  </action-sheet>
</template>

<script>
import $bus from '@/services/bus';
import $relationships from '@/api/relationships';
import ActionSheet from '@/components/general/ActionSheet.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';

export default {
  components: {
    ActionSheet,
    AppAvatar,
    AppUsername,
  },
  data() {
    return {
      show: false,
      user: null,
      view: null,
    };
  },
  computed: {
    noOfChars() {
      const characters = this.reason.length;
      return characters;
    },
  },
  methods: {
    cancel() {
      this.show = false;
      this.view = null;
      this.user = null;
    },
    async block(user) {
      await $relationships.blockUser(this.user);
      if (this.view === 'Conversation') {
        this.$router.back();
      }
      this.$router.push({ name: 'Profile', params: user});
      this.cancel();
    },
  },
  mounted() {
    $bus.$on('show-block-user', (user, view) => {
      this.user = user;
      this.view = view;
      this.show = true;
    });
  },
};
</script>

<style scoped>
.ch-block__actionsheet{
  display: flex;
  flex-direction: column;
}
.ch-block__title,
.ch-block__subtitle {
  color: var(--cs-gray-06);
}
.ch-block__description {
  color: var(--cs-gray-05);
}
.ch-block__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.ch-block__button{
  margin: 0 7.5px;
}
</style>
