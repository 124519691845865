<template>
  <div class="ch-profile__card">
    <cs-spinner v-if="!people" />
    <div v-if="people">
      <div v-if="title === 'Recommended'">
        <app-user-card
          v-for="user in filteredPeople"
          :key="user.id"
          :user="user"
          :is-friend="isFriend"
        />
      </div>
      <div v-else>
        <app-user-card
          v-for="user in filteredPeople"
          :key="user.id"
          :user="user"
          :is-friend="isFriend"
        />
        <app-infinite-offset :query="$apollo.queries.people" />
      </div>
    </div>
  </div>
</template>

<script>
import AppUserCard from '@/components/connections/UserSegmentCard.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    AppUserCard,
    AppInfiniteOffset,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isFriend: {
      type: Boolean,
      required: false,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    people: {
      fetchPolicy: 'cache-only',
      query() {
        return this.query;
      },
    },
  },
  computed: {
    filteredPeople() {
      if (this.title === 'Recommended') {
        return (
          this.people &&
          this.people.filter(
            (person) =>
              !person.myRelationship.isBlocked &&
              !person.myRelationship.isFollower
          )
        );
      }
      return this.people.filter((person) => !person.myRelationship.isBlocked);
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.people.refetch();
      return true;
    },
  },
};
</script>

<style scoped></style>
