<template>
  <div class="meeting-resources">
    <div v-if="files && files.length">
      <app-meeting-file
        v-for="file in files"
        :key="file.id"
        :file="file"
        :is-admin="isAdmin"
        :disable-action="disableUpload"
        @deleted="handleDeleted"
      >
      </app-meeting-file>
    </div>
    <div v-if="files && !files.length">
      <cs-empty-state>
        <div slot="title" class="cs-textstyle-item-heading">
          No Files Shared
        </div>
      </cs-empty-state>
    </div>
  </div>
</template>

<script>
import $socket from '@/services/socket';
// Component
import AppMeetingFile from '@/components/meetings/MeetingFile.vue';
// GQL
import ListMeetingFiles from '@/gql/meetings/ListMeetingFiles.gql';

export default {
  components: {
    AppMeetingFile,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    files: {
      query: ListMeetingFiles,
      variables() {
        return {
          meetingId: this.meeting.id,
        };
      },
    },
  },
  computed: {
    disableUpload() {
      return (
        this.meeting.status === 'TRANSCODING' || this.meeting.status === 'ENDED'
      );
    },
  },
  mounted() {
    $socket.onRoom(
      'meeting',
      this.meeting.id,
      'meeting-handouts-modified',
      () => {
        this.$apollo.queries.files.refetch();
      }
    );
  },
  methods: {
    handleDeleted() {
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-handouts-modified');
    },
  },
};
</script>

<style scoped>
.meeting-resources {
  position: relative;
}
</style>
