<template>
  <div class="ch-video-search">
    <div class="ch-video-search__header">
      <cs-search-bar
        placeholder="Search Videos"
        v-model="query"
        @submit="search"
      />
    </div>
    <div class="ch-video-search__result" v-if="posts">
      <!-- <app-video-item :video="video" :key="video.id" v-for="video in posts"/> -->
    </div>
  </div>
</template>

<script>
// import AppVideoItem from '@/components/videos/VideoItem.vue';
import PostSearchByType from '@/gql/search/PostSearchByType.gql';

export default {
  components: {
    // AppVideoItem,
  },
  data() {
    return {
      searchQuery: null,
      query: '',
    };
  },
  apollo: {
    posts: {
      query: PostSearchByType,
      variables() {
        return {
          query: this.searchQuery,
          type: 'video',
        };
      },
      skip() {
        return !this.searchQuery;
      },
    },
  },
  methods: {
    search() {
      this.searchQuery = this.query;
    },
  },
};
</script>

<style scoped>
.ch-video-search{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-video-search__header >>> .cs-search-bar{
  background-color: var(--cs-gray-00);
}
.ch-video-search__header >>> .cs-search-bar__input{
  background-color: var(--cs-gray-00);
}
</style>
