<template>
  <div class="ch-page-menu" slot="fixed" :class="{'ch-page-menu--hide': hideButton}">
    <div class="ch-page-menu__button-wrapper">
      <cs-button
        v-if="true"
        class="ch-page-menu__button"
        @click="showActionsheet = true"
        size="small"
      >
        <i class="cs-icons-menu ch-page-menu__icon"/>
        <span v-if="title">{{ title }}</span>
      </cs-button>
    </div>
    <app-action-sheet :open="showActionsheet" @close="showActionsheet = false">
      <div @click="showActionsheet = false">
        <slot/>
      </div>
    </app-action-sheet>
  </div>
</template>

<script>
import AppActionSheet from '@/components/general/ActionSheet.vue';

export default {
  components: {
    AppActionSheet,
  },
  props: {
    title: {
      type: String,
    },
    categories: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    if (window.Keyboard) {
      window.addEventListener('keyboardWillShow', () => {
        this.hideButton = true;
      });
      window.addEventListener('keyboardWillHide', () => {
        this.hideButton = false;
      });
    }
  },
  data() {
    return {
      showActionsheet: false,
      hideButton: false,
    };
  },
};
</script>

<style lang="css" scoped>
  .ch-page-menu {
  }
  .ch-page-menu__button-wrapper {
    position: fixed;
    border-radius: 20px;
    left: 50%;
    bottom: 0px;
    transform: translate3d(-50%, -15px, 0);
    transition: transform 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ch-page-menu--hide .ch-page-menu__button-wrapper {
    transform: translate3d(-50%, 150px, 0);
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .ch-page-menu__button-wrapper {
      bottom: calc(env(safe-area-inset-bottom) + 50px);
    }
  }
  .ch-page-menu__button {
    transform: translateY(0);
    transition: transform 0.2s;
    box-shadow: 0 3px 12px rgb(0 0 0 / 12%);
  }
</style>
