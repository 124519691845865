<template>
  <div class="ch-profile">
    <div class="ch-profile__background">
      <app-img
        v-if="user.bannerImageURL"
        :src="user.bannerImageURL"
        class="ch-profile__background-img"
        :width="600"
      />
      <img v-else class="ch-profile__background-img" :src="placeholderImg" />
      <div
        v-if="loggedInUser"
        class="profile-edit-icon"
        @click="openChangeBackground"
      >
        <i class="cs-icons-edit"></i>
      </div>
    </div>
    <div class="ch-profile__avatar-followers">
      <div class="ch-profile__avatar-holder">
        <app-avatar
          :picture="user.profilePhotoURL"
          :name="user.firstname"
          variant="primary"
          size="large"
          class="img-class"
          :width="300"
          @click.stop="openImagePreview"
        />
        <app-image-preview
          :show="visible"
          :data="profilePicture"
          profile-picture
          @close="visible = false"
        />

        <div
          v-if="loggedInUser"
          class="ch-profile__avatar-edit-icon"
          @click="openChangeProfile"
        >
          <i class="cs-icons-edit"></i>
        </div>
      </div>

      <!-- Channel Button, report button and share button  -->
      <div class="ch-profile__options">
        <div class="ch-profile__buttons-second">
          <div class="ch-profile__buttons-share" @click.stop="shareProfile">
            <i class="cs-icons-share2 ch-profile__icon"></i>
          </div>
          <div
            v-if="loggedInUser"
            class="ch-profile__buttons-more"
            @click.stop="openOptionSheet"
          >
            <i class="cs-icons-settings ch-profile__icon"></i>
          </div>
          <div
            v-else
            class="ch-profile__buttons-more"
            @click.stop="openOptionSheet"
          >
            <i class="cs-icons-options ch-profile__icon"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="ch-profile__info">
      <div class="ch-profile__name-username">
        <div class="cs-textstyle-informative-paragraph-bold">
          <app-username :user="user" type="displayname" />
        </div>
        <div class="cs-textstyle-paragraph ch-profile__username-limit all-copy">
          {{ user.username }}
        </div>
        <!-- if a user wants to show follower count  -->
        <div v-if="!loggedInUser" class="ch-profile__other-individual-count">
          <div class="cs-textstyle-paragraph-small-bold">
            {{ user.followerCount }}
          </div>
          <div class="cs-textstyle-paragraph-small-bold">followers</div>
        </div>
      </div>
      <div class="channel__buttons">
        <cs-button
          v-if="hasVideo"
          v-router-link="{ name: 'VideoProfile', params: { id: user.id } }"
          size="small"
          class="ch-profile__channel-btn"
        >
          <i class="cs-icons-play ch-profile__icon"></i>Channel
        </cs-button>
      </div>
    </div>
    <div class="ch-profile__member-since cs-textstyle-paragraph-small">
      Member since {{ user.createdAt | moment('MMMM YYYY') }}
    </div>
    <div
      v-if="!loggedInUser"
      class="ch-profile__follow-back cs-textstyle-paragraph-small"
    >
      <div class="cs-textstyle-paragraph-extra-small-bold">
        <span v-if="user.myRelationship.isFollowing">Follows you</span>
        <span
          v-if="showFollowBackButton"
          class="ch-profile__follow-back-button"
          @click="follow"
          >Follow back</span
        >
      </div>
    </div>
    <div v-if="user.bio" class="ch-profile__bio">
      <div class="ch-profile__bio-text">{{ user.bio }}</div>
    </div>
    <a
      :href="clickableLink"
      target="_blank"
      class="ch-profile__website-link cs-textstyle-paragraph-small"
      >{{ user.website }}</a
    >
    <app-profile-card-buttons :user="user" :logged-in-user="loggedInUser" />
    <!-- Profile Edit Sheet -->
    <profile-edit-modal
      :user="user"
      :show-profile-edit="showProfileEdit"
      @closeProfileEditModal="closeProfileEdit"
    >
    </profile-edit-modal>
    <!-- Change Profile Image Modal -->
    <app-change-profile-image-modal
      :user="user"
      :show-change-profile="showChangeProfile"
      @closeProfileImgModal="closeChangeProfile"
    >
    </app-change-profile-image-modal>
    <!-- Change Background Image Modal -->
    <app-change-background-modal
      :user="user"
      :show-change-background="showChangeBackground"
      @closeBackgroundModal="closeChangeBackground"
    >
    </app-change-background-modal>
    <!-- Action Sheet -->
    <app-action-sheet :open="showOptionSheet" @close="closeOptionSheet">
      <template v-if="loggedInUser">
        <app-action-sheet-button @click="openProfileEdit">
          Edit Profile
        </app-action-sheet-button>
      </template>
      <template v-else>
        <app-action-sheet-button @click="report">
          Report
          <app-username :style="{ marginLeft: '4px' }" :user="user" />
        </app-action-sheet-button>
        <app-action-sheet-button @click="blockUser">
          Block
          <app-username :style="{ marginLeft: '4px' }" :user="user" />
        </app-action-sheet-button>
      </template>
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $cdn from '@/services/cdn';
import $relationshipsApi from '@/api/relationships';

import GetChannel from '@/gql/videos/GetChannel.gql';
import AppChangeProfileImageModal from '@/components/connections/ChangeProfileImageModal.vue';
import AppChangeBackgroundModal from '@/components/connections/ChangeBackgroundModal.vue';
import AppProfileCardButtons from '@/components/connections/ProfileCardButtons.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppImagePreview from '@/components/general/ImagePreview.vue';
import AppImg from '@/components/general/Img.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppUsername from '@/components/general/Username.vue';
import ProfileEditModal from '@/components/connections/EditProfile.vue';

const placeholderImg = require('@/assets/images/profile-banner.png');

export default {
  components: {
    AppProfileCardButtons,
    AppAvatar,
    AppUsername,
    AppChangeProfileImageModal,
    AppChangeBackgroundModal,
    AppImagePreview,
    AppImg,
    AppActionSheet,
    AppActionSheetButton,
    ProfileEditModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placeholderImg,
      loggedIn: false,
      showChangeProfile: false,
      showChangeBackground: false,
      visible: false,
      channel: null,
      showOptionSheet: false,
      profileLink: '',
      showProfileEdit: false,
    };
  },
  computed: {
    hasVideo() {
      return (
        this.channel &&
        (this.channel.videoPosts.count > 0 || this.channel.channelName)
      );
    },
    loggedInUser() {
      const userId = $auth.getUserId();
      if (this.user.id === userId) {
        return true;
      }
      return false;
    },
    showFollowBackButton() {
      return (
        this.user.myRelationship.isFollowing &&
        !this.user.myRelationship.isFollower
      );
    },
    profilePicture() {
      const userImage = [
        {
          src: $cdn.optimize(this.user.profilePhotoURL, null, 500, 500),
        },
      ];
      return userImage;
    },
    clickableLink() {
      if (this.user && this.user.website) {
        const website = this.user.website;
        return website.startsWith('http://') || website.startsWith('https://')
          ? website
          : `http://${website}`;
      }
      return '';
    },
  },
  async mounted() {
    const resp = await this.$apollo.query({
      query: GetChannel,
      variables: {
        id: this.user.id,
      },
    });
    this.channel = { ...resp.data.user };
  },
  methods: {
    goToFriendsView(id) {
      if (id === $auth.getUserId()) {
        this.$router.push({ name: 'Friends', params: { id } });
      }
    },
    openProfileEdit() {
      this.showProfileEdit = true;
    },
    async follow() {
      await $relationshipsApi.followUser(this.user);
    },
    // Change Profile Modal
    openChangeProfile() {
      this.showChangeProfile = true;
    },
    closeChangeProfile() {
      this.showChangeProfile = false;
    },
    // Change Background Modal
    openChangeBackground() {
      this.showChangeBackground = true;
    },
    closeChangeBackground() {
      this.showChangeBackground = false;
    },
    // Image Preview
    openImagePreview() {
      if (this.user.profilePhotoURL) {
        this.visible = true;
      }
    },
    shareProfile() {
      this.profileLink = `/${this.user.id}`;
      if (this.user.username) {
        this.profileLink = `/${this.user.username.replace('@', '')}`;
      }
      $bus.$emit('show-share-sheet', {
        link: this.profileLink,
        title: 'Share Profile',
      });
    },
    // ActionSheet
    closeOptionSheet() {
      this.showOptionSheet = false;
    },
    openOptionSheet() {
      this.showOptionSheet = true;
    },
    closeProfileEdit() {
      this.showProfileEdit = false;
    },
    // Report user
    report() {
      const data = {
        report: this.user,
        reportType: 'user',
      };
      $bus.$emit('show-report-action', data);
    },
    // Block user
    blockUser() {
      $bus.$emit('show-block-user', this.user);
    },
  },
};
</script>

<style scoped>
.channel__buttons {
  float: right;
  padding: 10px 10px 0px 0px;
}
.ch-profile__img {
  position: relative;
}
.ch-profile__background {
  position: relative;
}
.ch-profile__background-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}
.profile-edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 36px;
  width: 36px;
  background-color: var(--cs-gray-00);
  color: var(--cs-gray-05);
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
}
.ch-profile__avatar-followers {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding-left: 20px;
}
.ch-profile__counts {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
}
.ch-profile__individual-count {
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
  padding-bottom: 0 !important;
  text-align: center;
  width: fit-content;
  height: fit-content;
}
.ch-profile__other-individual-count {
  display: flex;
  flex-direction: row;
  padding-bottom: 0 !important;
  text-align: center;
  width: fit-content;
  height: fit-content;
  gap: 5px;
}
.ch-profile__info {
  display: flex;
  justify-content: space-between;
}
.ch-profile__name-username {
  margin-left: 20px;
}
.ch-profile__location {
  margin-right: 20px;
  margin-top: 25px;
}
.ch-profile__address {
  color: var(--cs-gray-05);
  font-size: 12px;
}
.ch-profile__bio-text {
  color: var(--cs-gray-05);
}
.ch-profile__bio {
  margin: 5px 20px;
}

.ch-profile__avatar-holder {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  width: min-content;
  cursor: pointer;
  margin-top: -45px;
  margin-left: 0px;
}
.ch-profile__avatar-edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 32px;
  color: var(--cs-gray-00);
}
.ch-profile__buttons-share,
.ch-profile__buttons-more {
  font-size: 15px;
  padding-top: 10px;
}
.ch-profile__buttons-second {
  display: flex;
  flex-direction: row !important;
  gap: 10px;
  padding-right: 10px;
}
.ch-profile__member-since {
  padding: 1px 20px;
  color: var(--cs-gray-05);
}
.ch-profile__follow-back {
  padding-left: 20px;
}
.ch-profile__follow-back-button {
  background: var(--cs-gray-03);
  padding: 1px 4px;
  border-radius: 3px;
  margin-left: 5px;
}
.ch-profile__website-link {
  padding-left: 20px;
  color: #1e90ff;
  font-weight: 500 !important;
  text-decoration: none;
}
.ch-profile__channel-btn {
  background-color: var(--cs-gray-04) !important;
  --cs-button-color: var(--cs-gray-04) !important;
}
.ch-profile__username-limit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  font-size: 15px !important;
}
.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}
</style>
