<template>
  <div class="my-groups-posts">
    <app-post-list
        :posts="posts"
        list-id="myGroupsPostList"
        empty-description="Join more groups!"
    />
    <app-infinite-simple
      :query="$apollo.queries.posts"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import ListMyGroupsPosts from '@/gql/posts/ListMyGroupsPosts.gql';
import AppPostList from '@/components/post/lists/PostList.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppPostList,
    AppInfiniteSimple,
  },
  apollo: {
    posts: {
      query: ListMyGroupsPosts,
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.posts.refetch();
      return true;
    },
  },
};
</script>

<style scoped>
.my-groups-posts {
  padding-top: 5px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-bottom: -60px;
}
</style>