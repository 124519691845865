<template>
  <i
    ref="button"
    class="queue-button cs-icons-clock"
    :class="[size, { active: post.isQueued }, filled]"
    @click="toggleQueue()"
  ></i>
</template>

<script>
import $posts from '@/api/posts';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
  },
  computed: {
    filled() {
      return this.post.isQueued ? 'cs-icons-clock-filled' : 'cs-icons-clock';
    },
  },
  methods: {
    toggleQueue() {
      if (this.post.isQueued) {
        $posts.unqueuePost(this.post);
      } else {
        $posts.queuePost(this.post);
      }
    },
  },
};
</script>

<style scoped>
.queue-button {
  margin: 10px;
  cursor: pointer;
}
.queue-button.active {
  color: var(--cs-primary-base);
}
.queue-button.small {
  margin: 0px;
  font-size: 12px;
}
.queue-button.medium {
  margin: 0px;
  font-size: 16px;
}
.queue-button.large {
  margin: 0px;
  font-size: 22px;
}
</style>
