<template>
  <ion-page class="forum-search">
    <ion-header>
      <app-category-header title="Search Posts" />
      <div class="forum-search__input-header">
        <cs-search-bar v-model="search" placeholder="Search..." />
      </div>
    </ion-header>
    <ion-content>
      <app-spinner v-if="$apollo.queries.posts.loading" />
      <app-post-card
        v-for="post in posts"
        :key="post.id"
        :post="post"
        :preview="true"
      ></app-post-card>
      <cs-empty-state
        v-if="showEmptyState"
        :title="emptyStateTitle"
        :description="emptyStateDescription"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import PostSearch from '@/gql/search/PostSearch.gql';
import hideKeyboard from '@/mixins/hideKeyboard.mixin';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppPostCard from '@/components/post/items/PostCard.vue';

export default {
  components: {
    AppCategoryHeader,
    AppSpinner,
    AppPostCard,
  },
  mixins: [hideKeyboard],
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  apollo: {
    posts: {
      query: PostSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      search: this.query,
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.posts && this.posts.length === 0) return true;
      if (this.noQuery) return true;
      return false;
    },
    emptyStateTitle() {
      return this.noQuery ? 'Search for Post' : 'No Results';
    },
    emptyStateDescription() {
      return this.noQuery
        ? 'Try entering a term in the search box'
        : 'Try searching for something else';
    },
  },
};
</script>

<style scoped>
.forum-search__input-header {
  padding: 10px;
  background-color: var(--cs-gray-01);
}
.forum-search__input-header >>> .cs-search-bar {
  background-color: var(--cs-gray-00);
}
.forum-search__input-header >>> .cs-search-bar__input {
  background-color: var(--cs-gray-00);
}
</style>
