<template>
  <div>
    <ion-list v-if="groups.length > 0">
      <app-group-list-item
        v-for="group in groups"
        :key="group.id"
        :group="group"
        class="group-item"
      />
    </ion-list>
    <cs-empty-state
      v-else-if="!loading"
      title="No matching groups"
    ></cs-empty-state>
  </div>
</template>

<script>
import AppGroupListItem from '@/components/groups/GroupListItem.vue';

export default {
  components: {
    AppGroupListItem,
  },
  props: {
    groups: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.group-item {
  --padding-start: 10px;
}
</style>
