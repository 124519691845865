<template>
  <div class="meeting-container">
    <app-new-meeting-header :meeting="meeting" />
    <app-meeting-control-bar :meeting="meeting" />
    <div class="meeting-body">
      <template v-if="meeting && !cannotJoin">
        <div class="meeting-detail">
          <!-- Event Ended and also ending cloud recording -->
          <div
            v-if="meeting.status === 'TRANSCODING'"
            class="meeting-live__waiting"
          >
            <cs-spinner />
            <div class="cs-textstyle-detail-heading">Event Ended</div>
            <div class="cs-textstyle-paragraph">
              You will be able to watch the recorded video again shortly
            </div>
          </div>
        </div>
        <div v-if="meeting.status === 'ENDED'">
          <app-video
            :src="meeting.recordingUrl"
            :stream-id="meeting.streamId"
            :stream-status="meeting.streamStatus"
            class="post-video"
            autoplay
          />
        </div>
        <!-- Event is live -->
        <div
          v-if="meeting && meeting.status === 'LIVE'"
          class="meeting-active"
        >
          <app-meeting-main
            ref="main"
            :meeting="meeting"
            @signin="showConfirmModal"
          />

        </div>

        <!-- Custom Button  -->
        <div
          class="meeting__custom-buttons"
          v-if="button1Label || button2Label || button3Label"
        >
          <cs-button
            v-if="button1Label && button1Link"
            :href="button1Link"
            size="small"
            >{{ button1Label }}</cs-button
          >
          <cs-button
            v-if="button2Label && button2Link"
            :href="button2Link"
            size="small"
            >{{ button2Label }}</cs-button
          >
          <cs-button
            v-if="button3Label && button3Link"
            :href="button3Link"
            size="small"
            >{{ button3Label }}</cs-button
          >
        </div>

        <!-- Bottom Panel -->
        <div class="bottom-panel">

          <cs-page-tabs
            class="meeting-page-tabs"
            align="justify"
            :value="index"
            @input="selectedView"
          >
            <cs-page-tab label="People">
              <app-meeting-people-tab ref="peopleTab" :meeting="meeting" />
            </cs-page-tab>
            <cs-page-tab label="Chat" class="meeting-page-tabs__chat">
              <app-meeting-chat-tab
                :meeting="meeting"
                :is-admin="isAdmin"
                @confirm-modal="showConfirmModal"
              />
            </cs-page-tab>
            <cs-page-tab label="Handouts" class="meeting-page-tabs__handouts">
              <app-meeting-resources-tab
                :meeting="meeting"
                :is-admin="isAdmin"
              />
            </cs-page-tab>
            <cs-page-tab v-if="!meeting.parentMeeting" class="meeting-page-tabs__breakout" label="Breakouts Rooms">
              <app-meeting-breakout-tab
                :meeting="meeting"
                :is-admin="isAdmin"
              />
            </cs-page-tab>
          </cs-page-tabs>
        </div>
      </template>
      <!-- If meeting is private and not invited  -->
      <template v-if="cannotJoin && meeting">
        <cs-empty-state
          title="This meeting is private"
          description="You must be invited to participate"
        />
      </template>
      <!-- meeting loading  -->
    </div>
    <div class="meeting-footer">
      <app-meeting-chat-composer
        v-if="ifChatTab"
        :meeting="meeting"
        @confirm-modal="showConfirmModal"
      />
      <app-meeting-file-upload v-if="ifFileTab && isAdmin" :meeting="meeting" />
      <app-meeting-create-breakout
        v-if="ifBreakoutTab && isAdmin"
        :meeting="meeting"
      />
    </div>

    <!-- Invite Modal -->
    <app-invite-modal
      v-if="showInviteModal"
      :meeting="meeting"
      :show="showInviteModal"
      @close="showInviteModal = false"
    />
    <!-- Popup -->
    <app-modal
      v-if="confirmModal"
      :show="confirmModal"
      type="confirm"
      variant="primary"
      title="Sign up/Sign in to continue"
      @close="confirmModal = false"
    >
      <div>Please signup or signin to access this function</div>
      <div class="confirm-btns">
        <cs-button @click="openSignupModal">Signup</cs-button>
        <cs-button @click="openSigninModal">Signin</cs-button>
      </div>
    </app-modal>
    <!-- Signup Modal -->
    <app-meeting-signup
      :show-signup="showSignup"
      @close-signup="closeSignupModal"
      @account-created="authorized"
    />
    <!-- Signin Modal -->
    <app-meeting-signin
      :show-signin="showSignin"
      @close-signin="closeSigninModal"
      @logged-in="authorized"
    />
  </div>
</template>

<script>
import $meetingsApi from '@/api/meetings';
import $popups from '@/services/popups';
import $socket from '@/services/socket';
import $auth from '@/services/auth';

// Components
import AppMeetingControlBar from '@/components/meetings/MeetingControlBar.vue';
import AppMeetingMenu from '@/components/meetings/MeetingMenu.vue';
import AppMeetingMain from '@/components/meetings/MeetingMain.vue';
import AppInviteModal from '@/components/meetings/InviteModal.vue';
import AppMeetingPeopleTab from '@/components/meetings/MeetingPeopleTab.vue';
import AppMeetingResourcesTab from '@/components/meetings/MeetingResourcesTab.vue';
import AppMeetingChatTab from '@/components/meetings/MeetingChatTab.vue';
import AppMeetingBreakoutTab from '@/components/meetings/MeetingBreakoutTab.vue';
import AppMeetingSignup from '@/components/meetings/MeetingSignup.vue';
import AppMeetingSignin from '@/components/meetings/MeetingSignin.vue';
import AppModal from '@/components/general/Modal.vue';
import AppVideo from '@/components/general/Video.vue';
import AppMeetingChatComposer from '@/components/meetings/MeetingChatComposer.vue';
import AppNewMeetingHeader from '@/components/meetings/NewMeetingHeader.vue';
import AppMeetingFileUpload from '@/components/meetings/MeetingFileUpload.vue';
import AppMeetingCreateBreakout from '@/components/meetings/MeetingCreateBreakout.vue';
import AppStreamingDetailButton from '@/components/meetings/buttons/StreamingDetailButton.vue';

// GQL
import JoinMeeting from '@/gql/meetings/JoinMeeting.gql';
import LeaveMeeting from '@/gql/meetings/LeaveMeeting.gql';

export default {
  components: {
    AppMeetingMenu,
    AppMeetingMain,
    AppMeetingPeopleTab,
    AppMeetingResourcesTab,
    AppMeetingChatTab,
    AppMeetingBreakoutTab,
    AppInviteModal,
    AppMeetingSignup,
    AppMeetingSignin,
    AppModal,
    AppVideo,
    AppMeetingChatComposer,
    AppMeetingFileUpload,
    AppMeetingCreateBreakout,
    AppStreamingDetailButton,
    AppNewMeetingHeader,
    AppMeetingControlBar,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      showMenu: false,
      showInviteModal: false,
      showSignup: false,
      showSignin: false,
      confirmModal: false,
      selectedIndex: 0,
      index: 0,
    };
  },
  computed: {
    button1Label() {
      if (this.meeting.buttons && this.meeting.buttons[0])
        return this.meeting.buttons[0].label;
      return null;
    },
    button2Label() {
      if (this.meeting.buttons && this.meeting.buttons[1])
        return this.meeting.buttons[1].label;
      return null;
    },
    button3Label() {
      if (this.meeting.buttons && this.meeting.buttons[2])
        return this.meeting.buttons[2].label;
      return null;
    },
    button1Link() {
      if (this.meeting.buttons && this.meeting.buttons[0])
        return this.meeting.buttons[0].link;
      return null;
    },
    button2Link() {
      if (this.meeting.buttons && this.meeting.buttons[1])
        return this.meeting.buttons[1].link;
      return null;
    },
    button3Link() {
      if (this.meeting.buttons && this.meeting.buttons[2])
        return this.meeting.buttons[2].link;
      return null;
    },
    isAdmin() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role === 'ADMIN'
      );
    },
    cannotJoin() {
      if (!this.meeting) return true;
      return !this.meeting.myMembership && this.meeting.privacy === 'private';
    },
    isMember() {
      return !!this.meeting.myMembership;
    },
    displayTime() {
      return (
        this.meeting &&
        this.$moment
          .utc(this.meeting.startTime)
          .local()
          .format('MMM D [at] h:mmA')
      );
    },
    ifChatTab() {
      return this.selectedIndex === 1;
    },
    ifFileTab() {
      return this.selectedIndex === 2;
    },
    ifBreakoutTab() {
      return this.selectedIndex === 3;
    },
  },
  watch: {
    async meeting() {
      await this.joinIfNeeded();
    },
  },
  async mounted() {
    await this.joinIfNeeded();
  },
  methods: {
    selectedView(view) {
      this.selectedIndex = view;
    },
    showConfirmModal() {
      this.confirmModal = true;
    },
    // Signup
    openSignupModal() {
      this.confirmModal = false;
      this.showSignup = true;
    },
    closeSignupModal() {
      this.showSignup = false;
    },
    authorized() {
      // TODO: Might have to replace this with something better
      window.location.reload();
    },
    // Signin
    openSigninModal() {
      this.confirmModal = false;
      this.showSignin = true;
    },
    closeSigninModal() {
      this.showSignin = false;
    },
    // Start Event
    async startMeeting() {
      try {
        await $meetingsApi.startMeeting(this.meeting.id);
      } catch (e) {
        await $popups.alert(
          'There was an error starting.  Please try again or contact support.'
        );
        throw e;
      }
    },
    async joinIfNeeded() {
      if (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role === 'BANNED'
      ) {
        alert('You are not permitted to participate in this meeting.');
        await this.$router.push({ name: 'MeetingHome' });
        return false;
      }
      if (
        this.meeting &&
        !this.isAdmin &&
        this.meeting.status === 'LIVE' &&
        this.meeting.privacy === 'public'
      ) {
        await this.joinMeeting();
      }
      if (
        this.meeting &&
        !this.isAdmin &&
        this.meeting.status === 'LIVE' &&
        this.meeting.privacy === 'private' &&
        this.meeting.myMembership
      ) {
        await this.joinMeeting();
      }
    },
    async joinMeeting() {
      if (this.cannotJoin) return;
      console.log('Join Event', this.meeting.myMembership);
      if (
        $auth.getUserId() ||
        !this.meeting.myMembership ||
        (this.meeting.myMembership &&
          this.meeting.myMembership.role &&
          (this.meeting.myMembership.role === 'INVITED' ||
            this.meeting.myMembership.role === 'ATTENDING'))
      ) {
        await this.$apollo.mutate({
          mutation: JoinMeeting,
          variables: {
            meetingId: this.id,
          },
        });
        console.log('Join Event Trigger');
        $socket.sendRoom(
          'meeting',
          this.meeting.id,
          'meeting-members-modified'
        );
      } else {
        console.log('Skip Join');
      }
    },
    async leaveMeeting() {
      // console.log('Left');
      // await this.$apollo.mutate({
      //   mutation: LeaveMeeting,
      //   variables: {
      //     meetingId: this.id,
      //   },
      // });
      // if (!this.isFuture && this.meeting.isOpen) await this.$refs.main.leave();
      // this.$router.push({ name: 'MeetingHome' });
      // window.location.href = '/';
      if (this.meeting.status === 'NOT_STARTED') this.$emit('exitMAnage');
      else {
        if ($auth.getUserId()) {
          console.log('leave meeting');

          await this.$apollo.mutate({
            mutation: LeaveMeeting,
            variables: {
              meetingId: this.meeting.id,
            },
          });
          console.log('calling socket');

          await $socket.leaveRoom('meeting', this.meeting.id);
          console.log('done');
        }
        console.log('closing window');

        $socket.sendRoom(
          'meeting',
          this.meeting.id,
          'meeting-members-modified'
        );
        window.close();
        alert('Please close this window to leave this event');
        $socket.sendRoom(
          'meeting',
          this.meeting.id,
          'meeting-members-modified'
        );
      }
    },
    openMeeting(meeting) {
      window.location.href = `/#/meetings/${meeting.id}`;
    },
    refreshMemberList() {
      this.$refs.peopleTab.refresh();
    },
    async signup() {
      try {
        await $meetingsApi.signup(this.meeting.id);
        $socket.sendRoom(
          'meeting',
          this.meeting.id,
          'meeting-members-modified'
        );
      } catch (e) {
        await $popups.alert(
          'There was an error saving. Please try again or contact support.'
        );
        throw e;
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$refs.main) {
      await this.$refs.main.leave();
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.$refs.main) {
      await this.$refs.main.leave();
    }
    next();
  },
};
</script>

<style>
.meeting-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.meeting-body {
  flex: 1;
  flex-direction: column;
  overflow: scroll;
}
.meeting-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
}
.meeting-detail {
  display: flex;
  flex-direction: column;
}
.meeting-live__waiting {
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--cs-gray-07);
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  border: dashed 1px var(--cs-primary-base);
  padding: 10px;
}
.meeting-active {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-color: #333;
}
.bottom-panel {
  display: flex;
  height: 100%;
  background-color: var(--cs-gray-00);
  border-radius: 5px;
}
.meeting-page-tabs {
  display: flex !important;
  flex-direction: column;
  flex: 1;
}
.meeting-page-tabs .cs-page-tabs__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f7f7f7;
  overflow: auto;
}
.confirm-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}
.meeting__custom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
.meeting-page-tabs .cs-page-tabs {
  box-shadow: none !important;
  background: #f7f7f7 !important;
}
</style>
<style scoped>
:root {
  --cs-font-primary: 'Lato';
}
.meeting-page-tabs__breakout, .meeting-page-tabs__handouts {
  padding: 0;
}

.meeting-page-tabs__chat {
  flex: 1;
  background: #ffffff;
}
.meeting-page-tabs .cs-page-tab {
  padding-bottom: 136px;
}
</style>
