<template>
  <app-modal
    :show="showMeetingEdit"
    title="Edit Event"
    action="Update"
    :disable-action="saving || slugLoading"
    class="meeting-edit-modal"
    :class="{ 'edit-custom-modal': listView }"
    @close="closeMeetingModal"
    @action="onUpdateMeeting"
  >
    <cs-spinner v-if="saving" />
    <!-- Tabs  -->
    <cs-page-tabs v-if="!saving" align="justify" class="modal-tabs">
      <!-- First Tab - Details  -->
      <cs-page-tab label="Details">
        <validation-observer
          v-if="draftMeeting"
          ref="meeting"
          tag="div"
          class="ch-create-meeting__fields"
        >
          <!-- Title -->
          <cs-input v-model="draftMeeting.name" label="Name"></cs-input>
          <!-- Category -->
          <cs-select
            v-model="draftMeeting.category"
            label="Category"
            :options="categoryOptions"
          ></cs-select>
          <!-- Description -->
          <cs-textarea
            v-model="draftMeeting.description"
            label="Description"
          ></cs-textarea>
          <!-- paid event price -->

          <label v-if="hasPaymentId" class="cs-textstyle-label-text"
            >Tickets</label
          >
          <cs-checkbox
            v-if="hasPaymentId"
            v-model="enableTickets"
            @input="clearTicketPrice"
            >Charge for access</cs-checkbox
          >

          <div v-if="enableTickets">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, min_value: 1 }"
            >
              <cs-input
                v-model="draftMeeting.ticketPrice"
                class="input-size"
                label="Ticket Price (USD)"
                type="number"
              >
              </cs-input>
              <div v-if="errors[0]" class="meeting-referral-code__error">
                The ticket price cannot be 0 or less than 0 or empty
              </div>
            </validation-provider>
            <div
              v-for="(referralCode, index) in referralCodes"
              :key="index"
              class="meeting-referral-code"
            >
              <div
                class="meeting-referral-code__title cs-textstyle-label-text date-label"
              >
                Referral code #{{ index + 1 }}
              </div>
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
              >
                <cs-input
                  v-model="referralCode.name"
                  class="mt-3 input-size"
                  label="Name"
                  type="text"
                >
                </cs-input>
                <div v-if="errors[0]" class="meeting-referral-code__error">
                  Referral code name is required
                </div>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min_value: 1,
                  max_value:
                    referralCode.type === 'USD'
                      ? draftMeeting.ticketPrice
                      : 100,
                }"
              >
                <cs-input
                  v-model="referralCode.amount"
                  class="mt-3 input-size"
                  :label="`Discount in ${referralCode.type}`"
                  type="number"
                >
                </cs-input>
                <div v-if="errors[0]" class="meeting-referral-code__error">
                  Discount is required and it should be lower than Ticket price
                </div>
              </validation-provider>
              <div class="meeting-referral-code__footer">
                <cs-toggle
                  label-checked="Return to USD"
                  label-unchecked="Switch to %"
                  :value="referralCodes[index].type === '%'"
                  @change="changeReferralCodeType($event, index)"
                ></cs-toggle>
                <cs-button
                  title="Delete ReferralCode"
                  @click="areYouSure(index)"
                >
                  <i class="cs-icons-delete"></i> #{{ index + 1 }}
                </cs-button>
              </div>
            </div>
            <app-popup
              :show="showDeleteReferralCodeModal"
              type="confirm"
              variant="danger"
              ok-label="Delete"
              :title="`Delete Referral code #${referralCodeDeleteIndex + 1}`"
              message="Are you sure you wish to delete this referral code?"
              @close="showDeleteReferralCodeModal = false"
              @ok="deleteReferralCode"
            />
            <cs-button
              class="meeting-referral-code__add mt-5 input-size"
              @click="addNewReferralCode"
            >
              <i class="cs-icons-plus"></i> Referral code
            </cs-button>
          </div>

          <!-- Image -->
          <app-image-input
            v-model="draftMeeting.picture"
            :enforce-ratio="1 / 1"
            label="Picture (required ratio 1 : 1)"
          />
          <!-- Event Start/End date -->
          <label class="cs-textstyle-label-text date-label">Event Date</label>
          <cs-date-input
            v-model="startDate"
            class="datetime-input"
            label="Start Date (dd/mm/yyyy)"
          ></cs-date-input>
          <cs-time-input
            v-model="startTime"
            class="datetime-input"
            label="Start Time"
          ></cs-time-input>
          <!-- Enable Queue -->
          <cs-radio-group
            v-model="draftMeeting.enableQueue"
            label="Allow audience to raise hand?"
            :options="enableQueueOption"
          />
          <!-- <cs-radio-group
            v-model="draftMeeting.liveStreamMode"
            label="Where do you want to host speakers?"
            :options="liveStreamModeOptions"
          /> -->
          <!-- slug -->
          <app-slug-input
            :slug="draftMeeting.slug"
            :name="draftMeeting.name"
            slug-type="MEETING"
            @slugInput="handleSlugInput"
            @slugChecking="slugLoading = true"
          />
        </validation-observer>
      </cs-page-tab>
      <!-- Second Tab - Custom Buttons  -->
      <cs-page-tab label="Custom Buttons">
        <div class="cs-textstyle-paragraph-small info-text custom-button-text">
          Edit your custom buttons
        </div>
        <!-- First Button  -->
        <cs-input
          v-model="button1Label"
          class="input-size"
          label="Custom Button 1 Label"
        />
        <cs-input
          v-model="button1Link"
          class="input-size"
          label="Custom Button 1 Link"
        />
        <!-- second button  -->
        <cs-input
          v-model="button2Label"
          class="input-size"
          label="Custom Button 2 Label"
        />
        <cs-input
          v-model="button2Link"
          class="input-size"
          label="Custom Button 2 Link"
        />
        <!-- third button  -->
        <cs-input
          v-model="button3Label"
          class="input-size"
          label="Custom Button 3 Label"
        />
        <cs-input
          v-model="button3Link"
          class="input-size"
          label="Custom Button 3 Link"
        />
      </cs-page-tab>
    </cs-page-tabs>
  </app-modal>
</template>

<script>
import $aws from '@/services/aws';
import $socket from '@/services/socket';
import $auth from '@/services/auth';
import $meetingsApi from '@/api/meetings';

import meetingcategories from '@/assets/config/meetingcategories.json';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UpdateMeeting from '@/gql/meetings/UpdateMeeting.gql';
import AppModal from '@/components/general/Modal.vue';
import AppImageInput from '@/components/general/ImageInput.vue';
import AppSlugInput from '@/components/general/SlugInput.vue';
import AppPopup from '@/components/general/Popup.vue';

export default {
  components: {
    AppModal,
    AppImageInput,
    AppSlugInput,
    AppPopup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    originalMeeting: {
      type: Object,
    },
    showMeetingEdit: {
      type: Boolean,
      default: false,
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      referralCodes: [],
      referralCodeDeleteIndex: null,
      showDeleteReferralCodeModal: false,
      draftMeeting: null,
      startDate: null,
      startTime: null,
      startsImmediately: true,
      categoryOptions: meetingcategories,
      dateOptions: ['Start Now', 'Schedule'],
      enableQueueOption: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      liveStreamModeOptions: [
        { label: 'CloutHub', value: 'false' },
        { label: 'External Software', value: 'true' },
      ],
      value: 'Start Now',
      saving: false,
      formattedDate: null,
      isPrivate: false,
      button1Label: null,
      button2Label: null,
      button3Label: null,
      button1Link: null,
      button2Link: null,
      button3Link: null,
      buttons: [],
      slugLoading: false,
      hasPaymentId: false,
      enableTickets: true,
    };
  },
  async mounted() {
    const user = await $auth.getUser();
    if (user.enablePayments) this.hasPaymentId = true;
    this.draftMeeting = this.originalMeeting;
    this.draftMeeting.enableQueue = this.originalMeeting.enableQueue
      ? 'true'
      : 'false';
    this.draftMeeting.liveStreamMode = this.originalMeeting.liveStreamMode
      ? 'true'
      : 'false';
    this.draftMeeting.ticketPrice = this.originalMeeting.ticketPrice
      ? this.originalMeeting.ticketPrice
      : 0;
    this.enableTickets = this.originalMeeting.ticketPrice;
    const currentStartTime = new Date(this.originalMeeting.startTime);
    this.startDate = this.originalMeeting.startTime;
    this.startTime = currentStartTime;
    this.button3Label =
      this.originalMeeting.buttons && this.originalMeeting.buttons[2]
        ? this.originalMeeting.buttons[2].label
        : '';
    this.button2Label =
      this.originalMeeting.buttons && this.originalMeeting.buttons[1]
        ? this.originalMeeting.buttons[1].label
        : '';
    this.button1Label =
      this.originalMeeting.buttons && this.originalMeeting.buttons[0]
        ? this.originalMeeting.buttons[0].label
        : '';
    this.button3Link =
      this.originalMeeting.buttons && this.originalMeeting.buttons[2]
        ? this.originalMeeting.buttons[2].link
        : '';
    this.button2Link =
      this.originalMeeting.buttons && this.originalMeeting.buttons[1]
        ? this.originalMeeting.buttons[1].link
        : '';
    this.button1Link =
      this.originalMeeting.buttons && this.originalMeeting.buttons[0]
        ? this.originalMeeting.buttons[0].link
        : '';

    this.referralCodes = this.originalMeeting.referralCodes.map(
      ({ __typename, ...rest }) => {
        if (Number.isInteger(Number(rest.amount)))
          rest.amount = Math.trunc(Number(rest.amount)).toString();
        return rest;
      }
    );
  },
  methods: {
    areYouSure(index) {
      this.referralCodeDeleteIndex = index;
      this.showDeleteReferralCodeModal = true;
    },
    async deleteReferralCode() {
      const index = this.referralCodeDeleteIndex;
      this.showDeleteReferralCodeModal = false;
      await $meetingsApi.deleteReferralCode(
        this.referralCodes[index].id,
        this.originalMeeting
      );
      this.referralCodes.splice(index, 1);
      this.referralCodeDeleteIndex = null;
    },
    changeReferralCodeType({ value }, index) {
      this.referralCodes[index].type = value ? '%' : 'USD';
    },
    async addNewReferralCode() {
      const valid = await this.$refs.meeting.validate();
      if (!valid) return;
      this.referralCodes.push({
        name: '',
        amount: '',
        type: 'USD',
      });
    },
    selectDateOption(val) {
      if (val === 'Start Now') {
        this.startsImmediately = true;
        this.value = val;
      } else {
        this.startsImmediately = false;
        this.value = val;
      }
    },
    closeMeetingModal() {
      this.$emit('close-edit');
    },
    // Save changes
    async onUpdateMeeting() {
      const valid = await this.$refs.meeting.validate();
      if (!valid) return;
      this.saving = true;
      if (this.button1Label || this.button1Link) {
        const link =
          this.button1Link &&
          this.button1Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button1Link}`
            : this.button1Link === '' ||
              this.button1Link === null ||
              this.button1Link.value === 0
            ? null
            : `http://${this.button1Link}`;
        this.buttons = [{ label: this.button1Label, link }];
      }
      if (this.button2Label || this.button2Link) {
        const link =
          this.button2Link &&
          this.button2Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button2Link}`
            : this.button2Link === '' ||
              this.button2Link === null ||
              this.button2Link.value === 0
            ? null
            : `http://${this.button2Link}`;
        this.buttons = [...this.buttons, { label: this.button2Label, link }];
      }
      if (this.button3Label || this.button3Link) {
        const link =
          this.button3Link &&
          this.button3Link.split('').slice(0, 4).join('') === 'http'
            ? `${this.button3Link}`
            : this.button3Link === '' ||
              this.button3Link === null ||
              this.button3Link.value === 0
            ? null
            : `http://${this.button3Link}`;
        this.buttons = [...this.buttons, { label: this.button3Label, link }];
      }
      let finalTime;
      if (this.startDate.split(':').length > 1) {
        finalTime = new Date(this.startDate);
      } else {
        finalTime = new Date(this.startDate.replace(/-/g, '\/'));
      }
      console.log('---', typeof this.startTime);
      if (typeof this.startTime === 'string') {
        finalTime.setHours(this.startTime.split(':')[0]);
        finalTime.setMinutes(this.startTime.split(':')[1]);
      } else {
        finalTime.setHours(this.startTime.getHours());
        finalTime.setMinutes(this.startTime.getMinutes());
      }
      if (
        this.draftMeeting.picture &&
        this.draftMeeting.picture !== this.originalMeeting.picture
      ) {
        this.draftMeeting.picture = await $aws.uploadFile(
          this.draftMeeting.picture,
          'forum'
        );
      }
      await this.$apollo.mutate({
        mutation: UpdateMeeting,
        variables: {
          id: this.draftMeeting.id,
          name: this.draftMeeting.name,
          description: this.draftMeeting.description,
          picture: this.draftMeeting.picture,
          category: this.draftMeeting.category,
          privacy: this.draftMeeting.privacy,
          startTime: finalTime,
          endTime: this.draftMeeting.endTime,
          buttons: this.buttons,
          enableQueue: this.draftMeeting.enableQueue === 'true',
          liveStreamMode: this.draftMeeting.liveStreamMode === 'true',
          slug: this.draftMeeting.slug,
          ticketPrice: parseFloat(this.draftMeeting.ticketPrice),
          referralCodes: this.referralCodes,
        },
      });
      this.buttons = [];
      this.saving = false;
      this.formattedDate = null;
      this.closeMeetingModal();
      $socket.sendRoom('meeting', this.draftMeeting.id, 'meeting-edited');
    },
    handleSlugInput(slug) {
      this.draftMeeting.slug = slug;
      this.slugLoading = false;
    },
    clearTicketPrice() {
      this.draftMeeting.ticketPrice = 0;
    },
  },
};
</script>

<style scoped>
.ch-edit-meeting__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px auto 20px;
}
* >>> .ch-modal__body {
  padding: 1px 0px;
}
.checkbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
input[type='checkbox'] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--cs-primary-base);
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: white;
}
input[type='checkbox']:checked {
  background: white;
}
input[type='checkbox']:checked:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 8px;
  background: rgba(0, 0, 0, 0);
  top: 3px;
  left: 2px;
  border: 2px solid var(--cs-primary-base);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.custom-button-text {
  margin-bottom: 10px;
}
.meeting-edit-modal >>> .ch-modal__content {
  position: fixed;
  top: 0;
  left: 0;
}
.edit-custom-modal >>> .cs-page-tabs__content {
  padding: 16px;
}

.meeting-referral-code__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 0;
}

.meeting-referral-code__title {
  margin: 15px 0;
}

.meeting-referral-code__add {
  width: 100%;
  margin: 15px 0;
}

.meeting-referral-code__error {
  color: var(--cs-danger-base);
}
</style>
