<template>
  <ion-page>
    <ion-header>
      <div class="ch-video-category__header">
        <app-category-header :title="currentTitle" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-meeting-list :meetings="meetings" direction="column"></app-meeting-list>
      <app-infinite-offset :query="$apollo.queries.meetings" />
    </ion-content>
  </ion-page>
</template>

<script>
import AppMeetingList from '@/components/meetings/MeetingList.vue';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

// GQL
import ListLiveMeetings from '@/gql/meetings/ListLiveMeetings.gql';
import ListScheduledMeetings from '@/gql/meetings/ListScheduledMeetings.gql';
import ListPublicMeetings from '@/gql/meetings/ListPublicMeetings.gql';
import ListAttendingMeetings from '@/gql/meetings/ListAttendingMeetings.gql';
import ListAttendedMeetings from '@/gql/meetings/ListAttendedMeetings.gql';
import ListInvitedMeetings from '@/gql/meetings/ListInvitedMeetings.gql';

const viewSettings = {
  all: {
    title: 'All Meetings',
    query: ListPublicMeetings,
  },
  live: {
    title: 'Live Meetings',
    query: ListLiveMeetings,
  },
  upcoming: {
    title: 'Upcoming Meetings',
    query: ListScheduledMeetings,
  },
  attending: {
    title: 'Attending Meetings',
    query: ListAttendingMeetings,
  },
  attended: {
    title: 'Attended Meetings',
    query: ListAttendedMeetings,
  },
  invited: {
    title: 'Invited Meetings',
    query: ListInvitedMeetings,
  },
};
export default {
  components: {
    AppMeetingList,
    AppCategoryHeader,
    AppPullToRefresh,
    AppInfiniteOffset,
  },
  data() {
    return {
      view: this.$route.params.view,
    };
  },
  apollo: {
    meetings: {
      query() {
        return this.currentView.query;
      },
    },
  },
  computed: {
    currentTitle() {
      return viewSettings[this.$route.params.view].title;
    },
    currentView() {
      return viewSettings[this.$route.params.view];
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.meetings.refetch();
      $event && $event.target.complete();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped></style>
