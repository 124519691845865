<template>
  <ion-page>
    <ion-header>
      <app-main-header hide-notifications title="Meetings" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-search-bar v-model="query" @search="search" />
      <div class="top-panel">
        <cs-page-tabs class="meeting-page-tabs" align="justify">
          <cs-page-tab label="Live Now" class="bg-fix">
            <app-meeting-segment
              ref="liveMeetings"
              title="Live Events"
              :query="liveMeetings"
              view="live"
            />
          </cs-page-tab>
          <cs-page-tab label="Scheduled" class="bg-fix">
            <app-meeting-segment
              ref="upcomingMeetings"
              title="Upcoming Events"
              :query="upcomingMeetings"
              view="upcoming"
            />
          </cs-page-tab>
          <cs-page-tab label="Completed" class="bg-fix">
            <app-meeting-segment
              ref="completedMeetings"
              title="Completed Events"
              :query="completedMeetings"
              view="completed"
            />
          </cs-page-tab>
        </cs-page-tabs>
      </div>

      <!-- Floating Button -->
      <app-meeting-explore-btn />
    </ion-content>
    <app-create-button @click="createMeeting()" />
  </ion-page>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';

import AppSearchBar from '@/components/general/SearchBar.vue';
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppMeetingExploreBtn from '@/components/meetings/MeetingExploreBtn.vue';
import AppMeetingSegment from '@/components/meetings/MeetingSegment.vue';
import AppCreateButton from '@/components/general/CreateButton.vue';

// GQL
import ListLiveMeetings from '@/gql/meetings/ListLiveMeetings.gql';
import ListScheduledMeetings from '@/gql/meetings/ListScheduledMeetings.gql';
import ListInvitedMeetings from '@/gql/meetings/ListInvitedMeetings.gql';
import ListCompletedMeetings from '@/gql/meetings/ListCompletedMeetings.gql';

export default {
  components: {
    AppMainHeader,
    AppPullToRefresh,
    AppMeetingSegment,
    AppMeetingExploreBtn,
    AppSearchBar,
    AppCreateButton,
  },
  data() {
    return {
      query: '',
      showCreate: false,
      user: {},
      liveMeetings: ListLiveMeetings,
      upcomingMeetings: ListScheduledMeetings,
      invitedMeetings: ListInvitedMeetings,
      completedMeetings: ListCompletedMeetings,
      whitelist: [
        'be03853c-6190-4c6f-bdf5-912253d9b90d', // @janakan
        '39a4e45f-c0c3-4b82-8c35-acd03a1a7f8b', // @rajivkhatri
      ],
    };
  },
  computed: {
    canCreate() {
      return (
        this.user.badgeType === 'influencer' ||
        this.user.badgeType === 'founder' ||
        this.user.badgeType === 'channel' ||
        this.whitelist.includes(this.user.id)
      );
    },
  },
  async mounted() {
    this.user = await $auth.getUser();
    this.refresh();
  },
  methods: {
    createMeeting() {
      $bus.$emit('show-create-meeting-modal');
    },
    async refresh($event) {
      await Promise.all([
        this.$refs.liveMeetings.refresh(),
        this.$refs.upcomingMeetings.refresh(),
        this.$refs.completedMeetings.refresh(),
      ]);
      $event && $event.target.complete();
    },
    search() {
      this.$router.push({
        name: 'MeetingSearch',
        query: {
          q: this.query,
        },
      });
    },
  },
};
</script>

<style scoped>
* >>> .cs-page-tabs .cs-page-tab {
  padding: 0px;
}
.bg-fix {
  background-color: var(--cs-gray-00)
}
</style>
