<template>
  <div class="post-list">
    <cs-spinner v-if="!posts"></cs-spinner>
    <div v-if="posts" class="ch-profile-post-list">
      <app-post-card v-for="post in posts" :key="post.id" :post="post" />
    </div>
    <app-infinite-simple :query="$apollo.queries.posts" sort-key="createdAt"></app-infinite-simple>
  </div>
</template>

<script>
import ListTagPosts from '@/gql/posts/ListTagPosts.gql';
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppPostCard,
    AppInfiniteSimple,
  },
  apollo: {
    posts: {
      query: ListTagPosts,
      variables() {
        return {
          tag: this.$route.params.tag,
        };
      },
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.posts.refetch();
      return true;
    },
  },
};
</script>
