<template>
  <div>
    <app-form-modal
      :show="showModal"
      :title="modalTitle"
      :action="saveButtonLabel"
      :disable-action="isEmpty || isBlocked"
      :saving="saving"
      :with-closing-alert="true"
      @close="closeModal"
      @action="save()"
    >
      <template v-if="post">
        <!-- Post composer and user locaion -->
        <app-post-composer-text
          v-model="post"
          :body-text="initialBodyText"
          :shared-link="sharedLink"
          :has-images="!!post.images && !!post.images.length"
          :is-comment="isComment"
          @textHandle="textHandle"
          @detectLink="detectLink"
          @removeLinkPreview="removeLinkPreview"
          @handleTags="handleTags"
          @addUserLocation="addUserLocation"
          @removeUserLocation="removeUserLocation"
          @blockPosting="blockPosting"
        />

        <app-check-box
          v-if="forGroups"
          v-model="isSharingToSubgroups"
          label="Subgroups"
          @click="openSelectSubgroups"
        />

        <!-- Tags -->
        <div v-if="postBodyTags.length" class="ch-posts-tags">
          <cs-tag
            v-for="(tag, $index) in postBodyTags"
            :key="tag + $index"
            class="ch-posts-tags__tag"
          >
            <span>#{{ tag }}</span>
          </cs-tag>
        </div>

        <!-- Uploads/Attachments -->
        <app-attachment-previews v-model="post" />

        <!-- Main/Upload Buttons -->
        <app-attachment-buttons v-model="post" />
      </template>

      <!-- <app-tag-search-modal :show="true" @closeSearch="closeTagsModal" @add="onAddTags" /> -->
    </app-form-modal>

    <app-group-select-subgroup
      v-if="forGroups"
      :group-id="groupId"
      :open="visibleSelectSubgroup"
      @close="closeSelectSubgroup"
      @changeSelectedSubgroups="changeSelectedSubgroups"
    />
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $popups from '@/services/popups';
import $upload from '@/services/upload';

import $postsApi from '@/api/posts';

import AppFormModal from '@/components/general/FormModal.vue';
import AppPostComposerText from '@/components/post/composer/PostComposerText.vue';
import AppAttachmentPreviews from '@/components/post/composer/PostComposerAttachmentPreviews.vue';
import AppAttachmentButtons from '@/components/post/composer/PostComposerAttachmentButtons.vue';
import AppGroupSelectSubgroup from '@/components/groups/GroupSelectSubgroup.vue';
import AppCheckBox from '@/components/general/CheckBox.vue';

function cleanPost(postdata) {
  const postData = JSON.parse(JSON.stringify(postdata));
  const createProps = [
    'images',
    'document',
    'bodyText',
    'video',
    'visibility',
    'location',
    'room',
    'tags',
    'linkPreview',
    'postLevel',
    'groupId',
    'parentPostId',
    'rootPostId',
    'sharedPostId',
    'category',
    'forGroups',
  ];
  const editProps = [
    'postId',
    'bodyText',
    'linkPreview',
    'images',
    'document',
    'video',
    'location',
    'tags',
    'category',
    'forGroups',
  ];
  const props = postData.id ? editProps : createProps;
  const post = {};
  props.forEach((prop) => {
    if (postData[prop]) post[prop] = postData[prop];
  });
  // // Extra line for editing posts to use 'postId' instead of 'id'
  if (postData.location) post.location = postData.location;
  if (postData.id) post.postId = postData.id;
  return post;
}

export default {
  components: {
    AppFormModal,
    AppPostComposerText,
    AppAttachmentPreviews,
    AppAttachmentButtons,
    AppGroupSelectSubgroup,
    AppCheckBox,
  },
  data() {
    return {
      post: null,
      initialBodyText: '',
      bodyText: '',
      showModal: false,
      saving: false,
      sharedLink: null,
      postBody: {},
      postBodyTags: [],
      docAttachment: null,
      videoAttachment: null,
      attachments: [],
      user: null,
      saveCallback: undefined,
      forGroups: false,
      isComment: false,
      visibleSelectSubgroup: false,
      groupId: undefined,
      selectedSubgroups: [],
      isBlocked: false,
    };
  },
  computed: {
    hasAttachment() {
      if (!this.post) return false;
      return (
        !!(this.post.images && this.post.images.length) ||
        this.post.video ||
        this.post.document
      );
    },
    isEmpty() {
      return !this.bodyText && !this.hasAttachment;
    },
    isCreate() {
      return !(this.post && this.post.postId);
    },
    saveButtonLabel() {
      return this.isCreate ? 'Post' : 'Update';
    },
    modalTitle() {
      return this.isCreate ? 'Create Post' : 'Edit Post';
    },
    isSharingToSubgroups() {
      return !!this.selectedSubgroups.length;
    },
  },
  mounted() {
    $bus.$on(
      'show-post-composer-modal',
      ({
        postData,
        video,
        groupId,
        link,
        images,
        bodyText,
        saveCallback,
        forGroups,
        isComment,
      }) => {
        const data = postData || {};
        this.post = cleanPost(data);

        if (video) this.$set(this.post, 'video', video);
        if (images) this.$set(this.post, 'images', images);
        if (groupId) {
          this.$set(this.post, 'groupId', groupId);
          this.groupId = groupId;
        }

        if (bodyText) {
          this.initialBodyText = bodyText;
          this.bodyText = this.initialBodyText;
        }

        if (postData) {
          this.initialBodyText = postData.bodyText ? postData.bodyText : '';
          this.bodyText = this.initialBodyText;
          if (postData.tags) {
            this.postBodyTags = postData.tags ? postData.tags : [];
          }
        }
        this.sharedLink = link;
        this.showModal = true;
        this.saveCallback = saveCallback;
        this.forGroups = forGroups;
        this.isComment = isComment;
      }
    );
  },
  methods: {
    addUserLocation(location) {
      this.$set(this.post, 'location', location);
    },
    removeUserLocation() {
      this.$set(this.post, 'location', '');
    },
    textHandle(bodyText) {
      this.bodyText = bodyText;
    },
    handleTags(tags) {
      this.postBodyTags = tags;
    },
    detectLink(link) {
      this.$set(this.post, 'linkPreview', {
        ...link,
      });
    },
    blockPosting(blocked) {
      this.isBlocked = blocked;
    },
    removeLinkPreview() {
      this.$set(this.post, 'linkPreview', null);
    },
    setDefault() {
      this.post = null;
      this.initialBodyText = '';
      this.bodyText = '';
      this.sharedLink = null;
      this.postBody = {};
      this.postBodyTags = [];
    },
    closeModal() {
      this.setDefault();
      this.showModal = false;
      this.saveCallback = undefined;
      $bus.$emit('remove-share');
    },
    cacheInsertPost(cache, query, params, post) {
      const cachedData = cache.readQuery({
        query,
        variables: params,
      });
      if (cachedData && cachedData.posts) {
        cache.writeQuery({
          query,
          variables: params,
          data: {
            posts: [post, ...cachedData.posts],
          },
        });
      }
    },
    async save() {
      this.saving = true;

      this.post.bodyText = this.bodyText;
      this.post.tags = this.postBodyTags;
      if (!this.post.visibility) this.post.visibility = 'all';
      if (this.forGroups) this.post.subgroupIds = this.selectedSubgroups;

      if (this.hasAttachment) {
        try {
          const images = this.post.images;
          if (images && images.length > 0) {
            this.post.images = await $upload.uploadImages(images);
          }
          // Add video Attachment
          if (this.post.video) {
            this.post.video = await $upload.uploadVideo(this.post.video);
          }
          // Add Document Attachment
          if (this.post.document) {
            this.post.document = await $upload.uploadDocument(
              this.post.document
            );
          }
        } catch (error) {
          $popups.alert({
            title: 'Error Uploading',
            message:
              'There was an error uploading.  Please check your internet connection.',
          });
          this.saving = false;
          throw error;
        }
      }

      try {
        if (this.isCreate) {
          await $postsApi.createPost(this.post);
        } else {
          await $postsApi.updatePost(this.post);
        }
      } catch (e) {
        $popups.alert({ title: 'Error Publishing', message: e.message });
        this.saving = false;
        throw e;
      }
      this.saving = false;
      if (this.saveCallback) {
        this.saveCallback();
        this.saveCallback = undefined;
      }
      this.$emit('close');
      this.closeModal();
    },
    openSelectSubgroups() {
      this.visibleSelectSubgroup = true;
    },
    closeSelectSubgroup() {
      this.visibleSelectSubgroup = false;
    },
    changeSelectedSubgroups(list) {
      this.selectedSubgroups = list;
    },
  },
};
</script>

<style scoped>
.ch-posts-tags {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: 7px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}
.ch-posts-tags__tag {
  --cs-tag-color: var(--cs-primary-base) !important;
}
</style>
