import { render, staticRenderFns } from "./PublicPostTags.vue?vue&type=template&id=558b4638&scoped=true&"
import script from "./PublicPostTags.vue?vue&type=script&lang=js&"
export * from "./PublicPostTags.vue?vue&type=script&lang=js&"
import style0 from "./PublicPostTags.vue?vue&type=style&index=0&id=558b4638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "558b4638",
  null
  
)

export default component.exports