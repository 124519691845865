<template>
  <div>
    <div class="post-response-summary cs-textstyle-paragraph-small-bold">
      <div class="ch-reactions" @click="trigger('show-reaction-list-modal')">
        <span v-for="reaction in userReactions" :key="reaction">
          <img
            class="emoji"
            :src="'./assets/emojis/' + reaction + '.png'"
            :alt="reaction"
          />
        </span>
        <span>{{ post.reactionCount }}</span>
        <span v-if="!userReactions || !userReactions.length"
          ><i class="ch-icons-reaction"></i>
        </span>
      </div>
      <span @click="$router.push({ name: 'PostDetail', params: post })">
        {{ post.commentsCount }} <i class="ch-icons-comments"></i>
      </span>
    </div>
    <div class="post-response-buttons">
      <cs-action-bar-button
        :icon="myReaction ? '' : 'ch-icons-reaction'"
        style="text-transform: capitalize"
        class="ch-reactions"
        @click="handleEmojiClick"
      >
        <img
          v-if="myReaction"
          class="emoji"
          :src="'./assets/emojis/' + myReaction.value + '.png'"
          :alt="myReaction.value"
        />
        <span>{{ myReaction.name || 'React' }}</span>
      </cs-action-bar-button>
      <cs-action-bar-button icon="ch-icons-comments" @click="commentClick"
        >Comment</cs-action-bar-button
      >
      <cs-action-bar-button icon="cs-icons-share2" @click="share()"
        >Share</cs-action-bar-button
      >
      >
    </div>

    <div class="ch-video-detail__card">
      <div class="ch-video-detail__card--info">
        <app-expandable-text
          class="cs-textstyle-paragraph-small description-block"
          :content="post.video.description || 'No description'"
          :max-lines="5"
          is-description
          placeholder="No Description"
        />
      </div>
    </div>

    <app-post-comments @refresh="emitRefresh" :post="post"></app-post-comments>
    <div class="comment-composer">
      <app-post-comment-composer
        ref="commentComposer"
        :parent="post"
        @refresh="emitRefresh"
      ></app-post-comment-composer>
    </div>
    <app-post-reaction
      :event="emojiClickEvent"
      :show="showEmojies"
      :post="post"
      @refresh="emitRefresh"
      @close="showEmojies = false"
    ></app-post-reaction>

    <!-- Embed Code popup -->
    <app-popup :show-popup="showPopup" class="popup" @close-popup="closePopup">
      <div class="popup-content">
        <div class="cs-textstyle-detail-heading">
          Select and Copy the link below
        </div>
        <div class="cs-textstyle-paragraph-extra-small text-light all-copy">
          {{ embedCode }}
        </div>
      </div>
    </app-popup>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $postsApi from '@/api/posts';
import $reactions from '@/assets/emojis/reactions.json';

import AppPostComments from '@/components/post/response/PostComments.vue';
import AppPostCommentComposer from '@/components/post/composer/PostCommentComposer.vue';
import AppPostReaction from '@/components/post/response/PostReaction.vue';
import AppExpandableText from '@/components/general/ExpandableText.vue';
import AppPopup from '@/components/videos/ChannelPopup.vue';

export default {
  components: {
    AppPostComments,
    AppPostCommentComposer,
    AppPostReaction,
    AppExpandableText,
    AppPopup,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEmojies: false,
      showMsgModal: false,
      emojiClickEvent: null,
      isRemovingReaction: false,
      showPopup: false,
    };
  },
  computed: {
    isLive() {
      return this.post && this.post.video.isLive;
    },    
    embedCode() {
      return `<iframe width="710" height="400" allowfullscreen="true" src="${process.env.VUE_APP_SHARE_DOMAIN}/embed/${this.post.id}"></iframe>`;
    },
    userReactions() {
      return this.post.reactionTotals
        .filter((data) => data.count > 0)
        .map((reaction) => {
          if (reaction.count < 1) return null;
          const emojiData = $reactions.find(
            (data) =>
              data.value === reaction.type || data.name === reaction.type
          );
          // return emojiData && emojiData.emoji ? emojiData.emoji : null;
          return (emojiData && emojiData.value) || null;
        })
        .filter((emoji) => emoji);
    },
    myReaction() {
      if (this.post.myReaction) {
        const myReactionType = this.post.myReaction.type;
        const myReaction = $reactions.find(
          (reaction) => reaction.value === myReactionType
        );
        return myReaction;
      }
      return false;
    },
  },
  methods: {
    async emitRefresh() {
      this.$emit('refresh');
    },
    closePopup() {
      this.showPopup = false;
    },
    async copyEmbedCode() {
      try {
        await navigator.clipboard.writeText(this.embedCode);
        this.$toast.open({
          message: 'Code copied',
          type: 'success',
          duration: 1000,
          dismissible: true,
          position: 'top',
        });
      } catch (err) {
        this.showPopup = true;
      }
    },
    async handleEmojiClick($event) {
      if (!this.post.myReaction && !this.isRemovingReaction) {
        // NOTE: emojiClickEvent is used to position the reaction popover in the right place
        this.emojiClickEvent = $event;
        this.showEmojies = true;
      } else if (this.post.myReaction) {
        // Added isRemovingReaction to stop user from choosing another reaction when removing reaction is in proress
        this.isRemovingReaction = true;
        await $postsApi.unreactPost(this.post);
        await this.emitRefresh();
        this.isRemovingReaction = false;
      }
    },
    share() {
      const link = `/p/${this.post.slug || this.post.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Post',
        post: this.post,
      });
    },
    commentClick() {
      this.$refs.commentComposer.$el.querySelector('textarea').focus();
    },
    trigger(name) {
      $bus.$emit(name, this.post);
    },
  },
};
</script>

<style scoped>
.post-response-summary {
  justify-content: space-between;
  display: flex;
  gap: 20px;
  font-size: 15px;
  align-items: center;
  color: var(--cs-gray-04);
  border-bottom: solid 1px var(--cs-gray-01);
  padding-top: 5px; /* CloutHub requested */
  padding-bottom: 5px; /* CloutHub requested */
}
.post-response-summary__reactions {
  flex: 1;
}
.post-response-buttons {
  display: flex;
  justify-content: space-around; /* CloutHub requested */
  margin-bottom: 7px; /* CloutHub requested */
  padding-top: 4px; /* CloutHub requested */
  padding-bottom: 4px; /* CloutHub requested */
  border-bottom: 1px solid var(--cs-gray-01);
}
.post-response-summary span {
  cursor: pointer;
}
.share-heading {
  margin-bottom: 10px;
}
.embed-btn {
  margin-right: 6px;
}
.ch-video-detail__card--info {
  padding: 10px 5px;
  color: var(--cs-gray-05);
}

.emoji {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 2px;
}
.ch-reactions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.see-more-button {
  color: var(--cs-gray-04);
}
.text-light {
  color: var(--cs-gray-04);
}
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 2px;
  text-align: center;
}
.all-copy {
  -webkit-user-select: all; /* Chrome all / Safari all */
  -moz-user-select: all; /* Firefox all */
  -ms-user-select: all; /* IE 10+ */
  user-select: all; /* Likely future */
}
</style>
