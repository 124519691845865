<template>
  <div>
    <app-form-modal
      :show="show"
      title="Announcements"
      action="Send"
      :saving="saving"
      :disable-action="invalidForm"
      @close="close"
      @action="send"
    >
      <div class="group-send-notification__form">
        <div class="group-send-notification__info">
          <h2 class="group-send-notification__title">
            Send Broadcast Notification
          </h2>
          <p>
            Select recipients you would like to receive this notification. You
            can send this notification to the Admins, Moderators and/or Members
            of specific subgroups or all subgroups
          </p>
        </div>
        <app-role-select
          v-model="recipients"
          :group="group"
          :subgroups="subgroups"
        />
        <div>
          <app-textarea
            v-model="description"
            class="group-broadcast-modal__textarea"
            label="Enter what you want your notification to say"
            placeholder="Enter text here"
            maxlength="200"
          />
          <div class="group-send-notification__description-chars">
            {{ description ? description.length : 0 }}/200 Characters
          </div>
        </div>
        <cs-input
          v-model="link"
          label="Link (optional)"
          placeholder="https://example.com"
        />
      </div>
    </app-form-modal>
  </div>
</template>

<script>
import $popups from '@/services/popups';

import AppTextarea from '@/components/general/Textarea.vue';
import AppFormModal from '@/components/general/FormModal.vue';
import AppRoleSelect from '@/components/groups/GroupRoleSelect.vue';

import SendAnnouncement from '@/gql/groups/SendAnnouncement.gql';

export default {
  components: {
    AppFormModal,
    AppTextarea,
    AppRoleSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      required: true,
    },
    subgroups: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      saving: false,
      description: '',
      link: '',
      recipients: [],
    };
  },
  computed: {
    invalidForm() {
      if (this.description.length < 6) return true;
      if (!this.recipients.length) return true;
      return false;
    },
  },
  methods: {
    send() {
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: SendAnnouncement,
          variables: {
            groupId: this.group.id,
            recipients: this.recipients,
            description: this.description,
            link: this.link,
          },
        })
        .then(({ data }) => {
          if (data.send_announcement) this.close();
          else
            $popups.alert(
              'There was an error.  Please try again or contact support.'
            );
        })
        .catch((err) => {
          $popups.alert(
            'There was an error.  Please try again or contact support.'
          );
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.saving = false;
      this.$emit('close');
    },
    error(message, err) {
      this.saving = false;
      $popups.alert(message);
      throw err;
    },
  },
};
</script>

<style scoped>
.group-send-notification__description-chars {
  text-align: right;
  color: var(--cs-gray-04);
  margin-top: 5px;
}
.group-send-notification__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.group-send-notification__info {
  text-align: center;
}
.group-send-notification__title {
  color: var(--cs-primary-base);
}
</style>
