<template>
  <ion-page>
    <ion-header class="ch-search__header">
      <app-category-header title="Search" />
      <cs-page-tabs :tabs="tabs" v-model="tabIndex" align="justify" no-border />
    </ion-header>
    <ion-content>
      <div v-if="tabIndex === 0"><app-user-search /></div>
      <div v-if="tabIndex === 1"><app-post-search /></div>
      <div v-if="tabIndex === 2"><app-group-search /></div>
      <div v-if="tabIndex === 3"><app-video-search /></div>
      <div v-if="tabIndex === 4"><app-news-search /></div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppUserSearch from '@/components/search/UserSearch.vue';
import AppGroupSearch from '@/components/search/GroupSearch.vue';
import AppPostSearch from '@/components/search/PostSearch.vue';
import AppNewsSearch from '@/components/search/NewsSearch2.vue';
import AppVideoSearch from '@/components/search/VideoSearch.vue';

export default {
  components: {
    AppCategoryHeader,
    AppUserSearch,
    AppGroupSearch,
    AppPostSearch,
    AppNewsSearch,
    AppVideoSearch,
  },
  data() {
    return {
      tabs: ['Users', 'Posts', 'Groups', 'Videos', 'News'],
      tabIndex: 0,
    };
  },
  mounted() {
    if (this.$route.params.index) {
      const index = this.$route.params.index;
      this.tabIndex = parseInt(index, 10);
    }
  },
};
</script>

<style scoped>
.cs-page-tabs {
  --cs-page-tabs-label-padding: 12px;
  background-color: var(--cs-gray-00);
}
</style>
