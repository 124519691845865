<template>
  <span hidden></span>
</template>

<script>
import AppReactionListPopover from '@/components/post/items/ReactionListPopover.vue';

export default {
  props: {
    show: {
      type: Boolean,
    },
    post: {
      type: Object,
      required: true,
    },
    event: {
      type: Event,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      popover: null,
    };
  },
  watch: {
    async show() {
      if (this.show && !this.popover) {
        const options = {
          component: AppReactionListPopover,
          componentProps: {
            propsData: {
              post: this.post,
            },
          },
          cssClass: 'ch-post-reaction-popover',
          event: this.event,
          showBackdrop: true,
        };
        this.popover = await this.$ionic.popoverController.create(options);
        this.popover.onDidDismiss().then(this.close).then(this.emitRefresh);
        this.popover.present();
      } else if (!this.show && this.popover) {
        this.popover.dismiss();
      }
    },
  },
  methods: {
    close() {
      this.popover = null;
      this.$emit('close');
    },
    async emitRefresh() {
      this.$emit('refresh');
    },
  },
};
</script>

<style>
/* NOTE: Ideally this would be scoped but it doesn't apply to Ionic when that happens */
/* If you add more icons the min-width may need to be further styled */
.ch-post-reaction-popover {
  --min-width: 360px;
  /* NOTE: We could show box-shadow instead of background, but the white triangle at the top disappears */
  /* --box-shadow: 0 3px 12px rgb(0 0 0 / 12%); */
}
.ch-post-reaction-popover .popover-content {
  border-radius: 50px;
}
</style>
