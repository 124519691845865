<template>
  <div class="meeting-invite-button">
    <app-meeting-control-button @click="openShareModal">
      <i class="meeting-invite-button__icon ch-icons-share" /> Share
    </app-meeting-control-button>
    <app-meeting-share-sheet
      :meeting="meeting"
      :show-modal="isShareModalOpen"
      @close="closeShareModal"
    />
  </div>
</template>
<script>
import AppMeetingShareSheet from '@/components/meetings/MeetingShareSheet.vue';
import AppMeetingControlButton from '@/components/meetings/buttons/MeetingControlButton.vue';

export default {
  components: {
    AppMeetingShareSheet,
    AppMeetingControlButton,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isShareModalOpen: false,
  }),
  methods: {
    openShareModal() {
      this.isShareModalOpen = true;
    },
    closeShareModal() {
      this.isShareModalOpen = false;
    },
  },
};
</script>
<style>
.meeting-invite-button__icon {
  font-size: 20px;
}
</style>
