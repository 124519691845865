<template>
  <app-page-menu title="Explore Connections">
    <div class="cs-textstyle-item-heading">Explore your connections</div>
    <!-- Discover -->
    <app-page-menu-category title="Discover">
      <app-page-menu-category-item
        label="Recommended"
        :to="{ name: 'UserList', params: { view: 'recommended' } }"
      />
      <app-page-menu-category-item
        v-for="item in discover"
        :key="item.label"
        :label="item.label"
        :to="{ name: 'BadgeUserList', params: { badge: item.badge } }"
      />
    </app-page-menu-category>
    <!-- My Connections -->
    <app-page-menu-category title="My Connections">
      <app-page-menu-category-item
        label="My Profile"
        :to="{ name: 'Profile', params: { id: myid } }"
      />
      <app-page-menu-category-item
        v-for="item in myConnections"
        :key="item.view"
        :label="item.label"
        :to="{ name: 'UserList', params: { view: item.view } }"
      />
    </app-page-menu-category>
  </app-page-menu>
</template>

<script>
import $auth from '@/services/auth';
// Component
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuCategory from '@/components/general/PageMenuCategory.vue';
import AppPageMenuCategoryItem from '@/components/general/PageMenuCategoryItem.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuCategory,
    AppPageMenuCategoryItem,
  },
  data() {
    return {
      myid: $auth.getUserId(),
      discover: [
        {
          label: 'Influencer',
          badge: 'influencer',
        },
        {
          label: 'News',
          badge: 'news',
        },
        {
          label: 'Channel',
          badge: 'channel',
        },
      ],
      myConnections: [
        {
          label: 'Friends',
          view: 'friends',
        },
        {
          label: 'Followers',
          view: 'followers',
        },
        {
          label: 'Following',
          view: 'following',
        },
      ],
    };
  },
};
</script>
