import $aws from '@/services/aws';
import $utils from '@/services/utils';

function isFile(file) {
  // Accepts files, or blobs pretending to be files
  //  && (file.name || file.filename) && file.lastModifiedDate
  return (file instanceof File) || (file instanceof Blob);
}

const formatAttachment = (attachment, type) => {
  if (isFile(attachment)) {
    return {
      contentType: attachment.type,
      size: attachment.size,
      url: attachment.url,
      name: attachment.name,
      type,
    };
  }
  return attachment;
}

const uploadImages = async (files) => {
  try {
    const filesToUpload = files.filter(isFile);
    const urls = await $aws.bulkUpload(filesToUpload);
    let index = 0;
    files.forEach((file) => {
      if (file && file === filesToUpload[index]) {
        file.url = urls[index];
        index++;
      }
    });
    const result = files.map(file => formatAttachment(file, 'image'));
    return files.map(file => formatAttachment(file, 'image'));
  } catch (e) {
    console.error(e);
    throw new Error('Error uploading images');
  }
}

const uploadVideo = async (file) => {
  try {
    // If it's not a file, already uploaded, so just return;
    if (!isFile(file)) return file;

    // set thumb to the video thumbnail as a file. if there isn't one, create one
    if (!file.thumbnail) {
      file.thumbnail = await $utils.generateVideoThumbnail(file);
    }

    let thumb = file.thumbnail;

    const urls = await $aws.bulkUpload([file, thumb]);
    file.url = urls[0];
    const video = formatAttachment(file, 'video');
    video.thumbUrl = urls[1];
    return video;
  } catch(e) {
    throw new Error('Error uploading video');
  }
}

const uploadDocument = async (file) => {
  try {
    if (!isFile(file)) return file;
    file.url = await $aws.uploadFile(file);
    return formatAttachment(file, 'document');
  } catch (e) {
    console.error(e);
    throw new Error('Error uploading document');
  }
}

export default {
  uploadImages,
  uploadVideo,
  uploadDocument,
};
