var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meeting-member"},[(_vm.member.online)?_c('div',{staticClass:"ch-user-online"}):_vm._e(),_c('app-avatar',{staticClass:"meeting-member-avatar",class:{
      speaker: _vm.member.role === 'SPEAKER',
      moderator: _vm.member.role === 'MODERATOR',
      presenter:
        _vm.member.role === 'ADMIN' ||
        _vm.member.role === 'PRESENTER' ||
        _vm.member.role === 'PRESENTER_QUEUE',
      leader: ['ADMIN', 'MODERATOR', 'PRESENTER', 'PRESENTER_QUEUE'].includes(
        _vm.member.role
      ),
    },attrs:{"name":_vm.nameToShow,"picture":_vm.member.user.profilePhotoURL,"size":"medium"},on:{"click":_vm.showRelvantModal}}),_c('div',{staticClass:"meeting-member-info"},[_c('div',{staticClass:"meeting-member-username"},[_vm._v(" "+_vm._s(_vm.nameToShow)+" ")]),(_vm.showRole)?_c('div',{staticClass:"meeting-member__role"},[_vm._v(" "+_vm._s(_vm.leaderRole)+" ")]):_vm._e()]),_c('app-meeting-roles-menu',{attrs:{"show-roles":_vm.showRoles,"member":_vm.member,"meeting":_vm.meeting},on:{"close-meeting-roles":_vm.closeRolesMenu,"show-profile":_vm.openProfileModal}}),(_vm.loggedIn)?_c('app-profile-preview-modal',{attrs:{"is-meeting":_vm.showUser,"meeting-user":_vm.meetingUser},on:{"change-status":_vm.changeStatus}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }