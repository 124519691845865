<template>
  <ion-item class="group-item">
    <ion-avatar
      slot="start"
      v-router-link="{ name: 'GroupDetail', params: group }"
      class="group-avatar"
    >
      <app-img hide-on-error :src="groupPicture" />
    </ion-avatar>

    <ion-label class="group-item-label">
      <h3
        v-router-link="{ name: 'GroupDetail', params: group }"
        class="group-name"
      >
        {{ group.name }}
      </h3>
      <p class="group-meta">
        {{ group.privacy === 'public' ? 'Public' : 'Private' }} Group •
        {{ group.memberCount }} {{ membersLabel }}
      </p>
    </ion-label>

    <app-group-item-config-menu
      v-if="showMenu"
      slot="end"
      class="group-config-menu"
      :group="group"
    />

    <template v-else>
      <template
        v-if="
          group.myMembership && group.myMembership.role !== 'pending-member'
        "
      >
        <img
          class="group-icon"
          src="@/assets/icons/check-circle.svg"
          alt="Group Member"
        />
        <cs-button
          v-router-link="{ name: 'GroupDetail', params: group }"
          class="group-button"
          size="small"
          fill="outline"
        >
          Go To
        </cs-button>
      </template>
      <app-group-join-button
        v-else
        class="group-join-button"
        short
        :group="group"
      />
    </template>
  </ion-item>
</template>

<script>
// General components
import AppImg from '@/components/general/Img.vue';
import AppGroupItemConfigMenu from '@/components/groups/GroupItemConfigMenu.vue';
import AppGroupJoinButton from '@/components/groups/GroupJoinButton.vue';

const defaultPictureUrl = require('@/assets/images/placeholders/group-picture.png');

export default {
  components: {
    AppImg,
    AppGroupItemConfigMenu,
    AppGroupJoinButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groupPicture() {
      return this.group.picture ? this.group.picture : defaultPictureUrl;
    },
    membersLabel() {
      if (this.group.memberCount === 1) return 'member';
      return 'members';
    },
  },
};
</script>

<style scoped>
.group-avatar {
  --border-radius: 6px;
  height: 56px;
  width: 56px;
  margin-inline-end: 10px;
  background-color: #d8d8d8;
}

.group-item {
  --inner-border-width: 0px;
  margin-bottom: 10px;
}

.group-item-label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.group-name {
  margin-bottom: 0;
  white-space: initial;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.group-meta {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #747576;
}

.group-config-menu {
  margin-inline-end: 6px;
}

.group-button,
.group-join-button >>> .cs-button {
  min-width: 68px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  border-color: #e2896e;
  --cs-button-text-color: #e2896e;
}

.group-icon {
  margin: 0 10px;
  width: 24px;
  height: 24px;
}
</style>
