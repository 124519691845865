<template>
  <ion-page :key="id" class="video-detail">
    <app-video-detail-navigation-bar v-if="post" :post="post" />
    <app-spinner v-if="!post"></app-spinner>
    <ion-content v-else
      ref="content" 
      :scroll-events="true"
      @ionScroll="handleScroll($event)"
      class="video-detail__body video-detail-page">
      <transition name="ch-action-sheet-fade">
        <app-video-detail-header v-if="isHeaderVisible" :post="post" />
      </transition>
      <app-video
        :playsinline="true"
        :src="post.video.url"
        :stream-id="post.video.streamId"
        :stream-status="post.video.streamStatus"
        :poster="post.video.thumbUrl"
        :autoplay="autoplay"
      />
      <app-video-detail-live-chat
        v-if="!isLive"
        class="video-detail__live-chat"
        :post="post"
        @refresh="refreshVideo"
      />
      <live-post-response
        v-if="isLive" 
        @refresh="refreshVideo" 
        :post="post">
      </live-post-response>
      <!--
      <app-video-detail-related v-else :post="post" @refresh="refreshVideo" />
      -->
    </ion-content>
    <!--
    <app-video-detail-live-input v-if="isLive && post" :post="post" />
    -->
  </ion-page>
</template>
<script>
import { injectStyles } from 'shadow-dom-inject-styles';
import $bus from '@/services/bus';
import $socket from '@/services/socket';
import $videosApi from '@/api/videos';
import AppVideo from '@/components/general/PublicVideo.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppVideoDetailTabs from '@/components/videos/detail/PublicVideoDetailTabs.vue';
import AppVideoDetailHeader from '@/components/videos/detail/PublicVideoDetailHeader.vue';
import AppVideoDetailRelated from '@/components/videos/detail/PublicVideoDetailRelated.vue';
import AppVideoDetailLiveChat from '@/components/videos/detail/PublicVideoDetailLiveChat.vue';
import AppVideoDetailLiveInput from '@/components/videos/detail/PublicVideoDetailLiveInput.vue';
import AppVideoDetailNavigationBar from '@/components/videos/detail/PublicVideoDetailNavigationBar.vue';
import GetVideo from '@/gql/videos/PublicGetVideo.gql';
import LivePostResponse from '@/components/post/response/LivePostResponse.vue';

export default {
  components: {
    AppVideo,
    AppSpinner,
    AppPullToRefresh,
    AppVideoDetailTabs,
    AppVideoDetailHeader,
    AppVideoDetailRelated,
    AppVideoDetailLiveChat,
    AppVideoDetailLiveInput,
    AppVideoDetailNavigationBar,
    LivePostResponse
  },
  data() {
    return {
      id: this.$route.params.id,
      isHeaderVisible: true,
    };
  },
  apollo: {
    post: {
      query: GetVideo,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result() {
        this.markVideoViewed();
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    isLive() {
      return this.post && this.post.video.isLive;
    },
    autoplay() {
      return true;
    },
  },
  watch: {
    isLive(available) {
      if (!available) return;
      this.initChat();
      this.setupMessagesScrollListeners();
    },
  },
  mounted() {
    this.injectShadowDomStyles();
  },
  methods: {
    injectShadowDomStyles() {
      setTimeout(() => {
        const wrapper = document.querySelector('.video-detail__body');

        const styles = `
        .overscroll {
            display: flex;
            flex-direction: column;
        }
    `;

        injectStyles(wrapper, '.overscroll', styles);
      }, 1500);
    },
    async initChat() {
      if (!this.isLive) return;
      await $socket.connect();
      await $socket.joinRoom('post', this.post.id);
    },
    setupMessagesScrollListeners() {
      $bus.$on('video-detail-live-messages-scroll-up', () => {
        this.isHeaderVisible = false;
      });
      $bus.$on('video-detail-live-messages-scrolled-bottom', () => {
        this.isHeaderVisible = true;
      });
    },
    markVideoViewed() {
      $videosApi.markVideoViewed(this.post);
      this.$apollo.queries.post.skip = true;
    },
    async refresh($event) {
      this.$apollo.queries.post.skip = false;
      await this.$apollo.queries.post.refetch();
      $event.target.complete();
    },
    async refreshVideo() {
      this.$apollo.queries.post.skip = false;
      await this.$apollo.queries.post.refetch();
      this.$emit('refresh');
    },
    async handleScroll(event){
      const videoContainer = document.querySelector('.video-container');
      const videoPostitionOffset = videoContainer.offsetTop;
      if(event.detail.scrollTop >= videoPostitionOffset && !videoContainer.classList.contains('video-container-sticky')) {
        videoContainer.classList.add("video-container-sticky");
        videoContainer.style.position = "fixed";
      } else {
        if(event.detail.scrollTop <= videoPostitionOffset) {
          videoContainer.classList.remove("video-container-sticky");
          videoContainer.style.position = "unset";
        }
      }     
    }
  },
};
</script>
<style>
.video-detail__body {
  --background: #ffffff;
  --padding-bottom: 0;
}
.video-detail__body .ion-page {
  display: flex;
  flex-direction: column;
}
</style>
<style scoped>
:root {
  --cs-font-primary: 'Lato';
  --ion-background-color: #ffffff;
}
.video-detail__live-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
</style>
