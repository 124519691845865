<template>
  <ion-page>
    <ion-header>
      <app-main-header title="News" :search="{ name: 'NewsSearch' }" />
      <app-tabs v-model="selectedTab" :tabs="tabs" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <app-search-bar v-if="selectedTab === 1" v-model="query" />
      <app-news-search-results
        v-if="selectedTab === 1 && query.length > 0"
        :query="query"
      />
      <just-the-news v-if="selectedTab === 0" />
      <app-news-list
        v-if="selectedTab !== 1"
        ref="ListTab"
        :query="selectedView.query"
        :variables="selectedView.variables"
        :placeholder="selectedView.placeholder"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import AppNewsSearchResults from '@/components/news/NewsSearchResults.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppNewsList from '@/components/news/NewsList.vue';
import AppSearchBar from '@/components/general/SearchBar.vue';
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import ListNewsByCategory from '@/gql/news/ListNews.gql';
import ListNewsLatest from '@/gql/news/ListNewsLatest.gql';
import ListNewsBookmarked from '@/gql/news/ListNewsBookmarked.gql';
import ListNewsFollowing from '@/gql/news/ListNewsFollowing.gql';
import ListNewsHistory from '@/gql/news/ListNewsHistory.gql';
import ListNewsQueued from '@/gql/news/ListNewsQueued.gql';

export default {
  components: {
    AppMainHeader,
    AppPullToRefresh,
    AppTabs,
    AppNewsList,
    AppSearchBar,
    AppNewsSearchResults,
  },
  data() {
    return {
      selectedTab: (localStorage.newsSelectedTab || 0) * 1,
      query: '',
      tabs: [
        {
          label: 'Latest',
          key: 'latest',
          query: ListNewsLatest,
        },
        {
          label: 'Find',
          key: 'find',
        },
        {
          label: 'Following',
          key: 'following',
          query: ListNewsFollowing,
          placeholder: `You haven't followed any news publishers yet.`,
        },
        {
          label: 'Politics',
          key: 'US',
          query: ListNewsByCategory,
          variables: {
            category: 'US',
          },
        },
        {
          label: 'Entertainment',
          key: 'Entertainment',
          query: ListNewsByCategory,
          variables: {
            category: 'Entertainment',
          },
        },
        {
          label: 'Technology',
          key: 'Technology',
          query: ListNewsByCategory,
          variables: {
            category: 'Technology',
          },
        },
        {
          label: 'Fashion',
          key: 'Fashion',
          query: ListNewsByCategory,
          variables: {
            category: 'Fashion',
          },
        },
        {
          label: 'Family',
          key: 'Parents',
          query: ListNewsByCategory,
          variables: {
            category: 'Parents',
          },
        },
        {
          label: 'Health',
          key: 'Health',
          query: ListNewsByCategory,
          variables: {
            category: 'Health',
          },
        },
        {
          label: 'Sports',
          key: 'Sports',
          query: ListNewsByCategory,
          variables: {
            category: 'Sports',
          },
        },
        {
          label: 'Business',
          key: 'Business',
          query: ListNewsByCategory,
          variables: {
            category: 'Business',
          },
        },
        {
          label: 'Finance',
          key: 'Finance',
          query: ListNewsByCategory,
          variables: {
            category: 'Finance',
          },
        },
        {
          label: 'World',
          key: 'Global',
          query: ListNewsByCategory,
          variables: {
            category: 'Global',
          },
        },
        {
          label: 'Bookmarked',
          key: 'bookmarked',
          query: ListNewsBookmarked,
          placeholder: `You haven't bookmarked any articles yet.`,
        },
        {
          label: 'To Read',
          key: 'queue',
          query: ListNewsQueued,
          placeholder: `You haven't queued any articles yet.`,
        },
        {
          label: 'History',
          key: 'history',
          query: ListNewsHistory,
          placeholder: `You haven't read any articles yet.`,
        },
      ],
    };
  },
  computed: {
    selectedView() {
      return this.tabs[this.selectedTab];
    },
  },
  watch: {
    selectedTab() {
      localStorage.newsSelectedTab = this.selectedTab;
    },
  },
  methods: {
    async refresh($event) {
      if (this.$refs.ListTab) {
        await this.$refs.ListTab.refresh();
      }
      $event.target.complete();
    },
  },
};
</script>
