function openDefault(url) {
  if (window.cordova) {
    return window.cordova.InAppBrowser.open(
      url,
      '_blank',
      'location=yes&toolbarposition=top&toolbartranslucent=no&toolbarcolor=#F38367'
    );
  } else {
    return window.open(url, '_system');
  }
}

function open(url) {
  let ref;
  if (window.cordova && window.cordova.plugins && window.cordova.plugins.browsertab) {
    window.cordova.plugins.browsertab.isAvailable(
      (isAvailable) => {
        if (isAvailable) {
          ref = window.cordova.plugins.browsertab.openUrl(url);
        } else {
          ref = openDefault(url);
        }
      },
      () => {
        ref = openDefault(url);
      }
    );
  } else {
    ref = openDefault(url);
  }
  return ref;
}

window.openUrl = function openUrl(url) {
  open(url);
};

export default {
  open,
};
