<template>
  <div class="meeting-breakout-tab">
    <div v-if="meetings && meetings.length">
      <div
        v-for="meeting in meetings"
        :key="meeting.id"
        v-router-link="{ name: 'MeetingDetail', params: meeting }"
        class="test"
      >
        <app-meeting-breakouts :meeting="meeting" />
      </div>
    </div>
    <div v-if="meetings && !meetings.length">
      <cs-empty-state>
        <div slot="title" class="cs-textstyle-item-heading">
          No Breakouts Created
        </div>
      </cs-empty-state>
    </div>
  </div>
</template>

<script>
import $socket from '@/services/socket';
import AppMeetingBreakouts from '@/components/meetings/MeetingBreakouts.vue';
import ListBreakoutMeetings from '@/gql/meetings/ListBreakoutMeetings.gql';

export default {
  components: {
    AppMeetingBreakouts,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    meetings: {
      query: ListBreakoutMeetings,
      variables() {
        return {
          id: this.meeting.id,
        };
      },
    },
  },
  mounted() {
    console.log(this.meetings);
    $socket.onRoom(
      'meeting',
      this.meeting.id,
      'meeting-breakouts-modified',
      this.refresh
    );
  },

  methods: {
    refresh() {
      this.$apollo.queries.meetings.refetch();
    },
  },
};
</script>
<style scoped></style>
