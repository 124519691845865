<template>
  <span class="ch-action-sheet">
    <transition name="ch-action-sheet-fade">
      <div v-show="isOpen" class="ch-action-sheet__background" @click="close">
        <div class="ch-action-sheet__background-opacity"></div>
      </div>
    </transition>
    <transition name="ch-action-sheet-slide">
      <div v-show="isOpen" class="ch-action-sheet__content-wrapper">
        <div ref="actionsheet" class="ch-action-sheet__content">
          <div class="ch-action-sheet__content-slot">
            <slot />
          </div>
          <div
            class="ch-action-sheet__handle"
            @touchstart="touchstart"
            @touchend="touchend"
            @touchmove="touchmove"
          >
            <div class="ch-action-sheet__handle-bar"></div>
          </div>
        </div>
        <div class="ch-action-sheet__background-click" @click="close"></div>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$parent) {
      this.$parent.$once('hook:beforeDestroy', () => {
        this.$emit('close');
        this.$destroy();
        if (this.$el) this.$el.remove();
      });
    }
  },
  data() {
    return {
      isOpen: this.open,
      appendedToBody: false,
      touching: false,
      touchStartPos: null,
      touchEndPos: null,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    touchstart($event) {
      this.touching = true;
      this.touchStartPos = $event.touches[0];
    },
    touchend($event) {
      this.touchEndPos = $event.changedTouches[0];
      this.resetPosition();
    },
    touchmove($event) {
      if (this.touching === true);
      {
        const actionsheet = this.$refs.actionsheet;
        if (!actionsheet) return;
        let distance = -1 * (this.touchStartPos.clientY - $event.touches[0].clientY);
        if (distance < 0) distance = 0;
        actionsheet.style.transform = `translateY(${distance}px)`;
      }
    },
    resetPosition() {
      const touching = this.touching;
      this.touching = false;
      const actionsheet = this.$refs.actionsheet;
      if (!actionsheet || !touching) return;
      const distance = -1 * (this.touchStartPos.clientY - this.touchEndPos.clientY);
      if (distance > 100) {
        this.$emit('close');
        setTimeout(() => {
          if (actionsheet && actionsheet.style) actionsheet.style.transform = 'translateY(0)';
        }, 300);
      } else {
        actionsheet.style.transform = 'translateY(0)';
      }
    },
  },
  watch: {
    async open() {
      const tabsPage = document.querySelector('ion-tab-bar');
      if (tabsPage && this.$el && !this.appendedToBody) {
        tabsPage.parentElement.appendChild(this.$el);
        this.appendedToBody = true;
        await this.$nextTick();
      }
      this.isOpen = this.open;
    },
  },
};
</script>

<style>
.ch-action-sheet-fade-enter-active,
.ch-action-sheet-fade-leave-active {
  transition: all 0.3s;
}
.ch-action-sheet-fade-enter,
.ch-action-sheet-fade-leave-to {
  opacity: 0;
}
.ch-action-sheet-slide-enter-active,
.ch-action-sheet-slide-leave-active {
  transition: all 0.3s;
}
.ch-action-sheet-slide-enter,
.ch-action-sheet-slide-leave-to {
  transform: translateY(100%);
}

.ch-action-sheet__background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
}
.ch-action-sheet__background-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--cs-gray-06);
  opacity: 0.4;
}
.ch-action-sheet__content-wrapper {
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.ch-action-sheet__background-click {
  flex: 1;
}
.ch-action-sheet__content {
  position: relative;
  background-color: var(--cs-gray-00);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  max-height: 80%;
  bottom: 0;
}
.ch-action-sheet__content-slot {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
}

.ch-action-sheet__handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
}
.ch-action-sheet__handle-bar {
  height: 8px;
  width: 50px;
  border-radius: 4px;
  background-color: var(--cs-gray-02);
  border: solid 2px var(--cs-gray-00);
}
</style>
