<template>
  <app-infinite-loader
    :paused="query.loading"
    :finished="!mayHaveMore"
    class="ch-infinite-loader"
    @more="loadMore()"
  ></app-infinite-loader>
</template>

<script>
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
  components: {
    AppInfiniteLoader,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    queryKey: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      offset: 0,
      mayHaveMore: true,
    };
  },
  methods: {
    async loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const items = this.query.vm[this.query.key];
        if (items.length === 0) {
          this.mayHaveMore = false;
          return;
        }
        this.offset += this.limit;
        const vars = {
          offset: this.offset,
        };
        const key = this.queryKey || this.query.key;
        const { data } = await this.query.fetchMore({
          variables: vars,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const list = previousResult[key].slice();
            const incoming = fetchMoreResult[key];
            incoming.forEach((item) => {
              const id = item.id;
              if (!list.find((entry) => entry.id === id)) {
                list.push(item);
              }
            });
            return { [key]: list };
          },
        });
        this.$nextTick(() => {
          this.mayHaveMore = !!data[key]?.length;
        });
      }
    },
  },
};
</script>

<style scoped>
.ch-infinite-loader {
  min-height: 200px;
}
</style>
