<template>
  <app-page-menu title="Explore News">
    <div class="cs-textstyle-detail-heading">Explore your news</div>
    <app-page-menu-category title="Explore">
      <app-page-menu-category-item
        :to="{ name: 'NewsList', params: { view: 'latest' } }"
        label="Latest News"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'global' } }"
        label="Global"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'us' } }"
        label="US"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'business' } }"
        label="Business"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'technology' } }"
        label="Technology"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'home' } }"
        label="Home"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsCategory', params: { category: 'finance' } }"
        label="Finance"
      />
    </app-page-menu-category>
    <app-page-menu-category title="My News">
      <app-page-menu-category-item
        :to="{ name: 'NewsList', params: { view: 'following' } }"
        label="Following"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsList', params: { view: 'bookmarked' } }"
        label="Bookmarked"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsList', params: { view: 'queue' } }"
        label="Read Later"
      />
      <app-page-menu-category-item
        :to="{ name: 'NewsList', params: { view: 'history' } }"
        label="Recently Read"
      />
    </app-page-menu-category>
    <app-page-menu-category title="Sources">
      <app-page-menu-category-item :to="{ name: 'NewsPublisherRecommended' }" label="Recommended" />
    </app-page-menu-category>
  </app-page-menu>
</template>

<script>
import AppPageMenu from '@/components/general/PageMenu.vue';
import AppPageMenuCategory from '@/components/general/PageMenuCategory.vue';
import AppPageMenuCategoryItem from '@/components/general/PageMenuCategoryItem.vue';

export default {
  components: {
    AppPageMenu,
    AppPageMenuCategory,
    AppPageMenuCategoryItem,
  },
};
</script>
