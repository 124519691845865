<template>
  <div v-if="site && user" class="news-item">
    <img
      v-router-link="{ name: 'NewsDetail', params: post }"
      :src="picture"
      class="news-item-picture"
    />
    <div class="news-item-body">
      <div
        v-router-link="{ name: 'NewsDetail', params: post }"
        class="cs-textstyle-paragraph-small-bold news-item-title"
      >
        {{ post.linkPreview.title }}
      </div>
      <div class="news-item-footer">
        <app-avatar
          v-router-link="{ name: 'NewsDetail', params: post }"
          :name="user.displayname"
          :picture="user.profilePhotoURL"
          size="small"
        ></app-avatar>
        <div class="news-item-meta">
          <div
            v-router-link="{ name: 'NewsDetail', params: post }"
            class="cs-textstyle-paragraph-extra-small-bold news-item-username"
          >
            {{ user.displayname }}
          </div>
          <div class="cs-textstyle-paragraph-extra-small">
            {{ post.viewCount || 0 }}
            {{ post.viewCount === 1 ? 'view' : 'views' }} ·
            {{ post.createdAt | moment('from') }}
          </div>
        </div>
        <i class="cs-icons-share2" @click="shareNews"></i>
        <app-bookmark-button :post="post" />
        <app-queue-button :post="post" />
      </div>
    </div>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppAvatar from '@/components/general/Avatar.vue';
const defaultPicUrl = require('@/assets/images/placeholders/group-picture.png');

export default {
  components: {
    AppBookmarkButton,
    AppQueueButton,
    AppAvatar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    site() {
      try {
        return (
          this.post &&
          this.post.linkPreview &&
          this.post.linkPreview.url &&
          new URL(this.post.linkPreview.url).hostname
        );
      } catch (e) {
        return null;
      }
    },
    picture() {
      if (this.post.linkPreview.image) {
        return this.post.linkPreview.image;
      }
      return defaultPicUrl;
    },
    user() {
      // Search results currently return postedByUser;
      return this.post.user || this.post.postedByUser;
    },
  },
  methods: {
    shareNews() {
      const link = `/p/${this.post.slug || this.post.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        title: 'Share Article',
        post: this.post,
      });
    },
  },
};
</script>

<style scoped>
.news-item {
  display: flex;
  flex-direction: row;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 5px;
}
.news-item-picture {
  width: 150px;
  object-fit: cover;
}
.news-item-body {
  padding: 10px;
  flex: 1;
}
.news-item-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.news-item-footer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  border-top: solid 1px var(--cs-gray-01);
  padding-top: 10px;
  align-items: center;
  gap: 4px;
}
.news-item-username {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}
.news-item-meta {
  flex: 1;
}
.ch-avatar--small {
  --ch-avatar-size: 30px !important;
  --ch-avatar-font-size: 12px;
}
</style>
