<template>
  <div class="meeting-header-details">
    <div class="meeting-header-details__header">
      <app-img class="meeting-header-details__logo" :src="meeting.picture" />
      <div class="meeting-header-details__title">{{ meeting.name }}</div>
    </div>
    <div class="meeting-header-details__subheader">
      <div class="meeting-header-details__time">{{ startTime }}</div>
      <div class="meeting-header-details__host">
        Hosted by {{ meeting.host.firstname }}
      </div>
    </div>
    <div class="meeting-header-details__description">{{ description }}</div>
  </div>
</template>
<script>
import AppImg from '@/components/general/Img.vue';

export default {
  components: {
    AppImg,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description() {
      return this.meeting.description
        ? this.meeting.description
        : 'No description';
    },
    startTime() {
      const currentUserTimezone = this.$moment.tz.guess();
      return (
        this.meeting &&
        this.$moment
          .utc(this.meeting.startTime)
          .local()
          .tz(currentUserTimezone)
          .format('h:mm A z [|] MMM D, yyyy')
      );
    },
  },
};
</script>
<style scoped>
.meeting-header-details {
  margin-top: 10px;
}
.meeting-header-details__header {
  display: flex;
  align-items: center;
  gap: 15px;
}
.meeting-header-details__logo {
  max-width: 60px;
}
.meeting-header-details__title {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}
.meeting-header-details__subheader {
  display: flex;
  gap: 20px;
  margin-top: 7px;
  font-size: 14px;
}
.meeting-header-details__host {
  font-weight: bold;
}
.meeting-header-details__description {
  margin-top: 7px;
  font-size: 16px;
}
</style>