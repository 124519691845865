<template>
  <div class="ch-video-segment">
    <app-video-list :videos="displayVideos" direction="column"></app-video-list>
    <app-infinite-simple
      :query="$apollo.queries.videos"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>

<script>
import AppVideoList from '@/components/videos/new/PublicVideoListNew.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';

export default {
  components: {
    AppVideoList,
    AppInfiniteSimple,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    videos: {
      query() {
        return this.query;
      },
    },
  },
  computed: {
    displayVideos() {
      if (!this.videos) return null;
      return this.videos && this.videos.filter((video) => video.safe);
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.videos.refetch();
      return true;
    },
  },
};
</script>

<style scoped>
.ch-video-segment {
  margin: 15px 0;
}
.ch-video-segment__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ch-video-segment__header--title {
  padding: 0 15px;
}
.ch-video-segment__header--btn {
  --cs-button-padding: 0px 15px;
}
.ch-icons {
  margin-left: 5px;
}
.ch-video-segment >>> .ch-video-list__main--wrapper:first-child {
  padding-left: 15px;
}
</style>
