<template>
  <ion-page>
    <ion-content>
      <div class="ch-create-account">
        <div class="ch-create-account__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <app-register-subheader
          title="Create your Account"
          description="Enter your information below to create your account."
        />
        <app-register-form>
          <cs-input
            v-model="firstName"
            label="First Name"
            type="text"
            placeholder="Enter First Name"
          />
          <cs-input
            v-model="lastName"
            label="Last Name"
            type="text"
            placeholder="Enter Last Name"
          />
          <cs-checkbox
            v-model="confirmTerms"
            class="ch-create-account__checkbox"
          >
            I agree to the
            <a
              :href="termsOfUseUrl"
              target="_system"
              class="ch-create-account__terms-of-use"
              @click.stop
              >Terms of Use</a
            >
            &amp;
            <a
              :href="privacyPolicyUrl"
              target="_system"
              class="ch-create-account__terms-of-use"
              @click.stop
              >Privacy Policy<span class="dot">.</span></a
            >
          </cs-checkbox>
          <cs-checkbox v-model="confirmAge" class="ch-create-account__checkbox"
            >I confirm I am 16 years or older to join CloutHub.
            <p class="ch-create-account__confirm-age">
              To join CloutHub you must be over 16 years of age.
            </p>
          </cs-checkbox>
          <cs-spinner v-if="loading" size="medium" />
          <div v-if="!loading">
            <cs-button
              block
              corners="round"
              size="large"
              :disabled="missingFields"
              @click="continueToEmailForm"
            >
              Continue
            </cs-button>
          </div>
          <cs-button
            v-if="!isMeeting"
            block
            fill="clear"
            size="large"
            to="/login"
            >Back to Login</cs-button
          >
        </app-register-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppRegisterForm from '@/components/register/Form.vue';
import AppRegisterSubheader from '@/components/register/Subheader.vue';
import { policyPagesUrls } from '@/constants';

export default {
  components: {
    AppRegisterSubheader,
    AppRegisterForm,
  },
  props: {
    draftUser: {
      type: Object,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastName: '',
      firstName: '',
      confirmTerms: false,
      confirmAge: false,
      loading: false,
      ...policyPagesUrls
    };
  },
  computed: {
    missingFields() {
      if (!this.firstName || !this.firstName.trim()) return true;
      if (!this.lastName || !this.lastName.trim()) return true;
      if (!this.confirmTerms || !this.confirmAge) return true;
      return false;
    },
  },
  created() {
    this.firstName = this.draftUser.firstname ? this.draftUser.firstname : '';
    this.lastName = this.draftUser.lastname ? this.draftUser.lastname : '';
  },
  methods: {
    async continueToEmailForm() {
      this.loading = true;
      this.$emit('next', {
        firstname: this.firstName,
        lastname: this.lastName,
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.ch-create-account {
  padding: 20px;
  background: var(--cs-gray-01);
}
.dot {
  color: var(--cs-gray-05);
}
.ch-create-account__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-create-account__checkbox {
  padding: 0;
}
a {
  color: var(--cs-primary-base);
  text-decoration: none;
}
.ch-create-account__confirm-age {
  margin: 0;
  font-size: 13px;
}
.cs-spinner {
  min-height: 45px;
}
.cs-spinner >>> .cs-spinner__dots {
  margin: 0px auto !important;
}
</style>
