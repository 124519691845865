<template>
  <div>
    <div class="search-header">News Publishers</div>
    <div v-if="users">
      <cs-empty-state
        v-if="showEmptyStatePublisher"
        title="No matching publisher"
      />
      <div v-else>
        <app-news-publisher-item
          v-for="user in users"
          :key="user.id"
          class="publisher-result"
          :user="user"
          item-class="col-md-12"
        />
        <cs-button
          v-if="mayHaveMorePublisher && !$apollo.queries.users.loading"
          block
          fill="outline"
          size="small"
          @click="loadMorePublisher"
        >
          More Publisher
        </cs-button>
      </div>
    </div>
    <app-spinner v-if="$apollo.queries.users.loading" />

    <div class="search-header">News</div>
    <div v-if="posts">
      <cs-empty-state
        v-if="showEmptyState"
        title="No matching articles"
        class="cs-textstyle-detail-heading"
      />
      <div v-else>
        <app-news-item
          v-for="post in posts"
          :key="post.id"
          :post="post"
        ></app-news-item>
        <cs-button
          v-if="mayHaveMore && !$apollo.queries.posts.loading"
          block
          fill="outline"
          size="small"
          @click="loadMore"
        >
          More News
        </cs-button>
      </div>
    </div>
    <app-spinner v-if="$apollo.queries.posts.loading" />
  </div>
</template>

<script>
import AppNewsItem from '@/components/news/NewsItem.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppNewsPublisherItem from '@/components/news/NewsPublisherItem.vue';

import NewsSearch from '@/gql/search/NewsArticleSearch.gql';
import NewsPublishersSearch from '@/gql/search/NewsPublishersSearch.gql';

export default {
  components: {
    AppNewsItem,
    AppSpinner,
    AppNewsPublisherItem
  },
  props: {
    search: String,
  },
  apollo: {
    posts: {
      query: NewsSearch,
      variables() {
        return {
          query: this.search,
          type: 'news',
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
    users: {
      query: NewsPublishersSearch,
      variables() {
        return {
          searchType: 'KEYWORD',
          searchString: this.search,
          badgeType: 'news',
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      mayHaveMore: true,
      mayHaveMorePublisher: true
    };
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.posts && !this.posts.length) return true;
      return false;
    },
    showEmptyStatePublisher() {
      if (this.users && !this.users.length) return true;
      return false;
    },
  },
  methods: {
    loadMorePublisher() {
      const offset = this.users.length;
      this.$apollo.queries.users.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMorePublisher = !!fetchMoreResult.users.length;
          return {
            users: [...previousResult.users, ...fetchMoreResult.users],
          };
        },
      });
    },
    loadMore() {
      const offset = this.posts.length;
      this.$apollo.queries.posts.fetchMore({
        variables: {
          offset,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.mayHaveMore = !!fetchMoreResult.posts.length;
          return {
            posts: [...previousResult.posts, ...fetchMoreResult.posts],
          };
        },
      });
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
