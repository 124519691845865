<template lang="html">
  <div class="sidebar-card">
    <div v-if="subgroups && subgroups.length !== 0" class="card-content">
      <div class="card-content__bar">
        <cs-search-bar
          v-model="searchQuery"
          icon="ch-icons-search"
          placeholder="Search for Subgroups"
          class="group-subgroups-card__search-bar"
        ></cs-search-bar>
        <cs-button
          v-if="canCreateSubgroups"
          fill="outline"
          size="small"
          class="create-subgroup-button"
          @click="showSubgroupModal = true"
          >Create Subgroup</cs-button
        >
      </div>
      <app-subgroup-list v-if="groupsDisplayed" :groups="groupsDisplayed" />
      <div v-if="groupsDisplayed && groupsDisplayed.length === 0">
        <cs-empty-state description="No matching subgroups!" />
      </div>
    </div>
    <div
      v-if="subgroups && subgroups.length === 0"
      class="empty-state-no-subgroups"
    >
      <cs-empty-state description="This group doesn't have a subgroup">
        <div slot="button">
          <cs-button
            v-if="canCreateSubgroups"
            fill="outline"
            size="small"
            @click="showSubgroupModal = true"
            >Create Subgroup</cs-button
          >
        </div>
      </cs-empty-state>
    </div>
    <app-create-subgroup-modal
      title="Create Subgroup"
      :show="showSubgroupModal"
      :parent-group="group"
      :siblings="subgroups"
      @close="showSubgroupModal = false"
    />
  </div>
</template>

<script>
// Group subgroup components
import AppSubgroupList from '@/components/groups/subgroup/GroupSubgroupList.vue';
import AppCreateSubgroupModal from '@/components/groups/subgroup/CreateSubgroupModal.vue';

export default {
  components: {
    AppSubgroupList,
    AppCreateSubgroupModal,
  },
  props: {
    canCreateSubgroups: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      required: true,
    },
    subgroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchQuery: '',
      showSubgroupModal: false,
    };
  },
  computed: {
    groupsDisplayed() {
      return (
        this.subgroups &&
        this.subgroups
          .filter((subgroup) =>
            subgroup.name
              .toLowerCase()
              .trim()
              .includes(this.searchQuery.toLowerCase().trim())
          )
          .slice()
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          )
      );
    },
  },
};
</script>

<style lang="css" scoped>
.group-subgroups-card__search-bar {
  width: 90%;
  margin-top: 20px;
  margin-left: 20px;
}
.card-content__bar {
  display: flex;
  align-items: center;
}
.create-subgroup-button {
  padding: 0px;
  height: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  width: 200px;
}
.empty-state-no-subgroups {
  margin-top: 30px;
}
</style>
