<template>
  <ion-page>
    <ion-header>
      <!-- Message Header -->
      <div v-if="conversation" class="ch-conversation__header">
        <app-message-header
          :user="conversation.otherParty"
          @deleteConversation="deleteConversation"
        />
      </div>
    </ion-header>
    <!-- Message Body -->
    <ion-content class="message-content">
      <div class="ch-conversation__detail">
        <app-conversation-message
          v-for="(message, index) in messages"
          :key="message.id + index"
          :message="message"
          :prev-message="messages[index + 1]"
          :next-message="messages[index - 1]"
        />
        <!-- For Loading Older Messages -->
        <app-infinite-simple
          :query="$apollo.queries.messages"
          sort-key="msgCreateTime"
        />
        <br />
      </div>
    </ion-content>
    <ion-footer class="ion-no-border ch-conversation__footer">
      <app-conversation-composer
        v-if="conversation"
        :receiver="conversation.otherParty"
        :conversation-id="conversation.conversationId"
      />
    </ion-footer>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';
// Components
import AppMessageHeader from '@/components/conversations/MessageHeader.vue';
import AppConversationComposer from '@/components/conversations/ConversationComposer.vue';
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppConversationMessage from '@/components/conversations/ConversationMessage.vue';
// GQL
import GetConversation from '@/gql/conversations/GetConversation.gql';
import ListConversationMessages from '@/gql/conversations/ListConversationMessages.gql';
import MarkConversationAsRead from '@/gql/conversations/MarkConversationAsRead.gql';
import DeleteConversation from '@/gql/conversations/DeleteConversation.gql';
import ConversationReadFragment from '@/gql/fragments/ConversationRead.gql';

export default {
  components: {
    AppMessageHeader,
    AppConversationMessage,
    AppInfiniteSimple,
    AppConversationComposer,
  },
  data() {
    return {
      id: `${$auth.getUserId()}@${this.$route.params.id}`,
      userId: $auth.getUserId(),
    };
  },
  apollo: {
    conversation: {
      query: GetConversation,
      variables() {
        return {
          participantId: this.id,
        };
      },
    },
    messages: {
      query: ListConversationMessages,
      variables() {
        return {
          conversationId: this.$route.params.id,
        };
      },
    },
  },
  mounted() {
    this.$apollo.queries.messages.refetch();
    this.callMarkConversationRead();
  },
  methods: {
    async callMarkConversationRead() {
      const participantId = this.id;
      await this.$apollo.mutate({
        mutation: MarkConversationAsRead,
        variables: {
          id: participantId,
        },
        update: (cache, { data: { markedConversation } }) => {
          cache.writeFragment({
            id: cache.identify(markedConversation),
            fragment: ConversationReadFragment,
            data: {
              unreadCount: markedConversation.unreadCount,
            },
          });
        },
      });
    },
    // Delete Conversation
    async deleteConversation() {
      await this.$apollo.mutate({
        mutation: DeleteConversation,
        variables: {
          conversationId: this.conversation.conversationId,
        },
        update: (_cache, { data: { deletedConversation } }) => {
          if (deletedConversation) {
            this.$router.back();
          }
        },
      });
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--cs-gray-00);
}
.ch-conversation__detail {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column-reverse;
  overflow: auto;
  overflow-x: hidden;
  padding: 20px;
  background-color: var(--cs-gray-00);
}
.ch-conversation__footer {
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.05);
}
</style>
