<template>
  <ion-page class="meeting-search">
    <ion-header>
      <app-category-header title="Search Events" />
      <div class="meeting-search__input-header">
        <cs-search-bar placeholder="Search..." v-model="search" />
      </div>
    </ion-header>
    <ion-content>
      <app-spinner v-if="$apollo.queries.meetings.loading" />
      <app-meeting-list
        v-if="meetings"
        :meetings="meetings"
        direction="column"
      ></app-meeting-list>
      <!-- <cs-empty-state
        v-if="showEmptyState"
        :title="emptyStateTitle"
        :description="emptyStateDescription"
      /> -->
    </ion-content>
  </ion-page>
</template>

<script>
import MeetingSearch from '@/gql/search/MeetingSearch.gql';
import hideKeyboard from '@/mixins/hideKeyboard.mixin';

import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppSpinner from '@/components/general/Spinner.vue';
import AppMeetingList from '@/components/meetings/MeetingList.vue';

export default {
  components: {
    AppCategoryHeader,
    AppSpinner,
    AppMeetingList,
  },
  mixins: [hideKeyboard],
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: this.$route.query ? this.$route.query.q : this.query,
    };
  },
  apollo: {
    meetings: {
      query: MeetingSearch,
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  computed: {
    noQuery() {
      return this.search.trim().length === 0;
    },
    showEmptyState() {
      if (this.meetings && this.meetings.length === 0) return true;
      if (this.noQuery) return true;
      return false;
    },
    emptyStateTitle() {
      return this.noQuery ? 'Find a Event' : 'No Results';
    },
    emptyStateDescription() {
      return this.noQuery
        ? 'Try entering a term in the search box'
        : 'Try searching for something else';
    },
  },
};
</script>

<style scoped>
.meeting-search__input-header {
  padding: 10px;
  background-color: var(--cs-gray-01);
}
.meeting-search__input-header >>> .cs-search-bar {
  background-color: var(--cs-gray-00);
}
.meeting-search__input-header >>> .cs-search-bar__input {
  background-color: var(--cs-gray-00);
}
</style>
