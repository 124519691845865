import $router, { unprotectedRoutes } from '@/router';
import moment from 'moment-timezone';
import jsonwebtoken from 'jsonwebtoken';

import GetProfile from '@/gql/general/GetProfile.gql';
import GetUserSetting from '@/gql/auth/GetUserSetting.gql';
import InsertUserSession from '@/gql/auth/InsertUserSession.gql';
import RefreshToken from '@/gql/auth/RefreshToken.gql';
import $amplitude from './amplitude';
import $apollo from './apollo';

let user = null;
let userSettings = null;

const getUserId = () => {
  if (localStorage.access_token) {
    try {
      const payload = jsonwebtoken.decode(localStorage.access_token);
      const expires = payload.exp * 1000;
      if (Date.now() < expires) {
        return payload.id;
      }
      logout();
    } catch (e) {
      logout();
    }
  }

  return null;
};

const registerUserSession = async () => {
  let appVersion = 'MOBILE-WEB';
  if (window.cordova && window.cordova.getAppVersion) {
    appVersion = await window.cordova.getAppVersion.getVersionNumber();
  }
  await $apollo.mutate({
    mutation: InsertUserSession,
    variables: {
      appVersion,
      timeZone: moment.tz.guess(),
    },
  });
};
const refreshToken = async () => {
  const {
    data: { token },
  } = await $apollo.query({
    query: RefreshToken,
  });
  localStorage.access_token = token;
};

if (localStorage.access_token) {
  registerUserSession();
  refreshToken();
}

const getUser = async () => {
  const userId = getUserId();
  if (user) return user;
  if (userId) {
    const { data } = await $apollo.query({
      query: GetProfile,
      variables: {
        id: userId,
      },
    });
    user = data.user;
    $amplitude.initUser({ ...user });
    return data.user;
  }
  return user;
};

const getUserSettings = async () => {
  const userId = getUserId();
  if (userSettings) return userSettings;
  if (userId) {
    const { data } = await $apollo.query({
      query: GetUserSetting,
      variables: {
        id: userId,
      },
    });
    userSettings = data.get_user_settings;
    return userSettings;
  }
  return userSettings;
};

function isLoggedIn() {
  return !!getUserId();
}

const logout = async () => {
  const currentRoute = $router && $router.currentRoute;
  if (!currentRoute) return;
  if (currentRoute && unprotectedRoutes.indexOf(currentRoute.name) > -1) return;
  try {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    window.location.href = '/';
  } catch (e) {
    console.log('Error logging out');
  }
};

const saveToken = (token) => {
  console.log('Save Token', token);
};

const isAdmin = () => {
  const authorizedAdmins = [
    '3954ee24-5dd1-4b0e-b29a-42bdd750fbc8', // @clouthub
    'ebcdf8b5-ede5-4737-b6c9-f9a857fee5d3', // @jeff
    'be03853c-6190-4c6f-bdf5-912253d9b90d', // @janakan
    '1015d97f-4078-4cde-80d6-9d14c62a4aef', // @ukris
    '55d1a52e-e665-47e7-a23a-b038cb779d04', // @sonia
  ];
  return authorizedAdmins.includes(getUserId());
};

export default {
  isLoggedIn,
  getUser,
  logout,
  getUserId,
  isAdmin,
  saveToken,
  getUserSettings,
};
