<template>
  <ion-page>
    <ion-header>
      <app-main-header hide-notifications title="Channels" :create="true">
        <app-my-channel-btn />
      </app-main-header>
      <app-tabs v-model="tabIndex" :tabs="tabs" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />

      <!-- Latest section -->
      <div v-show="tabIndex === 0" class="video-tab-content">
        <app-new-video-segment ref="recentQuery" :query="recentQuery" />
      </div>

      <!-- Subscribed section -->
      <div v-show="tabIndex === 1" class="video-tab-content">
        <div class="row">
          <app-channel-segment ref="subscribedQuery" :query="subscribedQuery" />
        </div>
      </div>

      <!-- Upcoming section -->
      <div v-show="tabIndex === 2" class="video-tab-content">
        <UpcomingEvents />
      </div>

      <!-- Live section -->
      <div v-show="tabIndex === 3" class="video-tab-content__search">
        <div class="row">
          <app-new-video-segment ref="liveQuery" :query="liveQuery" />
        </div>
      </div>

      <!-- Channels section -->
      <div v-show="tabIndex === 4" class="video-tab-content">
        <app-explore-videos />
      </div>

      <!-- Search -->
      <div v-show="tabIndex === 5" class="video-tab-content__search">
        <div class="row">
          <app-search-bar v-model="query" />
          <app-video-search-results :query="query" />
        </div>
      </div>

      <!-- History section -->
      <div v-show="tabIndex === 6" class="video-tab-content">
        <app-video-list-mini
          ref="historyQuery"
          :query="historyQuery"
          is-history
        />
      </div>

      <!-- Bookmarked section -->
      <div v-show="tabIndex === 7" class="video-tab-content">
        <div class="row">
          <app-video-list-mini
            ref="bookmarksQuery"
            :query="bookmarksQuery"
            show-bookmark
          />
        </div>
      </div>

      <!-- To watch section -->
      <div v-show="tabIndex === 8" class="video-tab-content">
        <div class="row">
          <app-video-list-mini
            ref="queuedQuery"
            :query="queuedQuery"
            show-queue
          />
        </div>
      </div>


      <!-- Recommended section -->
      <!-- <div v-show="tabIndex === 4" class="video-tab-content">
        <div class="row">
          <app-channel-segment
            ref="recommendedChannels"
            :query="recommendedChannels"
            show-button
            @refresh-recommendation="refreshRecommendedChannels"
          />
        </div>
      </div> -->

    </ion-content>
  </ion-page>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import $auth from '@/services/auth';

// Component
import AppVideoSearchResults from '@/components/videos/new/VideoSearchResults.vue';
import AppSearchBar from '@/components/general/SearchBar.vue';
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppMyChannelBtn from '@/components/general/MyChannelBtn.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppChannelSegment from '@/components/videos/new/ChannelSegmentNew.vue';
import AppTabs from '@/components/general/Tabs.vue';
import AppNewVideoSegment from '@/components/videos/new/VideoSegmentNew.vue';
import AppExploreVideos from '@/components/videos/new/ExploreVideos.vue';
import AppVideoListMini from '@/components/videos/VideoListMini.vue';
import UpcomingEvents from '@/views/videos/UpcomingEvents.vue';

// GQL
import ListBookmarkedVideos from '@/gql/videos/ListBookmarkedVideos.gql';
import ListFollowingVideos from '@/gql/videos/ListFollowingVideos.gql';
import ListRecentVideos from '@/gql/videos/ListRecentVideos.gql';
import ListPopularVideos from '@/gql/videos/ListPopularVideos.gql';
import ListQueuedVideos from '@/gql/videos/ListQueuedVideos.gql';
import ListLiveVideos from '@/gql/videos/ListLiveVideos.gql';
import ListWatchedVideos from '@/gql/videos/ListWatchedVideos.gql';
import ListFeaturedChannels from '@/gql/videos/ListFeaturedChannels.gql';
import ListSubscribedChannels from '@/gql/videos/ListSubscribedChannels.gql';
import ListLiveChannels from '@/gql/videos/ListLiveChannels.gql';

export default {
  components: {
    AppVideoSearchResults,
    AppMainHeader,
    AppSearchBar,
    AppMyChannelBtn,
    AppPullToRefresh,
    AppChannelSegment,
    AppTabs,
    AppExploreVideos,
    AppNewVideoSegment,
    AppVideoListMini,
    UpcomingEvents,
  },
  data() {
    return {
      bookmarksQuery: ListBookmarkedVideos,
      followingQuery: ListFollowingVideos,
      recentQuery: ListRecentVideos,
      popularQuery: ListPopularVideos,
      queuedQuery: ListQueuedVideos,
      historyQuery: ListWatchedVideos,
      liveQuery: ListLiveVideos,
      subscribedQuery: ListSubscribedChannels,
      // recommendedChannels: ListFeaturedChannels,
      liveChannels: ListLiveChannels,
      id: $auth.getUserId(),
      tabIndex: this.$route.params.view ? Number(this.$route.params.view) : 0,
      query: '',
      tabs: [
        'Latest',
        'Subscribed',
        'Upcoming',
        'Live',
        'Channels',
        'Search',
        'History',
        'Bookmarked',
        'Watch Later',
      ],
    };
  },
  methods: {
    // goToRelevantView() {
    //   if (this.user && !this.user.channelName && !this.user.videoPosts.count) {
    //     this.$router.push({
    //       name: 'VideoProfile',
    //       params: { id: this.id },
    //     });
    //   } else {
    //     $bus.$emit('show-create-video-modal');
    //   }
    // },
    async refresh($event) {
      await Promise.all([
        this.$refs.recentQuery.refresh(),
        this.$refs.queuedQuery.refresh(),
        this.$refs.bookmarksQuery.refresh(),
        this.$refs.historyQuery.refresh(),
        this.$refs.liveQuery.refresh(),
        this.$refs.subscribedQuery.refresh(),
        // this.$refs.recommendedChannels.refresh(),
      ]);
      $event.target.complete();
    },
    // async refreshRecommendedChannels() {
    //   await this.$refs.recommendedChannels.refresh();
    // },
  },
};
</script>
<style scoped>
.video-tab-content {
  margin-top: 15px;
}
</style>
