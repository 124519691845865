<template>
  <div v-if="query">
    <cs-page-tabs
      v-model="selectedTab"
      :tabs="tabs"
      align="justify"
    ></cs-page-tabs>
    <div v-if="selectedTab == 0">
      <app-news-item
        v-for="post in posts"
        :key="post.id"
        :post="post"
      ></app-news-item>
      <cs-empty-state
        v-if="posts && posts.length === 0"
        description="There are currently no articles matching your search"
      />
    </div>
    <div v-if="selectedTab == 1">
      <app-news-publisher-item
        v-for="user in users"
        :key="user.id"
        :user="user"
      />
      <cs-empty-state
        v-if="users && users.length === 0"
        description="There are currently no publishers matching your search"
      />
    </div>
  </div>
</template>

<script>
import AppNewsItem from '@/components/news/NewsItem.vue';
import AppNewsPublisherItem from '@/components/news/NewsPublisherItem.vue';

import NewsArticleSearch from '@/gql/search/NewsArticleSearch.gql';
import NewsPublishersSearch from '@/gql/search/NewsPublishersSearch.gql';

export default {
  components: {
    AppNewsItem,
    AppNewsPublisherItem,
  },
  props: {
    query: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          label: 'Articles',
        },
        {
          label: 'Publishers',
        },
      ],
    };
  },
  apollo: {
    posts: {
      query: NewsArticleSearch,
      variables() {
        return {
          type: 'news',
          query: this.query,
        };
      },
    },
    users: {
      query: NewsPublishersSearch,
      variables() {
        return {
          searchType: 'KEYWORD',
          searchString: this.query,
          badgeType: 'news',
        };
      },
    },
  },
};
</script>

<style></style>
