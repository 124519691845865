<template>
  <div>
    <div class="meeting-chat-message">
      <app-avatar
        :name="message.postedByUser.displayname"
        :picture="message.postedByUser.profilePhotoURL"
      />
      <div class="meeting-chat-body">
        <div class="meeting-chat-body__header">
          <div class="cs-textstyle-paragraph-small-bold">
            {{ message.postedByUser.displayname }}
          </div>
          <div
            class="cs-textstyle-paragraph-small meeting-chat-body__header-text"
          >
            {{ message.createdAt | moment('h:mm A') }}
          </div>
        </div>
        <div class="cs-textstyle-paragraph-small">
          {{ message.bodyText }}
        </div>
      </div>
      <i
        v-if="canDelete"
        class="cs-icons-options"
        @click="deleteMessageOption"
      />
    </div>
    <!-- Delete Message -->
    <app-action-sheet :open="showDelete">
      <app-action-sheet-button @click="deleteMessage"
        >Delete Message</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AppAvatar from '@/components/general/Avatar.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import DeleteMeetingMessage from '@/gql/meetings/DeleteMeetingMessage.gql';
import ListMeetingMessages from '@/gql/meetings/ListMeetingMessages.gql';
import ListPostMessages from '@/gql/live/ListPostMessages.gql';
import DeletePostMessage from '@/gql/posts/DeletePostMessage.gql';
import $socket from '@/services/socket';

export default {
  components: {
    AppAvatar,
    AppActionSheet,
    AppActionSheetButton,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    meeting: Object,
    postId: {
      type: String,
      default: null,
    },
    isAuthor: {
      type: Boolean
    }
  },
  data() {
    return {
      showDelete: false,
    };
  },
  computed: {
    canDelete() {
      if (this.postId) {        
        return this.message.postedByUserId === $auth.getUserId() || this.isAuthor;
      } else {
      return (
          this.message.postedByUserId === $auth.getUserId() || this.meeting&&
          (this.meeting.myMembership &&
            (this.meeting.myMembership.role === 'ADMIN' ||
              this.meeting.myMembership.role === 'MODERATOR'))
        );
      }
    },
  },
  methods: {
    deleteMessageOption() {
      this.showDelete = true;
    },
    async deleteMessage() {
      if (this.postId) {
        await this.$apollo.mutate({
          mutation: DeletePostMessage,
          variables: {
            id: this.message.id,
          },
          refetchQueries: [
            {
              query: ListPostMessages,
              variables: {
                postId: this.postId,
              },
            },
          ],
        });
        $socket.sendRoom('post', this.postId, 'post-chat-modified');
      } else {
        await this.$apollo.mutate({
          mutation: DeleteMeetingMessage,
          variables: {
            id: this.message.id,
          },
          refetchQueries: [
            {
              query: ListMeetingMessages,
              variables: {
                meetingId: this.message.meetingId,
              },
            },
          ],
        });
      }
    },
  },
};
</script>
<style scoped>
.meeting-chat-message {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.meeting-chat-body {
  flex: 1;
  background: var(--cs-gray-01);
  padding: 5px;
  border-radius: 5px;
  width: 90%;
  word-wrap: break-word;
}
.meeting-chat-body__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.meeting-chat-body__header-text {
  font-size: 11px;
}
* >>> .ch-action-sheet__content {
  position: fixed;
  width: 100%;
  left: 0px;
}
* >>> .ch-action-sheet-fade-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-enter,
.ch-action-sheet-fade-leave-to {
  opacity: 0;
}
* >>> .ch-action-sheet-slide-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-enter {
  transform: none;
}
* >>> .ch-action-sheet-slide-leave-to {
  transform: none;
}
</style>
