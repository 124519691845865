<template>
  <div class="video-detail-related">
    <div class="video-detail-related__card">
      <app-video-response
        :post="post"
        @refresh="emitRefresh"
      ></app-video-response>
    </div>
    <div class="video-detail-related__more">More Related Videos</div>
    <app-video-item
      v-for="relatedVideo in relatedVideos"
      :key="relatedVideo.id"
      ref="relatedVideo"
      :video="relatedVideo"
      direction="column"
    />
  </div>
</template>
<script>
import AppVideoItem from '@/components/videos/VideoItem.vue';

import ListRelatedVideos from '@/gql/videos/ListRelatedVideos.gql';
import AppVideoResponse from '@/components/videos/VideoResponse.vue';

export default {
  components: {
    AppVideoItem,
    AppVideoResponse,
  },
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    relatedVideos: {
      query: ListRelatedVideos,
      variables() {
        return {
          category: (this.post && this.post.category) || null,
        };
      },
      fetchPolicy: 'no-cache',
    },
  },
  methods: {
    async emitRefresh() {
      this.$emit('refresh');
    },
  },
};
</script>
<style scoped>
.video-detail-related__card {
  padding: 10px;
  background-color: white;
  margin-bottom: 20px;
}
.video-detail-related__more {
  padding: 0 20px 12px;
  color: var(--cs-gray-06);
}
</style>
