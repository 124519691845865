<template>
  <div class="meeting-menu">
    <!-- Action sheet icon and options -->
    <app-action-sheet
      :open="showMeetingOptions"
      @close="$emit('close-meeting-menu')"
    >
      <app-action-sheet-button
        v-if="
          meeting.privacy === 'private' && meeting.status !== 'LIVE' && !isAdmin
        "
        >Empty action list</app-action-sheet-button
      >
      <!-- Share -->
      <app-action-sheet-button
        v-if="canShare && !listView"
        @click="shareMeeting()"
        >Share Event</app-action-sheet-button
      >
      <!-- Invite Member -->
      <app-action-sheet-button
        v-if="
          canShare &&
          !listView &&
          (meeting.status == 'NOT_STARTED' || meeting.status == 'LIVE') &&
          userId
        "
        @click="inviteMembers"
      >
        Invite Members
      </app-action-sheet-button>
      <!-- Raise Hand -->
      <app-action-sheet-button
        v-if="
          meeting.myMembership &&
          (meeting.myMembership.role == 'VIEWER' ||
            meeting.myMembership.role == 'ATTENDING') &&
          meeting.status == 'LIVE' &&
          meeting.enableQueue
        "
        @click="setRole('QUEUE')"
        >✋ Raise Hand</app-action-sheet-button
      >

      <!-- Admin Buttons -->
      <template v-if="isAdmin">
        <!-- TEMPORARILY DISABLING ADMIN FUNCTIONALITY ON MOBILE -->
        <!-- Delete Event -->
        <app-action-sheet-button @click="deleteMeeting()">{{
          deleteOrCancel
        }}</app-action-sheet-button>

        <!-- Edit Event -->
        <app-action-sheet-button @click="edit">
          Edit Meeting
        </app-action-sheet-button>

        <!-- Start Event -->
        <!-- <app-action-sheet-button @click="startMeeting" v-if="meeting.status === 'NOT_STARTED'"
          >Start Event
        </app-action-sheet-button> -->
        <!-- End Event -->
        <!-- <app-action-sheet-button
          @click="openEndMeetingPopup"
          v-if="isAdmin && meeting.status === 'LIVE'"
          >End Event</app-action-sheet-button
        > -->
      </template>
      <template v-else>
        <app-action-sheet-button
          v-if="isSuperAdmin"
          variant="primary"
          fill="outline"
          @click="deleteMeeting()"
        >
         [SA]{{deleteOrCancel}}
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="canAttend"
          variant="primary"
          fill="outline"
          @click="signup"
        >
          Attend
        </app-action-sheet-button>
        <app-action-sheet-button
          v-if="
            !canAttend &&
            meeting.myMembership &&
            meeting.myMembership.role === 'ATTENDING'
          "
          variant="secondary"
          fill="outline"
          @click="unsignup"
          >UnAttend</app-action-sheet-button
        >
      </template>
      <!-- Leave -->
      <app-action-sheet-button v-if="!listView" @click="leaveMeeting()"
        >Leave Meeting</app-action-sheet-button
      >
      <app-action-sheet-button
        v-if="!isScheduled && canModerate && meeting.recordingUrlMp4 !== null"
        @click="downloadMeeting"
        >Download Meeting</app-action-sheet-button
      >
    </app-action-sheet>
    <app-meeting-share-sheet
      v-if="showShareSheet"
      :meeting="meeting"
      :show-modal="showShareSheet"
      @close="closeShareSheet"
    />
    <app-edit-meeting-modal
      v-if="showEditModal"
      :original-meeting="editableMeeting"
      :show-meeting-edit="showEditModal"
      :list-view="listView"
      @close-edit="closeEdit"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $meetingsApi from '@/api/meetings';
import $socket from '@/services/socket';
// Components
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppMeetingShareSheet from '@/components/meetings/MeetingShareSheet.vue';
import AppEditMeetingModal from '@/components/meetings/EditMeetingModal.vue';
// Gql
import SetMeetingMemberRole from '@/gql/meetings/SetMeetingMemberRole.gql';
import DeleteMeeting from '@/gql/meetings/DeleteMeeting.gql';
import EndMeeting from '@/gql/meetings/EndMeeting.gql';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
    AppMeetingShareSheet,
    AppEditMeetingModal,
  },
  props: {
    meeting: Object,
    showMeetingOptions: {
      type: Boolean,
      value: false,
    },
    listView: {
      type: Boolean,
      default: false,
    },
    isAdmin: Boolean,
  },
  data() {
    return {
      editableMeeting: null,
      newMeeting: null,
      showShareSheet: false,
      showEditModal: false,
      userId: $auth.getUserId(),
    };
  },
  computed: {
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    isScheduled() {
      if (this.meeting.startTime > new Date().toISOString()) {
        return true;
      }
      return false;
    },
    role() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    canAttend() {
      return (
        !this.isAdmin &&
        this.meeting &&
        (!this.meeting.myMembership ||
          (this.meeting.myMembership &&
            this.meeting.myMembership.role === 'INVITED'))
      );
    },
    canShare() {
      if (this.meeting.privacy === 'private' && !this.isAdmin) return false;
      return true;
    },
    canModerate() {
      return this.role === 'ADMIN' || this.role === 'MODERATOR';
    },
    deleteOrCancel() {
      if (
        this.meeting.status === 'NOT_STARTED' ||
        this.meeting.status === 'LIVE'
      ) {
        return 'Cancel Event';
      }
      if (
        this.meeting.status === 'TRANSCODING' ||
        this.meeting.status === 'ENDED'
      ) {
        return 'Delete Event';
      }
      return 'Delete Event';
    },
  },
  methods: {
    // Share
    closeShareSheet() {
      this.showShareSheet = false;
    },
    shareMeeting() {
      this.showShareSheet = true;
    },
    // Edit Event
    edit() {
      this.editableMeeting = { ...this.meeting };
      this.showEditModal = true;
    },
    closeEdit() {
      this.showEditModal = false;
    },
    // Invite Members
    inviteMembers() {
      this.newMeeting = { ...this.meeting };
      this.$emit('openMeetingModal');
    },
    // Raise Hand
    async setRole(role, userId) {
      userId = userId || $auth.getUserId();
      await this.$apollo.mutate({
        mutation: SetMeetingMemberRole,
        variables: {
          meetingId: this.meeting.id,
          userId,
          role,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-members-modified');
    },
    // Delete meeting
    async deleteMeeting() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Cancel Event',
        message:
          "Are you sure you wish to cancel this event? This can't be undone.",
      });
      if (!confirmDelete) return;
      await this.$apollo.mutate({
        mutation: DeleteMeeting,
        variables: {
          id: this.meeting.id,
        },
        update: (cache) => {
          const normalizedId = cache.identify(this.meeting);
          if (normalizedId) {
            cache.evict({ id: normalizedId });
            cache.gc();
            this.$router.push({ name: 'MeetingHome' });
          }
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-deleted');
    },
    // Start Event
    async startMeeting() {
      try {
        await $meetingsApi.startMeeting(this.meeting.id);
        $socket.sendRoom('meeting', this.meeting.id, 'meeting-status-changed');
      } catch (e) {
        $popups.alert(
          'There was an error starting.  Please try again or contact support.'
        );
        throw e;
      }
    },
    async endMeeting() {
      await this.$apollo.mutate({
        mutation: EndMeeting,
        variables: {
          meetingId: this.meeting.id,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-status-changed');
      this.$toast.open({
        message:
          'This meeting has ended. You will be able to watch the video again shortly',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
      this.$router.push({ name: 'MeetingHome' });
    },
    // Leave
    leaveMeeting() {
      this.$emit('leaveMeeting');
    },
    // Signup/Attend
    async signup() {
      await $meetingsApi.signup(this.meeting.id);
    },
    // UnSignup/UnAttend
    async unsignup() {
      try {
        await $meetingsApi.unsignup(this.meeting.id);
      } catch (e) {
        $popups.alert(
          'There was an error saving.  Please try again or contact support.'
        );
        throw e;
      }
    },
    // Download meeting
    downloadMeeting() {
      const url = this.meeting.recordingUrlMp4;
      if (window.plugins && window.plugins.socialsharing) {
        alert('Downloading video... this may take a few minutes');
        window.plugins.socialsharing.shareWithOptions(
          {
            files: [url],
          },
          console.log,
          console.log
        );
      } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = true;
        a.click();
      }
    },
  },
};
</script>

<style scoped>
.meeting-menu__icon {
  font-size: 24px;
  color: var(--cs-gray-03);
}
* >>> .ch-action-sheet__content {
  position: fixed;
  width: 100%;
}
* >>> .ch-action-sheet-fade-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-fade-enter,
.ch-action-sheet-fade-leave-to {
  opacity: 0;
}
* >>> .ch-action-sheet-slide-enter-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-leave-active {
  transition: none;
}
* >>> .ch-action-sheet-slide-enter {
  transform: none;
}
* >>> .ch-action-sheet-slide-leave-to {
  transform: none;
}
</style>
