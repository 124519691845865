<template>
  <app-modal
    :show="value"
    title="Handouts"
    class="meeting-edit-modal"
    @close="close"
  >
    <app-post-resources can-upload :post-id="post.id" />
  </app-modal>
</template>
<script>
import AppModal from '@/components/general/Modal.vue';
import AppPostResources from '@/components/videos/PostRecources.vue'

export default {
  components: {
    AppModal,
    AppPostResources,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  },
}
</script>