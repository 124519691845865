const hideKeyboard = (e) => {
  if (e.keyCode === 13) {
    e.target.blur();
  }
};

export default {
  mounted() {
    this.$el.addEventListener('keyup', hideKeyboard);
  },
  beforeDestroy() {
    this.$el.removeEventListener('keyup', hideKeyboard);
  },
};
