<template>
  <div>
    <div class="ch-create-post__body-txtarea">
      <app-avatar
        v-if="user"
        :picture="user.profilePhotoURL"
        :name="user.displayname"
      />
      <app-textarea
        id="composer"
        ref="composer"
        v-model="draftPost"
        placeholder="Share an article, photo, video or an idea..."
        class="ch-textarea"
        :class="draftPost.length >= allowedCharacters ? 'invalid-data' : null"
        :maxlength="allowedCharacters"
      />
    </div>
    <div class="ch-list-container">
      <!-- User list to mention in comment -->
      <app-mention-user
        v-if="showList"
        :user-list="mentionUserList"
        @mention-user="mentionUser"
      />
    </div>
    <div class="ch-create-post__body-extra">
      <span class="ch-character-cnt"
        >{{ draftPost.length }}/{{ allowedCharacters }} characters</span
      >
      <span v-if="blockedUserFound" class="ch-character-cnt text-red"
        >You are not allowed to mention this user!</span
      >
      <div v-if="isUserLocation" class="ch-extra-btns">
        <app-user-location
          v-if="isUserLocation"
          v-model="location"
          @add="addUserLocation"
          @remove="removeUserLocation"
        />
      </div>
    </div>
    <cs-spinner v-if="loadingLink" class="ch-spinner"></cs-spinner>
    <div v-if="link" class="ch-create-post__link-preview-holder">
      <app-link-preview
        :title="link.title"
        :src="link.url"
        :hide-picture="hasImages"
        :description="link.details"
        :picture="link.image"
        @remove="removeLinkPreview"
      />
    </div>
  </div>
</template>

<script>
// JS
import $auth from '@/services/auth';
// GQL
import UserSearch from '@/gql/search/UserSearch.gql';
import GetLinkPreview from '@/gql/general/GetLinkPreview.gql';
import GetProfile from '@/gql/general/GetProfile.gql';
// VUE
import AppAvatar from '@/components/general/Avatar.vue';
import AppUserLocation from '@/components/general/UserLocation.vue';
import AppTextarea from '@/components/general/Textarea.vue';
import AppLinkPreview from '@/components/general/LinkPreview.vue';
import AppMentionUser from '@/components/post/composer/MentionUser.vue';

export default {
  components: {
    AppAvatar,
    AppUserLocation,
    AppTextarea,
    AppLinkPreview,
    AppMentionUser,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    bodyText: {
      type: String,
      default: '',
    },
    isUserLocation: {
      type: Boolean,
      default: true,
    },
    sharedLink: {
      type: String,
      required: false,
      default: '',
    },
    hasImages: {
      type: Boolean,
      required: false,
      default: false,
    },
    isComment: {  // Add this block
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userSetting: null,
      draftPost: this.bodyText,
      link: '',
      loadingLink: false,
      ignoredLinks: [],
      linkTimer: null,
      blockedUserList: new Set(),
      blockedUserFound: false,
      showList: false,
      userSearchTimer: null,
      mentionUserList: null,
      textarea: null,
      location: this.value ? this.value.location : null,
      user: null,
    };
  },
  apollo: {
    user: {
      query: GetProfile,
      variables() {
        return {
          id: $auth.getUserId(),
        };
      },
    },
  },
  computed: {
    allowedCharacters() {
      if (this.isComment) {
        // If this is a comment, return a different character limit
        return 500;
      }
      if (this.userSetting) {
        return this.userSetting.postCharacters;
      } else {
        return 300;
      }
    },
  },
  watch: {
    bodyText() {
      this.draftPost = this.bodyText ? this.bodyText : '';
    },
    draftPost() {
      this.handleInput(this.draftPost);
    },
  },
  async mounted() {
    this.userSetting = await $auth.getUserSettings();
    if (this.sharedLink) this.draftPost = this.sharedLink;
    if (this.sharedLink && this.bodyText) {
      this.draftPost = `${this.bodyText}\n${this.sharedLink}`;
    }
    this.textarea = this.$refs.composer.$el.querySelector('textarea');
    setTimeout(() => {
      this.textarea.focus();
      this.handleInput();
    }, 1000);
  },
  methods: {
    mentionUser(user) {
      const cursorPos = this.textarea.selectionStart;
      const beforeText = this.draftPost.substring(0, cursorPos);
      const beforeCursorWords = beforeText.replace(/\n/g, ' ').split(' ');
      const currentWord = beforeCursorWords[beforeCursorWords.length - 1];
      this.draftPost = this.draftPost.replace(currentWord, `${user.username} `);
      this.$nextTick(() => {
        this.textarea.selectionEnd = cursorPos + user.username.length;
        this.textarea.focus();
        this.showList = false;
      });
    },
    addUserLocation(location) {
      this.$emit('addUserLocation', location);
    },
    removeUserLocation() {
      this.$emit('removeUserLocation');
    },

    removeLinkPreview() {
      this.ignoredLinks.push(this.link.url);
      this.link = null;
      this.$emit('removeLinkPreview');
    },
    selectEmoji(emoji) {
      if (!this.textarea)
        this.textarea = this.$refs.composer.$el.querySelector('textarea');
      const textarea = this.textarea;
      const cursorPos = textarea.selectionStart;
      const beforeText = this.draftPost.substring(0, cursorPos);
      const afterText = this.draftPost.substring(
        cursorPos,
        this.draftPost.length
      );
      this.draftPost = `${beforeText}${emoji}${afterText}`;
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPos + emoji.length;
      });
    },
    async getLinks(notIgnoredLinks) {
      this.link = null;
      let url = notIgnoredLinks[0];
      if (!url.startsWith('http')) {
        url = `https://${url}`;
      }
      this.loadingLink = true;
      try {
        const { data } = await this.$apollo.query({
          query: GetLinkPreview,
          variables: {
            url,
          },
        });
        if (data) {
          const link = {
            ...data.link_preview,
            url,
          };
          delete link.__typename;
          this.link = link;
          this.$emit('detectLink', link);
        }
      } catch (e) {
        // TODO: track this error
        // eslint-disable-next-line
        console.log('e', e);
      }
      this.loadingLink = false;
    },
    async searchUsers(query) {
      const { data } = await this.$apollo.query({
        query: UserSearch,
        variables: {
          query,
        },
      });
      this.mentionUserList = data.users;
      data.users.forEach((user) => {
        if (user.myRelationship.isBlocked)
          this.blockedUserList.add(user.username);
      });
    },
    async handleInput() {
      if (!!this.link && !this.draftPost.includes(this.link.url)) {
        this.link = null;
        this.$emit('removeLinkPreview');
      }
      if (!this.draftPost.length) {
        this.ignoredLinks = [];
      }
      this.$emit('textHandle', this.draftPost);
      if (!this.textarea)
        this.textarea = this.$refs.composer.$el.querySelector('textarea');
      const tags = [];

      // Handle Mention logic
      const cursorPos = this.textarea.selectionStart;
      const beforeText = this.draftPost.substring(0, cursorPos);
      const beforeCursorWords = beforeText.replace(/\n/g, ' ').split(' ');
      const currentWord = beforeCursorWords[beforeCursorWords.length - 1];
      if (currentWord.startsWith('@')) {
        this.showList = true;
        clearTimeout(this.userSearchTimer);
        this.userSearchTimer = setTimeout(
          () => this.searchUsers(currentWord),
          700
        );
      } else this.showList = false;

      // Handling Hash tags
      const draftPostArray = this.draftPost.replace(/\n/g, ' ').split(' ');
      if (draftPostArray.length > 0) {
        draftPostArray.forEach((data) => {
          if (data.startsWith('#')) {
            tags.push(data.split('#')[1]);
          }
        });
      }
      const foundMentionnedUsers = this.draftPost.match(/\B@[a-z0-9_-]+/gi);
      let containsBlockedUsers;
      if (foundMentionnedUsers && this.blockedUserList) {
        containsBlockedUsers = foundMentionnedUsers.reduce(
          (accumulator, mentionedUser) => {
            return accumulator || !!this.blockedUserList.has(mentionedUser);
          },
          false
        );
      }
      this.blockedUserFound = containsBlockedUsers;
      if (containsBlockedUsers) {
        this.$emit('blockPosting', true);
      } else {
        this.$emit('blockPosting', false);
      }
      this.$emit('handleTags', tags);
      if (!this.link) {
        clearTimeout(this.linkTimer);
        const links = this.draftPost.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (links) {
          const notIgnoredLinks = links.filter(
            (link) => !this.ignoredLinks.includes(link)
          );
          if (notIgnoredLinks.length) {
            this.linkTimer = setTimeout(
              () => this.getLinks(notIgnoredLinks),
              700
            );
          }
        }
      }
    },
  },
};
</script>

<style>
.ch-create-post__body-txtarea {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
.ch-textarea {
  display: flex;
  flex: 1;
  width: 100%;
}
.ch-textarea.invalid-data > .cs-textarea__textarea {
  border: solid 1px #dc3545 !important;
}
.ch-create-post__body-extra {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
  justify-content: space-between;
}
.ch-character-cnt {
  font-size: 12px;
  color: var(--cs-gray-04);
}
.ch-extra-btns,
.ch-upload-btns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.cs-extra-btns {
  width: 50px;
  --cs-button-padding: 5px !important;
}
.cs-exta-btns,
.cs-upload-btns {
  --cs-button-color: var(--cs-gray-01) !important;
  --cs-button-text-color: var(--cs-gray-05) !important;
}
.ch-list-container {
  margin-left: 41px;
  background: transparent;
}
.ch-create-post__link-preview-holder {
  padding-bottom: 10px;
}

.text-red {
  color: red;
}
</style>
