<template>
  <div v-if="!!user" class="ch-edit-profile">
    <app-modal
      :show="showProfileEdit"
      title="Edit Profile"
      action="Update"
      class="ch-profile-modal"
      @close="onCloseProfileEditModal"
      @action="onUpdateProfile"
    >
      <cs-spinner v-if="loading" />
      <div v-if="!loading" class="ch-edit-profile__fields">
        <cs-alert variant="primary">
          <div slot="content">
            To edit your username, email address or password, please visit
            <b v-router-link="{ name: 'Settings' }" class="settings-text"
              >Settings</b
            >.
          </div>
          <i slot="close-icon" class="cs-icons-close"></i>
        </cs-alert>
        <cs-alert
          v-if="isVerified"
          variant="primary"
          class="ch-edit-profile__second-alert"
        >
          <div slot="content">
            You're a verified user. To change your first name, last name,
            display name, or username, please contact
            <b>support@clouthub.com</b>
          </div>
          <i slot="close-icon" class="cs-icons-close"></i>
        </cs-alert>
        <div
          class="
            edit-profile-details__personal-details
            cs-textstyle-item-heading
          "
        >
          Private Profile
          <div class="cs-textstyle-paragraph-small text-light">
            Your Private Profile is only visible to your friends.
          </div>
        </div>
        <div class="edit-profile-details__fields">
          <!-- First Name -->
          <cs-input
            v-model="localUser.firstname"
            placeholder="Your first name"
            label="First Name"
            :disabled="isVerified"
          />

          <!-- Last Name -->
          <cs-input
            v-model="localUser.lastname"
            placeholder="Your last name"
            label="Last Name"
            :disabled="isVerified"
          />
          <!-- Phone Number  -->
          <cs-input
            v-model="localUser.phoneNo"
            placeholder="Your phone number"
            label="Phone Number"
          />

          <!-- Date of Birth -->
          <cs-date-input
            v-model="localUser.dob"
            label="Date of Birth"
            type="date"
          />
          <!-- Gender  -->
          <div class="ch-edit-profile__other-labels">Gender</div>
          <cs-radio-group
            v-model="localUser.gender"
            :options="genderOptions"
            class="ch-edit-profile__radio-button-group"
            @input="genderInput"
          ></cs-radio-group>
          <div v-if="showGenderInputField">
            <cs-input
              v-model="genderInputForm"
              type="text"
              placeholder="Enter your gender"
            />
          </div>
        </div>
        <div class="ch-edit-profile__public-details cs-textstyle-item-heading">
          Public Profile
          <div class="cs-textstyle-paragraph-small text-light">
            Your Public Profile is visible to everyone on CloutHub.
          </div>
        </div>
        <!-- displayname -->
        <cs-input
          v-model="localUser.displayname"
          label="Display Name"
          placeholder="displayname"
          :disabled="isVerified"
        />
        <!-- Bio -->
        <app-textarea
          v-model="localUser.bio"
          label="Profile Bio"
          placeholder="Tell us about yourself..."
        />

        <!-- Website -->
        <cs-input
          v-model="localUser.website"
          label="Website"
          placeholder="Website"
        >
        </cs-input>

        <!-- location  -->
        <div class="edit-profile-details">
          <div class="cs-textstyle-detail-heading">Location</div>
          <div class="">
            <app-profile-location-input
              v-model="localUser.location"
            ></app-profile-location-input>
          </div>
        </div>
        <!-- Local Interest -->
        <app-local-interest v-model="localUser.issuesOfInterestLocal" />
        <!-- National Interest -->
        <app-national-interest v-model="localUser.issuesOfInterestNational" />
      </div>
    </app-modal>
  </div>
</template>

<script>
import UpdateUser from '@/gql/general/UpdateUserExample.gql';
import GetMe from '@/gql/general/GetMe.gql';
import ListLiveMeetings from '@/gql/meetings/ListLiveMeetings.gql';
import GetProfile from '@/gql/general/GetProfile.gql';
import $auth from '@/services/auth';

import AppModal from '@/components/general/Modal.vue';
import AppTextarea from '@/components/general/Textarea.vue';
import AppLocalInterest from '@/components/connections/LocalInterest.vue';
import AppNationalInterest from '@/components/connections/NationalInterest.vue';
import AppProfileLocationInput from '@/components/connections/ProfileLocationInput.vue';

export default {
  components: {
    AppModal,
    AppTextarea,
    AppLocalInterest,
    AppNationalInterest,
    AppProfileLocationInput,
  },
  props: {
    showProfileEdit: {
      type: Boolean,
      default: false,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasNoCountry: false,
      profileInfo: {},
      bio: '',
      firstname: '',
      lastname: '',
      displayname: '',
      website: '',
      loading: false,
      genderFromInput: '',
      localUser: {},
      genderValue: '',
      showInput: false,
      showPRInput: false,
      genderOptions: ['Male', 'Female', 'Other'],
      genderInputForm: '',
      politicalRegistrationFormInput: '',
      politicalRegistrationOptions: [
        { label: 'Democrat', value: 'Democrat' },
        { label: 'Republican', value: 'Republican' },
        { label: 'Independent', value: 'Independent' },
        { label: 'Green Party', value: 'Green Party' },
        { label: 'Libertarian', value: 'Libertarian' },
        { label: 'Decline to State', value: 'Decline to State' },
        { label: 'Other', value: 'Other' },
      ],
      politicalLeaningOptions: [
        { label: 'Conservative', value: 'Conservative' },
        { label: 'Liberal', value: 'Liberal' },
        { label: 'Middle of the road', value: 'Middle of the road' },
        { label: 'Decline to State', value: 'Decline to State' },
        { label: 'None', value: 'none' },
      ],
    };
  },
  computed: {
    isVerified() {
      return (
        this.localUser.badgeType !== null && this.localUser.badgeType !== 'none'
      );
    },
    showPoliticalRegistrationInputField() {
      return (
        this.showPRInput || this.localUser.politicalRegistration === 'Other'
      );
    },
    showGenderInputField() {
      return this.showInput || this.localUser.gender === 'Other';
    },
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    politicalRegistrationInput(val) {
      if (val === 'Other') {
        this.showPRInput = true;
      } else {
        this.politicalRegistrationFormInput = '';
        this.localUser.politicalRegistration = val;
        this.showPRInput = false;
      }
    },
    genderInput(val) {
      if (val === 'Other') {
        this.showInput = true;
      } else {
        this.genderInputForm = '';
        this.localUser.gender = val;
        this.showInput = false;
      }
    },
    async fetchProfile() {
      const resp = await this.$apollo.query({
        query: GetMe,
        variables: {
          id: $auth.getUserId(),
          viewerId: $auth.getUserId(),
        },
      });
      this.localUser = { ...resp.data.user };
      // Gender
      if (
        this.localUser.gender !== 'Male' &&
        this.localUser.gender !== 'Female' &&
        this.localUser.gender !== null
      ) {
        this.genderInputForm = this.localUser.gender;
        this.localUser.gender = 'Other';
      }
      // Political Registration
      if (
        this.localUser.politicalRegistration !== 'Democrat' &&
        this.localUser.politicalRegistration !== 'Republican' &&
        this.localUser.politicalRegistration !== 'Independent' &&
        this.localUser.politicalRegistration !== 'Green Party' &&
        this.localUser.politicalRegistration !== 'Decline to State' &&
        this.localUser.politicalRegistration !== 'Libertarian' &&
        this.localUser.politicalRegistration !== null
      ) {
        this.politicalRegistrationFormInput =
          this.localUser.politicalRegistration;
        this.localUser.politicalRegistration = 'Other';
      }
    },
    onCloseProfileEditModal() {
      this.$emit('closeProfileEditModal');
    },
    onSelectPoliticalLeaning(val) {
      if (val === 'none') {
        this.localUser.politicalLeaning = null;
      } else {
        this.localUser.politicalLeaning = val;
      }
    },
    async onUpdateProfile() {
      this.loading = true;
      if (this.localUser.gender === 'Other') {
        this.localUser.gender = this.genderInputForm;
      }
      if (this.localUser.politicalRegistration === 'Other') {
        this.localUser.politicalRegistration =
          this.politicalRegistrationFormInput;
      }
      const finalDob = new Date(this.localUser.dob);
      const variables = {
        bio: this.localUser.bio ? this.localUser.bio : '',
        website: this.localUser.website ? this.localUser.website : '',
        bannerImageURL: this.user.bannerImageURL,
        profilePhotoURL: this.user.profilePhotoURL,
        location: this.localUser.location,
        privateLocation: false,
        firstname: this.localUser.firstname ? this.localUser.firstname : '',
        lastname: this.localUser.lastname ? this.localUser.lastname : '',
        displayname: this.localUser.displayname
          ? this.localUser.displayname
          : '',
        gender: this.localUser.gender ? this.localUser.gender : '',
        politicalLeaning: this.localUser.politicalLeaning
          ? this.localUser.politicalLeaning
          : '',
        politicalRegistration: this.localUser.politicalRegistration
          ? this.localUser.politicalRegistration
          : '',
        issuesOfInterestLocal: this.localUser.issuesOfInterestLocal,
        issuesOfInterestNational: this.localUser.issuesOfInterestNational,
        username: this.localUser.username,
        phoneNo: this.localUser.phoneNo ? this.localUser.phoneNo : '',
        dob: finalDob || null,
      };
      const additionalCacheData = {
        mutual_national_interests:
          this.localUser.mutual_national_interests ?? '',
        mutual_local_interests: this.localUser.mutual_local_interests ?? '',
        mutual_followers: this.localUser.mutual_followers,
        mutual_following: this.localUser.mutual_following,
        mutual_friends: this.localUser.mutual_friends,
        myRelationship: this.user.myRelationship,
        allowUnsolicitedMessages: this.localUser.allowUnsolicitedMessages,
        enablePayments: this.user.enablePayments,
        createdAt: this.user.createdAt,
        followingCount: this.localUser.followingCount,
        followerCount: this.localUser.followerCount,
        badgeType: this.localUser.badgeType ?? '',
        id: this.localUser.id,
        friendCount: this.localUser.friendCount,
      };
      await this.$apollo.mutate({
        mutation: UpdateUser,
        variables,
        optimisticResponse: {
          update_user_data: {
            __typename: 'User',
            id: this.user.id,
            badgeType: this.localUser.badgeType,
            ...variables,
          },
        },
        refetchQueries: [{ query: ListLiveMeetings }],
        update: async (cache) => {
          const me = await $auth.getUser();
          cache.writeQuery({
            query: GetProfile,
            variables: {
              id: me.id,
            },
            data: {
              user: { ...variables, ...additionalCacheData },
            },
          });
        },
      });
      // Gender

      this.genderRetain();

      // Political Registration
      this.politicalRegistrationRetain();
      this.loading = false;
      this.$toast.open({
        message: 'Saved',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
    },
    genderRetain() {
      if (
        this.localUser.gender !== 'Male' &&
        this.localUser.gender !== 'Female' &&
        this.localUser.gender != null
      ) {
        this.genderInputForm = this.localUser.gender;
        this.localUser.gender = 'Other';
      }
    },
    politicalRegistrationRetain() {
      if (
        this.localUser.politicalRegistration !== 'Democrat' &&
        this.localUser.politicalRegistration !== 'Republican' &&
        this.localUser.politicalRegistration !== 'Independent' &&
        this.localUser.politicalRegistration !== 'Green Party' &&
        this.localUser.politicalRegistration !== 'Decline to State' &&
        this.localUser.politicalRegistration !== 'Libertarian'
      ) {
        this.politicalRegistrationFormInput =
          this.localUser.politicalRegistration;
        if (
          this.politicalRegistrationFormInput === '' ||
          this.politicalRegistrationFormInput === null
        ) {
          this.localUser.politicalRegistration = null;
        } else {
          this.localUser.politicalRegistration = 'Other';
        }
      }
    },
  },
};
</script>

<style scoped>
.ch-edit-profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--cs-gray);
}
.ch-edit-profile__images {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 300px;
  background-color: var(--cs-gray-00);
}
.ch-edit-profile__profile-photo {
  position: absolute;
  bottom: 10px;
  align-self: left;
  margin-left: 20px;
  border: solid 2px var(--cs-gray-00);
  z-index: 1;
}
.ch-edit-profile__background-img {
  height: auto;
}
.ch-edit-profile__background-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 106px;
}

.ch-edit-profile__fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px auto 20px;
}
.ch-edit-profile__public-details {
  margin-top: 10px;
}
.ch-edit-profile__radio-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cs-input__input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--cs-form-input-padding-x);
  color: var(--cs-gray-07);
  background-color: var(--cs-gray-00);
  border: 1px solid;
  border-color: var(--cs-gray-02);
  border-radius: var(--cs-input-border-radius);
  height: 40px;
}
.cs-input__input:focus {
  outline: none;
  border-color: var(--cs-gray-03);
}
.ch-edit-profile__other-labels {
  color: var(--cs-gray-05);
  font-size: 14px;
}
.ch-edit-profile__second-alert {
  margin-top: -15px;
}
</style>

<style
  src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>
