<template>
  <div class="post-list">
    <app-post-prompt v-if="isMe" />
    <app-post-list
      :list-id="user.id"
      :posts="posts"
      :with-recommended-content="withRecommendedContent"
    />
    <app-infinite-loader
      :paused="$apollo.queries.posts.loading"
      :finished="!mayHaveMore"
      @more="loadMore()"
    ></app-infinite-loader>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import ListUserPosts from '@/gql/posts/ListUserPosts.gql';
import AppPostPrompt from '@/components/post/PostPrompt.vue';
import AppPostList from '@/components/post/lists/PostList.vue';
import AppInfiniteLoader from '@/components/general/InfiniteLoader.vue';

export default {
  components: {
    AppPostList,
    AppInfiniteLoader,
    AppPostPrompt,
  },
  apollo: {
    posts: {
      query: ListUserPosts,
      variables() {
        return {
          id: this.user.id,
        };
      },
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    withRecommendedContent: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      mayHaveMore: true,
    };
  },
  computed: {
    isMe() {
      return this.$route.params.id === $auth.getUserId();
    },
  },
  methods: {
    loadMore() {
      if (this.mayHaveMore) {
        this.mayHaveMore = false;
        const before = this.posts[this.posts.length - 1].createdAt;
        this.$apollo.queries.posts.fetchMore({
          variables: {
            id: this.$route.params.id,
            viewerId: $auth.getUserId(),
            before,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.mayHaveMore = !!fetchMoreResult.posts.length;
            const previous = previousResult.posts;
            const newOnes = fetchMoreResult.posts;
            return {
              posts: [
                ...previous,
                ...newOnes.filter(({ id }) =>
                  previous.every((post) => post.id !== id)
                ),
              ],
            };
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.post-list {
  background-color: inherit;
  margin-top: 10px;
}
</style>
