<template>
  <div class="video-item-mini" v-if="safeToDisplay">
    <!-- <div v-if="video.video.isLive" class="ch-live">LIVE</div> -->
    <app-video-thumbnail
      class="video-item-mini-thumbnail"
      :video="video"
    ></app-video-thumbnail>
    <div class="video-item-mini-body">
      <div class="video-item-mini-header">
        <div
          v-router-link="{ name: 'VideoDetail', params: video }"
          class="video-item-mini-title cs-textstyle-paragraph-small-bold"
        >
          {{ title }}
        </div>
        <i class="ch-icons-options" @click="showMenu = true"></i>
      </div>
      <div v-if="video && video.user" class="video-item-mini-footer">
        <app-avatar
          v-router-link="{ name: 'VideoProfile', params: video.user }"
          :name="video.user.displayname"
          :picture="video.user.profilePhotoURL"
          :class="{ noShow: showSnapshot }"
          size="small"
        ></app-avatar>
        <div class="video-item-mini-info">
          <div
            v-router-link="{ name: 'VideoProfile', params: video.user }"
            :class="{ noShow: showSnapshot }"
          >
            <app-username :user="video.user" type="displayname" />
          </div>
          <div v-if="showSnapshot" class="video-item-mini-meta text-light">
            <div>
              {{ video.viewCount || 0 }}
              {{ video.viewCount === 1 ? 'view' : 'views' }}
            </div>
            <div>{{ video.createdAt | moment('from') }}</div>
          </div>
          <div v-else class="video-item-mini-meta text-light">
            {{ video.viewCount || 0 }}
            {{ video.viewCount === 1 ? 'view' : 'views' }} ·
            {{ video.createdAt | moment('from') }}
          </div>
        </div>
        <div v-if="showSnapshot" class="video-item-mini-icons">
          <i class="ch-icons-share" @click="shareVideo()"></i>
          <app-bookmark-button size="medium" :post="video" />
          <app-queue-button size="medium" :post="video" />
          <!-- <app-download-video-button v-if="postedByMe" :video="video" /> -->
        </div>
        <div v-else class="video-item-mini-icons">
          <app-bookmark-button
            v-if="showBookmark && !isHistory"
            size="medium"
            :post="video"
          />
          <app-queue-button
            v-if="showQueue && !isHistory"
            size="medium"
            :post="video"
          />
          <i v-if="showShare" class="ch-icons-share" @click="shareVideo()"></i>
          <!-- <app-download-video-button
            v-if="postedByMe && !isHistory && !showBookmark && !showQueue"
            :video="video"
          /> -->
        </div>
      </div>
    </div>
    <app-action-sheet :open="showMenu" @close="closeMenu">
      <app-action-sheet-button v-if="postedByMe" @click="editVideo()"
        >Edit Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="deleteVideo"
        >Delete Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="downloadVideo()"
        >Download Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="!postedByMe" @click="report('video')"
        >Report Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe" @click="report('own-video')"
        >Report Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="postedByMe && !video.ispinnedonchannel" @click="pinVideo()"
        >Pin Video</app-action-sheet-button
      >
      <app-action-sheet-button v-if="!postedByMe" @click="report('user')"
        >Report <app-username :user="video.user"
      /></app-action-sheet-button>
      <app-action-sheet-button v-if="!postedByMe" @click="blockAuthor()"
        >Block <app-username :user="video.user"
      /></app-action-sheet-button>
      <app-action-sheet-button v-if="isSuperadmin" @click="deleteVideo"
        >[SA] Delete Video</app-action-sheet-button
      >
    </app-action-sheet>
  </div>
</template>

<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $videosApi from '@/api/videos';

// Component
import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';
import AppVideoThumbnail from '@/components/videos/VideoThumbnail.vue';
import AppBookmarkButton from '@/components/post/buttons/BookmarkButton.vue';
import AppQueueButton from '@/components/post/buttons/QueueButton.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
// import AppDownloadVideoButton from '@/components/videos/DownloadVideoButton.vue';

export default {
  components: {
    AppAvatar,
    AppVideoThumbnail,
    AppUsername,
    AppQueueButton,
    AppBookmarkButton,
    AppActionSheet,
    AppActionSheetButton,
    // AppDownloadVideoButton,
  },
  props: {
    video: {
      type: Object,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: 'row',
    },
    showSnapshot: {
      type: Boolean,
      required: false,
      default: false,
    },
    showQueue: {
      type: Boolean,
      default: false,
      required: false,
    },
    showBookmark: {
      type: Boolean,
      default: false,
      required: false,
    },
    showShare: {
      type: Boolean,
      default: true,
      required: false,
    },
    isHistory: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    title() {
      if (this.video) {
        // return this.video.video.title || this.video.video.name || this.video.bodyText || 'Untitled';
        return (
          this.video.video.title ||
          this.video.bodyText ||
          this.video.video.name ||
          'Untitled Video'
        );
      }
      return '';
    },
    postedByMe() {
      return (
        this.video &&
        this.video.user &&
        this.video.user.id === $auth.getUserId()
      );
    },
    isSuperadmin() {
      return $auth.isAdmin();
    },
    safeToDisplay() {
      return this.postedByMe || this.video.safe;
    },
  },
  methods: {
    // Share
    shareVideo() {
      const link = `/v/${this.video.slug || this.video.id}`;
      $bus.$emit('show-share-sheet', {
        link,
        post: this.video,
        title: 'Share Video',
      });
    },
    // Report
    report(type) {
      const reportData = {
        report:
          type === 'video' || type === 'own-video'
            ? this.video
            : this.video.user,
        reportType: type,
      };
      $bus.$emit('show-report-action', reportData);
    },
    // Block
    blockAuthor() {
      $bus.$emit('show-block-user', this.video.user);
    },
    // Delete
    async deleteVideo() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Delete Video',
        message: 'Are you sure you wish to delete this video?',
      });
      if (!confirmDelete) return;
      await $videosApi.deleteVideo(this.video);
    },
    // Edit
    editVideo() {
      $bus.$emit('show-edit-video-modal', this.video);
    },
    closeMenu() {
      this.showMenu = false;
    },
    downloadVideo() {
      $videosApi.downloadVideo(this.video.video);
    },
    pinVideo() {
      $videosApi.pinUnpinUserVideo(this.video.id, true);
      // this.$parent.$emit('reloadVideosList');
      // $bus.$emit('reloadVideosList');
      window.location.reload();
    }
  },
};
</script>

<style scoped>
.video-item-mini {
  display: flex;
  height: 130px;
  margin-bottom: 3px;
  background-color: white;
}
.video-item-mini-thumbnail {
  width: 170px;
  height: 130px;
  object-fit: contain;
  background-color: black;
}
.video-item-mini-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  min-width: 0;
}
.video-item-mini-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.video-item-mini-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  max-width: 145px;
}
.video-item-mini-footer {
  display: flex;
}
.video-item-mini-info {
  font-size: 12px;
  overflow: hidden;
  flex: 1;
}
.video-item-mini-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  color: var(--cs-gray-05);
}
.video-item-mini-icons i {
  margin-left: 5px;
}
.video-item-mini-meta {
  display: flex;
  flex-direction: column;
}
.noShow {
  display: none;
}
</style>
