<template>
  <ion-page>
    <ion-header>
      <div class="ch-video-channel__header">
        <app-category-header :title="modifiedTitle" />
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <!-- <div class="video-search-input">
        <cs-search-bar placeholder="Search..." v-model="query" />
      </div> -->
      <cs-spinner v-if="!users" />
      <div v-if="users && users.length" class="ch-video-channel__body">
        <app-video-channel-item
          v-for="user in filterUsers"
          :key="user.id"
          :user="user"
        />
      </div>
      <cs-empty-state
        v-if="filterUsers.length === 0"
        description="No Matching Users"
      />
      <!-- Floating Button -->
      <app-video-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
// Component
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppVideoChannelItem from '@/components/videos/VideoChannelItem.vue';
import AppVideoExploreBtn from '@/components/videos/VideoExploreBtn.vue';
// GQL
import ListLiveChannels from '@/gql/videos/PublicListLiveChannels.gql';
import ListFeaturedChannels from '@/gql/videos/ListFeaturedChannels.gql';
//import ListSubscribedChannels from '@/gql/videos/ListSubscribedChannels.gql';
import ListAllChannels from '@/gql/videos/ListAllChannels.gql';

const viewSettings = {
  recommended: {
    title: 'Recommended Channels',
    query: ListFeaturedChannels,
  },
  live: {
    title: 'Live Channels',
    query: ListLiveChannels,
  },
  /*
  subscribed: {
    title: 'Subscribed Channels',
    query: ListSubscribedChannels,
  },
  */
  all: {
    title: 'All Channels',
    query: ListAllChannels,
  },
};
export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppVideoChannelItem,
    AppVideoExploreBtn,
  },
  data() {
    return {
      view: this.$route.params.view,
      query: '',
    };
  },
  computed: {
    currentView() {
      return viewSettings[this.$route.params.view];
    },
    modifiedTitle() {
      return viewSettings[this.$route.params.view].title;
    },
    filterUsers() {
      return (
        this.users &&
        this.users
          .filter((c) => {
            const searchText = `${c.channelName} ${c.channelDescription} ${c.channelNumber} ${c.displayname}`;
            return searchText.toLowerCase().includes(this.query.toLowerCase());
          })
          .sort((a, b) => a.channelNumber - b.channelNumber)
      );
    },
  },
  apollo: {
    users: {
      query() {
        return this.currentView.query;
      },
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.users.refetch();
      $event.target.complete();
    },
  },
};
</script>
