<template>
  <div class="channel-circle">
    <app-avatar
      v-router-link="redirectLink"
      :picture="displayPicture"
      :name="displayName"
      size="medium"
    />
    <div class="channel-circle-name cs-textstyle-paragraph-extra-small-bold">
      {{ displayName }}
    </div>
    <div class="subscriber-text">
      {{ user.subscribersCount }}
      <span class="cs-textstyle-paragraph-extra-small">subscribers</span>
    </div>
    <div v-if="showButton" class="subscribe-button-container">
      <cs-button
        v-if="user.subscribedToChannel"
        size="small"
        variant="secondary"
        fill="outline"
        @click="unsubscribe()"
        >Unsubscribe</cs-button
      >
      <cs-button
        v-if="!user.subscribedToChannel"
        size="small"
        fill="outline"
        variant="default"
        @click="subscribe"
        >Subscribe</cs-button
      >
    </div>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';
import $relationshipsApi from '@/api/relationships';
// import AppChannelSubscribeButton from '@/components/general/ChannelSubscribeButton.vue';

export default {
  components: {
    AppAvatar,
    // AppChannelSubscribeButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    displayName() {
      return this.user.channelName || this.user.displayname;
    },
    displayPicture() {
      return this.user.channelPicture || this.user.profilePhotoUrl;
    },
    redirectLink() {
      return { name: 'VideoProfile', params: this.user };
    },
  },
  methods: {
    async subscribe() {
      await $relationshipsApi.subscribeChannel(this.user);
      this.$emit('refresh-recommendation');
    },
    async unsubscribe() {
      await $relationshipsApi.unsubscribeChannel(this.user);
      this.$emit('refresh-recommendation');
    },
  },
};
</script>

<style scoped>
.channel-circle {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 15px;
}
.subscriber-text {
  color: var(--cs-gray-04);
}
.channel-circle-name {
  display: -webkit-box;
  max-width: 120px;
  max-height: 65px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.subscribe-button-container {
  margin-top: 10px;
}
.subscribe-button-container >>> .cs-button {
  color: var(--cs-gray-05);
  border: 1px solid var(--cs-gray-04);
}
</style>
