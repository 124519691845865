<template>
  <ion-row>
    <app-group-card v-for="group in groups" :key="group.id" :group="group" />
  </ion-row>
</template>

<script>
// Group common components
import AppGroupCard from '@/components/groups/GroupCard.vue';

export default {
  components: {
    AppGroupCard,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
ion-row {
  margin: 15px;
}
</style>
