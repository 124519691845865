  <template>
    <div class="live-chat">
      <transition name="ch-action-sheet-fade">
        <div class="live-chat__header" v-if="isHeaderVisible">
          <h3 class="live-chat__title">Chat</h3>
          <cs-button
            size="small"
            variant="secondary"
            fill="outline"
            @click="shareVideo"
          >
            <i class="ch-icons-share"></i> Share
          </cs-button>
        </div>
      </transition>
      <app-video-detail-live-messages class="live-chat__messages" :post="post" @refresh="emitRefresh" />
    </div>
  </template>
  <script>
  import $bus from '@/services/bus';

  import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
  import AppMeetingChatMessage from '@/components/meetings/MeetingChatMessage.vue';
  import AppVideoDetailLiveInput from '@/components/videos/detail/VideoDetailLiveInput.vue';
  import AppVideoDetailLiveMessages from '@/components/videos/detail/VideoDetailLiveMessages.vue';

  export default {
    components: {
      AppInfiniteSimple,
      AppMeetingChatMessage,
      AppVideoDetailLiveInput,
      AppVideoDetailLiveMessages,
    },
    props: {
      post: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      isHeaderVisible: true,
    }),
    mounted() {
      $bus.$on('video-detail-live-messages-scroll-up', () => {
        this.isHeaderVisible = false;
      });
      $bus.$on('video-detail-live-messages-scrolled-bottom', () => {
        this.isHeaderVisible = true;
      });
    },
    methods: {
      async emitRefresh() {
        this.$emit('refresh');
      },
      shareVideo() {
        const link = `/v/${this.post.id}`;
        $bus.$emit('show-share-sheet', {
          link,
          post: this.post,
          title: 'Share Video',
        });
      },
    }
  }
  </script>
  <style scoped>
  .live-chat__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .live-chat__title {
    margin: 0;
    font-size: 14px;
    font-weight: 800;
  }
  .live-chat__messages {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  </style>
