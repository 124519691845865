import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueObserveVisibility from 'vue-observe-visibility';
import VueChatScroll from 'vue-chat-scroll';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import $amplitude from '@/services/amplitude';
import PortalVue from 'portal-vue';
import VueGtag from "vue-gtag";

import 'firebase/analytics';
// country select
import vueCountryRegionSelect from 'vue-country-region-select';
// Importing Apollo Client
import VueApollo from 'vue-apollo';
import apolloClient from '@/services/apollo';

// Import CS components
import 'cs-components';
import 'cs-components/dist/csuite.css';

// Import Ionic
import Ionic from '@ionic/vue';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

// Import Emoji Picker
import VEmojiPicker from 'v-emoji-picker';

// Import Vue Carousel
import VueCarousel from 'vue-carousel';

// Import Router
import router from '@/router';

// Add a universal router-link directive so you can use it as an attribute
import RouterLink from '@/directives/router-link';

// Global styles
import '@/assets/css/global-styles.css';
// Design Tokens
import '@/assets/css/design-token.css';
// Override Ionic defaults
import '@/assets/css/ionic.css';
// ch-icon styles
import '@/assets/icons/css/styles.css';

import $cdn from '@/services/cdn';
import $auth from '@/services/auth';

import VueToast from 'vue-toast-notification';

// TODO: the latest version only supports vue3 but may have better features
import VueEasyLightbox from 'vue-easy-lightbox';

import VueVirtualScroller from './lib/vue-virtual-scroller';

// Default app starting point
import App from './App.vue';

// Import toast
import 'vue-toast-notification/dist/theme-sugar.css';
import '@/assets/css/toast.css';

Vue.use(PortalVue);

Vue.use(vueCountryRegionSelect);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueMoment, { moment });
Vue.use(VEmojiPicker);
Vue.use(VueApollo);
Vue.use(VueObserveVisibility);
Vue.use(VueToast);
Vue.use(VueEasyLightbox);
Vue.use(VueCarousel);
Vue.use(VueChatScroll);
Vue.use(VueVirtualScroller);
Vue.use(VueGtag, {
  appName: "ch-mobile",
  config: {
    id: "G-Q0014B3L6M",
    params: { send_page_view: true }
  }
}, router);

// Production environment Base URL
// Vue.$http.defaults.baseURL = 'https://api.clouthub.com';

// Dev environment base url
Vue.$http.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// Add authorization header to all the axios request
if (localStorage.access_token) {
  Vue.$http.defaults.headers.common.Authorization = `Bearer ${localStorage.access_token}`;
}

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.directive('router-link', RouterLink);
Vue.use(Ionic, { mode: 'ios' });
Vue.filter('cdn', (value) => {
  if (!value) return value;
  return $cdn.optimize(value);
});

Vue.prototype.$amplitude = $amplitude.init();

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
setInterval(() => {
  $auth.getUserId();
}, 60 * 1000);

// Cordova - hide splashscreen on start
// Cordova specific code (only runs in installed apps)

async function checkForUpdates() {
  const updateAvailable = await cordova.plugins.pushUpdate.isUpdateAvailable();
  if (updateAvailable) {
    if (confirm('A CloutHub update is available. Install now?')) {
      window.location.href = '/';
    }
  }
}

document.addEventListener('deviceready', async () => {
  navigator.splashscreen && navigator.splashscreen.hide();

  // // Check for application updates
  document.addEventListener('resume', checkForUpdates);
  // setInterval(checkForUpdates, 60 * 1000);

  // Set keyboard settings
  try {
    if (window.cordova && window.Keyboard) {
      // window.Keyboard.hideKeyboardAccessoryBar(false);
    }
  } catch (err) {}

  // Set Status Bar settings
  try {
    if (window.cordova && window.StatusBar) {
      // StatusBar.styleDefault();
      window.StatusBar.overlaysWebView(false);
      window.StatusBar.backgroundColorByHexString('#FFFFFF');
      window.StatusBar.styleDefault();
    }
    if (window.cordova && window.Keyboard) {
      window.Keyboard.hideFormAccessoryBar(false);
      console.log('Keyboard Hide Form Accessory Bar Set');
    }
  } catch (err) {
    console.error('Status Bar Error', err);
  }

  try {
    window.screen &&
      window.screen.orientation &&
      window.screen.orientation.lock &&
      window.screen.orientation.lock('portrait');
  } catch (err) {
    console.error('Screen Orientation Error', err);
  }
});

moment.tz.setDefault(moment.tz.guess());
moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
});

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
