<template>
  <div>
    <action-sheet :open="showModal" @close="close()">
      <div class="ch-share__actionsheet">
        <div class="ch-share-modal__header">
          <span>Share Event</span>
          <i class="cs-icons-close" @click="close()" />
        </div>
        <div class="ch-share-modal__body">
          <app-spinner v-if="!link" />
          <div v-if="link" class="ch-share-btns">
            <div class="ch-share-external">
              <div class="ch-share-btn internal" @click="sharePost()">
                <i class="ch-icons-forum"></i>
              </div>
              <div class="ch-share-external-label">CloutHub Forum</div>
            </div>
            <div class="ch-share-external">
              <div class="ch-share-btn internal" @click="shareMessage()">
                <i class="ch-icons-message"></i>
              </div>
              <div class="ch-share-external-label">CloutHub Message</div>
            </div>
            <div v-if="!isCordova" class="ch-share-external">
              <a
                class="ch-share-btn"
                :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
                target="_blank"
                @click="close()"
              >
                <i class="cs-icons-facebook"></i>
              </a>
              <div class="ch-share-external-label">Facebook</div>
            </div>
            <div v-if="!isCordova" class="ch-share-external">
              <a
                class="ch-share-btn"
                :href="`https://twitter.com/intent/tweet?url=${url}&text=`"
                target="_blank"
                @click="close()"
              >
                <i class="cs-icons-twitter"></i>
              </a>
              <div class="ch-share-external-label">Twitter</div>
            </div>
            <div v-if="!isCordova" class="ch-share-external">
              <a
                class="ch-share-btn"
                :href="`mailto:info@example.com?&subject=&cc=&bcc=&body=${url}`"
                target="_blank"
                @click="close()"
              >
                <i class="cs-icons-email"></i>
              </a>
              <div class="ch-share-external-label">Email</div>
            </div>
            <div v-if="!isCordova" class="ch-share-external">
              <div class="ch-share-btn" @click="copyLink()">
                <i class="ch-icons-clone"></i>
              </div>
              <div class="ch-share-external-label">Copy Link</div>
            </div>
            <div v-if="isCordova" class="ch-share-external">
              <div class="ch-share-btn" @click="shareCordova()">
                <i class="ch-icons-clone"></i>
              </div>
              <div class="ch-share-external-label">Other</div>
            </div>
          </div>
        </div>
      </div>
    </action-sheet>
    <app-new-message-modal :is-meeting="showMsgModal" :meeting-link="link" @close-share="close" />
    <app-share-post-modal
      :is-meeting="showPostModal"
      :post-link="meetingPost"
      @close-share-post="close"
    />
  </div>
</template>

<script>
import ActionSheet from '@/components/general/ActionSheet.vue';
import AppNewMessageModal from '@/components/conversations/NewMessageModal.vue';
import AppSharePostModal from '@/components/post/modals/SharePostModal.vue';
import AppSpinner from '@/components/general/Spinner.vue';
// import { FirebaseDynamicLinks } from 'firebase-dynamic-links';

export default {
  components: {
    ActionSheet,
    AppSpinner,
    AppNewMessageModal,
    AppSharePostModal,
  },
  props: {
    showModal: Boolean,
    title: String,
    meeting: Object,
  },
  data() {
    return {
      link: null,
      showMsgModal: false,
      showPostModal: false,
      meetingPost: null,
    };
  },
  computed: {
    url() {
      return this.link;
    },
    isCordova() {
      return !!window.cordova;
    },
  },
  mounted() {
    console.log('Share', this.meeting);
    // const metadata = {
    //   id: this.meeting.id,
    //   title: this.meeting.name,
    //   description: this.meeting.description,
    //   picture: this.meeting.picture,
    //   link: `${process.env.VUE_APP_SHARE_DOMAIN}/meetings/${this.meeting.slug || this.meeting.id}`,
    // };
    // this.createLink(metadata);
      this.link = `${process.env.VUE_APP_SHARE_DOMAIN}/m/${this.meeting.slug || this.meeting.id}`;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    shareMessage() {
      this.showMsgModal = true;
    },
    sharePost() {
      this.meetingPost = {
        linkPreview: {
          url: this.link,
          originalLink: this.link,
          title: this.meeting.itemTitle,
          details: this.meeting.itemDescription,
          image: this.meeting.itemPicture,
        },
      };
      this.showPostModal = true;
    },
    async copyLink() {
      await navigator.clipboard.writeText(this.link);
      this.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
      this.close();
    },
    async shareCordova() {
      window.plugins.socialsharing.shareWithOptions({ url: this.link }, console.log, console.log);
      this.close();
    },
    async createLink(metadata) {
      this.link = `${process.env.VUE_APP_SHARE_DOMAIN}/m/${metadata.slug || metadata.id}`;
    },
  },
};
</script>

<style scoped>
.ch-share__actionsheet {
  display: flex;
  flex-direction: column;
}
.ch-share-modal__header {
  display: flex;
  padding-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-size: 700;
}
.ch-share-modal__body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ch-share-btns {
  text-align: center;
}
.ch-share-external {
  text-align: center;
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-bottom: 20px;
}
.ch-share-btn {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: var(--cs-gray-03);
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.ch-share-external-label {
  font-size: 14px;
  margin-top: 5px;
}
.ch-share-btn.internal {
  background-color: var(--cs-primary-base);
}
</style>
