<template>
  <div class="home-group-search">
    <app-text-input
      v-model="search"
      class="home-group-search-input"
      placeholder="Search Groups"
      @input="$emit('searching', !!$event)"
    />

    <app-spinner v-if="$apollo.queries.groups.loading" />
    <template v-else-if="showSearchResults">
      <ion-list v-if="groups && groups.length" class="home-group-search-list">
        <app-group-list-item
          v-for="group in groups"
          :key="group.id"
          :group="group"
        />
      </ion-list>
      <div v-else>No Results</div>
    </template>
    <div v-else-if="!!search">Type at least three characters</div>
  </div>
</template>

<script>
import GroupSearch from '@/gql/search/GroupSearch.gql';
import AppTextInput from '@/components/search/TextInput.vue';
import AppGroupListItem from '@/components/groups/GroupListItem.vue';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  components: {
    AppTextInput,
    AppGroupListItem,
    AppSpinner,
  },
  props: {
    searchQuery: {
      type: Object,
      required: true,
      default: () => GroupSearch,
    },
  },
  apollo: {
    groups: {
      query() {
        return this.searchQuery;
      },
      variables() {
        return {
          query: this.search,
        };
      },
      skip() {
        return !this.showSearchResults;
      },
      throttle: 500,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    showSearchResults() {
      return this.search && this.search.length >= 3;
    },
  },
  mounted() {
    this.$emit('searching', !!this.search);
  },
};
</script>

<style scoped>
.home-group-search {
  padding: 15px 15px 0;
}

.home-group-search-list {
  margin: 0 -16px;
}

.home-group-search-input {
  margin-bottom: 10px;
}
</style>
