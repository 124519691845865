<template>
  <div class="ch-video-profile-header">
    <div class="ch-video-profile-header__banner">
      <app-img
        class="ch-video-profile-header__banner--img"
        :src="displayCover"
      />
    </div>
    <div class="ch-video-profile-header__info">
      <app-avatar size="medium" :picture="displayPicture" :name="channelName" />
      <div class="ch-video-profile-header__info--meta">
        <app-expandable-text
          class="cs-textstyle-item-heading description"
          :content="channelName"
          placeholder="Untitled Channel"
        />
        <!-- <div class="cs-textstyle-item-heading description">
          <span>
            {{ displayedText }}
          </span>
          <span
            v-if="user.channelName.length > 35 || user.displayname.length > 35"
            class="see-more-button"
            @click="showFullText = !showFullText"
          >
            ...{{ readMoreText }}
          </span>
        </div> -->
        <div class="cs-textstyle-paragraph-small">
          {{ user.subscribersCount }} {{ subscribersLabel }}
        </div>
      </div>
      <div class="header-icons">
        <i class="ch-icons-share" @click.stop="shareChannel"></i>
        <cs-button
          size="small"
          fill="clear"
          icon="ch-icons-rss"
          class="no-padding"
          :href="`${process.env.VUE_APP_GRAPH_ENDPOINT}/rss/channels/`" + user.id
          target="blank"
          variant="secondary"
        ></cs-button>
        <i
          v-if="isChannelView && !!isLogged"
          class="cs-icons-settings"
          @click="$emit('edit')"
        ></i>
        <i
          v-if="!isLogged"
          class="ch-icons-options"
          @click="openChannelOptionSheet"
        ></i>
      </div>
    </div>
    <div
      class="ch-video-profile-header__info--btn"
      :class="[
        {
          leftChannel: !user.channelButtonLink && !user.channelButtonLink2,
        },
        { ownerChannel: isLogged && !user.channelButtonLink2 },
      ]"
    >
      <app-channel-subscribe-button
        v-if="!isLogged"
        :target="user"
        size="small"
      />
      <cs-button
        v-if="user.channelButtonLabel && user.channelButtonLink"
        size="small"
        class="ch-buttons channel-button-link"
        @click="openUrl(user.channelButtonLink)"
        >{{ user.channelButtonLabel }}</cs-button
      >
      <cs-button
        v-if="user.channelButtonLabel2 && user.channelButtonLink2"
        size="small"
        class="ch-buttons channel-button-link"
        @click="openUrl(user.channelButtonLink2)"
        >{{ user.channelButtonLabel2 }}</cs-button
      >
    </div>

    <app-action-sheet
      :open="showChannelOptionSheet"
      @close="closeChannelOptionSheet"
    >
      <app-action-sheet-button @click="blockUser"
        >Block <app-username :user="user"
      /></app-action-sheet-button>
      <app-action-sheet-button @click="report"
        >Report <app-username :user="user"
      /></app-action-sheet-button>
    </app-action-sheet>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $cdn from '@/services/cdn';
import $browser from '@/services/browser';

// Component
import AppImg from '@/components/general/Img.vue';
import AppUsername from '@/components/general/Username.vue';
// import AppUploadVideo from '@/components/videos/UploadVideo.vue';
import AppChannelSubscribeButton from '@/components/general/ChannelSubscribeButton.vue';
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
// import AppLivestreamButton from '@/components/videos/LivestreamButton.vue';
import AppExpandableText from '@/components/general/ExpandableText.vue';

const placeholderImg = require('@/assets/images/profile-banner.png');

export default {
  components: {
    AppImg,
    AppChannelSubscribeButton,
    AppUsername,
    AppActionSheet,
    AppActionSheetButton,
    // AppUploadVideo,
    // AppLivestreamButton,
    AppAvatar,
    AppExpandableText,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showChannelOptionSheet: false,
      placeholderImg,
      showFullText: false,
    };
  },
  computed: {
    channelName() {
      return this.user.channelName || `${this.user.displayname}'s Channel`;
    },
    isLogged() {
      return this.user.id === $auth.getUserId();
    },
    subscribersLabel() {
      if (this.user.subscribersCount === 1) return 'subscriber';
      return 'subscribers';
    },
    displayCover() {
      if (this.user.channelCover) {
        return $cdn.optimize(this.user.channelCover);
      }
      return this.placeholderImg;
    },
    displayPicture() {
      return this.user.channelPicture || this.user.profilePhotoURL;
    },
    isChannelView() {
      return this.$router.currentRoute.name === 'VideoProfile';
    },
    enableLiveStream() {
      return this.isChannelView && this.user.liveStreamUrl;
    },
    displayedText() {
      if (!this.showFullText) {
        return (
          this.user.channelName.slice(0, 34) ||
          this.user.displayname.slice(0, 34)
        );
      }
      return this.user.channelName || this.user.displayname;
    },
    readMoreText() {
      return this.showFullText ? 'see less' : 'see more';
    },
  },
  methods: {
    shareChannel() {
      const link = `/c/${this.user.channelSlug || this.user.id}`;
      $bus.$emit('show-share-sheet', {
        title: 'Share Channel',
        link,
      });
    },
    // ActionSheet
    closeChannelOptionSheet() {
      this.showChannelOptionSheet = false;
    },
    openChannelOptionSheet() {
      this.showChannelOptionSheet = true;
    },
    // Report
    report() {
      const data = {
        report: this.user,
        reportType: 'user',
      };
      $bus.$emit('show-report-action', data);
    },
    // Block user
    blockUser() {
      $bus.$emit('show-block-user', this.user);
    },
    openUrl(url) {
      const prefix = url.startsWith('http');
      const modifiedLink = prefix ? url : 'http://'.concat(url);
      $browser.open(modifiedLink);
    },
  },
};
</script>

<style scoped>
.ch-video-profile-header {
  background: var(--cs-gray-00);
}

.ch-video-profile-header__banner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(100% * (1 / 5.75));
}

.ch-video-profile-header__banner--img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ch-video-profile-header__info {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;
}
.ch-video-profile-header__info--avatar {
  border: solid 4px var(--cs-gray-00);
  border-radius: 50%;
  cursor: pointer;
}
.square-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.ch-video-profile-header__info--meta {
  flex: 1;
}
.ch-video-profile-header__info--btn {
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
  margin-top: 5px;
  justify-content: space-around;
  gap: 10px;
}
.ch-buttons {
  --cs-button-padding: 6px 5px !important;
  min-width: 110px;
  width: 110px;
}
.channel-button-link >>> .cs-button__content {
  display: block;
  white-space: nowrap;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
}

* >>> .follow-button .cs-button {
  padding: 8px;
  width: 100%;
  width: 100px;
}
.description {
  word-break: break-word;
}
* >>> .subscribe-button .cs-button {
  width: 100px;
  min-width: 100px;
}
.leftChannel {
  display: flex;
  justify-content: flex-start;
  padding: 0px 15px;
  gap: 20px;
}
.ownerChannel {
  display: flex;
  justify-content: flex-start;
  padding: 0px 15px;
}
.see-more-button {
  color: var(--cs-gray-05);
  font-weight: 500;
  font-size: 20px;
}
.header-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.no-padding {
  padding: 0px !important;
}
</style>
