<template>
  <div class="ch-video-list">
    <cs-spinner v-if="!videos" class="ch-spinner" />
    <div v-if="videos" class="ch-video-list__main">
      <div
        v-for="(video, index) in safeVideos"
        :key="video.id + index"
        class="ch-video-list__main--wrapper"
      >
        <app-video-item :video="video" direction="column"></app-video-item>
      </div>
      <cs-empty-state
        v-if="videos && !videos.length"
        title="No Matching Videos"
        class="ch-video-list__empty cs-textstyle-detail-heading"
      ></cs-empty-state>
    </div>
  </div>
</template>

<script>
import AppVideoItem from '@/components/videos/PublicVideoItem.vue';

export default {
  components: {
    AppVideoItem,
  },
  props: {
    videos: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    safeVideos() {
      return this.videos && this.videos.filter((video) => video.safe) || [];
    },
  },
};
</script>

<style scoped>
.ch-video-list__main--wrapper:first-child {
  padding: 0px !important;
}
.ch-video-list__main--wrapper {
  width: 100%;
  margin-top: 10px;
}
.ch-video-list__empty {
  border: dashed 1px var(--cs-gray-02);
  border-radius: 10px;
  margin-top: 10px;
  flex: 1;
}
* >>> .cs-textstyle-page-title {
  font-size: 16px !important;
}
</style>
