<template>
  <div class="ch-profile-tab-posts">
    <div class="ch-mutual ch-connection-box">
      <div class="title cs-textstyle-paragraph-bold">Mutual Interests</div>
      <div class="ch-mutual-list-box">
        <div class="interest">
          Local Interests:
          <span
            v-if="
              !user.mutual_local_interests ||
              !user.mutual_local_interests.length
            "
            class="cs-gray-04"
          >
            Nothing in common
          </span>
          <cs-tag
            v-for="(interest, $index) in user.mutual_local_interests"
            :key="interest + $index"
          >
            <span>{{ interest }}</span>
          </cs-tag>
        </div>
        <div class="interest">
          National Interests:
          <span
            v-if="
              !user.mutual_national_interests ||
              !user.mutual_national_interests.length
            "
            class="cs-gray-04"
          >
            Nothing in common
          </span>
          <cs-tag
            v-for="(interest, $index) in user.mutual_national_interests"
            :key="interest + $index"
          >
            <span>{{ interest }}</span>
          </cs-tag>
        </div>
      </div>
    </div>
    <div class="ch-mutual ch-connection-box">
      <div class="title cs-textstyle-paragraph-bold">You both follow</div>
      <div class="ch-mutual-list-box">
        <app-user-list :users="user.mutual_followers"></app-user-list>
      </div>
    </div>

    <div class="ch-mutual">
      <div class="title cs-textstyle-paragraph-bold">Follow you both</div>
      <div class="ch-mutual-list-box">
        <app-user-list :users="user.mutual_following"></app-user-list>
      </div>
    </div>

    <div class="ch-mutual ch-connection-box">
      <div class="title cs-textstyle-paragraph-bold">Mutual Friends</div>
      <div class="ch-mutual-list-box">
        <app-user-list :users="user.mutual_friends"></app-user-list>
      </div>
    </div>
  </div>
</template>

<script>
import AppUserList from '@/components/general/UserList.vue';

export default {
  components: {
    AppUserList,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  padding-top: 11px;
}
.ch-mutual-list-box {
  max-height: 400px;
  overflow: scroll;
  min-height: 50px;
}
.interest {
  padding: 30px;
}
.ch-mutual {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 100%;
  overflow: auto;
  gap: 2px;
  margin: 10px;
  background: var(--cs-gray-00);
  border-top: 1px solid var(--cs-gray-02);
}
</style>
