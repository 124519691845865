<template>
  <ion-page>
    <ion-content>
      <div class="ch-set-username">
        <div class="ch-set-username__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <app-register-subheader
          title="Create your Account"
          description="Enter your information below to create your account."
        />
        <app-register-form>
          <div>
            <cs-input
              v-model="username"
              label="Username *"
              type="text"
              placeholder="Enter a username for yourself"
              :disabled="verifying"
              @input="checkUsernameStatus"
            />
            <app-username-validator
              v-model="validUsername"
              :username="username"
              :alreadyuse="alreadyUse"
            />
          </div>
          <cs-spinner v-if="loading" size="medium" />
          <div v-if="!loading">
            <cs-button
              block
              corners="round"
              size="large"
              :disabled="!validUsername || alreadyUse"
              @click="next"
            >
              Continue
            </cs-button>
          </div>
          <div v-if="!isMeeting" class="ch-username__back">
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" @click="previous"
                >Back</cs-button
              >
            </div>
            <div class="cs-textstyle-paragraph">
              <cs-button block fill="clear" size="large" to="/login"
                >Back to Login</cs-button
              >
            </div>
          </div>
        </app-register-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import CheckUsername from '@/gql/onboarding/CheckUsername.gql';
import AppRegisterForm from '@/components/register/Form.vue';
import AppRegisterSubheader from '@/components/register/Subheader.vue';
import AppUsernameValidator from '@/components/general/UsernameValidator.vue';

export default {
  components: {
    AppRegisterSubheader,
    AppRegisterForm,
    AppUsernameValidator,
  },
  props: {
    draftUser: {
      type: Object,
      required: true,
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayName: '',
      username: '',
      verifying: false,
      alreadyUse: false,
      errorMessage: '',
      validUsername: false,
      loading: false,
    };
  },
  created() {
    this.username = this.draftUser.username ? this.draftUser.username : '';
    this.displayName = this.draftUser.displayname
      ? this.draftUser.displayname
      : '';
  },
  methods: {
    previous() {
      this.$emit('previous');
    },
    checkUsernameStatus() {
      if (this.alreadyUse) {
        this.alreadyUse = false;
      }
    },
    async next() {
      this.alreadyUse = false;
      this.verifying = true;
      const username =
        this.username.charAt(0) === '@'
          ? `${this.username}`
          : `@${this.username}`;
      try {
        this.loading = true;
        const results = await this.$apollo.query({
          query: CheckUsername,
          variables: {
            username,
          },
        });
        this.verifying = false;
        this.loading = false;
        if (results.data.check_username_availability) {
          const finalUserName =
            this.username.charAt(0) === '@'
              ? this.username.substring(1)
              : this.username;

          this.$emit('next', { username, displayname: finalUserName });
        } else {
          this.errorMessage = 'Username already in use';
          this.alreadyUse = true;
        }
      } catch (err) {
        this.verifying = false;
        this.loading = false;
        this.errorMessage = err.message;
        this.alreadyUse = true;
      }
    },
  },
};
</script>

<style scoped>
.ch-set-username {
  padding: 20px;
  background: var(--cs-gray-01);
}
.ch-set-username__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.cs-spinner {
  min-height: 45px;
}
.cs-spinner >>> .cs-spinner__dots {
  margin: 0px auto !important;
}
.ch-username__back {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
}
</style>
