<template>
  <div class="meeting-setting-menu">
    <app-action-sheet
        :open="showMeetingOptions"
        @close="$emit('close')"
    >
      <app-action-sheet-button @click="deleteMeeting()">
        {{ isSuperAdmin ? '[SA]' : '' }} {{ deleteOrCancel }}
      </app-action-sheet-button>
      <app-action-sheet-button @click="edit">
        Edit Meeting
      </app-action-sheet-button>
      <app-action-sheet-button v-if="canDownloadRec" @click="downloadMeeting">
        Download Meeting
      </app-action-sheet-button>
    </app-action-sheet>
    <app-edit-meeting-modal
      v-if="showEditModal"
      :original-meeting="editableMeeting"
      :show-meeting-edit="showEditModal"
      @close-edit="closeEdit"
    />
  </div>
</template>

<script>
import $auth from '@/services/auth';
import $popups from '@/services/popups';
import $meetingsApi from '@/api/meetings';
import $socket from '@/services/socket';

import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppActionSheetButton from '@/components/general/ActionSheetButton.vue';
import AppMeetingShareSheet from '@/components/meetings/MeetingShareSheet.vue';
import AppEditMeetingModal from '@/components/meetings/EditMeetingModal.vue';

import DeleteMeeting from '@/gql/meetings/DeleteMeeting.gql';
import EndMeeting from '@/gql/meetings/EndMeeting.gql';

export default {
  components: {
    AppActionSheet,
    AppActionSheetButton,
    AppMeetingShareSheet,
    AppEditMeetingModal,
  },
  props: {
    meeting: Object,
    showMeetingOptions: {
      type: Boolean,
      value: false,
    },
    canOpenSettings: Boolean,
  },
  data() {
    return {
      editableMeeting: null,
      showEditModal: false,
    };
  },
  computed: {
    role() {
      return (
          this.meeting &&
          this.meeting.myMembership &&
          this.meeting.myMembership.role
      );
    },
    isSuperAdmin() {
      return $auth.isAdmin();
    },
    isScheduled() {
      return new Date(this.meeting.startTime).getTime() > new Date().getTime();
    },
    canModerate() {
      return this.role === 'ADMIN' || this.role === 'MODERATOR';
    },
    canDownloadRec() {
      return !this.isScheduled && this.canModerate && this.meeting.recordingUrlMp4 !== null
    },
    deleteOrCancel() {
      const { status } = this.meeting;
      return status === 'NOT_STARTED' || status === 'LIVE' ? 'Cancel Event' : 'Delete Event';
    },
  },
  methods: {
    edit() {
      this.editableMeeting = { ...this.meeting };
      this.showEditModal = true;
    },
    closeEdit() {
      this.showEditModal = false;
    },
    async deleteMeeting() {
      const confirmDelete = await $popups.confirmDelete({
        title: 'Cancel Event',
        message:
            "Are you sure you wish to cancel this event? This can't be undone.",
      });
      if (!confirmDelete) return;
      await this.$apollo.mutate({
        mutation: DeleteMeeting,
        variables: {
          id: this.meeting.id,
        },
        update: (cache) => {
          const normalizedId = cache.identify(this.meeting);
          if (normalizedId) {
            cache.evict({ id: normalizedId });
            cache.gc();
            this.$router.push({ name: 'MeetingHome' });
          }
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-deleted');
    },
    async startMeeting() {
      try {
        await $meetingsApi.startMeeting(this.meeting.id);
        $socket.sendRoom('meeting', this.meeting.id, 'meeting-status-changed');
      } catch (e) {
        $popups.alert(
            'There was an error starting.  Please try again or contact support.'
        );
        throw e;
      }
    },
    async endMeeting() {
      await this.$apollo.mutate({
        mutation: EndMeeting,
        variables: {
          meetingId: this.meeting.id,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-status-changed');
      this.$toast.open({
        message:
            'This meeting has ended. You will be able to watch the video again shortly',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
      this.$router.push({ name: 'MeetingHome' });
    },
    downloadMeeting() {
      const url = this.meeting.recordingUrlMp4;
      if (window.plugins && window.plugins.socialsharing) {
        window.plugins.socialsharing.shareWithOptions(
            {
              files: [url],
            },
            console.log,
            console.log
        );
      } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = true;
        a.click();
      }
    },
  },
};
</script>
