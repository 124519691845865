<template>
<div>
  <app-modal :show="show" @close="closeInviteModal" title="Invite Members">
    <div class="ch-edit-meeting__fields">
      <cs-page-tabs
        :key="'personal-conenctions-' + Math.random()"
        align="justify"
        class="ch-home__nav-page-tabs"
        :tabs="tabs"
        :value="selectedTab"
        @input="selectTab"
      >
      </cs-page-tabs>
      <app-meeting-tab-friends v-if="selectedView == 'friends'" :meeting="meeting" />
      <app-meeting-tab-followers v-if="selectedView == 'followers'" :meeting="meeting" />
      <app-meeting-tab-groups v-if="selectedView == 'groups'" :meeting="meeting" />
      <app-meeting-tab-all-users v-if="selectedView == 'all-users'" :meeting="meeting" />
    </div>
  </app-modal>
</div>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import AppMeetingTabFriends from '@/components/meetings/tabs/MeetingTabFriends.vue';
import AppMeetingTabFollowers from '@/components/meetings/tabs/MeetingTabFollowers.vue';
import AppMeetingTabGroups from '@/components/meetings/tabs/MeetingTabGroups.vue';
import AppMeetingTabAllUsers from '@/components/meetings/tabs/MeetingTabAllUsers.vue';

export default {
  components: {
    AppModal,
    AppMeetingTabFriends,
    AppMeetingTabFollowers,
    AppMeetingTabGroups,
    AppMeetingTabAllUsers,
  },
  props: {
    show: Boolean,
    meeting: Object,
  },
  data() {
    return {
      selectedView: 'friends',
      tabs: [
        {
          label: 'Friends',
          key: 'friends',
          view: 'ProfileFriends',
        },
        {
          label: 'Followers',
          key: 'followers',
          view: 'ProfileFollowers',
        },
        {
          label: 'My Groups',
          key: 'groups',
          view: 'Groups',
        },
        {
          label: 'All Users',
          key: 'all-users',
          view: 'All Users',
        },
      ],
    };
  },
  methods: {
    closeInviteModal() {
      this.$emit('close');
    },
    selectTab(index) {
      const tab = this.tabs[index];
      this.selectedView = tab.key;
    },
  },
  computed: {
    selectedTab() {
      return this.tabs.indexOf(this.tabs.find((tab) => tab.key === this.selectedView));
    },
  },
};
</script>

<style scoped>
* >>> .ch-modal__body {
  padding: 0px 0px !important;
}
</style>
