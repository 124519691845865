<template>
  <div class="ch-meeting-segment">
    <app-meeting-list :meetings="displayMeetings" direction="column"> </app-meeting-list>
  </div>
</template>

<script>
import AppMeetingList from '@/components/meetings/MeetingList.vue';

export default {
  components: {
    AppMeetingList,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Untitled Segment',
    },
    query: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
    },
  },
  apollo: {
    meetings: {
      query() {
        return this.query;
      },
    },
  },
  computed: {
    displayMeetings() {
      return (
        this.meetings &&
        this.meetings.filter(
          (meeting) =>
            meeting.privacy === 'public' || (meeting.privacy === 'private' && meeting.myMembership)
        )
      );
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.meetings.refetch();
      return true;
    },
  },
};
</script>

<style scoped>
.ch-meeting-segment__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ch-meeting-segment__header--title {
  padding: 0 15px;
}
.ch-meeting-segment__header--btn {
  --cs-button-padding: 0px 15px;
}
.ch-icons {
  margin-left: 5px;
}
</style>
