<template>
  <ion-col class="subgroup-item">
    <!-- Subgroup card item -->
    <div
      v-router-link="{ name: 'GroupDetail', params: group }"
      class="subgroup-item__content"
    >
      <div class="subgroup-item__cover">
        <app-img :src="groupPicture" class="subgroup-item__img" />
      </div>

      <div class="subgroup-item__body">
        <div>
          <div class="subgroup-item__name cs-textstyle-paragraph-bold">
            <i
              v-if="group.privacy !== 'public'"
              class="cs-icons-lock subgroup-item__lock-icon"
            ></i>
            {{ group.name }}
          </div>
          <div class="subgroup-item__summary cs-textstyle-paragraph-small">
            <span>{{ group.memberCount }} {{ membersLabel }}</span>
          </div>
        </div>

        <!-- Join group button -->
        <app-group-join-button :group="group" />
      </div>
    </div>
  </ion-col>
</template>

<script>
// General components
import AppImg from '@/components/general/Img.vue';

// Group common components
import AppGroupJoinButton from '@/components/groups/GroupJoinButton.vue';

const defaultPictureUrl = require('@/assets/images/placeholders/group-picture.png');

export default {
  components: {
    AppImg,
    AppGroupJoinButton,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    groupPicture() {
      return this.group.picture ? this.group.picture : defaultPictureUrl;
    },
    membersLabel() {
      if (this.group.memberCount === 1) return 'member';
      return 'members';
    },
  },
};
</script>

<style scoped>
.subgroup-item {
  padding-bottom: 10px;
}
.subgroup-item__content {
  overflow: hidden;
  display: flex;
  border: solid 1px var(--cs-gray-02);
  background-color: var(--cs-gray-00);
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.02);
}
.subgroup-item__cover {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 10rem;
  height: 132px;
  overflow: hidden;
}
.subgroup-item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subgroup-item__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  text-align: left;
}
.subgroup-item__lock-icon {
  color: var(--cs-primary-base);
}
.subgroup-item__name {
  color: var(--cs-gray-07);
  font-size: var(--cs-font-size-300);
  word-wrap: break-word;
}
.subgroup-item__summary {
  margin-top: 4px;
  color: var(--cs-gray-04);
}
</style>
