<template>
  <ion-page>
    <ion-header>
      <app-category-header title="Connections" />
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div class="">
        <div class="mb-4 cs-textstyle-section-heading capitalize">
          Badge: {{ badge }}
        </div>
        <app-spinner v-if="!people" />
        <div v-if="people">
          <app-user-card
            v-for="user in people"
            :key="user.id"
            :user="user"
            item-class="col-md-2"
          />
        </div>
      </div>
      <!-- Floating Button -->
      <app-connections-explore-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import AppSpinner from '@/components/general/Spinner.vue';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppConnectionsExploreBtn from '@/components/connections/ConnectionsExploreBtn.vue';
import AppUserCard from '@/components/connections/UserCard.vue';
// GQL
import ListUsersByBadgeType from '@/gql/connections/ListUsersByBadgeType.gql';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppConnectionsExploreBtn,
    AppUserCard,
    AppSpinner,
  },
  data() {
    return {
      badge: this.$route.params.badge,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.badge = this.$route.params.badge;
      }
    },
  },
  apollo: {
    people: {
      query: ListUsersByBadgeType,
      variables() {
        return {
          badgeType: this.badge,
        };
      },
    },
  },
  methods: {
    async refresh($event) {
      await this.$apollo.queries.people.refetch();
      $event.target.complete();
    },
  },
};
</script>

<style scoped>
.capitalize {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
