<template>
  <ion-page>
    <ion-content>
      <div class="ch-report">
        <div class="ch-report__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <ch-register-subheader
          title="Report Error"
          description="Please provide the information below."
        />
        <ch-register-form>
          <cs-input v-model="username" label="Username" type="text" placeholder="Username" />
          <cs-input v-model="phoneno" label="Phone Number" type="tel" placeholder="Phone Number" />
          <cs-input v-model="email" label="Email" type="text" placeholder="Email Address" />
          <app-textarea
            v-model="description"
            label="Description"
            placeholder="Description"
            resize="auto"
          />
          <cs-button block size="large" corners="round"> Continue </cs-button>
          <cs-button block fill="clear" size="large" to="/login">Back to Login</cs-button>
        </ch-register-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppTextarea from '@/components/general/Textarea.vue';
import ChRegisterSubheader from '@/components/register/Subheader.vue';
import ChRegisterForm from '@/components/register/Form.vue';

export default {
  components: {
    AppTextarea,
    ChRegisterSubheader,
    ChRegisterForm,
  },
  data() {
    return {
      email: '',
      phoneno: '',
      username: '',
      description: '',
    };
  },
};
</script>

<style scoped>
.ch-report {
  padding: 20px;
  background: var(--cs-gray-01);
}
.ch-report__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
</style>
