<template>
  <div class="ch-reaction-list-popover">
    <app-reaction-list :post="post" @closeThis="close" />
  </div>
</template>

<script>
import AppReactionList from '@/components/post/items/ReactionList.vue';

export default {
  components: {
    AppReactionList,
  },
  props: {
    // NOTE: This property has a value set by IonicPopover.
    // So it cannot be "required" because the initial value is undefined
    // And it cannot have a "type" because the initial value is undefined
    // eslint-disable-next-line
    post: {},
  },
  methods: {
    close() {
      this.$ionic.popoverController.dismiss();
    },
  },
};
</script>

<style scoped>
.ch-reaction-list-popover {
  padding: 10px;
  padding-top: 5px;
}
</style>
