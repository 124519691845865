<template>
  <div>
    <app-name-form
      v-if="showNameForm"
      :is-meeting="isMeeting"
      :draft-user="draftUser"
      @next="displayEmailForm"
    />
    <app-email-form
      v-if="showEmailForm"
      :draft-user="draftUser"
      :is-meeting="isMeeting"
      @next="displayUsernameForm"
      @previous="displayNameForm"
    />
    <app-username-form
      v-if="showUsernameForm"
      :draft-user="draftUser"
      :is-meeting="isMeeting"
      @next="displayPasswordForm"
      @previous="displayBackEmailForm"
    />
    <app-password-form
      v-if="showPasswordForm"
      :is-meeting="isMeeting"
      @next="displayFinalForm"
      @previous="displayBackUserNameForm"
    />
    <app-final-form
      v-if="showFinalForm"
      :is-meeting="isMeeting"
      :draft-user="draftUser"
      @done="finishFinalForm"
    />
  </div>
</template>

<script>
import AppNameForm from '@/components/onboarding/NameForm.vue';
import AppEmailForm from '@/components/onboarding/EmailForm.vue';
import AppPasswordForm from '@/components/onboarding/PasswordForm.vue';
import AppUsernameForm from '@/components/onboarding/UsernameForm.vue';
import AppFinalForm from '@/components/onboarding/FinalForm.vue';

export default {
  components: {
    AppPasswordForm,
    AppNameForm,
    AppUsernameForm,
    AppEmailForm,
    AppFinalForm,
  },
  props: {
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNameForm: true,
      showEmailForm: false,
      showUsernameForm: false,
      showPasswordForm: false,
      showFinalForm: false,
      draftUser: {},
    };
  },
  methods: {
    async displayEmailForm(val) {
      this.showNameForm = false;
      this.showEmailForm = true;
      this.draftUser.firstname = val.firstname;
      this.draftUser.lastname = val.lastname;
    },
    displayUsernameForm(val) {
      this.showEmailForm = false;
      this.showUsernameForm = true;
      this.draftUser.email = val.email;
    },
    displayPasswordForm(val) {
      this.showUsernameForm = false;
      this.showPasswordForm = true;
      this.draftUser.username = val.username;
      this.draftUser.displayname = val.displayname;
    },
    displayFinalForm(val) {
      this.showPasswordForm = false;
      this.showFinalForm = true;
      this.draftUser.password = val.password;
      this.draftUser.recaptchaToken = val.recaptchaToken;
    },
    displayNameForm() {
      this.showNameForm = true;
      this.showEmailForm = false;
    },
    displayBackEmailForm() {
      this.showEmailForm = true;
      this.showUsernameForm = false;
    },
    displayBackUserNameForm() {
      this.showUsernameForm = true;
      this.showPasswordForm = false;
    },
    finishFinalForm() {
      this.$emit('registered');
    },
  },
};
</script>
