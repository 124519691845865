<template>
  <div>
    <div class="search-header">Events</div>
    <app-spinner v-if="$apollo.queries.meetings.loading" />
    <app-meeting-list
      v-if="meetings"
      :meetings="searchedMeetings"
      direction="column"
    ></app-meeting-list>
    <cs-button
      v-if="meetings && meetings.length"
      block
      fill="outline"
      size="small"
      @click="showAllMeetings = !showAllMeetings"
    >
      {{ showAllMeetings ? 'Less Events' : 'More Events' }}
    </cs-button>
  </div>
</template>

<script>
import AppMeetingList from '@/components/meetings/MeetingList.vue';
import AppSpinner from '@/components/general/Spinner.vue';

import MeetingSearch from '@/gql/search/MeetingSearch.gql';

export default {
  components: {
    AppMeetingList,
    AppSpinner,
  },
  props: {
    search: String,
  },
  apollo: {
    meetings: {
      query: MeetingSearch,
      variables() {
        return {
          query: this.search,
          limit: 3,
        };
      },
      skip() {
        return this.noQuery;
      },
    },
  },
  data() {
    return {
      showAllMeetings: false,
    };
  },
  computed: {
    searchedMeetings() {
      if (!this.meetings || !this.meetings.length) return [];
      if (this.showAllMeetings) return this.meetings;
      return this.meetings.slice(0, 3);
    },
    noQuery() {
      return this.search.trim().length === 0;
    },
  },
};
</script>

<style scoped>
.search-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
