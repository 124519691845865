<template>
  <div class="ch-news-search">
    <div class="ch-news-search__header">
      <cs-search-bar
        placeholder="Search News"
        v-model="query"
        @submit="search"
      />
    </div>
    <div class="ch-news-search__result" v-if="posts">
      <app-news-item :post="post" :key="post.id" v-for="post in posts"/>
    </div>
  </div>
</template>

<script>
import AppNewsItem from '@/components/news/NewsItem.vue';
import NewsArticleSearch from '@/gql/search/NewsArticleSearch.gql';

export default {
  components: {
    AppNewsItem,
  },
  data() {
    return {
      searchQuery: null,
      query: '',
    };
  },
  apollo: {
    posts: {
      query: NewsArticleSearch,
      variables() {
        return {
          query: this.searchQuery,
          type: 'news',
        };
      },
      skip() {
        return !this.searchQuery;
      },
    },
  },
  methods: {
    search() {
      this.searchQuery = this.query;
    },
  },
};
</script>

<style scoped>
.ch-news-search{
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
}
.ch-news-search__header >>> .cs-search-bar{
  background-color: var(--cs-gray-00);
}
.ch-news-search__header >>> .cs-search-bar__input{
  background-color: var(--cs-gray-00);
}
</style>
