<template>
  <div class="meeting-area">
    <div
      v-if="streams.length"
      ref="viewingArea"
      class="meeting-stream-area"
      :style="{ gridTemplateColumns: 'repeat(' + columnCount + ', auto)' }"
    >
      <app-meeting-stream
        v-for="stream in streams"
        :key="stream.uid"
        :stream="stream"
      />
    </div>
    <div v-if="!videoStreams.length" class="placeholder">
      No-one has shared their screen or camera yet.
    </div>
    <div
      v-if="showEnableAudioButton"
      class="meeting-enable-audio-button"
      @click="enableAudio()"
    >
      Tap here to enable audio
    </div>
    <div class="meeting-publish-panel">
      <div v-if="canGoLive">
        <cs-button
          v-if="!publishedStream"
          class="meeting-publish-panel__publish-btn"
          size="small"
          @click="publish()"
        >
          Go Live
        </cs-button>
        <cs-button
          v-if="publishedStream"
          class="meeting-publish-panel__publish-btn"
          size="small"
          fill="outline"
          @click="unpublish()"
        >
          Go Offline
        </cs-button>
      </div>
      <div v-if="canGoLiveViaRTMP">
        <app-agora-rtmp-button
          class="meeting-publish-panel__rtmp-btn"
          :meeting="meeting"
        />
      </div>
      <div v-if="canShareAudio" class="meeting-publish-panel__icons">
        <app-stream-control
          ref="streamControl"
          :can-share-audio="canShareAudio"
          :can-share-video="canShareVideo"
          :video-stream="publishedVideoStream"
          :audio-stream="publishedAudioStream"
          :role="role"
          @audioChange="handleAudioChange"
          @videoChange="handleVideoChange"
        />
      </div>
      <app-meeting-end-button
        v-if="meeting.status === 'LIVE' && isAdmin"
        :meeting="meeting"
      />
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import AgoraRTC from 'agora-rtc-sdk-ng';

import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $socket from '@/services/socket';

import GetAgoraToken from '@/gql/meetings/GetAgoraToken.gql';
import SetMeetingMemberRole from '@/gql/meetings/SetMeetingMemberRole.gql';
import AppStreamControl from '@/components/meetings/publishing/StreamControl.vue';
import AppMeetingStream from '@/components/meetings/MeetingStream.vue';
import AppMeetingEndButton from '@/components/meetings/buttons/MeetingEndButton.vue';
import AppAgoraRtmpButton from '@/components/meetings/buttons/AgoraRTMPButton.vue';

const AGORA_APP_ID = process.env.VUE_APP_AGORA_ID;

export default {
  components: {
    AppMeetingStream,
    AppStreamControl,
    AppMeetingEndButton,
    AppAgoraRtmpButton,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      started: false,
      client: null,
      publishedStream: null,
      publishedVideoStream: null,
      publishedAudioStream: null,
      selectedAudioDevice: null,
      streams: [],
      micTested: false,
      showEnableAudioButton: false,
    };
  },
  computed: {
    role() {
      return (
        this.meeting &&
        this.meeting.myMembership &&
        this.meeting.myMembership.role
      );
    },
    canGoLive() {
      if (this.meeting.liveStreamMode) {
        return (
          ['PRESENTER', 'MODERATOR', 'SPEAKER'].includes(this.role) &&
          !!this.client
        );
      }
      return (
        ['ADMIN', 'PRESENTER', 'MODERATOR', 'SPEAKER'].includes(this.role) &&
        !!this.client
      );
    },
    canGoLiveViaRTMP() {
      return this.meeting.liveStreamMode && this.role === 'ADMIN';
    },
    canShareAudio() {
      const rolesThatCan = [
        'ADMIN',
        'PRESENTER',
        'PRESENTER_QUEUE',
        'MODERATOR',
        'SPEAKER',
        'QUEUE',
        'VIEWER',
      ];
      if (this.meeting.liveStreamMode) {
        return (
          rolesThatCan.filter((r) => r !== 'ADMIN').includes(this.role) &&
          !!this.client
        );
      }
      return rolesThatCan.includes(this.role) && !!this.client;
    },
    canShareVideo() {
      return (
        [
          'ADMIN',
          'PRESENTER',
          'PRESENTER_QUEUE',
          'MODERATOR',
          'SPEAKER',
        ].includes(this.role) && !!this.client
      );
    },
    isAdmin() {
      return this.role === 'ADMIN';
    },
    columnCount() {
      return !this.videoStreams || this.videoStreams.length === 1 ? 1 : 2;
    },
    videoStreams() {
      if (this.streams)
        return this.streams && this.streams.filter((s) => s.video);
    },
  },
  watch: {
    async role(newRole) {
      if (newRole === 'ADMIN' || newRole === 'MODERATOR') {
        // ADMIN, MODERATOR, PRESENTER = do nothing - they can publish anything
        await this.client.setClientRole('host');
      } else if (newRole === 'PRESENTER') {
        // If upgrading from presenter queue and mic / camera are available, go live
        await this.client.setClientRole('host');
        if (this.selectedAudioDevice || this.selectedVideoDevice) {
          await this.publish();
        } else {
          await this.unpublish();
        }
      } else if (newRole === 'SPEAKER') {
        await this.client.setClientRole('host');
        if (this.selectedAudioDevice || this.selectedVideoDevice) {
          await this.publish();
        } else {
          await this.unpublish();
        }
      } else {
        await this.stopPublishing();
        await this.client.setClientRole('audience');
      }
    },
  },
  async mounted() {
    await this.start();
    $bus.$on('requireInteraction', async () => {
      this.showEnableAudioButton = true;
    });
  },
  beforeDestroy() {
    this.unpublish();
  },
  methods: {
    async stopPublishing() {
      await this.unpublish();
      this.streams = this.streams.filter((s) => s.uid !== this.client._uid);
    },
    async handleVideoChange(e) {
      this.selectedVideoDevice = e.device;
      if (this.publishedStream) {
        if (
          this.selectedVideoDevice &&
          this.publishedStream.video &&
          this.publishedStream.video.hasVideo()
        ) {
          this.publishedStream.switchDevice('video', this.selectedVideoDevice);
        } else {
          await this.publish();
        }
      }
    },
    async handleAudioChange(e) {
      this.selectedAudioDevice = e.device;
      if (this.publishedStream) {
        if (
          this.selectedAudioDevice &&
          this.publishedStream.audio &&
          this.publishedStream.audio.hasAudio()
        ) {
          this.publishedStream.switchDevice('audio', this.selectedAudioDevice);
        } else {
          await this.publish();
        }
      }
    },
    async joinChannel() {
      const userId = $auth.getUserId() ? $auth.getUserId() : uuidv4();
      const numericUserId = userId.match(/\d/g).join('').substring(0, 8) * 1;
      const randomUid = numericUserId.toString();
      const resp = await this.$apollo.query({
        query: GetAgoraToken,
        variables: {
          meetingId: this.meeting.id,
          randomUid,
        },
      });
      const token = resp.data.token;
      const channel = this.meeting.id;
      this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
      this.client
        .join(AGORA_APP_ID, channel, token, numericUserId)
        .catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          if (error.code === 'UID_CONFLICT') window.location.reload();
        });
      if (this.canShareAudio || this.canShareVideo)
        await this.client.setClientRole('host');
    },
    async publish() {
      await this.unpublish();
      if (this.canShareVideo && !!this.selectedVideoDevice) {
        this.publishedVideoStream = await AgoraRTC.createCameraVideoTrack({
          cameraId: this.selectedVideoDevice,
          encoderConfig: {
            width: 640,
            height: 480,
          },
        });
      }
      if (this.canShareAudio && !!this.selectedAudioDevice) {
        this.publishedAudioStream = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: this.selectedAudioDevice,
        });
      }
      try {
        const publishArray = [];
        if (this.publishedAudioStream)
          publishArray.push(this.publishedAudioStream);
        if (this.publishedVideoStream)
          publishArray.push(this.publishedVideoStream);
        await this.client.publish(publishArray);
        if (this.publishedVideoStream) {
          this.streams = this.streams.filter((s) => s.uid !== this.client._uid);
          this.publishedStream = {
            uid: this.client._uid,
            video: this.publishedVideoStream,
          };
          this.streams.push(this.publishedStream);
        } else if (this.publishedAudioStream) {
          this.streams = this.streams.filter((s) => s.uid !== this.client._uid);
          this.publishedStream = {
            uid: this.client._uid,
            audio: this.publishedAudioStream,
          };
        }
      } catch (e) {
        console.log('publish failed', e);
      }
    },
    async unpublish() {
      if (this.publishedStream) {
        await this.client.unpublish();
        this.publishedStream = null;
      }
      if (this.publishedVideoStream) {
        this.publishedVideoStream.close();
        this.publishedVideoStream = null;
      }
      if (this.publishedAudioStream) {
        this.publishedAudioStream.close();
        this.publishedAudioStream = null;
      }
    },
    async leave() {
      this.publishedStream = null;
      this.streams = [];
      this.selectedAudioDevice = null;
      this.selectedVideoDevice = null;
      if (this.$refs.streamControl) {
        await this.$refs.streamControl.unpreview();
      }
      return new Promise((resolve, reject) => {
        this.unpublish();
        this.client &&
          this.client.leave(
            () => {
              this.client = null;
              resolve();
            },
            (err) => {
              reject(err);
            }
          );
      });
    },
    removeStream(streamId, mediaType) {
      this.streams = this.streams.map((s) => {
        if (s.uid === streamId) {
          if (mediaType === 'video')
            return {
              ...s,
              video: null,
            };
          if (mediaType === 'audio')
            return {
              ...s,
              audio: null,
            };
        } else return s;
      });
      this.streams = this.streams.filter((s) => s.video || s.audio);
    },
    setupListeners() {
      const client = this.client;
      client.on('user-published', async (user, mediaType) => {
        const subscribtionData = await client.subscribe(user, mediaType);
        let found = false;
        const streamsData = this.streams.map((s) => {
          if (s.uid === user.uid) {
            found = true;
            if (mediaType === 'video')
              return {
                ...s,
                video: subscribtionData,
              };
            if (mediaType === 'audio')
              return {
                ...s,
                audio: subscribtionData,
              };
          } else return s;
        });
        if (found) {
          this.streams = streamsData;
        } else {
          const subscribingStream = {
            uid: user.uid,
          };
          if (mediaType === 'video') subscribingStream.video = subscribtionData;
          if (mediaType === 'audio') subscribingStream.audio = subscribtionData;
          this.streams.push(subscribingStream);
        }
      });
      client.on('user-unpublished', (evt, mediaType) => {
        this.removeStream(evt.uid, mediaType);
      });
    },
    async setRole(role, userId) {
      userId = userId || $auth.getUserId();
      await this.$apollo.mutate({
        mutation: SetMeetingMemberRole,
        variables: {
          meetingId: this.meeting.id,
          userId,
          role,
        },
      });
      $socket.sendRoom('meeting', this.meeting.id, 'meeting-members-modified');
    },
    setUserAttributes() {
      let micStatus = 'OFFLINE';
      if (this.micTested) {
        micStatus = 'READY';
      }
      if (this.publishedStream) {
        micStatus = 'LIVE';
      }
      $socket.setUserAttributes({
        camera: 'OFFLINE',
        mic: micStatus,
        device: 'MOBILE',
      });
      console.log('User Attributes Set OK');
    },
    async start() {
      this.started = true;
      await this.joinChannel();
      this.setupListeners();
      window.onbeforeunload = (e) => {
        if (this.publishedStream) {
          e.preventDefault();
          this.unpublish();
          return 'This will stop publishing';
        }
      };
    },
    enableAudio() {
      this.showEnableAudioButton = false;
      $bus.$emit('resetAudio');
    },
  },
};
</script>
<style>
.meeting-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}
.meeting-publish-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
  box-sizing: content-box;
}
.meeting-publish-panel__publish-btn {
  min-width: 105px;
}
.placeholder {
  display: flex;
  color: white;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.meeting-stream-area {
  position: relative;
  flex: 1;
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto;
}
.meeting-enable-audio-button {
  color: white;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  border: solid 1px var(--cs-gray-03);
  font-size: 14px;
}
.meeting-publish-panel__rtmp-btn {
  margin-right: 50px;
}
</style>
