<template>
  <div class="ch-channel-segment">
    <div v-if="users" class="ch-list">
      <app-channel-circle
        v-for="user in users"
        :key="user.id"
        :user="user"
        :show-button="showButton"
        @refresh-recommendation="$emit('refresh-recommendation')"
      />
    </div>
    <app-spinner v-if="!users" />
  </div>
</template>

<script>
import AppChannelCircle from '@/components/videos/new/ChannelCircleNew.vue';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  components: {
    AppChannelCircle,
    AppSpinner,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  apollo: {
    users: {
      query() {
        return this.query;
      },
    },
  },
  methods: {
    // Invoked from parent
    refresh() {
      this.$apollo.queries.users.refetch();
    },
  },
  mounted() {
    this.$apollo.queries.users.refetch();
  },
};
</script>

<style scoped>
.ch-list {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
}
</style>
