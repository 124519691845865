<template>
  <div class="ch-onboarding-main">
    <ion-slides
      ref="slides"
      pager
      :options="slideOpts"
      @ionSlidesDidLoad="slidesLoaded"
      @ionSlideDidChange="updateIndex"
    >
      <ion-slide>
        <app-slide-content
          title="What is CloutHub?"
          description="CloutHub is the first-ever Social Network that brings people together on a one-stop complete platform to socialize, connect and collaborate on today's trending topics and challenges."
        />
      </ion-slide>
      <ion-slide>
        <app-slide-content
          title="Together We Can Change the World!"
          description="CloutHub is using the power of the internet to bring diverse people together and empowers them to express themselves, organize, debate and influence the issues that impact their lives, communities, society and country."
        />
      </ion-slide>
      <ion-slide>
        <app-slide-content
          title="Protecting Your Privacy, Speech & Health"
          description="Just because you join a social media site doesn't mean you have to give up our right to privacy, speech or mental health. While other plateforms invade your privacy, CloutHub protects your privacy. We support your freedom of speech and we put your mental health above profits."
        />
      </ion-slide>
      <ion-slide>
        <app-slide-content
          title="Play Nice"
          description="The two key planks in CloutHub’s platform are “Bringing People Together” and “Freedom of speech”. Leave your animus at the login page. Come to debate with passion, discuss the tough issues, enjoy civil debate, and let's get back to finding common ground and solutions that enrich lives restores unity and spreads freedom, prosperity and liberty everywhere."
        >
          <cs-button v-router-link="{ name: 'Login' }" size="large" corners="round" block>
            Login
          </cs-button>
        </app-slide-content>
      </ion-slide>
    </ion-slides>

    <!-- Back/Next/Skip Buttons -->
    <cs-button
      v-if="index > 0"
      class="ch-onboarding-main__button ch-onboarding-main__button--back"
      fill="clear"
      @click="back"
    >
      <i class="cs-icons-back-angle-filled"></i>
      Back
    </cs-button>
    <cs-button
      v-if="index < 3"
      class="ch-onboarding-main__button ch-onboarding-main__button--next"
      fill="clear"
      @click="next"
    >
      Next
      <i class="cs-icons-next-angle-filled"></i>
    </cs-button>
    <cs-button
      v-if="index < 3"
      class="ch-onboarding-main__button ch-onboarding-main__button--skip"
      fill="clear"
      variant="default"
      @click="skip"
    >
      Skip
      <i class="cs-icons-skip"></i>
    </cs-button>
  </div>
</template>

<script>
import AppSlideContent from '@/components/onboarding/SlideContent.vue';

export default {
  components: {
    AppSlideContent,
  },
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        slideShadows: true,
      },
      index: 0,
    };
  },
  methods: {
    async slidesLoaded() {
      const swiper = await this.$refs.slides.getSwiper();
      swiper.update();
    },
    async back() {
      this.$refs.slides.slidePrev();
    },
    async next() {
      this.$refs.slides.slideNext();
    },
    async skip() {
      this.$refs.slides.slideTo(3);
    },
    async updateIndex() {
      this.index = await this.$refs.slides.getActiveIndex();
    },
  },
};
</script>

<style>
.ch-onboarding-main {
  background-color: #f7f7f7;
}
.ch-onboarding-main ion-slides {
  height: 100vh;
  width: 100vw;
  --bullet-background: var(--cs-primary-base);
  --bullet-background-active: var(--cs-primary-base);
}
.swiper-pagination-bullet {
  opacity: 0.3;
  background: var(--bullet-background);
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--bullet-background-active);
}

.ch-onboarding-main__button {
  position: absolute;
  z-index: 1;
  text-transform: var(--cs-transform-upper);
}
.ch-onboarding-main__button i {
  font-size: 12px !important;
}
.ch-onboarding-main__button--back {
  bottom: 0;
  left: 0;
}
.ch-onboarding-main__button--next {
  bottom: 0;
  right: 0;
}
.ch-onboarding-main__button--skip {
  top: 0;
  right: 0;
}
</style>
