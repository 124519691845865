<template>
  <div>
    <app-spinner
      v-if="$apollo.queries.posts.loading && !refreshing"
    ></app-spinner>
    <app-news-item
      v-for="post in posts"
      :key="post.id"
      :post="post"
    ></app-news-item>
    <cs-empty-state v-if="posts && !posts.length" :description="placeholder" />
    <app-infinite-simple
      v-if="paginateByTime"
      :query="$apollo.queries.posts"
      sort-key="createdAt"
    ></app-infinite-simple>
    <app-infinite-offset
      v-if="!paginateByTime"
      :query="$apollo.queries.posts"
    ></app-infinite-offset>
  </div>
</template>
<script>
import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';
import AppNewsItem from '@/components/news/NewsItem.vue';
import AppSpinner from '@/components/general/Spinner.vue';

export default {
  components: {
    AppNewsItem,
    AppSpinner,
    AppInfiniteSimple,
    AppInfiniteOffset,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    variables: {
      type: Object,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'No matching articles',
    },
  },
  data() {
    return {
      refreshing: false,
    };
  },
  apollo: {
    posts: {
      query() {
        return this.query;
      },
      variables() {
        return this.variables;
      },
    },
  },
  computed: {
    paginateByTime() {
      // Category and Latest views paginate by time
      return !!(
        this.query.ListNews ||
        this.query.ListNewsLatest ||
        this.query.ListFollowingNews
      );
    },
  },
  methods: {
    async refresh() {
      this.refreshing = true;
      await this.$apollo.queries.posts.refetch();
    },
  },
};
</script>
