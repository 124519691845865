<template>
  <div class="categories">
    <router-link
      v-for="cat in categories"
      :key="cat.value"
      :to="{ name: 'GroupsCategory', params: { category: cat.value } }"
      class="category-item"
    >
      <app-img :src="cat.img" auto-optimize class="category-item-img" />
      <span class="category-item-label">
        {{ cat.label }}
      </span>
    </router-link>
  </div>
</template>

<script>
import AppImg from '@/components/general/Img.vue';
import categories from '@/assets/config/groupcategories.json';

export default {
  components: {
    AppImg,
  },
  data() {
    return {
      categories,
    };
  },
};
</script>

<style scoped>
.categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.category-item {
  overflow: hidden;
  position: relative;
  display: block;
  flex: 0 0 110px;
  height: 90px;
  margin: 5px;
  border-radius: 4px;
}

.category-item-img {
  height: 100%;
  width: 100%;
}

.category-item-label {
  position: absolute;
  bottom: 7px;
  left: 7px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}
</style>
