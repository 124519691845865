<template>
  <div>
    <i class="cs-icons-options text-light context-menu-button" ref="menuButton" tabindex="-1"></i>
    <b-popover :target="$refs.menuButton" placement="bottomright" triggers="click blur" ref="menuPopover"
      v-if="enableMenu"
    >
      <slot></slot>
    </b-popover>
  </div>
</template>
<script>
export default {
  data() {
    return {
      enableMenu: false,
    };
  },
  mounted() {
    this.enableMenu = true; // Quirk for using b-popover with refs
  },
};
</script>
<style scoped>
  .context-menu-button
  {
    cursor:pointer;
  }
</style>
