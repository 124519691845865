<template>
  <action-sheet class="ch-report__actionsheet" :open="showModal" @close="close">
    <template v-if="showModal">
      <app-report-user
        v-if="reportType == 'user'"
        :report="report"
        @close="close()"
      />
      <app-report-post
        v-if="
          reportType == 'post' ||
          reportType == 'comment' ||
          reportType === 'video'
        "
        :report-type="reportType"
        :report="report"
        @close="close()"
      />
      <app-report-own-video
        v-if="reportType == 'own-video'"
        :report-type="reportType"
        :report="report"
        @close="close()"
      />
      <app-report-group
        v-if="reportType == 'group'"
        :report="report"
        @close="close()"
      />
    </template>
  </action-sheet>
</template>

<script>
import $bus from '@/services/bus';
import ActionSheet from '@/components/general/ActionSheet.vue';
import AppReportUser from '@/components/general/report/ReportUser.vue';
import AppReportPost from '@/components/general/report/ReportPost.vue';
import AppReportOwnVideo from '@/components/general/report/ReportOwnVideo.vue';
import AppReportGroup from '@/components/general/report/ReportGroup.vue';

export default {
  components: {
    ActionSheet,
    AppReportUser,
    AppReportPost,
    AppReportGroup,
    AppReportOwnVideo,
  },
  data() {
    return {
      showModal: false,
      reportType: null,
      report: null,
    };
  },
  mounted() {
    $bus.$on('show-report-action', ({ reportType, report }) => {
      this.reportType = reportType;
      this.report = report;
      this.showModal = true;
    });
  },
  methods: {
    close() {
      this.showModal = false;
    },
  },
};
</script>
