<template>
  <div id="usernamesList" class="autocomplete-results">
    <div v-if="notBlockedUsers">
      <div
        v-for="user in userList"
        :key="user.id"
        class="autocomplete-result"
        @click="$emit('mention-user', user)"
      >
        <app-avatar
          :picture="user.profilePhotoURL"
          :name="user.displayname"
          size="small"
        />
        <span class="searched-user-detail">{{ user.username }}</span>
      </div>
    </div>
    <cs-empty-state v-else description="No Matching user" />
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    userList: Array,
  },
  computed: {
    notBlockedUsers() {
      return this.userList && this.userList.length;
    },
  },
};
</script>

<style scoped>
.autocomplete-results {
  display: flex;
  position: relative;
  padding: 10px;
  margin: 0;
  border: 1px solid var(--cs-secondary-lightest);
  min-width: 100px;
  overflow: auto;
  z-index: 999;
  max-height: 200px;
  position: relative;
  background: var(--cs-secondary-contrast);
}
.autocomplete-result {
  display: flex;
  padding-bottom: 5px;
}

.searched-user-detail {
  padding-left: 10px;
}
</style>
