<template>
  <cs-page-tabs
    :key="'ch-tabs-' + tabKey"
    :tabs="tabs"
    :value="value"
    align="justify"
    no-border
    @input="onInput"
  />
</template>

<script>
import * as uuid from 'uuid';
import $bus from '@/services/bus';

export default {
  props: {
    tabs: {
      type: Array,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabKey: uuid.v4(),
    };
  },
  watch: {
    tabs() {
      this.tabKey = uuid.v4();
    },
    value() {
      const tabInfo = {
        route: this.$route,
        index: this.value,
      };
      $bus.$emit('ch-tabs-enter', tabInfo);
    },
  },
  mounted() {
    const eventID = `ch-tabs-restore-${uuid.v4()}`;
    $bus.$once(eventID, ($event) => {
      if ($event) {
        this.$el.setAttribute('data-tab-index', $event);
        this.$emit('input', $event);
      }
    });
    $bus.$emit('ch-tabs-mounted', { route: this.$route, eventID });
  },
  methods: {
    async onInput($event) {
      try {
        if (this.value === $event) {
          $bus.$emit('ch-tabs-scroll-top', { route: this.$route });
        } else {
          const ionPage = findAncestor(this.$el, '.ion-page');
          const ionContent = ionPage.querySelector('ion-content');
          const scrollEl = await ionContent.getScrollElement();
          const tabInfo = {
            route: this.$route,
            index: this.value,
            scrollTop: scrollEl.scrollTop,
          };
          $bus.$emit('ch-tabs-leave', tabInfo);
        }
      } catch (e) {
        console.error('TAB INPUT ERROR:', e);
      }
      this.$el.setAttribute('data-tab-index', $event);
      this.$emit('input', $event);
    },
  },
};

// https://stackoverflow.com/a/27037567
function findAncestor(el, sel) {
  while (
    (el = el.parentElement) &&
    !(el.matches || el.matchesSelector).call(el, sel)
  );
  return el;
}
</script>
