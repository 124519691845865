<template>
  <app-modal
    :show="showModal"
    class="ch-share-modal"
    title="Share Post"
    :class="{ 'ch-custom-modal': isMeeting }"
    action="Share"
    :disable-action="saving || isBlocked"
    @close="close()"
    @action="share()"
  >
    <cs-spinner v-if="saving" />
    <div v-if="targetPost && user && !saving" class="ch-share-modal__content">
      <div class="ch-share-modal__composer">
        <app-post-composer-text
          :is-user-location="false"
          @textHandle="textHandle"
          @handleTags="handleTags"
          @blockPosting="blockPosting"
        />
      </div>
      <!-- Tags -->
      <div v-if="tags.length" class="ch-posts-tags">
        <cs-tag
          v-for="(tag, $index) in tags"
          :key="tag + $index"
          class="ch-posts-tags__tag"
        >
          <span>#{{ tag }}</span>
        </cs-tag>
      </div>
      <app-post-card :post="targetPost" :preview="true" />
    </div>
  </app-modal>
</template>

<script>
// JS
import $auth from '@/services/auth';
import $bus from '@/services/bus';
import $postsApi from '@/api/posts';
import $popups from '@/services/popups';
// Components
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppPostComposerText from '@/components/post/composer/PostComposerText.vue';
import AppModal from '@/components/general/Modal.vue';
// GQL
import InsertPost from '@/gql/posts/InsertPost.gql';
import ListUserPosts from '@/gql/posts/ListUserPosts.gql';

export default {
  components: {
    AppPostCard,
    AppPostComposerText,
    AppModal,
  },
  props: {
    isMeeting: {
      type: Boolean,
      default: false,
    },
    postLink: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      post: null,
      tags: [],
      shareDraftPost: '',
      saving: false,
      user: null,
      isBlocked: false,
    };
  },
  computed: {
    noOfChars() {
      if (this.user) {
        const characters = this.shareDraftPost.length;
        return characters;
      }
      return 0;
    },
    targetPost() {
      if (this.post && this.post.sharedPost) {
        return this.post.sharedPost;
      }
      return this.post;
    },
  },
  watch: {
    isMeeting() {
      this.meetingConfig();
    },
  },
  async mounted() {
    this.user = await $auth.getUser();
    $bus.$on('show-share-post-modal', (post) => {
      this.showModal = true;
      this.post = post;
    });
  },
  methods: {
    blockPosting(blocked) {
      this.isBlocked = blocked;
    },
    close() {
      this.shareDraftPost = '';
      this.showModal = false;
    },
    meetingConfig() {
      this.showModal = true;
      this.post = this.postLink;
      this.title = 'Share Event';
    },
    textHandle(body) {
      this.shareDraftPost = body;
    },
    handleTags(tags) {
      this.tags = tags;
    },
    share() {
      if (this.isMeeting) {
        this.saving = true;
        const post = {
          ...this.post,
          bodyText: this.shareDraftPost ? this.shareDraftPost : '',
        };
        this.shareMeetingLink(post);
      } else {
        const post = {
          sharedPostId: this.targetPost.id,
          bodyText: this.shareDraftPost ? this.shareDraftPost : '',
        };
        this.shareThisPost(post);
      }
    },
    async shareMeetingLink(post) {
      try {
        if (this.isMeeting) {
          await $postsApi.createPost(post);
          this.saving = false;
          this.close();
          this.$emit('close-share-post');
        }
      } catch (e) {
        $popups.alert({ title: 'Error Sharing', message: e.message });
        this.$emit('saving', false);
        this.saving = false;
      }
      const toastText = 'Event shared';
      this.$toast.open({
        message: toastText,
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'top',
      });
    },
    cacheInsertPost(cache, query, params, post) {
      const cachedData = cache.readQuery({
        query,
        variables: params,
      });
      if (cachedData && cachedData.posts) {
        cache.writeQuery({
          query,
          variables: params,
          data: {
            posts: [post, ...cachedData.posts],
          },
        });
      }
    },
    async shareThisPost(post) {
      this.saving = true;
      await this.$apollo.mutate({
        mutation: InsertPost,
        variables: post,
        update: (cache, mutationResponse) => {
          cache.modify({
            id: cache.identify(this.targetPost),
            fields: {
              sharedPostCount(existingFieldData) {
                return parseInt(existingFieldData, 10) + 1;
              },
            },
          });
          this.cacheInsertPost(
            cache,
            ListUserPosts,
            {
              id: $auth.getUserId(),
            },
            mutationResponse.data.create_post
          );
          // Disabled to minimize share jumping
          // this.cacheInsertPost(cache, ListPublicPosts, {
          //   room: this.room,
          // }, mutationResponse.data.create_post);
        },
      });
      this.$toast.open({
        message: 'Shared',
        type: 'success',
        duration: 1000,
        dismissible: true,
        position: 'bottom',
      });
      this.saving = false;
      this.close();
    },
  },
};
</script>

<style scoped>
.ch-posts-tags {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: 7px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}
.ch-posts-tags__tag {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.ch-custom-modal >>> .ch-modal__content {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
