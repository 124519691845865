<template>
  <ion-page>
    <ion-header>
      <app-category-header title="My Bookmarked Posts" notifications />
    </ion-header>
    <ion-content>
      <div>
        <div v-if="posts && posts.length > 0">
          <app-post-card v-for="post in posts" :key="post.id" :post="post" />
        </div>
        <div v-if="posts && posts.length < 1">
          <cs-empty-state title="No Posts to display"></cs-empty-state>
        </div>
      </div>
      <app-infinite-offset :query="$apollo.queries.posts" />
    </ion-content>
  </ion-page>
</template>

<script>
// gql
import ListBookmarkedPosts from '@/gql/posts/ListBookmarkedPosts.gql';

// component
import AppPostCard from '@/components/post/items/PostCard.vue';
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

export default {
  components: {
    AppPostCard,
    AppCategoryHeader,
    AppInfiniteOffset,
  },
  apollo: {
    posts: {
      query: ListBookmarkedPosts,
    },
  },
  async mounted() {
    await this.$apollo.queries.posts.refetch();
  },
};
</script>
