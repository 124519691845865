<template>
  <ion-page>
    <ion-header>
      <div class="ch-notification">
        <div class="ch-notification__header">
          <app-category-header title="Requests" />
        </div>
      </div>
    </ion-header>
    <ion-content>
      <app-pull-to-refresh @refresh="refresh" />
      <div>
        <app-request-list ref="request-list" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppCategoryHeader from '@/components/general/CategoryHeader.vue';
import AppPullToRefresh from '@/components/general/PullToRefresh.vue';
import AppRequestList from '@/components/menu/requests/RequestList.vue';

export default {
  components: {
    AppCategoryHeader,
    AppPullToRefresh,
    AppRequestList,
  },
  methods: {
    async refresh($event) {
      await Promise.all([this.$refs['request-list'].refresh()]);
      $event.target.complete();
    },
  },
};
</script>

<style>
ion-content {
  --background: var(--cs-gray);
}
</style>
