<template>
  <app-modal
    class="signup-meeting-modal"
    :show="showSignin"
    title="Signin"
    @close="onClose"
  >
    <div class="ch-login">
      <div>
        <div class="ch-login__img-class">
          <img src="@/assets/logo/clouthub-logo.png" />
        </div>
        <app-register-subheader
          title="Welcome"
          description="Login with your email and password"
        />
        <cs-spinner v-if="loading" />
        <form v-if="!loading" class="ch-login__fields">
          <cs-input
            v-model="email"
            label="Email"
            type="text"
            required
            placeholder="Email Address"
          />
          <app-password-input v-model="password" label="Password" required />
          <div>
            <vue-recaptcha
              ref="recaptcha"
              size="invisible"
              :sitekey="sitekey"
              @verify="login"
              @expired="onCaptchaExpired"
            >
              <cs-button block corners="round" size="medium">
                Continue
              </cs-button>
            </vue-recaptcha>
          </div>
        </form>
      </div>
      <!-- OTP modal -->
      <app-verify-email-modal
        v-if="showVerifyModal"
        :show-verify-modal="showVerifyModal"
        :email="email"
        @close-verify-modal="closeVerifyModal"
      />
    </div>
  </app-modal>
</template>

<script>
import AppModal from '@/components/general/Modal.vue';
import Login from '@/gql/general/Login.gql';
import CheckEmailVerification from '@/gql/auth/CheckEmailVerification.gql';
import AppRegisterSubheader from '@/components/register/Subheader.vue';
import AppPasswordInput from '@/components/general/PasswordInput.vue';
import AppVerifyEmailModal from '@/components/onboarding/VerifyEmailModal.vue';
import { gReCaptchaSiteKey } from '@/constants';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    AppModal,
    AppRegisterSubheader,
    AppPasswordInput,
    AppVerifyEmailModal,
    VueRecaptcha,
  },
  props: {
    showSignin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sitekey: gReCaptchaSiteKey,
      email: localStorage.email,
      errMsg: null,
      loading: false,
      rememberUser: !!localStorage.email,
      password: localStorage.password || '',
      showOnLoad: false,
      showVerifyModal: false,
      token: null,
    };
  },
  methods: {
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onClose() {
      this.$emit('close-signin');
    },
    async login(recaptchaToken) {
      this.errMsg = null;
      localStorage.clear();
      if (this.email && this.password) {
        if (this.rememberUser) {
          localStorage.email = this.email.trim();
          localStorage.password = this.password.trim();
        }
        try {
          this.loading = true;
          const resp = await this.$apollo.query({
            query: Login,
            variables: {
              email: this.email.trim(),
              password: this.password.trim(),
              recaptchaToken,
            },
          });
          this.token = resp.data.token;
          this.checkIfEmailVerified();
        } catch (err) {
          this.showOnLoad = true;
          this.loading = false;
          alert(err.message);
        }
      }
    },
    async checkIfEmailVerified() {
      const response = await this.$apollo.query({
        query: CheckEmailVerification,
        variables: {
          email: this.email.trim(),
        },
      });
      if (response.data.verified) {
        this.redirectToApp();
      } else {
        this.loading = false;
        this.showVerifyModal = true;
      }
    },
    async redirectToApp() {
      this.loading = true;
      localStorage.access_token = this.token;
      this.loggedIn();
    },
    closeVerifyModal(verified) {
      this.showVerifyModal = false;
      if (verified) {
        this.redirectToApp();
      }
    },
    loggedIn() {
      this.$emit('logged-in');
      this.onClose();
    },
  },
};
</script>

<style scoped>
.ch-login {
  display: flex;
  flex-direction: column;
  align-content: center;
  background: #f7f7f7;
  position: relative;
  padding: 15px;
}
.ch-login__img-class {
  width: 150px;
  margin: 50px auto 0px;
}
.ch-login__img-class > img {
  width: 250px;
}
.ch-login__fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
}
</style>
