<template>
  <app-modal
    :key="showModal"
    :show="showModal"
    class="ch-gif-modal"
    title="Select GIFs"
    @close="close()"
  >
    <div class="ch-gifs-body">
      <cs-search-bar placeholder="Search all the GIFs" @input="onInput"></cs-search-bar>
      <div v-if="gifs" class="ch-gifs-body__content">
        <div class="ch-gifs-images">
          <app-img
            v-for="gif in gifs"
            :key="gif.id"
            :src="gif.url"
            class="ch-gif-img"
            @click="gifSelect(gif)"
          />
        </div>
        <cs-empty-state v-if="gifs.length === 0" description="No results" />
      </div>
      <div v-if="!gifs">
        <cs-spinner class="ch-spinner"></cs-spinner>
      </div>
    </div>
  </app-modal>
</template>

<script>
import $bus from '@/services/bus';
import AppImg from '@/components/general/Img.vue';
import AppModal from '@/components/general/Modal.vue';
import SearchGiphy from '@/gql/general/SearchGiphy.gql';

export default {
  components: {
    AppImg,
    AppModal,
  },
  data() {
    return {
      showModal: false,
      offset: 0,
      search: 'trending',
    };
  },
  apollo: {
    gifs: {
      query: SearchGiphy,
      variables() {
        return {
          query: this.search,
          offset: this.offset,
        };
      },
    },
  },
  mounted() {
    $bus.$on('show-select-gif-modal', () => {
      this.showModal = true;
    });
  },
  methods: {
    close() {
      this.search = 'trending';
      this.showModal = false;
    },
    onInput(query) {
      this.search = query;
    },
    gifSelect(gif) {
      $bus.$emit('gif-modal-select', gif);
      this.close();
    },
  },
};
</script>

<style scoped>
.body-header-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--cs-gray-02);
}
.cs-icons {
  font-size: 20px;
}
.cs-button-close {
  --cs-button-padding: 0px;
}
.title {
  margin: 0 auto;
}
.ch-gifs-title {
  font-size: 16px;
  font-weight: bold;
}
.ch-gifs-body {
  width: 100%;
}
.ch-gifs-body__content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-y: auto;
}
.ch-gifs-images {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
}
.ch-gif-img {
  height: 120px;
  width: 120px;
  border-radius: 3px;
  object-fit: contain;
}
</style>
