<template>
  <cs-button
    size="small"
    class="ch-profile__button btn-class"
    variant="default"
    @click="goToMyChannel()"
    >My Channel</cs-button
  >
</template>

<script>
import $auth from '@/services/auth';

export default {
  methods: {
    goToMyChannel() {
      this.$router.push({
        name: 'VideoProfile',
        params: {
          id: $auth.getUserId(),
        },
      });
    },
  },
};
</script>

<style scoped>
.btn-class {
  --cs-button-color: #747577 !important;
}
</style>
