<template lang="html">
  <span hidden></span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'alert',
      validator(x) {
        return ['alert', 'confirm', 'prompt'].indexOf(x) > -1;
      },
    },
    variant: {
      type: String,
      default: 'primary',
      validator(x) {
        return ['primary', 'secondary', 'default', 'info', 'success', 'warning', 'danger'].indexOf(x) > -1;
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    okLabel: {
      type: String,
      default: 'OK',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    isAlert() {
      return this.type === 'alert';
    },
    isConfirm() {
      return this.type === 'confirm';
    },
    isPrompt() {
      return this.type === 'prompt';
    },
    buttons() {
      if (this.isAlert) {
        return [this.okButton];
      } if (this.isConfirm) {
        return [this.cancelButton, this.okButton];
      } if (this.isPrompt) {
        return [this.cancelButton, this.okButton];
      }
      return [this.okButton];
    },
    okButton() {
      return {
        text: this.okLabel,
        role: 'ok',
        cssClass: `ch-popup-variant--${this.variant}`,
      };
    },
    cancelButton() {
      return {
        text: this.cancelLabel,
        role: 'cancel',
        cssClass: 'ch-popup-variant--default',
      };
    },
    inputs() {
      if (this.isPrompt) {
        return [
          {
            type: 'text',
            value: this.promptValue,
            placeholder: this.placeholder,
          },
        ];
      }
      return [];
    },
  },
  data() {
    return {
      promptValue: this.value,
      popup: null,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.close();
    },
    close() {
      this.popup = null;
      this.promptValue = '';
      this.$emit('close');
    },
    submit() {
      this.isPrompt ? this.$emit('ok', this.promptValue) : this.$emit('ok');
      this.close();
    },
  },
  watch: {
    async show() {
      if (this.show && !this.popup) {
        const options = {
          header: this.title,
          message: this.message,
          buttons: this.buttons,
          inputs: this.inputs,
          backdropDismiss: false,
        };
        this.popup = await this.$ionic.alertController.create(options);
        this.popup.onDidDismiss().then((result) => {
          if (result && result.role === 'ok') {
            if (result.data && result.data.values) this.promptValue = result.data.values[0];
            this.submit();
          } else {
            this.cancel();
          }
        });
        this.popup.present();
      } else if (!this.show && this.popup) {
        this.popup.dismiss();
      }
    },
  },
};
</script>

<style lang="css" scoped>
.popup__input {
  width: 100%;
}
.popup__cancel-button {
  margin-right: 8px;
}
</style>
