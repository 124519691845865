<template>
  <portal to="action-sheets">
    <app-action-sheet :open="open" @close="$emit('close')">
      <app-actions-title>Sort Groups</app-actions-title>
      <app-config-item
        :icon="layers"
        :active="value === SortBy.name"
        label="Groups A-Z"
        @click="$emit('input', SortBy.name)"
      />
      <app-config-item
        :icon="clock"
        :active="value === SortBy.joined"
        label="Recently Joined"
        @click="$emit('input', SortBy.joined)"
      />
    </app-action-sheet>
  </portal>
</template>

<script>
import AppActionSheet from '@/components/general/ActionSheet.vue';
import AppConfigItem from '@/components/general/ConfigItem.vue';
import AppActionsTitle from '@/components/general/ActionsTitle.vue';

import layers from '@/assets/icons/layers.svg';
import clock from '@/assets/icons/clock.svg';

const SortBy = {
  name: 'name',
  joined: 'joined',
};

export default {
  components: { AppActionsTitle, AppActionSheet, AppConfigItem },
  props: {
    value: {
      type: String,
      required: true,
      default: SortBy.joined,
    },
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return { layers, clock, SortBy };
  },
};
</script>

<style scoped></style>
