<template>
  <div class="group-members-list">
    <cs-search-bar
      v-if="showSearchBar"
      placeholder="Search members"
      @input="getSearchMemberInput"
    />
    <div v-for="member in members" :key="member.id" class="member-item">
      <app-avatar
        :picture="member.user.profilePhotoURL"
        :name="member.user.displayname"
        class="member-item-avatar"
        size="small"
        @click="viewMember(member)"
      />
      <div class="member-item-names" @click="viewMember(member)">
        <div class="cs-textstyle-paragraph-bold member-item-name">
          <app-username :user="member.user" type="displayname" />
        </div>
        <div class="ch-textstyle-paragraph-extra-small member-item-username">
          {{ member.user.username }}
        </div>
      </div>
      <app-group-member-menu
        class="member-item-menu"
        :group="group"
        :member="member"
      />
    </div>
    <app-infinite-offset
      v-if="showInfiniteLoading"
      :query="$apollo.queries.members"
    ></app-infinite-offset>
  </div>
</template>

<script>
import ListGroupMembers from '@/gql/groups/ListGroupMembers.gql';
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';
import AppAvatar from '@/components/general/Avatar.vue';
import AppUsername from '@/components/general/Username.vue';
import AppGroupMemberMenu from '@/components/groups/GroupMemberMenu.vue';

export default {
  components: {
    AppGroupMemberMenu,
    AppInfiniteOffset,
    AppAvatar,
    AppUsername,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchMemberInput: '',
    };
  },
  apollo: {
    members: {
      query: ListGroupMembers,
      variables() {
        return {
          id: this.group.id,
          role: this.role,
          searchMemberInput: this.searchMemberInput,
        };
      },
      result() {
        if (this.members) {
          this.$emit('member-count', this.members.length);
        } else {
          this.$emit('member-count', 0);
        }
      },
      skip() {
        return (
          this.searchMemberInput.length > 0 && this.searchMemberInput.length < 3
        );
      },
    },
  },
  computed: {
    showSearchBar() {
      return this.role === 'member';
    },
    showInfiniteLoading() {
      return this.searchMemberInput.length === 0;
    },
  },
  methods: {
    viewMember(member) {
      this.$router.push({ name: 'Profile', params: { id: member.user.id } });
    },
    getSearchMemberInput(input) {
      this.searchMemberInput = input;
    },
  },
};
</script>

<style scoped>
.member-item {
  display: flex;
  padding: 10px 20px;
  align-items: center;
}
.member-item-avatar {
  margin-right: 10px;
}
.member-item-names {
  flex: 1;
}
.member-item-name {
  color: var(--cs-gray-06);
}
.member-item-username {
  color: var(--cs-gray-05);
}
.member-item-menu {
  margin-left: 10px;
  padding: 10px;
  margin-right: -10px;
}
</style>
