<template>
  <div ref="liveMessages" class="live-messages" @scroll="onScroll">
    <app-meeting-chat-message
      v-for="message in messages"
      :key="message.id"
      :message="message"
      :post-id="post.id"
      :is-author="isAuthor"
      @click="$emit('showProfile', message.postedByUser)"
      @refresh="emitRefresh"
    ></app-meeting-chat-message>
    <app-infinite-simple
      :query="$apollo.queries.messages"
      sort-key="createdAt"
    ></app-infinite-simple>
  </div>
</template>
<script>
import $bus from '@/services/bus';
import $auth from '@/services/auth';
import $socket from '@/services/socket';

import AppInfiniteSimple from '@/components/general/InfiniteSimple.vue';
import AppMeetingChatMessage from '@/components/meetings/MeetingChatMessage.vue';

import ListPostMessages from '@/gql/live/ListPostMessages.gql';

export default {
  components: {
    AppInfiniteSimple,
    AppMeetingChatMessage,
  },
  props: {
    post: {
      type: Object,
      required: true,
    }
  },
  apollo: {
    messages: {
      query: ListPostMessages,
      variables() {
        return {
          postId: this.post.id,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    messages() {
      this.scrollToBottomOnMessages();
    },
  },
  computed: {
    isAuthor() {
      return this.post && this.post.user.id === $auth.getUserId();
    },
  },
  async mounted() {
    $socket.onRoom('post', this.post.id, 'post-chat-modified', () => {
      this.$apollo.queries.messages.refetch();
    });
  },
  methods: {
    async emitRefresh() {
      this.$emit('refresh');
    },
    scrollToBottomOnMessages(e) {
      const el = this.$refs.liveMessages;
      if (el.scrollTop <= 1 && el.scrollTop >= -50) {
        this.$nextTick(() => {
          el.scrollTop = 1;
        });
      }
    },
    onScroll(e) {
      if (e.target.scrollTop <= 1 && e.target.scrollTop >= -250) {
        $bus.$emit('video-detail-live-messages-scrolled-bottom');
      } else {
        $bus.$emit('video-detail-live-messages-scroll-up')
      }
    },
  },
}
</script>
<style>
.live-messages .ch-infinite-loader {
  visibility: hidden;
  min-height: 20px;
}
</style>
<style scoped>
.live-messages {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  padding: 10px 15px 60px;
}
</style>