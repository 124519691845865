<template>
  <div>
    <template v-if="displayGroups">
      <app-linear-group-list :groups="displayGroups" />
    </template>
    <app-infinite-offset :limit="50" :query="$apollo.queries.groups" />
  </div>
</template>

<script>
// GQL
import ListRecommendedGroups from '@/gql/groups/ListRecommendedGroups.gql';

// General components
import AppInfiniteOffset from '@/components/general/InfiniteOffset.vue';

// Group common components
import AppLinearGroupList from '@/components/groups/LinearGroupList.vue';

export default {
  apollo: {
    groups: {
      query: ListRecommendedGroups,
      fetchPolicy: 'network-only',
      variables: {
        limit: 50,
      },
    },
  },
  components: {
    AppInfiniteOffset,
    AppLinearGroupList,
  },
  computed: {
    displayGroups() {
      return this.groups && this.groups.filter((group) => !group.parentGroupId);
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.groups.refetch();
    },
  },
};
</script>
