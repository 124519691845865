<template>
  <ion-page>
    <div class="ch-reset-pw">
      <div class="ch-reset-pw__img-class">
        <img src="@/assets/logo/clouthub-logo.png" />
      </div>
      <cs-spinner v-if="sending"></cs-spinner>
      <div class="ch-reset-pw__fields">
        <div v-if="!sending && !sent">
          <div class="ch-reset-pw__title">Reset your Password</div>
          <p class="ch-reset-pw__description">
            Please enter your email address to receive a password reset link.
          </p>
          <form @submit="sendVerificationCode()">
            <div class="ch-reset-pw__options">
              <cs-input v-model="resetEmail" type="email" placeholder="Enter your email">
              </cs-input>
            </div>
          </form>
        </div>
        <div v-if="sent" class="cs-textstyle-paragraph-bold ch-reset-pw__inform">
          An email with the reset password link has been sent to your address.
        </div>
        <cs-button
          v-if="!sending && !sent"
          block
          corners="rounded"
          :disabled="disableContinue"
          @click="sendVerificationCode"
        >
          Continue</cs-button
        >
      </div>
      <div class="Ch-buttons">
        <div class="cs-textstyle-paragraph-bold ch-reset-pw__back">
          <router-link to="/login"><i class="cs-icons-back-angle"></i>BACK</router-link>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import $popups from '@/services/popups';
import SendPasswordReset from '@/gql/auth/SendPasswordReset.gql';

export default {
  data() {
    return {
      resetEmail: '',
      sending: false,
      sent: false,
    };
  },
  computed: {
    disableContinue() {
      return !this.resetEmail;
    },
  },
  methods: {
    async sendVerificationCode() {
      // const next = (window.location.href.replace('/resetpassword', '/setnewpassword/'));
      const next = 'https://m.clouthub.com/#/setnewpassword/';
      this.sending = true;
      const variables = {
        email: this.resetEmail.trim(),
        next,
      };
      try {
        await this.$apollo.mutate({
          mutation: SendPasswordReset,
          variables,
        });
        this.sending = false;
        this.sent = true;
      } catch (err) {
        $popups.alert(err.message);
        this.sending = false;
      }
    },
  },
};
</script>

<style scoped>
.ch-reset-pw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  background: #f7f7f7;
}
.ch-reset-pw__img-class {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0px auto;
  margin-top: -70px;
  margin-bottom: 70px;
}
.ch-reset-pw__img-class > img {
  width: 250px;
}
.ch-reset-pw__fields {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  gap: 0px;
}
.ch-reset-pw__back {
  position: absolute;
  left: 20px;
  bottom: 10px;
  margin: auto;
  text-align: center;
}
a {
  color: #f06027;
  text-decoration: none;
}
.ch-reset-pw__description {
  padding: 0px 10px 15px 10px;
  text-align: center;
  font-size: 16px;
  color: #4a4a4a;
}
.ch-reset-pw__title {
  margin-top: 20px;
  padding: 0px 30px 0px 30px;
  font-size: 22px;
  color: #f06027;
  font-weight: bold;
  text-align: center;
}
.ch-reset-pw__options {
  margin-bottom: 20px;
}
.ch-reset-pw__inform {
  text-align: center;
  align-self: center;
  width: 70%;
  margin-bottom: 20px;
}
</style>
